import React, { useRef, useEffect } from 'react';
import cx from 'classnames';

import styles from './TextInput.module.scss';

import Text, { TextProps } from '../Text';
import Icon, { IconProps } from '../Icon';

export type TextInputTypeType =
  | 'Text'
  | 'Password'
  | 'TextArea'
  | 'Default'
  | 'Filled'
  | 'Selected'
  | 'Error';
export type TextInputStyleType = 'Default' | 'LeadingIcon';

export const defaultProps = {
  type: 'Text' as TextInputTypeType,
  style: 'Default' as TextInputStyleType,
  text: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
};

export type TextInputProps = {
  type?: TextInputTypeType;
  style?: TextInputStyleType;
  textValue?: string;
  textPlaceholder?: string;
  onTextChanged?: (
    event: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
  className?: string;
  text?: TextProps;
  icon?: IconProps;
  showPassword?: boolean;
  focused?: boolean;
  rows?: number;
  maxLength?: number;
  disabled?: boolean;
};

const TextInput: React.FC<TextInputProps> = ({
  type,
  style,
  textValue,
  textPlaceholder,
  onTextChanged,
  className,
  text,
  icon,
  showPassword,
  focused,
  rows,
  maxLength,
  disabled,
  ...props
}) => {
  const currentStyle = styles[`textInput${type}${style}`];

  let textView;
  let iconView;
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focused && ref.current) {
      ref.current.focus();
    }
  }, [focused]);

  switch (type) {
    case 'Text':
      if(style === 'LeadingIcon') {
        iconView = <Icon className={styles.icon} {...icon} />;
      }
      textView = (
        <input
          placeholder={textPlaceholder}
          value={textValue}
          onChange={onTextChanged}
          className={styles.text}
          ref={ref}
          disabled={disabled}
          {...props}
        />
      );
      break;
    case 'Password':
      textView = (
        <input
          placeholder={textPlaceholder}
          value={textValue}
          type={showPassword === true ? 'text' : 'password'}
          onChange={onTextChanged}
          className={styles.text}
          disabled={disabled}
          ref={ref}
        />
      );

      iconView = <Icon className={styles.icon} {...icon} />;
      break;
    case 'TextArea':
      textView = (
        <textarea
          placeholder={textPlaceholder}
          value={textValue}
          onChange={onTextChanged}
          className={styles.text}
          rows={rows}
          maxLength={maxLength}
          disabled={disabled}
        />
      );
      break;
    case 'Default':
      textView = <Text className={styles.text} {...text} />;
      iconView = <Icon className={styles.icon} {...icon} />;
      break;
    case 'Filled':
      textView = <Text className={styles.text} {...text} />;
      iconView = <Icon className={styles.icon} {...icon} />;
      break;
    case 'Selected':
      textView = <Text className={styles.text} {...text} />;
      iconView = <Icon className={styles.icon} {...icon} />;
      break;
    case 'Error':
      textView = <Text className={styles.text} {...text} />;
      iconView = <Icon className={styles.icon} {...icon} />;
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {style === 'LeadingIcon' ? iconView : textView}
      {style === 'LeadingIcon' ? textView : iconView}
    </div>
  );
};

TextInput.defaultProps = defaultProps;

export default TextInput;
