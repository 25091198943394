import React from 'react';
import cx from 'classnames';

import styles from './RestaurantCard.module.scss';

import Image, { ImageProps } from '../../atoms/Image';
import Text, { TextProps } from '../../atoms/Text';
import Icon, { IconProps } from '../../atoms/Icon';
import ClosedRestaurantImage, { ClosedRestaurantImageProps } from '../../atoms/ClosedRestaurantImage';
import { getClickAccessibilityProps } from '../../../lib/utils';

export type RestaurantCardLoadingType = 'Default' | 'Loading';
export type RestaurantCardStateType = 'Default' | 'Closed';

export const defaultProps = {
  loading: 'Loading' as RestaurantCardLoadingType,
  state: 'Default' as RestaurantCardStateType,
  titleText: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  closedTitleText: { // MANUAL OVERRIDE
    type: 'Title3',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  icon: {
    asset: 'Clock',
    style: 'Grey700',
  } as IconProps,
  veganIcon: {
    asset: 'Vegan',
    style: 'Grey700',
  } as IconProps,
  cusineTypeText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  addressText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  prepRangeText: {
    type: 'Body3',
    align: 'Right',
    style: 'Grey700',
  } as TextProps,
  distanceText: {
    type: 'Body3',
    align: 'Right',
    style: 'Grey700',
  } as TextProps,
  closedRestaurantImage: { // MANUAL OVERRIDE
    icon: {
      asset: 'Closed',
      style: 'Grey800',
    },
    text: {
      type: 'Body1', // MANUAL OVERRIDE
      align: 'Center',
      style: 'Grey100',
    },
  } as ClosedRestaurantImageProps,
};

export type RestaurantCardProps = {
  loading?: RestaurantCardLoadingType;
  state?: RestaurantCardStateType;
  image?: ImageProps;
  titleText?: TextProps;
  closedTitleText?: TextProps; // MANUAL OVERRIDE
  icon?: IconProps;
  veganIcon?: IconProps;
  cusineTypeText?: TextProps;
  addressText?: TextProps;
  distanceText?: TextProps;
  prepRangeText?: TextProps;
  className?: string;
  onCardClick?: VoidFunction;
  closedRestaurantImage?: ClosedRestaurantImageProps;
};

const RestaurantCard: React.FC<RestaurantCardProps> = ({
  loading,
  image,
  titleText,
  closedTitleText, // MANUAL OVERRIDE
  icon,
  veganIcon,
  cusineTypeText,
  addressText,
  distanceText,
  prepRangeText,
  className,
  onCardClick,
  closedRestaurantImage,
  state,
}) => {
  const accessibilityProps = getClickAccessibilityProps(onCardClick);
  const currentStyle = styles[`restaurantCard${loading}${state}`];

  let imageView;
  let closedRestaurantImageView;
  let contentView;
  switch (`${loading}${state}`) {
    case 'DefaultDefault':
      imageView = (
        <Image
        className={styles.image}
        {...image} />
      );
      contentView = (
        <div className={styles.content}>
          <div className={styles.topContentGroup}>
            <Text
              className={styles.titleText}
              {...titleText} />
            {/* <Icon
              className={styles.icon}
              {...veganIcon} /> */}
          </div>
          <div className={styles.contentGroup}>
            <Text
              className={styles.cusineTypeText}
              {...cusineTypeText} />
             <div className={styles.rightContent}>
              <Text
                className={styles.distanceText}
                {...distanceText} />
             </div>
          </div>
          <div className={styles.contentGroup}>
            <Text
              className={styles.addressText}
              {...addressText} />
            <div className={styles.rightContent}>
              <Icon
                className={styles.icon}
                {...icon} />
              <Text
                className={styles.distanceText}
                {...prepRangeText} />
            </div>
          </div>
        </div>
      );
      break;
    case 'DefaultClosed':
      closedRestaurantImageView = (
        <ClosedRestaurantImage
          className={styles.closedRestaurantImage}
          {...closedRestaurantImage} />
      );
      contentView = (
        <div className={styles.content}>
          <div className={styles.topContentGroup}>
            <Text
              className={styles.titleText}
              {...closedTitleText} />
          </div>
          <Text
            className={styles.cusineTypeText}
            {...cusineTypeText} />
          <div className={styles.contentGroup}>
            <Text
              className={styles.addressText}
              {...addressText} />
            <div className={styles.rightContent}>
              <Icon
                className={styles.icon}
                {...icon} />
              <Text
                className={styles.distanceText}
                {...prepRangeText} />
            </div>
          </div>
        </div>
      );
      break;
    case 'LoadingDefault':
      imageView = (
        <div
          className={styles.image} />
      );
      contentView = (
        <div className={styles.content}>
          <div className={styles.contentGroupOne}/>
          <div className={styles.contentGroupTwo}/>
          <div className={styles.contentGroupThree}/>
        </div>
      );
      break;
    default: return null;
  }
  return (
    <div className={cx(currentStyle, className)}
      onClick={onCardClick}
      {...accessibilityProps}>
      {imageView}
      {closedRestaurantImageView}
      {contentView}
    </div>
  );
};

RestaurantCard.defaultProps = defaultProps;

export default RestaurantCard;
