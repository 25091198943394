import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import styles from './HelpBlock.module.scss';

import { Account } from '../../../lib/token';
import { defaultProps, HelpBlockProps } from './HelpBlock';

export type HelpBlockPresenterProps = HelpBlockProps & {
  account: Account | null;
};

const withPresenter = (
  View: React.FC<HelpBlockProps>,
): React.FC<HelpBlockPresenterProps> => {
  const Presenter: React.FC<HelpBlockPresenterProps> = (props) => {
    const { account } = props;
    const { t } = useTranslation();

    const onFaqClick = (): void => {
      window.open(t('urls.help'));
    };

    const sendMessageContent = (
      <Trans i18nKey='help_block.send_message_description'
        values={{
          suppli_number: '+1 (888) 205-2705',
          suppli_email: 'hello@mysuppli.ca',
        }}
        components={{
          div: <div className={styles.sendMessageDescription}></div>,
          p: <p></p>,
          number: <a href={`sms://+18882052705;?&body=Message%20from%20Suppli%20Marketplace%0ACustomer%3A%20${account?.firstName} ${account?.lastName}%20-%20${account?.email}%0A`}>{'+1 (888) 205-2705'}</a>,
          email: <a href={`mailto:hello@mysuppli.ca?subject=Message%20from%20Suppli%20Marketplace&body=Customer%3A%20${account?.firstName} ${account?.lastName}%20-%20${account?.profile?.phoneNumber}%0A`}>{'hello@mysuppli.ca'}</a>,
        }}
      />
    );
    const helpBlockProps: HelpBlockProps = {
      ...defaultProps,
      title: {
        ...defaultProps.title,
        value: t('help_block.title'),
      },
      faq: {
        ...defaultProps.faq,
        onListItemClicked: onFaqClick,
        label: {
          ...defaultProps.faq.label,
          value: t('help_block.faq'),
        },
        description: {
          ...defaultProps.faq.description,
          value: t('help_block.faq_description'),
        },
        button: {
          ...defaultProps.faq.button,
        },
      },
      sendMessage: {
        ...defaultProps.sendMessage,
        value: t('help_block.send_message'),
      },
      contactInstructions: {
        ...defaultProps.contactInstructions,
        value: sendMessageContent,
      },
    };
    return <View {...props} {...helpBlockProps} />;
  };
  return Presenter;
};

export default withPresenter;
