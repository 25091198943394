import React from 'react';
import cx from 'classnames';

import styles from './HelpBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import ListItem, { ListItemProps } from '../../molecules/ListItem';
import Divider, { DividerProps } from '../../atoms/Divider';

export const defaultProps = {
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  faq: {
    type: 'OffApp',
    label: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    button: {
      size: 'Medium',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'ArrowUpRight',
        style: 'Grey1100',
      },
    },
  } as ListItemProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  sendMessage: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  contactInstructions: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
};

export type HelpBlockProps = {
  title?: TextProps;
  faq?: ListItemProps;
  divider?: DividerProps;
  sendMessage?: TextProps;
  contactInstructions?: TextProps;
  className?: string;
  error?: Error;
};

const HelpBlock: React.FC<HelpBlockProps> = ({
  title,
  faq,
  divider,
  sendMessage,
  contactInstructions,
  className,
}) => (
    <div className={cx(styles.helpBlock, className)}>
      <div className={styles.content}>
        <Text
          className={styles.title}
          {...title} />
        <ListItem
          className={styles.faq}
          {...faq} />
        <Divider
          className={styles.divider}
          {...divider} />
        <Text
          className={styles.sendMessage}
          {...sendMessage} />
        <Text
          className={styles.contactInstructions}
          {...contactInstructions} />
      </div>
    </div>
);

HelpBlock.defaultProps = defaultProps;

export default HelpBlock;
