import React, { RefObject } from 'react';
import cx from 'classnames';

import styles from './RestaurantBanner.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import { Restaurant } from '../../../modules/restaurant/types';

export const defaultProps = {
  button: {
    size: 'ExtraSmall',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'Back',
      style: 'Grey1100',
    },
  } as ButtonProps,
  titleText: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  cusineTypeText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  addressText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
};

export type RestaurantBannerProps = {
  button?: ButtonProps;
  background?: string;
  titleText?: TextProps;
  cusineTypeText?: TextProps;
  addressText?: TextProps;
  className?: string;
  restaurant?: Restaurant;
  restaurantBannerRef?: RefObject<HTMLDivElement>;
};

const RestaurantBanner: React.FC<RestaurantBannerProps> = ({
  button,
  background,
  titleText,
  cusineTypeText,
  addressText,
  className,
  restaurantBannerRef,
}) => (
    <div className={cx(styles.restaurantBanner, className)} ref={restaurantBannerRef}>
      <div className={styles.banner} style={{ backgroundImage: `url(${background})` }}>
        <Button
          className={styles.button}
          {...button} />
      </div>
      <div className={styles.content}>
        <Text
          className={styles.titleText}
          {...titleText} />
        <Text
          className={styles.cusineTypeText}
          {...cusineTypeText} />
        <Text
          className={styles.addressText}
          {...addressText} />
      </div>
    </div>
);

RestaurantBanner.defaultProps = defaultProps;

export default RestaurantBanner;
