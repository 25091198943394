import React from 'react';
import cx from 'classnames';

import styles from './Toggle.module.scss';

import ToggleItem, { ToggleItemProps } from '../../atoms/ToggleItem';

export const defaultProps = {
  secondOption: {
    state: 'Selected',
    text: {
      type: 'Button2',
      align: 'Center',
      style: 'Grey100',
    },
  } as ToggleItemProps,
  firstOption: {
    state: 'Unselected',
    text: {
      type: 'Button2',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ToggleItemProps,
};

export type ToggleProps = {
  secondOption?: ToggleItemProps;
  firstOption?: ToggleItemProps;
  className?: string;
};

const Toggle: React.FC<ToggleProps> = ({
  secondOption,
  firstOption,
  className,
}) => {
  return (
    <div className={cx(styles.toggle, className)}>
      <ToggleItem
        className={styles.secondOption}
        {...secondOption} />
      <ToggleItem
        className={styles.firstOption}
        {...firstOption} />
    </div>
  );
};

Toggle.defaultProps = defaultProps;

export default Toggle;
