import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

import Text, { TextProps } from '../Text';
import Icon, { IconProps } from '../Icon';

export type ButtonSizeType =
  | 'Medium'
  | 'Small'
  | 'ExtraSmall'
  | 'ExtraExtraSmall';
export type ButtonTypeType = 'TextIcon' | 'Text' | 'IconText' | 'Icon';
export type ButtonStyleType =
  | 'BrandSquare'
  | 'GoogleSquare'
  | 'FacebookSquare'
  | 'InfoRounded'
  | 'NeutralSquare'
  | 'DangerRounded'
  | 'BasicSquare'
  | 'ClearSquare'
  | 'ContrastSquare'
  | 'SecondarySquare'
  | 'SocialSquare'; // MANUAL OVERRIDE
export type ButtonButtonTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  size: 'ExtraSmall' as ButtonSizeType,
  type: 'Icon' as ButtonTypeType,
  style: 'BasicSquare' as ButtonStyleType,
  text: {
    type: 'Button2',
    align: 'Center',
    style: 'Grey600',
  } as TextProps,
  icon: {
    asset: 'Back',
    style: 'Grey600',
  } as IconProps,
};

export type ButtonProps = {
  size?: ButtonSizeType;
  type?: ButtonTypeType;
  style?: ButtonStyleType;
  buttonType?: ButtonButtonTypeType;
  onButtonClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  text?: TextProps;
  icon?: IconProps;
  className?: string;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  size,
  type,
  style,
  buttonType,
  onButtonClicked,
  text,
  icon,
  className,
  disabled,
}) => {
  const currentStyle = styles[`button${size}${type}${style}`];

  let contentView;

  switch (type) {
    case 'TextIcon':
      contentView = (
        <div className={styles.content}>
          <Text className={styles.text} {...text} />
          <Icon className={styles.icon} {...icon} />
        </div>
      );
      break;
    case 'Text':
      contentView = (
        <div className={styles.content}>
          <Text className={styles.text} {...text} />
        </div>
      );
      break;
    case 'IconText':
      contentView = (
        <div className={styles.content}>
          <Icon className={styles.icon} {...icon} />
          <Text className={styles.text} {...text} />
        </div>
      );
      break;
    case 'Icon':
      contentView = (
        <div className={styles.content}>
          <Icon className={styles.icon} {...icon} />
        </div>
      );
      break;
  }

  return (
    <button
      type={buttonType}
      disabled={disabled}
      onClick={onButtonClicked}
      className={cx(currentStyle, className)}>
      {contentView}
    </button>
  );
};

Button.defaultProps = defaultProps;

export default Button;
