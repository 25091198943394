import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import { resendVerifyAccount } from '../../../modules/account/api';
import { AuthContext } from '../../../modules/auth';
import { ActionCompleteBlockProps } from './ActionCompleteBlock';
import { ActionCompletePresenterProps } from './ActionCompleteBlock.presenter';
import { forgotPassword } from '../../../modules/account/api';

const withInteractor = (
  Presenter: React.FC<ActionCompletePresenterProps>,
): React.FC <ActionCompleteBlockProps> => {
  const Interactor: React.FC <ActionCompleteBlockProps> = (props) => {
    const { logout } = useContext(AuthContext);
    const { mutateAsync } = useMutation(resendVerifyAccount);
    const { mutateAsync: forgotPasswordMutateAsync, isLoading } = useMutation(forgotPassword);
    return (
        <Presenter
          {...props}
          logout={logout}
          resendVerifyEmail={mutateAsync}
          forgotPassword={forgotPasswordMutateAsync}
          />
    );
  };
  return Interactor;
};
export default withInteractor;
