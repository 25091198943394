import React from 'react';
import cx from 'classnames';

import styles from './ChangePasswordBlock.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import Divider, { DividerProps } from '../../atoms/Divider';
import ToastNotification, { ToastNotificationProps } from '../../molecules/ToastNotification';

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    icon: {
      asset: 'Back',
      style: 'Grey1100',
    },
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  instructions: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  email: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  updateButton: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  errorToast: {
    type: 'Error',
    icon: {
      asset: 'ErrorCircle',
      style: 'Red800',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
  } as ToastNotificationProps,
};

export type ChangePasswordBlockProps = {
  backButton?: ButtonProps;
  title?: TextProps;
  instructions?: TextProps;
  email?: TextFieldProps;
  divider?: DividerProps;
  updateButton?: ButtonProps;
  className?: string;
  error?: Error;
  toast?: ToastNotificationProps;
  toastMessage?: boolean;
};

const ChangePasswordBlock: React.FC<ChangePasswordBlockProps> = ({
  backButton,
  title,
  instructions,
  email,
  divider,
  updateButton,
  className,
  toastMessage,
  toast,
}) => {
  let toastNotif;
  if (toastMessage) {
    toastNotif = <ToastNotification {...toast} />;
  }
  return (
    <div className={cx(styles.changePasswordBlock, className)}>
      {toastNotif}
      <div className={styles.content}>
        <div className={styles.bodyContent}>
          <Button
            className={styles.backButton}
            {...backButton} />
          <Text
            className={styles.title}
            {...title} />
          <Text
            className={styles.instructions}
            {...instructions} />
          <TextField
            className={styles.email}
            {...email} />
        </div>
        <div className={styles.bottomContent}>
          <Divider
            className={styles.divider}
            {...divider} />
          <Button
            className={styles.updateButton}
            {...updateButton} />
        </div>
      </div>
    </div>
  );
};

ChangePasswordBlock.defaultProps = defaultProps;

export default ChangePasswordBlock;
