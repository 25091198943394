import React from 'react';
import cx from 'classnames';

import styles from './CelebratoryBlock.module.scss';

import Image, { ImageProps } from '../../atoms/Image';
import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text'; 

export const defaultProps = {
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  subHeader: {
    type: 'Body1',
    align: 'Left',
    style: 'Grey600',
  } as TextProps,
  continueButton: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  shareButton: {
    size: 'Medium',
    type: 'TextIcon',
    style: 'SecondarySquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'SocialShare',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type CelebratoryBlockProps = {
  title?: TextProps;
  subHeader?: TextProps;
  image?: ImageProps;
  shareButton?: ButtonProps;
  continueButton?: ButtonProps;
  className?: string;
  showShareModal?: boolean; // MANUAL OVERRIDE
};

const CelebratoryBlock: React.FC<CelebratoryBlockProps> = ({
  title,
  subHeader,
  image,
  shareButton,
  continueButton,
  className,
  showShareModal,
}) => {
  return (
    <div className={cx(styles.content, className)}>
      <Text
        className={styles.title}
        {...title} />
       <Text
        className={styles.subHeader}
        {...subHeader} />
      <Image
        className={styles.image}
        {...image}/>
      <div className={styles.buttons}>
        <Button
          className={styles.continueButton}
          {...continueButton} />
        <Button
          className={styles.shareButton}
          {...shareButton} />
      </div>
    </div>
  );
};

CelebratoryBlock.defaultProps = defaultProps;

export default CelebratoryBlock;
