import axios from '../../lib/api/axios';
import { BFF_URL } from "../../lib/config";
import { Restaurant, RestaurantDetailPayload } from "./types";

export const getRestaurantsList = async (payload: RestaurantDetailPayload): Promise<Restaurant[]> => {
  try {
    const { data } = await axios.get<Restaurant[]>(
      `${BFF_URL}/restaurants`, {
        params: {
          latitude: payload.userLocation?.latitude,
          longitude: payload.userLocation?.longitude,
          orderType: payload.orderType
        }
      }
    );
    return data;
  } catch (err) {
    throw new Error();
  }
};

export const getRestaurant = async (id: string, payload?: RestaurantDetailPayload): Promise<Restaurant> => {
  try {
    const { data } = await axios.get<Restaurant>(
      `${BFF_URL}/restaurants/${id}`, {
        params: {
          latitude: payload?.userLocation?.latitude,
          longitude: payload?.userLocation?.longitude,
          orderType: payload?.orderType
        }
      }
    );
    return data;
  } catch (err) {
    throw new Error();
  }
};
