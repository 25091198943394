import React from 'react';
import cx from 'classnames';
import styles from './Text.module.scss';
import { getClickAccessibilityProps } from '../../../lib/utils';

export type TextTypeType = 
| 'Title1'
| 'Title2'
| 'Title3'
| 'Title4'
| 'Title5' 
| 'Body1'
| 'Body2'
| 'Body3'
| 'Body4'
| 'Body5'
| 'Button1'
| 'Button2';
export type TextAlignType = 'Left' | 'Center' | 'Right';
export type TextStyleType = 
| 'Grey1100'
| 'Grey600'
| 'Grey700'
| 'Blue800'
| 'Red800'
| 'Green800'
| 'Grey800'
| 'Grey100'
| 'Purple800'; // MANUAL OVERRIDE

export const defaultProps = {
  type: 'Button2' as TextTypeType,
  align: 'Center' as TextAlignType,
  style: 'Blue800' as TextStyleType,
  value: '',
};

export type TextProps = {
  type?: TextTypeType;
  align?: TextAlignType;
  style?: TextStyleType;
  value?: string | React.ReactNode;
  onClick?: VoidFunction;
  className?: string;
};

const Text: React.FC<TextProps> = ({
  type,
  align,
  style,
  value,
  onClick,
  className,
}) => {
  const currentStyle = styles[`text${type}${align}${style}`];

  let valueView = <p className={styles.value}>{value}</p>;

  switch (type) {
    case 'Title1':
      break;
    case 'Title2':
      break;
    case 'Title3':
      break;
    case 'Title4':
      break;
    case 'Title5':
      break;
    case 'Body1':
      break;
    case 'Body2':
      break;
    case 'Body3':
      break;
    case 'Body4':
      break;
    case 'Body5':
      break;
    case 'Button1':
      break;
    case 'Button2':
      break;
  }

  const accessibilityProps = getClickAccessibilityProps(onClick);
  return (
    <div
      className={cx(currentStyle, className)}
      onClick={onClick}
      {...accessibilityProps}
    >
      {valueView}
    </div>
  );
};

Text.defaultProps = defaultProps;

export default Text;
