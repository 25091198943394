import React, { useContext } from "react";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PaymentInfoBlockProps } from "./PaymentInfoBlock";
import { PaymentInfoBlockPresenterProps } from "./PaymentInfoBlock.presenter";
import { STRIPE_API_KEY } from "../../../lib/config";
import { CheckoutContext } from "../../../modules/checkout";


const stripePromise = loadStripe(STRIPE_API_KEY);

const withInteractor = (
    Presenter: React.FC<PaymentInfoBlockPresenterProps>,
  ): React.FC <PaymentInfoBlockProps> => {
    const Interactor: React.FC <PaymentInfoBlockProps> = (props) => {
        const { updateBasket} = useContext(CheckoutContext);
        return (
          <Elements 
            stripe={stripePromise}
            options={{
              fonts: [
                {
                  cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap',
                }
              ]
            }}>
            <Presenter 
              {...props}
              updateBasket={updateBasket}
              />
          </Elements>
        );
    };
    return Interactor;
  };
  export default withInteractor;