import React, { useCallback} from "react";
import { MenuSectionItemProps } from "./MenuSectionItem";
import { MenuSectionItemPresenterProps } from "./MenuSectionItem.presenter";

const withInteractor = (
  Presenter: React.FC<MenuSectionItemPresenterProps>,
): React.FC<MenuSectionItemProps> => {
    const Interactor: React.FC<MenuSectionItemProps> = (props) => {
    return (
      <Presenter
        {...props}
      />
    );
  };
  return Interactor;
};
export default withInteractor;
