import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import ErrorHandler from '../error/ErrorHandler';
import { AuthContext } from './AuthContext';

const CheckUserAccess: React.FC<RouteProps> = (props) => {
  const authContext = useContext(AuthContext);
  const { account, loading, error } = authContext;
  const history = useHistory();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!account) {
    let searchParam = '';
    if(props.path == '/order/:orderId'){
      searchParam = `?redirect_to=${history.location.pathname}`;
      return <Redirect to={ '/signIn' + searchParam} />;
    }
    return <Redirect to={ '/signUp' } />;
  }

  if (!account.profile && String(props.path).toLowerCase() != '/completeprofile') {
    return <Redirect
      to={{
        pathname: "/completeProfile",
        state: { referrer: props.path }
      }}
    />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }
  
 // if no errors then proceed to the route
  return <Route {...props} />;
};

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  return <CheckUserAccess {...props} />;
};

export default ProtectedRoute;
