import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { formatDeliveryETA, formatEtaTime } from "../../../lib/utils";
import { AuthContext } from "../../../modules/auth";
import { CheckoutContext } from "../../../modules/checkout";
import { calculateTotals, createPaymentIntent } from "../../../modules/checkout/api";
import { useRestaurantList } from "../../../modules/restaurant";
import { getRestaurant } from "../../../modules/restaurant/api";
import { CheckoutPriceBlockProps } from "./CheckoutPriceBlock";
import { CheckoutPriceBlockPresenterProps } from "./CheckoutPriceBlock.presenter";

const withInteractor = (
    Presenter: React.FC<CheckoutPriceBlockPresenterProps>,
  ): React.FC <CheckoutPriceBlockProps> => {
    const Interactor: React.FC <CheckoutPriceBlockProps> = (props) => {
        const { account } = useContext(AuthContext);
        const { basket, updateBasket, updateUseBasketOrderType } = useContext(CheckoutContext);
        const calculateTotalsState = useMutation(calculateTotals);
        const paymentIntentState = useMutation(createPaymentIntent);

        let userLocation;
        if(account?.profile?.preferredAddress?.location) {
          userLocation = account.profile.preferredAddress.location;
        } else if(account?.profile?.address) {
          userLocation = account?.profile?.address[0].location;
        }        

        const { data: restaurantData } = useQuery(['getRestaurant',  { restaurantId: basket?.restaurantId, userLocation: userLocation, orderType: basket?.orderType }], () =>
          getRestaurant(basket?.restaurantId || '', { userLocation: userLocation, orderType: basket?.orderType }),
        );
        
        const { isLoading, data: restaurantsData } = useRestaurantList({ userLocation: userLocation, orderType: basket?.orderType });
        
        return (
          <Presenter
            {...props}
            account={account}
            basket={basket}
            calculateTotals={calculateTotalsState.mutateAsync}
            createPaymentIntent={paymentIntentState.mutateAsync}
            isLoading={paymentIntentState.isLoading && isLoading}
            isCalculating={calculateTotalsState.isLoading}
            updateBasket={updateBasket}
            updateUseBasketOrderType={updateUseBasketOrderType}
            restaurantsData={restaurantsData}
            restaurantData={restaurantData}
            userLocation={userLocation}
            error={calculateTotalsState.error as Error || paymentIntentState.error as Error}
          />
        );
    };
    return Interactor;
  };
  export default withInteractor;