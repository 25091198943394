import React from 'react';
import cx from 'classnames';

import styles from './EmailSignUpBlock.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import ToastNotification, { ToastNotificationProps } from '../../molecules/ToastNotification/ToastNotification';

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
  } as ButtonProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  firstName: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  lastName: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  email: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  createPassword: {
    state: 'Default',
    type: 'Password',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Password',
      style: 'Default',
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      icon: {
        asset: 'Show',
        style: 'Grey1100',
      },
    },
  } as TextFieldProps,
  confirmPassword: {
    state: 'Default',
    type: 'Password',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Password',
      style: 'Default',
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      icon: {
        asset: 'Show',
        style: 'Grey1100',
      },
    },
  } as TextFieldProps,
};

export type EmailSignUpBlockProps = {
  backButton?: ButtonProps;
  button?: ButtonProps;
  title?: TextProps;
  description?: TextProps;
  firstName?: TextFieldProps;
  lastName?: TextFieldProps;
  email?: TextFieldProps;
  createPassword?: TextFieldProps;
  confirmPassword?: TextFieldProps;
  toast?: ToastNotificationProps;
  toastMessage?: boolean,
  className?: string;
};

const EmailSignUpBlock: React.FC<EmailSignUpBlockProps> = ({
  backButton,
  button,
  title,
  description,
  firstName,
  lastName,
  email,
  createPassword,
  confirmPassword,
  toast,
  toastMessage,
  className,
}) => {
  let toastDisplay
  if (toastMessage) {
    toastDisplay = <ToastNotification {...toast} />;
  }
  return (
    <div className={cx(styles.emailSignUpBlock, className)}>
      <div className={styles.topContent}>
        <Button
          className={styles.backButton}
          {...backButton} />
        <Text
          className={styles.title}
          {...title} />
        <Text
          className={styles.description}
          {...description} />
      </div>
      <div className={styles.formContent}>
        <TextField
          className={styles.firstName}
          {...firstName} />
        <TextField
          className={styles.lastName}
          {...lastName} />
        <TextField
          className={styles.email}
          {...email} />
        <TextField
          className={styles.createPassword}
          {...createPassword} />
        <TextField
          className={styles.confirmPassword}
          {...confirmPassword} />
        {toastDisplay}
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

EmailSignUpBlock.defaultProps = defaultProps;

export default EmailSignUpBlock;
