import React from 'react';
import cx from 'classnames';

import styles from './DropdownToggle.module.scss';

import Text, { TextProps } from '../Text';
import Icon, { IconProps } from '../Icon';

export const defaultProps = {
  text: {
    type: 'Body3',
    align: 'Center',
    style: 'Grey600',
  } as TextProps,
  icon: {
    asset: 'ChevronDown',
    style: 'Grey600',
  } as IconProps,
};

export type DropdownToggleProps = {
  text?: TextProps;
  icon?: IconProps;
  className?: string;
  onClick?: () => void;
};

const DropdownToggle: React.FC<DropdownToggleProps> = ({
  text,
  icon,
  className,
  onClick
}) => {
  return (
    <div className={cx(styles.dropdownToggle, className)} onClick={onClick}>
      <Text
        className={styles.text}
        {...text} />
      <Icon
        className={styles.icon}
        {...icon} />
    </div>
  );
};

DropdownToggle.defaultProps = defaultProps;

export default DropdownToggle;
