import React from 'react';
import { useQuery } from 'react-query';
import { getOrders } from '../../../modules/checkout/api';
import { RestaurantListPageProps } from './RestaurantListPage';
import { RestaurantListPagePresenterProps } from './RestaurantListPage.presenter';
import { filterPendingOrders } from './util';

const withInteractor = (
  Presenter: React.FC<RestaurantListPagePresenterProps>,
): React.FC<RestaurantListPageProps> => {
  const Interactor: React.FC<RestaurantListPageProps> = (props) => {
    const orders = useQuery(['getOrders'], () =>
      getOrders(),
    );
    return (
      <Presenter {...{
        ...props,
        pendingOrders: filterPendingOrders(orders.data),
      }} />
    );
  };
  return Interactor;
};

export default withInteractor;
