import React from 'react';
import cx from 'classnames';

import styles from './OrderAccordian.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Icon, { IconProps } from '../../atoms/Icon';
import PriceOverviewItemList, { PriceOverviewItemListProps } from '../../organisms/PriceOverviewItemList';
import ButtonItem, { ButtonItemProps } from '../../atoms/ButtonItem';
import PaymentInfo, { PaymentInfoProps } from '../PaymentInfo';
import PriceItem, { PriceItemProps } from '../PriceItem';
import PriceList, { PriceListProps } from '../../organisms/PriceList';

export type OrderAccordianStateType = 'Default' | 'Expanded';

export const defaultProps = {
  state: 'Expanded' as OrderAccordianStateType,
  titleText: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  text: {
    type: 'Button2',
    align: 'Center',
    style: 'Green800',
  } as TextProps,
  icon: {
    asset: 'ChevronUp',
    style: 'Green800',
  } as IconProps,
  priceOverviewItemList: {
    priceOverviewItems: [
    ],
  } as PriceOverviewItemListProps,
  priceList: {
    priceItems: [
    ],
  } as PriceListProps,
  priceItem: {
    type: 'ListItemDark',
    state: 'Collapsed',
    loading: 'Default',
    item: {
      type: 'Body5',
      align: 'Left',
      style: 'Grey1100',
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey800',
    },
    price: {
      type: 'Body5',
      align: 'Right',
      style: 'Grey1100',
    },
  } as PriceItemProps,
  paymentInfo: {
    Loading: 'Default',
    topDivider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
    title: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    text: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
    icon: {
      asset: 'Card',
      style: 'Grey1100',
    },
    bottomDivider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as PaymentInfoProps,
  buttonItem: {
    size: 'Small',
    type: 'Default',
    style: 'Default',
    icon: {
      asset: 'Pdf',
      style: 'Grey1100',
    },
    text: {
      type: 'Button2',
      align: 'Left',
      style: 'Grey1100',
    },
  } as ButtonItemProps,
};

export type OrderAccordianProps = {
  state?: OrderAccordianStateType;
  titleText?: TextProps;
  text?: TextProps;
  icon?: IconProps;
  className?: string;
  priceOverviewItemList?: PriceOverviewItemListProps;
  priceList?: PriceListProps;
  priceItem?: PriceItemProps;
  paymentInfo?: PaymentInfoProps;
  buttonItem?: ButtonItemProps;
};

const OrderAccordian: React.FC<OrderAccordianProps> = ({
  state,
  titleText,
  text,
  icon,
  className,
  priceOverviewItemList,
  priceList,
  priceItem,
  paymentInfo,
  buttonItem,
}) => {
  const currentStyle = styles[`orderAccordian${state}`];

  const topContentView = (
    <div className={styles.topContent}>
      <Text
        className={styles.titleText}
        {...titleText} />
      <div className={styles.viewOrder}>
        <Text
          className={styles.text}
          {...text} />
        <Icon
          className={styles.icon}
          {...icon} />
      </div>
    </div>
  );

  let priceOverviewItemListView;
  let priceListView;
  let priceItemView;
  let paymentInfoView;
  let buttonItemView;

  switch (state) {
    case 'Default':
      break;
    case 'Expanded':
      priceOverviewItemListView = (
        <PriceOverviewItemList
          className={styles.priceOverviewItemList}
          {...priceOverviewItemList} />
      );
      priceListView = (
        <PriceList
          className={styles.priceList}
          {...priceList} />
      );
      priceItemView = (
        <PriceItem
          className={styles.priceItem}
          {...priceItem} />
      );
      paymentInfoView = (
        <PaymentInfo
          className={styles.paymentInfo}
          {...paymentInfo} />
      );
      // TODO: download pdf
      // buttonItemView = (
      //   <ButtonItem
      //     className={styles.buttonItem}
      //     {...buttonItem} />
      // );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {topContentView}
      {priceOverviewItemListView}
      {priceListView}
      {priceItemView}
      {paymentInfoView}
      {/* {buttonItemView} */}
    </div>
  );
};

OrderAccordian.defaultProps = defaultProps;

export default OrderAccordian;
