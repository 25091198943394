import { PRODUCT_TYPE_MODIFIER_GROUP, PRODUCT_TYPE_BUNDLE } from '../../lib/constants';
import { CustomerBasket, MenuItem } from './types';

export const calculateBasketTotal = (
  basket: CustomerBasket,
  withTax: boolean = false,
): number => {
  let result = 0;
  basket.items.forEach((item) => {
    result = result + calculateItemTotal(item, withTax);
  });
  return result;
};

// tax is represented as 6% == 6000
const TAX_CONVERSION = 10 ** 5;
export const calculateTax = (price: number, tax: number): number => {
  return Math.ceil((price * tax) / TAX_CONVERSION);
};

export const calculateItemTotal = (
  item: MenuItem,
  withTax: boolean = false,
): number => {
  let result = item.price;
  if (withTax) {
    result = result + calculateTax(item.price, item.tax || 0);
  }
  item.subItems?.forEach((subitem) => {
    result = result + calculateItemTotal(subitem);
  });
  switch (item.productType) {
    case PRODUCT_TYPE_MODIFIER_GROUP:
    case PRODUCT_TYPE_BUNDLE:
      return result;
    default:
      return result * item.quantity;
  }
};
