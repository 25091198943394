import React from 'react';
import cx from 'classnames';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

import styles from './Cart.module.scss';
import { useTranslation } from 'react-i18next';
import { IconAssetType } from '../Icon/Icon';

export type CartStateType = 'Empty' | 'Filled';

export const defaultProps = {
  state: 'Filled' as CartStateType,
  icon: {
    asset: 'CartFull',
    style: 'Grey1100',
  } as IconProps,
  text: {
    type: 'Button1',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
};

export type CartProps = {
  state?: CartStateType;
  icon?: IconProps,
  text?: TextProps,
  onClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

const Cart: React.FC<CartProps> = ({
  state,
  icon,
  text,
  onClicked,
  className,
}) => {
  const { t } = useTranslation();

  let cartIcon: IconAssetType;
  switch (state) {
    case 'Filled':
      cartIcon = 'CartFull';
      break;
    default:
      cartIcon = 'CartEmpty';
      break;
  }

  const cartText = t('restaurant.cart');

  return (
    <button 
      className={cx(styles.cart, className)}
      onClick={onClicked}>
      <Icon 
        className={styles.icon}
        {...icon}
        asset={cartIcon} />
      <Text 
        className={styles.text} 
        {...text}
        value={cartText} />
    </button>
  );
};

Cart.defaultProps = defaultProps;

export default Cart;
