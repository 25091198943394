import React, { useCallback, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import styles from './AddressAutoComplete.module.scss';
import useAddressSuggestions from '../../../modules/address/useAddressSuggestions';
import AutoComplete from '../../molecules/AutoComplete';
import { debounce } from '../../../lib/utils';
import { getAddressDetails } from '../../../modules/address/api';
import Text, { TextProps } from '../../atoms/Text';
import { Address } from '../../../lib/token';

export type AddressAutoCompleteProps = {
  value?: string;
  label?: TextProps;
  errorText?: TextProps;
  className?: string;
  onSelection?: (selection: Address) => void;
};

const AddressAutoComplete: React.FC<AddressAutoCompleteProps> = ({
  value,
  label,
  errorText,
  className,
  onSelection,
}) => {
  const [input, setInput] = useState(value || '');
  const sessionTokenRef = useRef(uuidv4());
  const { data } = useAddressSuggestions(input, sessionTokenRef.current);

  const onChange = useCallback(async ({ placeId }) => {
    const data = await getAddressDetails({
      placeId,
      sessionToken: sessionTokenRef.current,
    });
    if(onSelection) {
      onSelection(data);
    };
    sessionTokenRef.current = uuidv4();
  }, []);

  const onInputValueChange = useCallback(debounce((value) => {
    if (value) {
      setInput(value);
    }
  }, 500), []);

  const currentStyle = styles[`addressAutoComplete`];
  const errorTextView = <Text className={styles.errorText} {...errorText} />;

  return (
    <div className={cx(currentStyle, className)}>
        <Text className={styles.label} {...label} />
        <AutoComplete
            initialInputValue={value}
            items={data?.predictions}
            onInputValueChange={onInputValueChange}
            onChange={onChange}
        />
        {errorTextView}
    </div>
  );
};
export default AddressAutoComplete;
