import React from 'react';
import cx from 'classnames';

import styles from './PriceOverviewItemList.module.scss';

import PriceOverviewItem, { PriceOverviewItemProps } from '../PriceOverviewItem';

export const defaultProps = {
  priceOverviewItems: [
  ] as PriceOverviewItemProps[],
};

export type PriceOverviewItemListProps = {
  priceOverviewItems?: PriceOverviewItemProps[];
  className?: string;
};

const PriceOverviewItemList: React.FC<PriceOverviewItemListProps> = ({
  priceOverviewItems,
  className,
}) => {
  const priceOverviewItemArray = priceOverviewItems?.map((priceOverviewItem, i) => (
    <PriceOverviewItem key={i}
      className={styles.priceOverviewItem}
      {...priceOverviewItem} />
  ));
  
  return (
    <div className={cx(styles.priceOverviewItemList, className)}>
      {priceOverviewItemArray}
    </div>
  );
};

PriceOverviewItemList.defaultProps = defaultProps;

export default PriceOverviewItemList;
