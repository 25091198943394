import React from 'react';
import cx from 'classnames';

import styles from './RadioItem.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

export type RadioItemTypeType = 'Default' | 'TrailingText' | 'TrailingIcon' | 'RadioCardItem';
export type RadioItemStateType = 'Unselected' | 'Selected';
export type RadioItemRadioItemTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  type: 'RadioCardItem' as RadioItemTypeType,
  state: 'Selected' as RadioItemStateType,
  icon: {
    asset: 'RadioChecked',
    style: 'Grey600',
  } as IconProps,
  label: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey600',
  } as TextProps,
  trailingText: {
    type: 'Body2',
    align: 'Right',
    style: 'Grey600',
  } as TextProps,
  trailingIcon: {
    asset: 'ChevronRight',
    style: 'Grey600',
  } as IconProps,
  primaryText: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  secondaryText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
};

export type RadioItemProps = {
  type?: RadioItemTypeType;
  state?: RadioItemStateType;
  radioItemType?: RadioItemRadioItemTypeType;
  onRadioItemClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  label?: TextProps;
  className?: string;
  trailingText?: TextProps;
  trailingIcon?: IconProps;
  primaryText?: TextProps;
  secondaryText?: TextProps;
};

const RadioItem: React.FC<RadioItemProps> = ({
  type,
  state,
  radioItemType,
  onRadioItemClicked,
  icon,
  label,
  className,
  trailingText,
  trailingIcon,
  primaryText,
  secondaryText,
}) => {
  const currentStyle = styles[`radioItem${type}${state}`];

  let iconView;
  let primaryTextView;
  let contentView;
  let secondaryTextView;
  switch (`${type}${state}`) {
    case 'DefaultUnselected':
      iconView = (
        <Icon
          className={styles.icon}
          {...icon} />
      );
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.label}
            {...label} />
        </div>
      );
      break;
    case 'TrailingTextUnselected':
      iconView = (
        <Icon
          className={styles.icon}
          {...icon} />
      );
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.label}
            {...label} />
          <Text
            className={styles.trailingText}
            {...trailingText} />
        </div>
      );
      break;
    case 'TrailingIconUnselected':
      iconView = (
        <Icon
          className={styles.icon}
          {...icon} />
      );
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.label}
            {...label} />
          <Icon
            className={styles.trailingIcon}
            {...trailingIcon} />
        </div>
      );
      break;
    case 'DefaultSelected':
      iconView = (
        <Icon
          className={styles.icon}
          {...icon} />
      );
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.label}
            {...label} />
        </div>
      );
      break;
    case 'TrailingTextSelected':
      iconView = (
        <Icon
          className={styles.icon}
          {...icon} />
      );
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.label}
            {...label} />
          <Text
            className={styles.trailingText}
            {...trailingText} />
        </div>
      );
      break;
    case 'TrailingIconSelected':
      iconView = (
        <Icon
          className={styles.icon}
          {...icon} />
      );
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.label}
            {...label} />
          <Icon
            className={styles.trailingIcon}
            {...trailingIcon} />
        </div>
      );
      break;
    case 'RadioCardItemUnselected':
      primaryTextView = (
        <Text
          className={styles.primaryText}
          {...primaryText} />
      );
      secondaryTextView = (
        <Text
          className={styles.secondaryText}
          {...secondaryText} />
      );
      break;
    case 'RadioCardItemSelected':
      primaryTextView = (
        <Text
          className={styles.primaryText}
          {...primaryText} />
      );
      secondaryTextView = (
        <Text
          className={styles.secondaryText}
          {...secondaryText} />
      );
      break;
  }

  return (
    <button
      type={radioItemType}
      onClick={onRadioItemClicked}
      className={cx(currentStyle, className)}>
      {iconView}
      {primaryTextView}
      {contentView}
      {secondaryTextView}
    </button>
  );
};

RadioItem.defaultProps = defaultProps;

export default RadioItem;
