import React from 'react';
import cx from 'classnames';

import styles from './ChangePasswordPage.module.scss';

import ChangePasswordBlock, { ChangePasswordBlockProps } from '../../block/ChangePasswordBlock';

export const defaultProps = {
  changePasswordBlock: {
    backButton: {
      size: 'ExtraSmall',
      type: 'IconText',
      style: 'BasicSquare',
      icon: {
        asset: 'Back',
        style: 'Grey1100',
      },
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    instructions: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
    email: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
    updateButton: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
  } as ChangePasswordBlockProps,
};

export type ChangePasswordPageProps = {
  changePasswordBlock?: ChangePasswordBlockProps;
  className?: string;
};

const ChangePasswordPage: React.FC<ChangePasswordPageProps> = ({
  changePasswordBlock,
  className,
}) => (
    <div className={cx(styles.changePasswordPage, className)}>
      <ChangePasswordBlock
        className={styles.changePasswordBlock}
        {...changePasswordBlock} />
    </div>
);

ChangePasswordPage.defaultProps = defaultProps;

export default ChangePasswordPage;
