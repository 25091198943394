import { PENDING_ORDER_MAX_TIME } from "../../../lib/constants";
import { OrderObject, OrderStatusEnum } from "../../../modules/checkout/types";

export const filterPendingOrders = (orders?: OrderObject[]) => {
    if (!orders) {
        return [];
    }
    const date = new Date();
    const nowUtc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds());    
    const currDate = new Date(nowUtc);
    return orders.filter( order => {      
      switch (order.orderstatus) {
        case OrderStatusEnum.accepted:
        case OrderStatusEnum.new:
        case OrderStatusEnum.preparing:
        case OrderStatusEnum.prepared:
        case OrderStatusEnum.readyForPickUp:
        case OrderStatusEnum.dispatched:
        case OrderStatusEnum.courierArrived:
        case OrderStatusEnum.enRouteToPickup:
        case OrderStatusEnum.enRouteToDropoff:
            return compareDates(new Date(order.created_at), currDate);
        default:
            return false;
      }
    })
};
const compareDates = (orderDate: Date, nowUtc: Date) => {
    const timeDifference = nowUtc.getTime() - orderDate.getTime();
    return timeDifference <= PENDING_ORDER_MAX_TIME;
};