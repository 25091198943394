import React, { useRef, useEffect } from 'react';
import cx from 'classnames';
import Form from 'react-bootstrap/Form';
import styles from './Select.module.scss';

export const defaultProps: SelectProps = {
  options: [],
};

export const OTHER_OPTION_VALUE = 'OTHER_OPTION_VALUE';

export type SelectItem = {
  label: string;
  value: string;
};

export type SelectProps = {
  onSelectChanged?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectPlaceholder?: string;
  className?: string;
  options?: SelectItem[];
  value?: string;
  selectId?: string;
  focused?: boolean;
};

const Select: React.FC<SelectProps> = ({
  onSelectChanged,
  selectPlaceholder,
  className,
  options = [],
  value = '',
  selectId,
  focused,
}) => {
  const optionsView = options.map(({ label, value }) => (
    <option key={label} value={value}>
      {label}
    </option>
  ));

  let selectPlaceholderView;
  if (selectPlaceholder) {
    selectPlaceholderView = (
      <option value='' disabled>
        {selectPlaceholder}
      </option>
    );
  }
  const ref = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (focused && ref.current) {
      ref.current.focus();
    }
  }, [focused]);

  return (
    <Form.Control
      id={selectId}
      as='select'
      className={cx(styles.select, className)}
      onChange={onSelectChanged}
      value={value}
      custom
      ref={ref}
    >
      {selectPlaceholderView}
      {optionsView}
    </Form.Control>
  );
};

Select.defaultProps = defaultProps;

export default Select;
