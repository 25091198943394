import React from 'react';
import cx from 'classnames';

import styles from './SectionHeader.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  titleText: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  helpText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey800',
  } as TextProps,
};

export type SectionHeaderProps = {
  titleText?: TextProps;
  helpText?: TextProps;
  className?: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({
  titleText,
  helpText,
  className,
}) => {
  return (
    <div className={cx(styles.sectionHeader, className)}>
      <div className={styles.content}>
        <Text className={styles.titleText} {...titleText} />
        <Text className={styles.helpText} {...helpText} />
      </div>
    </div>
  );
};

SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
