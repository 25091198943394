import React from 'react';
import cx from 'classnames';

import styles from './Divider.module.scss';

export type DividerTypeType = 'HorizontalSolid' | 'VerticalSolid' | 'HorizontalDotted';
export type DividerStyleType = 'Grey400' | 'Grey500' | 'Grey800' | 'Blue200';

export const defaultProps = {
  type: 'HorizontalSolid' as DividerTypeType,
  style: 'Blue200' as DividerStyleType,
};

export type DividerProps = {
  type?: DividerTypeType;
  style?: DividerStyleType;
  className?: string;
};

const Divider: React.FC<DividerProps> = ({
  type,
  style,
  className,
}) => {

  const currentStyle = styles[`divider${type}${style}`];

  
  switch (type) {
    case 'HorizontalSolid':
      break;
    case 'VerticalSolid':
      break;
    case 'HorizontalDotted':
      break;
  }

  return (
    <div className={cx(currentStyle, className)}/>
  );
};

Divider.defaultProps = defaultProps;

export default Divider;
