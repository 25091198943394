import React, { RefObject } from "react";
import { useHistory } from "react-router-dom";
import { RestaurantListBlockProps } from "./RestaurantListBlock";
import { defaultProps } from "./RestaurantListBlock";
import { defaultProps as restaurantCardDefaultProps } from "../../molecules/RestaurantCard/RestaurantCard";
import { RestaurantCardProps } from "../../molecules/RestaurantCard";
import { Restaurant } from "../../../modules/restaurant/types";
import { formatDeliveryTime, formatDistance } from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import { OrderType } from "../../../modules/checkout/types";
import NoRestaurants from '../../../resources/images/NoRestaurants.png';

export type RestaurantListBlockPresenterProps = RestaurantListBlockProps & {
  isLoading: boolean;
  data?: Restaurant[];
  orderType?: OrderType;
  restaurantListBlockRef?: RefObject<HTMLDivElement>;
  updateSkipToAddressChange?: (isSkipToChange: boolean) => void;
};

const loadingRestaurantCards: RestaurantCardProps[] = new Array(9).fill({
  loading: 'Loading'
});

const withPresenter = (
  View: React.FC<RestaurantListBlockProps>
): React.FC<RestaurantListBlockPresenterProps> => {
  const Presenter: React.FC<RestaurantListBlockPresenterProps> = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { data, isLoading, handleChangeAddress, orderType, restaurantListBlockRef } = props;

    let restaurantContent: RestaurantCardProps[] = [];
    
    if(isLoading){
      restaurantContent = [...loadingRestaurantCards];
    }

    if(data) {
      restaurantContent = data.map((restaurant: Restaurant): RestaurantCardProps  => ({
        ...restaurantCardDefaultProps,
        onCardClick: () => history.push(`/restaurant/${restaurant.restaurantid}`),
        loading: 'Default',
        state: restaurant.isOpened ? 'Default' : 'Closed',
        image: {
          srcValue: restaurant.details?.thumbnails?.large,
        },
        titleText: {
          ...restaurantCardDefaultProps.titleText,
          value: restaurant.details?.name,
        },
        closedTitleText: {
          ...restaurantCardDefaultProps.closedTitleText,
          value: restaurant.details?.name,
        },
        cusineTypeText: {
          ...restaurantCardDefaultProps.cusineTypeText,
          value: restaurant.details?.cuisineType,
        },
        addressText: {
          ...restaurantCardDefaultProps.addressText,
          value: restaurant.details?.address,
        },
        distanceText: {
          ...restaurantCardDefaultProps.distanceText,
          value: restaurant.details ? formatDistance(restaurant.details.distance) : undefined,
        },
        prepRangeText: {
          ...restaurantCardDefaultProps.prepRangeText,
          value: (orderType === 'Pickup') ? restaurant.details?.prepRange : formatDeliveryTime(restaurant.details?.distance, restaurant.details?.prepTime),
        },
        closedRestaurantImage: {
          ...restaurantCardDefaultProps.closedRestaurantImage,
          srcValue: restaurant.details?.thumbnails?.large,
          text: {
            ...restaurantCardDefaultProps.closedRestaurantImage.text,
            value: t('restaurant.currently_unavailable'),
          }
        }
      }));
    }

    const blockProps: RestaurantListBlockProps = {
      ...defaultProps,
      restaurantListBlockRef: restaurantListBlockRef,
      blockTitle: {
        ...defaultProps.blockTitle,
        value: t('restaurant.restaurants_nearby'),
      },
      restaurantCardList: {
        ...defaultProps.restaurantCardList,
        restaurantCards: restaurantContent,
      },
      noContentBlock: {
        ...defaultProps.noContentBlock,
        button: {
          ...defaultProps.noContentBlock.button,
          onButtonClicked: handleChangeAddress,
          text: {
            ...defaultProps.noContentBlock.button?.text,
            value: t('button.change_address')
          },
        },
        emptyStateContent: {
          ...defaultProps.noContentBlock.emptyStateContent,
          image: {
            ...defaultProps.noContentBlock.emptyStateContent?.image,
            srcValue: NoRestaurants
          },
          titleText: {
            ...defaultProps.noContentBlock.emptyStateContent?.titleText,
            value: t('restaurant.no_restaurants')
          },
          messageText: {
            ...defaultProps.noContentBlock.emptyStateContent?.messageText,
            value: t('restaurant.no_restaurants_message')
          },
        }
      },
      noRestaurantFound: (data && data.length === 0)
    };

    return <View 
      {...props}
      {...blockProps} />;
  };
  return Presenter;
};

export default withPresenter;
