import React from 'react';
import cx from 'classnames';

import styles from './DeliveryInfo.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import Text, { TextProps } from '../../atoms/Text';
import Icon, { IconProps } from '../../atoms/Icon';
import DropdownToggle, { DropdownToggleProps } from '../../atoms/DropdownToggle';
import ErrorNotification, { ErrorNotificationProps } from '../ErrorNotification';

export type DeliveryInfoTypeType = 'Delivery' | 'Pickup';
export type DeliveryInfoLoadingType = 'Default' | 'Loading';
export type DeliveryInfoStateType = 'Default' | 'Error';

export const defaultProps = {
  type: 'Pickup' as DeliveryInfoTypeType,
  loading: 'Loading' as DeliveryInfoLoadingType,
  State: 'Default' as DeliveryInfoStateType,
  topDivider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  text: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  icon: {
    asset: 'Restaurant',
    style: 'Grey1100',
  } as IconProps,
  dropdownToggle: {
    text: {
      type: 'Body3',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'Grey1100',
    },
  } as DropdownToggleProps,
  bottomDivider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  errorNotification: {
    type: 'Error',
    icon: {
      asset: 'ErrorCircle',
      style: 'Red800',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
  } as ErrorNotificationProps,
};

export type DeliveryInfoProps = {
  type?: DeliveryInfoTypeType;
  loading?: DeliveryInfoLoadingType;
  state?: DeliveryInfoStateType;
  topDivider?: DividerProps;
  text?: TextProps;
  topText?: TextProps;
  bottomText?: TextProps;
  topIcon?: IconProps;
  bottomIcon?: IconProps; 
  deliveryInstruction?: TextProps;
  dropdownToggle?: DropdownToggleProps;
  bottomDivider?: DividerProps;
  className?: string;
  errorNotification?: ErrorNotificationProps;
};

const DeliveryInfo: React.FC<DeliveryInfoProps> = ({
  type,
  loading,
  state,
  topDivider,
  text,
  topText,
  bottomText,
  topIcon,
  bottomIcon,
  deliveryInstruction,
  dropdownToggle,
  bottomDivider,
  className,
  errorNotification,
}) => {

  const currentStyle = styles[`deliveryInfo${type}${loading}${state}`];

  const topDividerView = (
    <Divider
      className={styles.topDivider}
      {...topDivider} />
  );
  const bottomDividerView = (
    <Divider
      className={styles.bottomDivider}
      {...bottomDivider} />
  );
  
  let textView;
  let contentOneView;
  let contentView;
  let errorNotificationView;
  switch (`${type}${loading}${state}`) {
    case 'DeliveryDefaultDefault':
      textView = (
        <Text
          className={styles.text}
          {...text} />
      );
      contentView = (
        <div className={styles.content}>
          <div className={styles.deliveryTime}>
            <Icon
              className={styles.icon}
              {...topIcon} />
            <Text
              className={styles.text}
              {...topText} />
          </div>
          <div className={styles.subContent}>
            <div className={styles.deliveryAddress}>
              <Icon
                className={styles.icon}
                {...bottomIcon} />
              <DropdownToggle
                className={styles.dropdownToggle}
                {...dropdownToggle} />
            </div>
            <div className={styles.deliveryInstruction}>
              <Text
                className={styles.text}
                {...deliveryInstruction} />
            </div>
          </div>
        </div>
      );
      break;
    case 'PickupDefaultDefault':
      textView = (
        <Text
          className={styles.text}
          {...text} />
      );
      contentView = (
        <div className={styles.content}>
          <div className={styles.pickupTime}>
            <Icon
              className={styles.icon}
              {...topIcon} />
            <Text
              className={styles.text}
              {...topText} />
          </div>
          <div className={styles.restaurantAddress}>
            <Icon
              className={styles.icon}
              {...bottomIcon} />
            <Text
              className={styles.text}
              {...bottomText} />
          </div>
        </div>
      );
      break;
    case 'PickupLoadingDefault':
      contentOneView = (
        <div className={styles.contentOne}/>
      );
      contentView = (
        <div className={styles.content}>
          <div className={styles.contentOne}/>
          <div className={styles.contentTwo}/>
          <div className={styles.contentThree}/>
        </div>
      );
      break;
    case 'DeliveryDefaultError':
      textView = (
        <Text
          className={styles.text}
          {...text} />
      );
      contentView = (
        <div className={styles.content}>
          <div className={styles.subContent}>
            <div className={styles.deliveryAddress}>
              <Icon
                className={styles.icon}
                {...bottomIcon} />
              <DropdownToggle
                className={styles.dropdownToggle}
                {...dropdownToggle} />
            </div>
            <div className={styles.deliveryInstruction}>
              <Text
                className={styles.text}
                {...bottomText} />
            </div>
          </div>
        </div>
      );
      errorNotificationView = (
        <ErrorNotification
          className={styles.errorNotification}
          {...errorNotification} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {topDividerView}
      {textView}
      {contentOneView}
      {contentView}
      {errorNotificationView}
      {bottomDividerView}
    </div>
  );
};

DeliveryInfo.defaultProps = defaultProps;

export default DeliveryInfo;
