import { ModifierGroup, Product } from '../../../modules/menu/types';
import {
  SectionHeaderProps,
  defaultProps as sectionHeaderPropsDefaultProps,
} from '../../molecules/SectionHeader/SectionHeader';
import i18n from '../../../modules/locale/i18n';
import {
  RadioOptionItemProps,
  defaultProps as RadioOptionItemDefaultProps,
} from '../../molecules/RadioOptionItem/RadioOptionItem';
import {
  CheckboxOptionItemProps,
  defaultProps as checkboxOptionItemDefaultProps,
} from '../../molecules/CheckboxOptionItem/CheckboxOptionItem';
import {
  MenuItemListProps,
  defaultProps as menuItemListDefaultProps,
} from '../MenuItemList/MenuItemList';
import { SelectableProduct } from './types';
import { formatPrice } from '../../block/MenuBlocksList/utils';
import { MenuItem } from '../../../modules/checkout/types';

const modifierGroupHelperText = (modifierGroupInfo?: ModifierGroup) => {
  let helperText: string[] = [];
  if (modifierGroupInfo) {
    if (modifierGroupInfo.min > 0) {
      helperText.push(i18n.t('menu_modal.menu_template.required'));
    }
    if (modifierGroupInfo.max > 1) {
      helperText.push(
        `${i18n.t('menu_modal.menu_template.choose_upto')} ${
          modifierGroupInfo.max
        }`,
      );
    }
  }

  return helperText.join(' - ');
};
export function convertToSectionHeader(
  modifierGroupInfo?: ModifierGroup,
  isError?: boolean,
): SectionHeaderProps {
  const modifierGroup: SectionHeaderProps = {
    ...sectionHeaderPropsDefaultProps,
    titleText: {
      ...sectionHeaderPropsDefaultProps.titleText,
      value: modifierGroupInfo?.name,
    },
    helpText: {
      ...sectionHeaderPropsDefaultProps.helpText,
      value: modifierGroupHelperText(modifierGroupInfo),
      style: isError ? 'Red800' : 'Grey800',
      type: isError ? 'Body2' : 'Body3',
      align: 'Left',
    },
  };

  return modifierGroup;
}

export const convertToMenuItemList = (
  modifierGroupInfo: ModifierGroup,
  groupProducts: SelectableProduct[],
  selectedItems: string[],
  onItemSelected: (
    id: string,
    selected: boolean,
    type: 'Radio' | 'Checkbox',
  ) => void,
  onQuantityChanged: (
    id: string,
    quantity: string,
    type: 'Radio' | 'Checkbox',
  ) => void,
  isQuantityError?: boolean,
): MenuItemListProps => {
  let menuListItemType: MenuItemListProps = menuItemListDefaultProps;
  if (modifierGroupInfo.min < 2 && modifierGroupInfo.max === 1) {
    menuListItemType = {
      ...menuItemListDefaultProps,
      type: 'Radio',
      radioOptionItems: groupProducts.map((product) => ({
        ...convertToSectionItemRadio(product, onItemSelected),
      })),
    };
  } else {
    const itemsSelectable =
      modifierGroupInfo.max === 0 ||
      selectedItems.length < modifierGroupInfo.max;

    let itemGroupQuantity = 0;
    menuListItemType = {
      ...menuItemListDefaultProps,
      type: 'Checkbox',
      checkboxOptionItems: groupProducts.map((product) => {
        let quantity = selectedItems.filter(
          (selectedId) => selectedId === product._id,
        ).length;
        itemGroupQuantity += quantity;

        return convertToSectionItemCheckBox(
          modifierGroupInfo,
          product,
          itemsSelectable,
          quantity > 0 ? `${quantity}` : '',
          itemGroupQuantity,
          onItemSelected,
          onQuantityChanged,
          isQuantityError,
        );
      }),
    };
  }
  return menuListItemType;
};

export function convertToSectionItemRadio(
  modifierInfo: SelectableProduct,
  onItemSelected: (
    id: string,
    selected: boolean,
    type: 'Radio' | 'Checkbox',
  ) => void,
): RadioOptionItemProps {
  const modifier: RadioOptionItemProps = {
    ...RadioOptionItemDefaultProps,
    radioItem: {
      ...RadioOptionItemDefaultProps.radioItem,
      type: modifierInfo.price ? 'TrailingText' : 'Default',
      icon: {
        ...RadioOptionItemDefaultProps.radioItem.icon,
        asset: modifierInfo.selected ? 'RadioChecked' : 'RadioUnchecked',
      },
      label: {
        ...RadioOptionItemDefaultProps.radioItem.label,
        value: modifierInfo.name,
      },
      trailingText: {
        ...RadioOptionItemDefaultProps.radioItem.trailingText,
        value: `+${formatPrice(modifierInfo.price)}`,
      },
      onRadioItemClicked: () => {
        onItemSelected(modifierInfo._id, true, 'Radio');
      },
    },
    textInput: {
      ...RadioOptionItemDefaultProps.textInput,
      textValue: '1',
    },
    state: modifierInfo.selected ? 'Selected' : 'Default',
    type: modifierInfo.multiMax > 1 ? 'TextField' : 'Default',
  };
  return modifier;
}

export function convertToSectionItemCheckBox(
  itemGroup: ModifierGroup,
  selectedItem: SelectableProduct,
  selectable: boolean,
  quantity: string,
  itemGroupQuantity: number,
  onItemSelected: (id: string, selected: boolean, type: 'Checkbox') => void,
  onQuantityChanged: (
    id: string,
    quantity: string,
    type: 'Radio' | 'Checkbox',
  ) => void,
  isQuantityError?: boolean,
): CheckboxOptionItemProps {
  const modifier: CheckboxOptionItemProps = {
    ...checkboxOptionItemDefaultProps,
    checkboxItem: {
      ...checkboxOptionItemDefaultProps.checkboxItem,
      type: selectedItem.price ? 'TrailingText' : 'Default',
      icon: {
        ...checkboxOptionItemDefaultProps.checkboxItem.icon,
        asset: selectedItem.selected ? 'CheckboxChecked' : 'CheckboxUnchecked',
      },
      label: {
        ...checkboxOptionItemDefaultProps.checkboxItem.label,
        value: selectedItem.name,
      },
      state: selectedItem.selected
        ? selectedItem.quantity && quantity === '0'
          ? 'SelectedZero'
          : 'Selected'
        : 'Unselected',
      trailingText: {
        ...checkboxOptionItemDefaultProps.checkboxItem.trailingText,
        value: `+${formatPrice(selectedItem.price)}`,
      },
      onCheckboxItemClicked: () => {
        onItemSelected(selectedItem._id, !selectedItem.selected, 'Checkbox');
      },
      disabled: selectedItem.selected ? false : !selectable,
    },
    textInput: {
      ...checkboxOptionItemDefaultProps.textInput,
      textValue: quantity,
      onTextChanged: (e) => {
        onQuantityChanged(selectedItem._id, e.target.value, 'Checkbox');
      },
    },
    state:
      itemGroupQuantity > itemGroup.multiMax && selectedItem.selected
        ? 'Error'
        : 'Default',
    errorText: {
      ...checkboxOptionItemDefaultProps.errorText,
      // TODO: translation
      value: `You can only select ${itemGroup.multiMax} items`,
    },
    type: itemGroup.multiMax > 1 ? 'TextField' : 'Default',
  };
  return modifier;
}

export function convertToSelectedMenuItem(
  products: Record<string, Product>,
  selectedItems: string[],
): MenuItem[] {
  const selectedMenuItem: MenuItem[] = [];
  for (const productId in products) {
    if (selectedItems.includes(productId)) {
      const quantity = selectedItems.filter(
        (selectedId) => selectedId === productId,
      ).length;
      const product = products[productId];
      selectedMenuItem.push({
        name: product.name,
        price: product.price,
        productType: product.productType,
        plu: product.plu,
        tax: product.takeawayTax,
        quantity: quantity,
        productId: productId,
      });
    }
  }
  return selectedMenuItem;
}
