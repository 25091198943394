/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPLI_SHARE_LINK } from './constants';
import {
  ShareSheetModalBlockProps, defaultProps,
} from './ShareSheetModalBlock';

// MANUAL OVERRIDE
export type ShareSheetModalBlockPresenterProps = ShareSheetModalBlockProps
const withPresenter = (
  View: React.FC<ShareSheetModalBlockProps>,
): React.FC<ShareSheetModalBlockPresenterProps> => {
  const Presenter: React.FC<ShareSheetModalBlockPresenterProps> = (props) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const toggleModal = (): void => {
      setShowModal((prev) => !prev);
    };
    const blockProps: ShareSheetModalBlockProps = {
      ...defaultProps,
      shareSheetModal: {
        ...defaultProps?.shareSheetModal,
        modalHeader: {
          ...defaultProps?.shareSheetModal?.modalHeader,
          button: {
            ...defaultProps?.shareSheetModal?.modalHeader?.button,
            onButtonClicked: props.onClose,
          },
          menuItem: {
            ...defaultProps?.shareSheetModal?.modalHeader?.menuItem,
            value: t('order_status.share_modal_header_text'),
          },
        },
        topContentText: {
          ...defaultProps?.shareSheetModal?.topContentText,
          value: t('order_status.share_modal_top_content_text'),
        },
        text: {
          ...defaultProps?.shareSheetModal?.text,
          value: t('order_status.share_modal_copy_text'),
        },
        copyLinkField: {
          ...defaultProps?.shareSheetModal?.copyLinkField,
          link: {
            ...defaultProps?.shareSheetModal?.copyLinkField?.link,
            value: SUPPLI_SHARE_LINK,
          },
          copyText: {
            ...defaultProps?.shareSheetModal?.copyLinkField?.copyText,
            value: t('order_status.share_modal_copy_text_button'),
            onClick: () => {
              navigator?.clipboard?.writeText(SUPPLI_SHARE_LINK);
              setShowModal((prev) => !prev);
            }
          }
        },
        sMS:{
          ...defaultProps?.shareSheetModal.sMS,
          onButtonClicked: () => {
            window.location.href = `sms:?&body=${encodeURIComponent(t('order_status.share_modal_message', { shareableLink: SUPPLI_SHARE_LINK }) as string)}`;
          }
        },
        email: {
          ...defaultProps?.shareSheetModal.email,
          onButtonClicked: () => {
            window.location.href = `mailto:?subject=${encodeURIComponent(t('order_status.share_modal_subject') as string)}
                                    &body=${encodeURIComponent(t('order_status.share_modal_message', { shareableLink: SUPPLI_SHARE_LINK }) as string)}`;
          }
        },
        facebook: {
          ...defaultProps?.shareSheetModal.facebook,
          onButtonClicked: () => {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${SUPPLI_SHARE_LINK}&quote=${encodeURIComponent(t('order_status.share_modal_message', { shareableLink: SUPPLI_SHARE_LINK }) as string)}`, '_blank');
          },
        },
        twitter: {
          ...defaultProps?.shareSheetModal.twitter,
          onButtonClicked: () => {
            window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(t('order_status.share_modal_message', { shareableLink: SUPPLI_SHARE_LINK }) as string)}`, '_blank');
          },
        },
        toast: {
          ...defaultProps?.shareSheetModal?.toast,
          text: {
            ...defaultProps?.shareSheetModal?.toast?.text,
            value: t('order_status.share_modal_link_copied'),
          },
        },
        showModal,
        toggleModal,
      },
    };

    return <View {...props} {...blockProps} />;
  };
  return Presenter;
};

export default withPresenter;
