import React from "react";
import { ConfirmationModalBlockProps, defaultProps } from "./ConfirmationModalBlock";
import { formatPrice } from "../MenuBlocksList/utils";
import { MenuItem } from '../../../modules/checkout/types';
import { useTranslation } from "react-i18next";

export type ConfirmationModalBlockPresenterProps = ConfirmationModalBlockProps & {
    removeFromBasket?: (itemPosition: number) => void;
};

const withPresenter = (
    View: React.FC<ConfirmationModalBlockProps>,
): React.FC<ConfirmationModalBlockPresenterProps> => {
    const Presenter: React.FC<ConfirmationModalBlockPresenterProps> = (props) => {
        const { t } = useTranslation()
        const { removeFromBasket, itemToRemove, itemPositionToRemove, closeConfirmation } = props;

        let confirmationModalBlockProps: ConfirmationModalBlockProps = {};
        if (itemToRemove && (itemPositionToRemove || itemPositionToRemove === 0)) {
            confirmationModalBlockProps = {
                ...defaultProps,
                modalHeader: {
                    ...defaultProps.modalHeader,
                    menuItem: {
                        ...defaultProps.modalHeader.menuItem,
                        value: t('confirmation_modal.remove_item_question')
                    },
                    button: {
                        ...defaultProps.modalHeader.button,
                        onButtonClicked: closeConfirmation
                    }
                },
                messageText: {
                    ...defaultProps.messageText,
                    value: t('confirmation_modal.are_you_sure')
                },
                menuItem: {
                    itemQuantity: {
                        ...defaultProps.menuItem.itemQuantity,
                        value: `${itemToRemove?.quantity}x`
                    },
                    itemName: {
                        ...defaultProps.menuItem.itemName,
                        value: itemToRemove?.name
                    },
                    cost: {
                        ...defaultProps.menuItem.cost,
                        value: formatPrice(itemToRemove.price * itemToRemove.quantity),
                    },
                },

                buttonGroup2: {
                    primaryButton: {
                        ...defaultProps.buttonGroup2.primaryButton,
                        text: {
                            ...defaultProps.buttonGroup2.primaryButton?.text,
                            value: t('button.remove_item')
                        },
                        onButtonClicked: () => {
                            removeFromBasket && removeFromBasket(itemPositionToRemove);
                            closeConfirmation && closeConfirmation();
                        }
                    },
                    secondaryButton: {
                        ...defaultProps.buttonGroup2.secondaryButton,
                        text: {
                            ...defaultProps.buttonGroup2.secondaryButton?.text,
                            value: t('button.cancel')
                        },
                        onButtonClicked: closeConfirmation
                    }
                }
            }
        }

        return <View
            {...props}
            {...confirmationModalBlockProps}
        />;
    }
    return Presenter;
};

export default withPresenter;