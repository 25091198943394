import React from 'react';
import cx from 'classnames';

import styles from './CheckboxItem.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

export type CheckboxItemTypeType = 'Default' | 'TrailingText' | 'TrailingIcon';
export type CheckboxItemStateType = 'Unselected' | 'Selected' | 'SelectedZero';
export type CheckboxItemCheckboxItemTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  type: 'TrailingIcon' as CheckboxItemTypeType,
  state: 'Selected' as CheckboxItemStateType,
  icon: {
    asset: 'CheckboxChecked',
    style: 'Grey600',
  } as IconProps,
  label: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey600',
  } as TextProps,
  trailingText: {
    type: 'Body2',
    align: 'Right',
    style: 'Grey700',
  } as TextProps,
  trailingIcon: {
    asset: 'ChevronRight',
    style: 'Grey600',
  } as IconProps,
};

export type CheckboxItemProps = {
  type?: CheckboxItemTypeType;
  state?: CheckboxItemStateType;
  checkboxItemType?: CheckboxItemCheckboxItemTypeType;
  onCheckboxItemClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  label?: TextProps;
  className?: string;
  trailingText?: TextProps;
  trailingIcon?: IconProps;
  disabled?: boolean;
};

const CheckboxItem: React.FC<CheckboxItemProps> = ({
  type,
  state,
  checkboxItemType,
  onCheckboxItemClicked,
  icon,
  label,
  className,
  trailingText,
  trailingIcon,
  disabled,
}) => {
  const currentStyle = styles[`checkboxItem${type}${state}`];

  const iconView = <Icon className={styles.icon} {...icon} />;

  let contentView;

  switch (type) {
    case 'Default':
      contentView = (
        <div className={styles.content}>
          <Text className={styles.label} {...label} />
        </div>
      );
      break;
    case 'TrailingText':
      contentView = (
        <div className={styles.content}>
          <Text className={styles.label} {...label} />
          <Text className={styles.trailingText} {...trailingText} />
        </div>
      );
      break;
    case 'TrailingIcon':
      contentView = (
        <div className={styles.content}>
          <Text className={styles.label} {...label} />
          <Icon className={styles.trailingIcon} {...trailingIcon} />
        </div>
      );
      break;
  }

  return (
    <button
      type={checkboxItemType}
      onClick={onCheckboxItemClicked}
      disabled={disabled}
      className={cx(currentStyle, className)}
    >
      {iconView}
      {contentView}
    </button>
  );
};

CheckboxItem.defaultProps = defaultProps;

export default CheckboxItem;
