import React from 'react';
import cx from 'classnames';

import styles from './EmptyBlock.module.scss';

import Image, { ImageProps } from '../../atoms/Image';
import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  description: {
    type: 'Body1',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
};

export type EmptyBlockProps = {
  image?: ImageProps;
  description?: TextProps;
  className?: string;
};

const EmptyBlock: React.FC<EmptyBlockProps> = ({
  image,
  description,
  className,
}) => {
  return (
    <div className={cx(styles.emptyBlock, className)}>
      <Image
        className={styles.image}
        {...image} />
      <Text
        className={styles.description}
        {...description} />
    </div>
  );
};

EmptyBlock.defaultProps = defaultProps;

export default EmptyBlock;
