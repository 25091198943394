export const CanadaProvinces = ['Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon'];

export const OrderTypeSessionStoragekey = 'orderType';

export const ORDER_SERVICE_DELIVERY_FEE_RATE = 10;

export const ORDER_SERVICE_PICKUP_FEE_RATE = 5;

export const PENDING_ORDER_MAX_TIME = 60000 * 90; // 90 minutes

export const PRODUCT_TYPE_PRODUCT = 1;
export const PRODUCT_TYPE_MODIFIER = 2;
export const PRODUCT_TYPE_MODIFIER_GROUP = 3;
export const PRODUCT_TYPE_BUNDLE = 4;
