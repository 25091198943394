import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  ProfileBlockProps,
  defaultProps,
} from './ProfileBlock';
import {
  formatPhoneNumber, isEmptyString, isMobile, normalizePhoneNumber,
} from '../../../lib/utils';
import {
  UpdateAccountPayload,
} from '../../../modules/account/types';
import { Account } from '../../../lib/token';
import { ToastNotificationProps } from '../../molecules/ToastNotification';

export type ProfileBlockPresenterProps = ProfileBlockProps & {
  updateAccount: (payload: UpdateAccountPayload) => Promise<Account>;
  isLoading: boolean;
  account: Account | null;
  setAccount: (account: Account | null) => void;
  error?: Error;
};

type ProfileDetails = {
  phoneNumber: string;
  firstName: string;
  lastName: string;
};

const withPresenter = (
  View: React.FC<ProfileBlockProps>,
): React.FC<ProfileBlockPresenterProps> => {
  const Presenter: React.FC<ProfileBlockPresenterProps> = (props) => {
    const {
      updateAccount, isLoading, account, setAccount, error,
    } = props;
    const history = useHistory();
    const { t } = useTranslation();
    const [isUpdatingAccount, setIsUpdatingAccount] = useState<boolean>(false);
    const [isUpdateError, setIsUpdateError] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [formState, setFormState] = useState({
      phoneNumber: '',
      firstName: '',
      lastName: '',
      email: '',
      errors: {
        phoneNumber: '',
        firstName: '',
        lastName: '',
      },
    });

    useEffect(() => {
      if (account?.profile) {
        const {
          profile, firstName, lastName, email,
        } = account;
        setFormState((previousState) => ({
          ...previousState,
          phoneNumber: profile.phoneNumber,
          firstName,
          lastName,
          email,
        }));
      }
    }, [account]);

    const [focused, setFocusedState] = useState({
      phoneNumber: false,
      firstName: false,
      lastName: false,
    });

    const handleInputChanged = (key: keyof ProfileDetails) => ({ target: { value } }): void => {
      if (key === 'phoneNumber') {
        setFormState((previousState) => ({
          ...previousState,
          [key]: normalizePhoneNumber(value, previousState.phoneNumber),
          errors: {
            ...previousState.errors,
            [key]: '',
          },
        }));
      } else {
        setFormState((previousState) => ({
          ...previousState,
          [key]: value,
          errors: {
            ...previousState.errors,
            [key]: '',
          },
        }));
      }
    };

    const isFormValid = (): boolean => {
      setFocusedState({
        phoneNumber: false,
        firstName: false,
        lastName: false,
      });

      const {
        phoneNumber,
        firstName,
        lastName,
      } = formState;

      let phoneNumberError = '';
      if (isEmptyString(phoneNumber)) {
        phoneNumberError = t('error.required', {
          field: t('textLabels.phone_number'),
        });
      } else if (!isMobile(phoneNumber)) {
        phoneNumberError = t('error.format.phone_number');
      }

      let firstNameError = '';
      if (isEmptyString(firstName)) {
        firstNameError = t('error.required', {
          field: t('textLabels.first_name'),
        });
      }

      let lastNameError = '';
      if (isEmptyString(lastName)) {
        lastNameError = t('error.required', {
          field: t('textLabels.last_name'),
        });
      }

      const errors = {
        phoneNumber: phoneNumberError,
        firstName: firstNameError,
        lastName: lastNameError,
      };

      setFormState((previousState) => ({
        ...previousState,
        errors,
      }));
      return (
        Object.values(errors).filter((errorMessage: string) => !!errorMessage)
          .length === 0
      );
    };

    const handleUpdateAccount = async (): Promise<void> => {
      if (isFormValid() && account) {
        setIsUpdatingAccount(true);
        const {
          phoneNumber,
          firstName,
          lastName,
        } = formState;
        try {
          if (account.profile) {
            const data = await updateAccount({
              id: account.id,
              firstName,
              lastName,
              profile: {
                ...account.profile,
                phoneNumber: formatPhoneNumber(phoneNumber),
              },
            });
            setAccount(data);
          }
        } catch {
          setIsUpdateError(true);
        } finally {
          setShowToast(true);
          setIsUpdatingAccount(false);
        }
      }
    };

    useEffect(() => {
      const newFocusedState = {
        phoneNumber: false,
        firstName: false,
        lastName: false,
      };

      if (formState.errors.phoneNumber) {
        newFocusedState.phoneNumber = true;
      } else if (formState.errors.firstName) {
        newFocusedState.firstName = true;
      } else if (formState.errors.lastName) {
        newFocusedState.lastName = true;
      }

      setFocusedState(newFocusedState);
    }, [formState.errors]);

    useEffect(() => {
      setFormState((previousState) => ({
        ...previousState,
        errors: {
          ...previousState.errors,
          phoneNumber: formState.phoneNumber ? '' : previousState.errors.phoneNumber,
        },
      }));
    }, [formState.phoneNumber]);

    const closeToast = (): void => {
      setShowToast(false);
    };

    const toastNotificationProps: ToastNotificationProps = {
      ...defaultProps.toast,
      type: 'Success',
      icon: {
        ...defaultProps.toast.icon,
        asset: 'CheckCircle',
        style: 'Green700',
      },
      text: {
        ...defaultProps.toast.text,
        value: t('profile_block.success'),
      },
      show: showToast,
      onClose: closeToast,
    };

    const toastErrorNotificationProps: ToastNotificationProps = {
      ...defaultProps.toast,
      type: 'Error',
      icon: {
        ...defaultProps.errorToast.icon,
      },
      text: {
        ...defaultProps.errorToast.text,
        value: t('profile_block.error'),
      },
      show: showToast,
      onClose: closeToast,
    };

    const profileBlockProps: ProfileBlockProps = {
      ...defaultProps,
      title: {
        ...defaultProps.title,
        value: t('profile_block.title'),
      },
      firstName: {
        ...defaultProps.phoneNumber,
        state: formState.errors.firstName ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.firstName.textInput,
          onTextChanged: handleInputChanged('firstName'),
          textPlaceholder: t('textLabels.first_name'),
          textValue: formState.firstName,
          focused: focused.firstName,
        },
        label: {
          ...defaultProps.firstName?.label,
          value: t('textLabels.first_name'),
        },
        errorText: {
          value: formState.errors.firstName,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      lastName: {
        ...defaultProps.phoneNumber,
        state: formState.errors.lastName ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.lastName.textInput,
          onTextChanged: handleInputChanged('lastName'),
          textPlaceholder: t('textLabels.last_name'),
          textValue: formState.lastName,
          focused: focused.lastName,
        },
        label: {
          ...defaultProps.lastName?.label,
          value: t('textLabels.last_name'),
        },
        errorText: {
          value: formState.errors.lastName,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      phoneNumber: {
        ...defaultProps.phoneNumber,
        state: formState.errors.phoneNumber ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.phoneNumber.textInput,
          onTextChanged: handleInputChanged('phoneNumber'),
          textPlaceholder: t('textLabels.phone_number'),
          textValue: formState.phoneNumber,
          focused: focused.phoneNumber,
        },
        label: {
          ...defaultProps.phoneNumber?.label,
          value: t('textLabels.phone_number'),
        },
        errorText: {
          value: formState.errors.phoneNumber,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      emailAddress: {
        ...defaultProps.emailAddress,
        state: 'Disabled',
        textInput: {
          ...defaultProps.emailAddress.textInput,
          textPlaceholder: formState.email,
          disabled: true,
        },
        label: {
          ...defaultProps.lastName?.label,
          value: t('textLabels.email'),
        },
      },
      changePassword: {
        ...defaultProps?.changePassword,
        value: t('profile_block.change_password'),
        onClick: (): void => history.push('/profile/changePassword'),
      },
      button: {
        ...defaultProps.button,
        onButtonClicked: handleUpdateAccount,
        text: {
          ...defaultProps.button?.text,
          value: t('textLabels.update'),
        },
        disabled: ((isLoading || isUpdatingAccount) && !error),
      },
      toast: isUpdateError ? toastErrorNotificationProps : toastNotificationProps,
      toastMessage: showToast,
    };

    return <View {...props} {...profileBlockProps} />;
  };

  return Presenter;
};

export default withPresenter;
