import React from 'react';
import cx from 'classnames';

import styles from './SelectField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Select, { SelectProps } from '../../atoms/Select';

export type SelectFieldStateType = 'Default' | 'Error';

export const defaultProps = {
  state: 'Default' as SelectFieldStateType,
  label: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  select: {
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'Grey1100',
    },
  } as SelectProps,
  errorText: {
    type: 'Body2',
    align: 'Left',
    style: 'Red800',
  } as TextProps,
};

export type SelectFieldProps = {
  state?: SelectFieldStateType;
  label?: TextProps;
  select?: SelectProps;
  errorText?: TextProps;
  className?: string;
};

const SelectField: React.FC<SelectFieldProps> = ({
  state,
  label,
  select,
  errorText,
  className,
}) => {
  const currentStyle = styles[`selectField${state}`];
  const errorTextView = <Text className={styles.errorText} {...errorText} />;

  return (
    <div className={cx(currentStyle, className)}>
      <Text className={styles.label} {...label} />
      <Select className={styles.select} {...select} />
      {errorTextView}
    </div>
  );
};

SelectField.defaultProps = defaultProps;

export default SelectField;
