import React from "react";
import { CreatePasswordBlockProps } from "./CreatePasswordBlock";
import { CreatePasswordBlockPresenterProps } from "./CreatePasswordBlock.presenter";
import { useMutation } from "react-query";
import { updatePassword } from '../../../modules/account/api';

const withInteractor = (
  Presenter: React.FC<CreatePasswordBlockPresenterProps>,
): React.FC<CreatePasswordBlockProps> => {
  const Interactor: React.FC<CreatePasswordBlockProps> = (props) => {
    const { mutateAsync, isLoading } = useMutation(updatePassword);

    return (
      <Presenter 
        {...props}
        updatePassword={mutateAsync} 
        isLoading={isLoading}
      />
    );
  };
  return Interactor;
};
export default withInteractor;