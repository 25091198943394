import React from 'react';
import cx from 'classnames';

import styles from './TagMenu.module.scss';

import TabList, { TabListProps } from '../../organisms/TabList';
import { MenuResponseObject } from '../../../modules/menu/types';

export const defaultProps = {
  tabList: {
    tabs: [
    ],
  } as TabListProps,
};

export type TagMenuProps = {
  tabList?: TabListProps;
  menu?: MenuResponseObject[];
  setFocusedBlockId?: (blockId: string) => void;
  className?: string;
  focusedBlockId?: string;
  scrolledBlockId?: string;
};

const TagMenu: React.FC<TagMenuProps> = ({
  tabList,
  className,
  focusedBlockId,
  scrolledBlockId,
}) => (
    <div className={cx(styles.tagMenu, className)}>
      <TabList
        className={styles.tabList}
        focusedBlockId={focusedBlockId}
        scrolledBlockId={scrolledBlockId}
        {...tabList}
        />
    </div>
);

TagMenu.defaultProps = defaultProps;

export default TagMenu;
