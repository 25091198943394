import axios, { isAxiosError } from '../../lib/api/axios';
import { ApiError } from '../../lib/api/types';
import { BFF_URL } from '../../lib/config';
import { getRestaurant } from '../restaurant/api';
import { RestaurantDetailPayload } from '../restaurant/types';
import { CreatePaymentIntent, OrderObject, OrderTotals, PaymentIntent, RestaurantOrder } from './types';

export const calculateTotals = async (payload: CreatePaymentIntent): Promise<OrderTotals> => {
  try {
      const { data } = await axios.post<OrderTotals>(
          `${BFF_URL}/payment/calculateTotals`, 
          payload, 
          { withCredentials: true },
      );
      return data;
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        throw new ApiError({
          code: error.response.status,
          type: error.response.statusText,
          message: error.message,
        });
      }
      throw error;
  }
}

export const createPaymentIntent = async (payload: CreatePaymentIntent): Promise<PaymentIntent> => {
    try {
        const { data } = await axios.post<PaymentIntent>(
            `${BFF_URL}/payment/createPaymentIntent`, 
            payload, 
            { withCredentials: true },
        );
        return data;
      } catch (error) {
        if (isAxiosError(error) && error.response) {
          throw new ApiError({
            code: error.response.status,
            type: error.response.statusText,
            message: error.message,
          });
        }
        throw error;
    }
}


export const getOrder = async (id: string, payload?: RestaurantDetailPayload): Promise<RestaurantOrder> => {
  try {
    const { data } = await axios.get<OrderObject>(
      `${BFF_URL}/orders/${id}`
    );
    const restaurant = await getRestaurant(data.restaurantid, payload);
    return {
      ...data,
      restaurant,
    };
  } catch (err) {
    throw new Error();
  }
};


export const getOrders = async (): Promise<OrderObject[]> => {
  try {
    const { data } = await axios.get<OrderObject[]>(
      `${BFF_URL}/orders`
    );
    return data;
  } catch (err) {
    throw new Error();
  }
};