import React, { useContext } from 'react';
import { ProfileMenuModalPresenterProps } from './ProfileMenuModal.presenter';
import { ProfileMenuModalProps } from '.';
import { AuthContext } from '../../../modules/auth';
import { CheckoutContext } from '../../../modules/checkout';

const withInteractor = (
  Presenter: React.FC<ProfileMenuModalPresenterProps>,
): React.FC<ProfileMenuModalProps> => {
  const Interactor: React.FC<ProfileMenuModalProps> = (props) => {
    const { account, logout } = useContext(AuthContext);
    const { basket } = useContext(CheckoutContext);

    return (
      <Presenter
        {...props}
        account={account}
        basket={basket}
        logout={logout}
      />
    );
  };
  return Interactor;
};
export default withInteractor;
