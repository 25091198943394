import React, { useEffect, useState } from 'react';
import { TagMenuProps } from './TagMenu';
import { defaultProps } from '../../pages/RestaurantPage/RestaurantPage';
import { defaultProps as tabDefaultProps } from '../../atoms/Tab/Tab';
import { TabProps } from '../../atoms/Tab';
import { Category } from '../../../modules/menu/types';
import { nameToSlug } from '../../../lib/utils';

export type TagMenuPresenterProps = TagMenuProps;

const withPresenter = (
  View: React.FC<TagMenuProps>,
): React.FC<TagMenuPresenterProps> => {
  const Presenter: React.FC<TagMenuPresenterProps> = (props) => {
    const {
      menu, setFocusedBlockId, focusedBlockId, scrolledBlockId,
    } = props;
    const [selectedTab, setSelectedTab] = useState<string>();

    useEffect(() => {
      if (focusedBlockId && setFocusedBlockId) {
        setSelectedTab(focusedBlockId);
      }
    }, [focusedBlockId, setFocusedBlockId]);

    useEffect(() => {
      if (scrolledBlockId && setFocusedBlockId) {
        setSelectedTab(scrolledBlockId);
      }
    }, [scrolledBlockId, setFocusedBlockId]);

    let tabContent: TabProps[] = [];
    if (menu && menu.length > 0) {
      tabContent = menu[0].menuobject.categories.map((tab: Category, index: number) => {
        const tabProps: TabProps = {
          ...tabDefaultProps,
          tabId: tab._id,
          state: 'Unselected',
          text: {
            ...tabDefaultProps.text,
            value: tab.name,
          },
          onTabClicked: (): void => {
            setSelectedTab(tab._id);
            setFocusedBlockId && setFocusedBlockId(tab._id);
          },
        };

        if ((!selectedTab && index === 0) || selectedTab === tab._id) {
          tabProps.state = 'Selected';
          tabProps.text = {
            ...tabProps.text,
            type: 'Body5',
            style: 'Green800',
          };
        }
        return tabProps;
      });
    }

    const blockProps: TagMenuProps = {
      ...defaultProps.tagMenu,
      tabList: {
        ...defaultProps.tagMenu.tabList,
        tabs: tabContent,
      },
      focusedBlockId,
    };

    return <View {...props} {...blockProps} />;
  };
  return Presenter;
};

export default withPresenter;
