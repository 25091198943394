import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MenuModalBlockPresenterProps } from './MenuModalBlock.presenter';
import { MenuModalBlockProps } from '.';
import { CheckoutContext } from '../../../modules/checkout';
import { CustomerBasket, MenuItem } from '../../../modules/checkout/types';
import { Restaurant } from '../../../modules/restaurant/types';
import {
  MenuResponseObject,
  MenuPayload,
  Product,
} from '../../../modules/menu/types';
import { formatDeliveryETA } from '../../../lib/utils';

const withInteractor = (
  Presenter: React.FC<MenuModalBlockPresenterProps>,
): React.FC<MenuModalBlockProps> => {
  const Interactor: React.FC<MenuModalBlockProps> = (props) => {
    const { menu: allMenus, productId } = props;
    const { basket, updateBasket, orderType } = useContext(CheckoutContext);

    const [productData, setProductData] = useState<Product>();
    const [menu, setMenu] = useState<MenuResponseObject>();
    const [menuObject, setMenuObject] = useState<MenuPayload>();

    useEffect(() => {
      if (allMenus && allMenus.length > 0 && productId) {
        const firstMenu = allMenus[0];
        const menuObject = firstMenu.menuobject;
        const product = menuObject.products[productId];

        setMenu(firstMenu);
        setMenuObject(menuObject);
        setProductData(product);
      }
    }, [allMenus, productId]);
    
    const handleAddToNewCart = useCallback(
      (
        item: MenuItem,
        restaurant: Restaurant,
        menu: MenuResponseObject,
      ): void => {
        const newBasket: CustomerBasket = {
          restaurantId: restaurant.restaurantid,
          restaurantName: restaurant.details?.name || '',
          restaurantAddress: restaurant.details?.address || '',
          restaurantPrepRange: restaurant.details?.prepRange || '',
          menuId: `${menu.id}`,
          orderType,
          items: [{ 
            ...item,
            remark: item.remark?.trim() 
          }],
          delivery: restaurant.details?.delivery,
          pickup: restaurant.details?.pickup,
          deliveryETA: formatDeliveryETA(restaurant.details?.distance, restaurant.details?.prepTime),
        };
        updateBasket(newBasket);
      },
      [updateBasket],
    );

    const handleAddToCart = useCallback(
      (
        item: MenuItem,
        restaurant: Restaurant,
        menu: MenuResponseObject,
      ): void => {
        if (basket) {
          const newBasket = {
            ...basket,
            items: [...basket.items, {
              ...item,
              remark: item.remark?.trim()
            }],
            delivery: restaurant.details?.delivery,
            pickup: restaurant.details?.pickup,
            deliveryETA: formatDeliveryETA(restaurant.details?.distance, restaurant.details?.prepTime),
            restaurantDistance: restaurant.details?.distance || 0
          } as CustomerBasket;
          updateBasket(newBasket);

        } else {
          handleAddToNewCart(item, restaurant, menu);
        }
      },
      [handleAddToNewCart, basket, updateBasket],
    );

    const handleEditCart = useCallback(
      (item: MenuItem, position: number) => {
        if (basket) {
          const newBasket = {
            ...basket,
            items: [...basket.items],
          } as CustomerBasket;
          newBasket.items[position] = {
            ...item,
              remark: item.remark?.trim()
          };
          updateBasket(newBasket);
        }
      },
      [basket, updateBasket],
    );

    return (
      <Presenter
        {...props}
        basket={basket}
        firstMenu={menu}
        menuObject={menuObject}
        productData={productData}
        handleAddToNewCart={handleAddToNewCart}
        handleAddToCart={handleAddToCart}
        handleEditCart={handleEditCart}
      />
    );
  };
  return Interactor;
};
export default withInteractor;
