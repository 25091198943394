import React from 'react';
import cx from 'classnames';

import styles from './LineItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  labelText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey800',
  } as TextProps,
  contentText: {
    type: 'Body5',
    align: 'Left',
    style: 'Grey800',
  } as TextProps,
};

export type LineItemProps = {
  labelText?: TextProps;
  contentText?: TextProps;
  className?: string;
};

const LineItem: React.FC<LineItemProps> = ({
  labelText,
  contentText,
  className,
}) => {
  return (
    <div className={cx(styles.lineItem, className)}>
      <Text
        className={styles.labelText}
        {...labelText} />
      <Text
        className={styles.contentText}
        {...contentText} />
    </div>
  );
};

LineItem.defaultProps = defaultProps;

export default LineItem;
