import React from 'react';
import cx from 'classnames';

import styles from './ProfileBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import Divider, { DividerProps } from '../../atoms/Divider';
import Button, { ButtonProps } from '../../atoms/Button';
import ToastNotification, { ToastNotificationProps } from '../../molecules/ToastNotification';

export const defaultProps = {
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  firstName: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  lastName: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  phoneNumber: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  emailAddress: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  changePassword: {
    type: 'Body2',
    align: 'Center',
    style: 'Green800',
  } as TextProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  errorToast: {
    type: 'Error',
    icon: {
      asset: 'ErrorCircle',
      style: 'Red800',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
  } as ToastNotificationProps,
  toast: {
    type: 'Success',
    icon: {
      asset: 'CheckCircle',
      style: 'Green700',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Green800',
    },
  } as ToastNotificationProps,
};

export type ProfileBlockProps = {
  title?: TextProps;
  firstName?: TextFieldProps;
  lastName?: TextFieldProps;
  phoneNumber?: TextFieldProps;
  emailAddress?: TextFieldProps;
  changePassword?: TextProps;
  divider?: DividerProps;
  button?: ButtonProps;
  className?: string;
  error?: Error;
  toast?: ToastNotificationProps;
  toastMessage?: boolean;
};

const ProfileBlock: React.FC<ProfileBlockProps> = ({
  title,
  firstName,
  lastName,
  phoneNumber,
  emailAddress,
  changePassword,
  divider,
  button,
  className,
  toastMessage,
  toast,
}) => {
  let toastNotif;
  if (toastMessage) {
    toastNotif = <ToastNotification {...toast} />;
  }
  return (
    <div className={cx(styles.profileBlock, className)}>
      {toastNotif}
      <div className={styles.content}>
        <div className={styles.bodyContent}>
          <Text
            className={styles.title}
            {...title} />
          <TextField
            className={styles.firstName}
            {...firstName} />
          <TextField
            className={styles.lastName}
            {...lastName} />
          <TextField
            className={styles.phoneNumber}
            {...phoneNumber} />
          <TextField
            className={styles.emailAddress}
            {...emailAddress} />
          <Text
            className={styles.changePassword}
            {...changePassword} />
        </div>
        <div className={styles.frame2}>
          <Divider
            className={styles.divider}
            {...divider} />
          <Button
            className={styles.button}
            {...button} />
        </div>
      </div>
    </div>
  );
};

ProfileBlock.defaultProps = defaultProps;

export default ProfileBlock;
