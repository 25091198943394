import React from "react";
import ErrorHandler from "./ErrorHandler";

export type ErrorHandlerProps = {
  error?: Error;
};

const withErrorHandler = (View: React.FC<ErrorHandlerProps>) => {
  const Wrapper: React.FC<ErrorHandlerProps> = (props) => {
    if (props.error) {
      return <ErrorHandler error={props.error} />;
    }
    return <View {...props} />;
  };
  return Wrapper;
};

export default withErrorHandler;