import axios from "axios";
import { BFF_URL } from "../../lib/config";
import { MenuResponseObject } from "./types";

export const getMenuList = async (id: string): Promise<MenuResponseObject[]> => {
  try {
    const { data } = await axios.get<MenuResponseObject[]>(
      `${BFF_URL}/menus?restaurantid=${id}`
    );
    return data;
  } catch (err) {
    throw new Error();
  }
};
