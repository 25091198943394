import React from 'react';
import cx from 'classnames';

import styles from './ModalHeader.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';
import Divider, { DividerProps } from '../../atoms/Divider';

export type ModalHeaderTypeType = 'Close' | 'Back';

export const defaultProps = {
  type: 'Back' as ModalHeaderTypeType,
  menuItem: {
    type: 'Title4',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  button: {
    size: 'ExtraSmall',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'Back',
      style: 'Grey1100',
    },
  } as ButtonProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
};

export type ModalHeaderProps = {
  type?: ModalHeaderTypeType;
  menuItem?: TextProps;
  button?: ButtonProps;
  divider?: DividerProps;
  className?: string;
};

const ModalHeader: React.FC<ModalHeaderProps> = ({
  type,
  menuItem,
  button,
  divider,
  className,
}) => {

  const currentStyle = styles[`modalHeader${type}`];

  const dividerView = (
    <Divider
      className={styles.divider}
      {...divider} />
  );
  
  let topContentView;
  
  switch (type) {
    case 'Close':
      topContentView = (
        <div className={styles.topContent}>
          <Text
            className={styles.menuItem}
            {...menuItem} />
          <Button
            className={styles.button}
            {...button} />
        </div>
      );
      break;
    case 'Back':
      topContentView = (
        <div className={styles.topContent}>
          <Button
            className={styles.button}
            {...button} />
          <Text
            className={styles.menuItem}
            {...menuItem} />
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {topContentView}
      {dividerView}
    </div>
  );
};

ModalHeader.defaultProps = defaultProps;

export default ModalHeader;
