import React from 'react';
import cx from 'classnames';

import styles from './CardInfo.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextInput, { TextInputProps } from '../../atoms/TextInput';
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';

export const defaultProps = {
  label: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
};

export type CardInfoProps = {
  label?: TextProps;
  cardElementOptions?: StripeCardElementOptions;
  handleChange?: (event: StripeCardElementChangeEvent) => any,
  error?: TextProps;
  className?: string;
};

const CardInfo: React.FC<CardInfoProps> = ({
  label,
  cardElementOptions,
  handleChange,
  error,
  className,
}) => {
  const errorView = error ? (
    <Text
      className={styles.label}
      {...error} />
  ): undefined;
  const containerStyle = error ? styles.stripeContainerError : styles.stripeContainer;
  return (
    <div className={cx(styles.cardInfo, className)}>
      <div className={styles.cardNumber}>
        <Text
          className={styles.label}
          {...label} />
        <div className={containerStyle}>
          <CardElement 
            id="card-element" 
            options={cardElementOptions}
            onChange={handleChange}/>
        </div>
        {errorView}
      </div>
    </div>
  );
};

CardInfo.defaultProps = defaultProps;

export default CardInfo;
