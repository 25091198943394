import React, { useEffect, useRef, useCallback } from 'react';
import cx from 'classnames';

import styles from './MenuListItemBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import FoodItemCardList, {
  FoodItemCardListProps,
} from '../../organisms/FoodItemCardList';

export const defaultProps = {
  blockTitle: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  foodItemCardList: {
    foodItemCards: [],
  } as FoodItemCardListProps,
};

export type MenuListItemBlockProps = {
  blockId?: string;
  blockTitle?: TextProps;
  foodItemCardList?: FoodItemCardListProps;
  focusedBlockId?: string;
  className?: string;
  isLoading?: boolean;
  blocksOffsets?: Map<string, object>;
  setBlocksOffsets?: (blocksOffsets: Map<string, object>) => void;
};

const MenuListItemBlock: React.FC<MenuListItemBlockProps> = ({
  blockId,
  blockTitle,
  foodItemCardList,
  focusedBlockId,
  className,
  blocksOffsets,
  setBlocksOffsets,
}) => {
  const blockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (blockRef.current && blockId && focusedBlockId === blockId) {
      blockRef.current?.scrollIntoView();
    }
  }, [blockId, focusedBlockId, blockRef]);

  const updateBlockOffsets = useCallback((): void => {
    if (blocksOffsets && setBlocksOffsets && blockId) {
      setBlocksOffsets(blocksOffsets.set(blockId, blockRef));
    }
  }, [blockId, blocksOffsets, setBlocksOffsets]);

  useEffect(() => {
    updateBlockOffsets();
    window.addEventListener('resize', updateBlockOffsets);
    return (): void => {
      window.removeEventListener('resize', updateBlockOffsets);
    };
  }, [blockId, updateBlockOffsets]);

  return (
    <div
      id={blockId}
      ref={blockRef}
      className={cx(styles.menuListItemBlock, className)}>
      <Text className={styles.blockTitle} {...blockTitle} />
      <FoodItemCardList
        className={styles.foodItemCardList}
        {...foodItemCardList}
      />
    </div>
  );
};

MenuListItemBlock.defaultProps = defaultProps;

export default MenuListItemBlock;
