import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { Account } from '../../../lib/token';
import { CreatePaymentIntent, CustomerBasket, OrderTotals, PaymentIntent, TipInfo } from '../../../modules/checkout/types';
import { PriceItemLoadingType } from '../../molecules/PriceItem/PriceItem';

import Tip from '../../../resources/images/Tip.png';
import { RadioItemProps } from '../../atoms/RadioItem';
import { defaultProps as radioItemDefaultProps } from '../../atoms/RadioItem/RadioItem';

import { AddTipBlockProps, defaultProps } from './AddTipBlock';
import { formatPrice, isValidAmount } from '../MenuBlocksList/utils';
import { isNumberOrDot } from '../../../lib/utils';


export type AddTipBlockPresenterProps = AddTipBlockProps & {
  account: Account | null;
  basket: CustomerBasket | undefined;
  calculateTotals: (payload: CreatePaymentIntent) => Promise<OrderTotals>;
  createPaymentIntent: (payload: CreatePaymentIntent) => Promise<PaymentIntent>;
  handleDropdownToggleClick?: () => void;
  error?: Error;
};

const withPresenter = (
  View: React.FC<AddTipBlockProps>
): React.FC<AddTipBlockPresenterProps> => {
    const Presenter: React.FC<AddTipBlockPresenterProps> = (props) => {
      const {
        account,
        basket,
        calculateTotals,
        createPaymentIntent,
    } = props;

    const FIXED_TIP_PERCENTS = [5, 10, 15, 20];
    const history = useHistory();
    const { t } = useTranslation();

    const [orderTotals, setOrderTotals] = useState<OrderTotals>();
    const [tipsInfo, setTipsInfo] = useState<TipInfo[]>();
    const [selectedTip, setSelectedValue] = useState(0);
    const [orderTotalPrice, setOrderTotalPrice] = useState(0);
    const [radioItems, setRadioItems] = useState<RadioItemProps[]>();
    const [priceItemsLoading, setPriceItemsLoading] = useState<PriceItemLoadingType>('Loading');
    const [customAmountState, setCustomAmountState] = useState<'Selected' | 'Default'>('Default');
    const [customAmountError, setCustomAmountError] = useState('');

    const defaultAddress = account?.profile?.address ? account.profile.address[0] : undefined;

    const doCalculateTipsInfo = useCallback((totalPrice: number) => {
      if (totalPrice) {
        const info = FIXED_TIP_PERCENTS.map((percentage) => {
          const price = ((totalPrice / 100) * percentage).toFixed(2);
          const sum = parseInt(price);
          if (percentage === 10 ) {
            setSelectedValue(sum);
          } 
          return {
            sum,
            percentage,
            state: percentage === 10 ? 'Selected' : 'Unselected',
          } as TipInfo
        });

        setTipsInfo(info);
      }
    }, [basket]);

    const doCalculateTotals = useCallback( async (id: string, basket: CustomerBasket) => {
      try {
        const response = await calculateTotals({
          restaurantId: basket.restaurantId,
          menuId: basket.menuId,
          items: basket.items,
          userId: id,
          orderType: basket.orderType
        });
        setOrderTotals(response);
        doCalculateTipsInfo(response.subtotal);
        setPriceItemsLoading('Default');
      } catch {
          // no-op
      }
    }, [account, basket]);

    const handleTipFocus = (percentage?: number) => {
      setCustomAmountState('Default');
      let updatedTipsInfo: TipInfo[];
      if (percentage && tipsInfo) {
        updatedTipsInfo = tipsInfo.map((tip) => {
          if (tip.percentage === percentage) {
            setSelectedValue(tip.sum);
          }
          return {
            ...tip,
            state: tip.percentage === percentage ? 'Selected' : 'Unselected'
          } as TipInfo
        });

      setTipsInfo(updatedTipsInfo);
      } else if (tipsInfo) {
        updatedTipsInfo = tipsInfo.map((tip) => {
          return {
            ...tip,
            state: 'Unselected'
          } as TipInfo
        });

        setTipsInfo(updatedTipsInfo);
      }
    };

    useEffect(() => {
      if (account && basket) {
          doCalculateTotals(account.uuid, basket);
      }
    }, [account, basket]);

    useEffect(() => {
      if(tipsInfo && tipsInfo.length) {
        const radioItems: RadioItemProps[] = tipsInfo?.map((tip) => {
          return {
            ...radioItemDefaultProps,
            type: "RadioCardItem",
            state: tip.state,
            radioItemType: "button",
            primaryText: {
              ...radioItemDefaultProps.primaryText,
              value: `${tip.percentage}%`,
            },
            secondaryText: {
              ...radioItemDefaultProps.secondaryText,
              value: formatPrice(tip.sum)
            },
            label: {value: 'label'},
            onRadioItemClicked: () => handleTipFocus(tip.percentage)
          }
        });

        setRadioItems(radioItems);
      }
    }, [tipsInfo]);

    useEffect(() => {
      if (orderTotals) {
        setOrderTotalPrice(selectedTip + orderTotals?.ordertotal);
      }
    }, [selectedTip, orderTotals]);

    if (!basket || !basket.items) {
        // if the basket is empty then exit checkout
        return <Redirect to='/' />
    }

    const handlePaymentClicked = async () => {
      // TODO: should prompt error if user doesn't have a valid address(empty or out of delivery range) or account so user can take action
      if(account && defaultAddress) {
        const preferredAddress = account.profile?.preferredAddress ? account.profile.preferredAddress : defaultAddress;
        const paymentIntent = await createPaymentIntent({
          restaurantId: basket.restaurantId,
          menuId: basket.menuId,
          items: basket.items,
          userId: account.uuid,
          tip: selectedTip,
          orderType: basket.orderType,
          deliveryAddress: preferredAddress,
          deliveryTime: basket.deliveryETA,
        });

        history.push('/checkout/payment', paymentIntent);
      }
    }

    const onCustomTextInputBlur = (e) => {
      const input = e.target.value;
      if (input === '') {
        setSelectedValue(0);
        setCustomAmountState('Default');
      } else {
        if (!isValidAmount(input)) {
          setCustomAmountError(t('error.valid_tip_amount'));
        } else {
          setCustomAmountError('');
          setSelectedValue(parseFloat(input) * 100);
        }
      }
    };

    const onCustomTextInputKeypress = (e) => {
      if (!isNumberOrDot(e.key)) {
        e.preventDefault();
      }
    };

    const onCustomAmountButtonClicked = () => {
      handleTipFocus();
      setCustomAmountState('Selected');
    };

    const onCustomTextInputKeyDown = (e) => {
      if (e.keyCode === 13) {
        e.target.blur();
      }
    };

    const addTipBlockProps = {
      ...defaultProps,
      backButton: {
        ...defaultProps.backButton,
        text: {
          ...defaultProps.backButton.text,
          value: t('button.back'),
        },
        onButtonClicked: () => {
          history.goBack();
        },
      },
      title : {
        ...defaultProps.title,
        value: t("checkout.tip.title")
      },
      description: {
        ...defaultProps.description,
        value: t("checkout.tip.description")
      },
      image: {
        srcValue: Tip
      },
      button: {
        ...defaultProps.button,
        text: {
          ...defaultProps.button.text,
          value: t("button.next"),
          disabled: !orderTotals,
        },
        onButtonClicked: handlePaymentClicked
      },
      radioItemList: {
        radioItems: radioItems && radioItems.length ? radioItems : undefined,
      },
      customAmount: {
        ...defaultProps.customAmount,
        state: customAmountState,
        button: {
          ...defaultProps.customAmount.button,
          text: {
            ...defaultProps.button.text,
            value: t('button.add_custom'),
          },
        },
        onButtonClicked: onCustomAmountButtonClicked,
        textInput: {
          ...defaultProps.customAmount.textInput,
          autoFocus: true,
          textPlaceholder: t('textLabels.custom_amount_placeholder'),
          onKeyPress: onCustomTextInputKeypress,
          onBlur: onCustomTextInputBlur,
          onKeyDown: onCustomTextInputKeyDown,
        },
        errorText: {
          ...defaultProps.customAmount.errorText,
          value: customAmountError,
        },
      },
      tipAmount: {
        ...defaultProps.tipAmount,
        loading: priceItemsLoading,
        price: {
          ...defaultProps.tipAmount.price,
          value: formatPrice(selectedTip)
        },
        item: {
          ...defaultProps.tipAmount.item,
          value: t('textLabels.tip_amount')
        } ,
      },
      total: {
        ...defaultProps.total,
        loading: priceItemsLoading,
        price: {
          ...defaultProps.total.price,
          value: orderTotals && formatPrice(orderTotalPrice)
        },
        item: {
          ...defaultProps.total.item,
          value: t('textLabels.total'),
        },
      },
      loading: false,
    };

    if (!orderTotals) {
      return <View
        {...props}
        {...addTipBlockProps}
        loading={true}
      />
    }


    return <View 
      {...props} 
      {...addTipBlockProps} />
    }

  return Presenter
}

export default withPresenter;
