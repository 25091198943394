import React from 'react';
import cx from 'classnames';

import styles from './Address.module.scss';

import TextField, { TextFieldProps } from '../TextField';
import TextInput, { TextInputProps } from '../../atoms/TextInput';
import SelectField, { SelectFieldProps } from '../SelectField';
import Text, { TextProps } from '../../atoms/Text';

export type AddressState = 'Default' | 'Error';

export const defaultProps = {
  addressLine1: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  addressLine2: {
    type: 'Text',
    style: 'Default',
  } as TextInputProps,
  city: {
    state: 'Default',
    type: 'Text',
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  postalCode: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  province: {
    select: {
      options: [],
      selectId: 'province',
    },
  } as SelectFieldProps,
  country: {
    type: 'Text',
    style: 'Default',
  } as TextInputProps,
  errorText: {
    style: 'Red800',
    align: 'Left',
    type: 'Body2',
  } as TextProps,
  state: 'Default' as AddressState,
};

export type AddressProps = {
  addressLine1?: TextFieldProps;
  addressLine2?: TextInputProps;
  city?: TextFieldProps;
  postalCode?: TextFieldProps;
  province?: SelectFieldProps;
  country?: TextInputProps;
  className?: string;
  state?: AddressState;
  errorText?: TextProps;
};

const Address: React.FC<AddressProps> = ({
  addressLine1,
  addressLine2,
  city,
  postalCode,
  province,
  country,
  className,
  state,
  errorText,
}) => {
  return (
    <div className={cx(styles.address, className)}>
      <TextField className={styles.addressLine1} {...addressLine1} />
      <TextInput className={styles.addressLine2} {...addressLine2} />
      <div className={styles.row1}>
        <TextField className={styles.city} {...city} />
        <TextField className={styles.postalCode} {...postalCode} />
      </div>
      <div className={styles.row2}>
        <SelectField className={styles.province} {...province} />
        <TextInput className={styles.country} {...country} />
      </div>
      {state === 'Error' ? <Text {...errorText} /> : null}
    </div>
  );
};

Address.defaultProps = defaultProps;

export default Address;
