import React from 'react';
import cx from 'classnames';

import styles from './UserInfo.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Image, { ImageProps } from '../../atoms/Image';
import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  button: {
    size: 'Medium',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'Close',
      style: 'Grey1100',
    },
  } as ButtonProps,
  userAvatar: {
    style: 'Circle',
  } as ImageProps,
  name: {
    type: 'Title1',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
  email: {
    type: 'Body2',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
};

export type UserInfoProps = {
  button?: ButtonProps;
  userAvatar?: ImageProps;
  name?: TextProps;
  email?: TextProps;
  className?: string;
};

const UserInfo: React.FC<UserInfoProps> = ({
  button,
  userAvatar,
  name,
  email,
  className,
}) => {
  return (
    <div className={cx(styles.userInfo, className)}>
      <div className={styles.menuHeader}>
        <Button
          className={styles.button}
          {...button} />
      </div>
      <div className={styles.userContent}>
        <Image
          className={styles.userAvatar}
          {...userAvatar} />
        <div className={styles.userDetails}>
          <Text
            className={styles.name}
            {...name} />
          <Text
            className={styles.email}
            {...email} />
        </div>
      </div>
    </div>
  );
};

UserInfo.defaultProps = defaultProps;

export default UserInfo;
