import React from 'react';
import cx from 'classnames';

import styles from './NoContentBlock.module.scss';

import EmptyStateContent, { EmptyStateContentProps } from '../../molecules/EmptyStateContent';
import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  emptyStateContent: {
    titleText: {
      type: 'Title2',
      align: 'Center',
      style: 'Grey1100',
    },
    messageText: {
      type: 'Body3',
      align: 'Center',
      style: 'Grey1100',
    },
  } as EmptyStateContentProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type NoContentBlockProps = {
  emptyStateContent?: EmptyStateContentProps;
  button?: ButtonProps;
  className?: string;
};

const NoContentBlock: React.FC<NoContentBlockProps> = ({
  emptyStateContent,
  button,
  className,
}) => {
  return (
    <div className={cx(styles.noContentBlock, className)}>
      <div className={styles.content}>
        <EmptyStateContent
          className={styles.emptyStateContent}
          {...emptyStateContent} />
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

NoContentBlock.defaultProps = defaultProps;

export default NoContentBlock;
