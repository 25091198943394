import React from 'react';
import Downshift from 'downshift';
import styles from './AutoComplete.module.scss';
import TextInput from '../../atoms/TextInput';

const AutoComplete = ({
  initialInputValue, onInputValueChange, items, onChange,
}) => (
    <Downshift
        initialInputValue={initialInputValue}
        onChange={onChange}
        onInputValueChange={onInputValueChange}
        itemToString={(item) => (item ? item.description : '')}
    >
      {({
        getInputProps, getItemProps, getMenuProps, isOpen,
      }) => {
        let itemViews;
        if (isOpen) {
          itemViews = items?.map((item, index) => (
              <li
                  key={item.description}
                  className={styles.autocompleteSuggestions}
                  {...getItemProps({
                    index,
                    item,
                  })}
              >
                {item.description}
              </li>
          ));
        }
        return (
            <div>
              <TextInput {...getInputProps()} />
              <ul {...getMenuProps()} className={styles.autocompleteBox}>{itemViews}</ul>
            </div>
        );
      }}
    </Downshift>
);
export default AutoComplete;
