import React, {
  MutableRefObject, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useScrollPosition } from '../../../lib/use-scroll-position/src';
import { FoodItemHeaderStyleType } from '../FoodItemHeader/FoodItemHeader';
import { MenuItemConfigurationModalProps, defaultProps } from './MenuItemConfigurationModal';

export type MenuItemConfigurationModalPresenterProps = MenuItemConfigurationModalProps & {
};

const withPresenter = (
  View: React.FC<MenuItemConfigurationModalProps>,
): React.FC<MenuItemConfigurationModalPresenterProps> => {
  const Presenter: React.FC<MenuItemConfigurationModalPresenterProps> = (props) => {
    const { menuModalOpen } = props;
    const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
    const [foodItemHeaderStyle, setFoodItemHeaderStyle] = useState<FoodItemHeaderStyleType>('Default');
    const menuItemContentRef = useRef<HTMLDivElement>(null);
    const foodItemBannerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (menuModalOpen) {
        setFoodItemHeaderStyle('Default');
      }
    }, [menuModalOpen]);

    useScrollPosition(
      ({ currPos }) => {
        setScrollPosition(currPos);
      },
      undefined,
      foodItemBannerRef as MutableRefObject<HTMLDivElement>,
      false,
      0,
      menuItemContentRef as MutableRefObject<HTMLDivElement>,
    );

    useLayoutEffect(() => {
      if (scrollPosition) {
        if (scrollPosition.y > 0) {
          setFoodItemHeaderStyle('Scroll');
        } else {
          setFoodItemHeaderStyle('DefaultAnimated');
        }
      }
    }, [scrollPosition]);

    const menuItemConfigurationModalProps: MenuItemConfigurationModalProps = {
      ...defaultProps,
      ...props,
      foodItemHeader: {
        ...defaultProps.foodItemHeader,
        ...props.foodItemHeader,
        style: foodItemHeaderStyle,
        titleText: {
          ...defaultProps.foodItemHeader.titleText,
          ...props.foodItemBanner?.titleText,
          value: foodItemHeaderStyle === 'Scroll' ? props.foodItemBanner?.titleText?.value : '',
        },
      },
      foodItemBanner: {
        ...defaultProps.foodItemBanner,
        ...props.foodItemBanner,
        foodItemBannerRef,
      },
      menuItemContentRef,
    };

    return (
      <View
        {...menuItemConfigurationModalProps}
      />
    );
  };
  return Presenter;
};

export default withPresenter;
