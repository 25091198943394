import React from 'react';
import cx from 'classnames';

import styles from './EmailSignInBlock.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import SecondaryAction, { SecondaryActionProps } from '../../organisms/SecondaryAction';
import ToastNotification, { ToastNotificationProps } from '../../molecules/ToastNotification/ToastNotification';
import Notification, { NotificationProps } from '../../molecules/Notification';

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
  } as ButtonProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  email: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  password: {
    state: 'Default',
    type: 'Password',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Password',
      style: 'Default',
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      icon: {
        asset: 'Show',
        style: 'Grey1100',
      },
    },
  } as TextFieldProps,
  forgotPassword: {
    type: 'Body2',
    align: 'Left',
    style: 'Green800',
  } as TextProps,
  secondaryAction: {
    title: {
      type: 'Title4',
      align: 'Center',
      style: 'Grey1100',
    },
    link: {
      type: 'Body2',
      align: 'Center',
      style: 'Green800',
    },
  } as SecondaryActionProps,
  errorToast: {
    type: 'Error',
    icon: {
      asset: 'ErrorCircle',
      style: 'Red800',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
  } as ToastNotificationProps,
  toast: {
    type: 'Success',
    icon: {
      asset: 'CheckCircle',
      style: 'Green700',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Green800',
    }
  } as ToastNotificationProps,
};

export type EmailSignInBlockProps = {
  backButton?: ButtonProps;
  button?: ButtonProps;
  title?: TextProps;
  description?: TextProps;
  email?: TextFieldProps;
  password?: TextFieldProps;
  forgotPassword?: TextProps;
  secondaryAction?: SecondaryActionProps;
  toast?: ToastNotificationProps;
  toastMessage?: boolean,
  className?: string;
};

const EmailSignInBlock: React.FC<EmailSignInBlockProps> = ({
  backButton,
  button,
  title,
  description,
  email,
  password,
  forgotPassword,
  secondaryAction,
  toast,
  toastMessage,
  className,
}) => {
  let headDisplay = <Text className={styles.title} {...title} />;
  let toastNotif;
  if (toastMessage) {
    toastNotif = <ToastNotification {...toast} />;
  }
  return (
    <div className={cx(styles.emailSignInBlock, className)}>
      <div className={styles.topContent}>
        <Button
          className={styles.backButton}
          {...backButton} />
        {headDisplay}
        {toastNotif}
        <Text
          className={styles.description}
          {...description} />
      </div>
      <div className={styles.formContent}>
        <TextField
          className={styles.email}
          {...email} />
        <div className={styles.passwordSection}>
          <TextField
            className={styles.password}
            {...password} />
          <Text
            className={styles.forgotPassword}
            {...forgotPassword} />
        </div>
        <Button
          className={styles.button}
          {...button} />
      </div>
      <SecondaryAction
        className={styles.secondaryAction}
        {...secondaryAction} />
    </div>
  );
};

EmailSignInBlock.defaultProps = defaultProps;

export default EmailSignInBlock;
