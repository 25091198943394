import React from 'react';
import cx from 'classnames';

import styles from './OrderStatusPage.module.scss';

import OrderStatusBlock, { OrderStatusBlockProps } from '../../block/OrderStatusBlock';

export const defaultProps = {
  orderStatusBlock: {
    orderTracker: {
      State: 'Error',
      title: {
        type: 'Title1',
        align: 'Left',
        style: 'Grey1100',
      },
      description: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      notification: {
        type: 'Default',
        style: 'Blue',
        title: {
          type: 'Title4',
          align: 'Left',
          style: 'Grey1100',
        },
        divider: {
          type: 'HorizontalSolid',
          style: 'Blue200',
        },
        description: {
          type: 'Body3',
          align: 'Left',
          style: 'Grey1100',
        },
        phoneNumber: {
          type: 'Body2',
          align: 'Left',
          style: 'Blue800',
        },
      },
      button: {
        size: 'Medium',
        type: 'Text',
        style: 'BrandSquare',
        text: {
          type: 'Button1',
          align: 'Center',
          style: 'Grey1100',
        },
      },
    },
  } as OrderStatusBlockProps,
};

export type OrderStatusPageProps = {
  orderStatusBlock?: OrderStatusBlockProps;
  className?: string;
  openShareModal?: () => void;
};

const OrderStatusPage: React.FC<OrderStatusPageProps> = ({
  orderStatusBlock,
  className,
  openShareModal
}) => {
  return (
    <div className={cx(styles.orderStatusPage, className)}>
      <OrderStatusBlock
        className={styles.orderStatusBlock}
        {...orderStatusBlock} 
        openShareModal={openShareModal}
        />
    </div>
  );
};

OrderStatusPage.defaultProps = defaultProps;

export default OrderStatusPage;
