import React from 'react';
import cx from 'classnames';

import styles from './FoodItemCard.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Image, { ImageProps } from '../../atoms/Image';
import Divider, { DividerProps } from '../../atoms/Divider';
import { getClickAccessibilityProps } from '../../../lib/utils';
export type FoodItemCardLoadingType = 'Default' | 'Loading';

export const defaultProps = {
  loading: 'Loading' as FoodItemCardLoadingType,
  title: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  price: {
    type: 'Title4',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
};

export type FoodItemCardProps = {
  loading?: FoodItemCardLoadingType;
  title?: TextProps;
  description?: TextProps;
  price?: TextProps;
  image?: ImageProps;
  divider?: DividerProps;
  onClick?: VoidFunction;
  className?: string;
};

const FoodItemCard: React.FC<FoodItemCardProps> = ({
  loading,
  title,
  description,
  price,
  image,
  divider,
  onClick,
  className,
}) => {
  const accessibilityProps = getClickAccessibilityProps(onClick);
  const currentStyle = styles[`foodItemCard${loading}`];

  const dividerView = (
    <Divider
      className={styles.divider}
      {...divider} />
  );
  
  let contentView;
  
  switch (loading) {
    case 'Default':
      contentView = (
        <div className={styles.content} 
          onClick={onClick}
          {...accessibilityProps}>
          <div className={styles.text}>
            <div className={styles.topContent}>
              <Text
                className={styles.title}
                {...title} />
              <Text
                className={styles.description}
                {...description} />
            </div>
            <Text
              className={styles.price}
              {...price} />
          </div>
          <Image
            className={styles.image}
            {...image} />
        </div>
      );
      break;
    case 'Loading':
      contentView = (
        <div className={styles.content}>
          <div className={styles.topContent}>
            <div className={styles.contentGroupOne}/>
            <div className={styles.gontentGroupTwo}>
              <div className={styles.contentGroupTwo}/>
              <div className={styles.contentGroupThree}/>
            </div>
            <div className={styles.contentGroupThree}/>
          </div>
          <div className={styles.contentOne}/>
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {contentView}
      {dividerView}
    </div>
  );
};

FoodItemCard.defaultProps = defaultProps;

export default FoodItemCard;
