import React from 'react';
import cx from 'classnames';

import styles from './FoodItemHeader.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import Divider, { DividerProps } from '../../atoms/Divider';

export type FoodItemHeaderStyleType = 'Default' | 'Scroll' | 'DefaultAnimated';

export const defaultProps = {
  style: 'Default' as FoodItemHeaderStyleType,
  button: {
    size: 'Medium',
    type: 'Icon',
    style: 'ClearSquare',
    icon: {
      asset: 'Close',
      style: 'Grey1100',
    },
  } as ButtonProps,
  titleText: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  closeButton: {
    size: 'Medium',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'Close',
      style: 'Grey1100',
    },
  } as ButtonProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
};

export type FoodItemHeaderProps = {
  style?: FoodItemHeaderStyleType;
  button?: ButtonProps;
  className?: string;
  titleText?: TextProps;
  closeButton?: ButtonProps;
  divider?: DividerProps;
};

const FoodItemHeader: React.FC<FoodItemHeaderProps> = ({
  style,
  button,
  className,
  titleText,
  closeButton,
  divider,
}) => {
  const currentStyle = styles[`foodItemHeader${style}`];

  return (
    <div className={cx(currentStyle, className)}>
      <div className={styles.content}>
        <Text
          className={styles.titleText}
          {...titleText} />
        <Button
          className={styles.button}
          {...button} />
        <Button
          className={styles.closeButton}
          {...closeButton} />
      </div>
      <Divider
        className={styles.divider}
        {...divider} />
    </div>
  );
};

FoodItemHeader.defaultProps = defaultProps;

export default FoodItemHeader;
