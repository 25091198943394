import React from "react";
import { TagMenuProps } from "./TagMenu";
import { TagMenuPresenterProps } from "./TagMenu.presenter"

const withInteractor = (
  Presenter: React.FC<TagMenuPresenterProps>
): React.FC<TagMenuProps> => {
  const Interactor: React.FC<TagMenuProps> = (props) => {
    return <Presenter {...props}/>;
  };
  return Interactor;
};
export default withInteractor;
