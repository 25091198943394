import { formatPrice } from '../../components/block/MenuBlocksList/utils';
import { PriceListItemProps } from '../../components/molecules/PriceListItem';
import { MenuItem } from '../checkout/types';
import {
  defaultProps as priceListItemDefaultProps,
} from '../../components/molecules/PriceListItem/PriceListItem';
import { 
  defaultProps as priceSectionItemDefaultProps, PriceSectionItemProps 
} from '../../components/organisms/PriceSectionItem/PriceSectionItem';

const makePriceListItems = (subItems: MenuItem[]) => {
  const listItems = subItems.map((addon: MenuItem) => {
    return {
      ...priceListItemDefaultProps,
      type: 'Default',
      description: {
        ...priceListItemDefaultProps.description,
        type: 'Body3',
        align: 'Left',
        style: 'Grey700',
        value: `${addon.quantity}x ${addon.name}`,
      },
      cost: {
        ...priceListItemDefaultProps.cost,
        type: 'Body3',
        align: 'Left',
        style: 'Grey700',
        value: addon.price ? formatPrice(addon.price) : '',
      },
    } as PriceListItemProps;
  });
  return listItems;
};
  
export const makePriceSectionItem = (subItem: MenuItem) => {
  const dishAddOn = {
    ...priceSectionItemDefaultProps,
    priceListItem: {
      ...priceSectionItemDefaultProps.priceListItem,
      description: {
        // represents an add-on title or heading. eg: 'Toppings'
        ...priceSectionItemDefaultProps.priceListItem.description,
        value: subItem.name,
      },
    },
    // the list of of add-ons relating to the heading. eg: 'Lettuce'
    priceItemList: {
      ...priceSectionItemDefaultProps.priceItemList,
      priceListItems: subItem.subItems
        ? (makePriceListItems(subItem.subItems) as PriceListItemProps[])
        : [],
    } as PriceSectionItemProps,
  };
  return dishAddOn;
};