import React, { useContext } from "react";
import { CartOverviewBlockPresenterProps } from "./CartOverviewBlock.presenter"
import { CartOverviewBlockProps } from ".";
import { CheckoutContext } from "../../../modules/checkout";


const withInteractor = (
	Presenter: React.FC<CartOverviewBlockPresenterProps>
): React.FC<CartOverviewBlockProps> => {
	const Interactor: React.FC<CartOverviewBlockProps> = (props) => {
		const { basket } = useContext(CheckoutContext);
		return <Presenter 
			{...props} 
			basket={basket} />;
	};
	return Interactor;
};
export default withInteractor;