import React, { useState } from 'react';
import { CreatePasswordBlockProps } from "./CreatePasswordBlock";
import { defaultProps } from './CreatePasswordBlock';
import { isEmptyString, isPassword } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';
import { UpdatePasswordPayload } from '../../../modules/account/types';
import { useHistory, useLocation } from 'react-router-dom';
import { extractJwtPayload } from '../../../lib/token';
import { ApiError } from '../../../lib/api/types';

export type CreatePasswordBlockPresenterProps = CreatePasswordBlockProps & {
    updatePassword: (payload: UpdatePasswordPayload) => Promise<void>,
    isLoading: boolean,
};

const withPresenter = (
    View: React.FC<CreatePasswordBlockProps>,
): React.FC<CreatePasswordBlockPresenterProps> => {
    const Presenter: React.FC<CreatePasswordBlockPresenterProps> = (props) => {
        const {
            updatePassword,
            isLoading
        } = props;
        const { t } = useTranslation();
        const history = useHistory();
        const { search } = useLocation();
        const query = new URLSearchParams(search);
        const token = query.get('token')
        const [error, setError] = useState('')
        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [passwordError, setPasswordError] = useState('');
        const [showCreatePassword, setShowCreatePassword] = useState<boolean>(false);
        const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
        const isValidForm = !isEmptyString(password) && !isEmptyString(confirmPassword);

        const handlePassword = ({ target: { value } }) => {
            setPassword(value);
            setPasswordError("");
        };

        const handleConfirmPassword = ({ target: { value } }) => {
            setConfirmPassword(value);
            setPasswordError("");
        };


        const handleCreatePassword = async () => {
            try {
                if (password !== confirmPassword) {
                    setPasswordError(t('error.password_do_not_match'));
                } else if (!isPassword(password)) {
                    setPasswordError(t('error.password_length'));
                } else if (token) {
                    const { id } = extractJwtPayload(token);
                    await updatePassword({
                        id,
                        password,
                        token,
                    });
                    history.push({
                        pathname: '/emailSignIn',
                        state: {
                          message: t('create_password_block.password_changed'),
                        },
                      });
                }
            } catch (e) {
                if (ApiError.isApiError(e) && e.message) {
                    setError(e.message);
                } else {
                    setError(t("error.default"));
                }
            }
        };
        
        // render proper props base on sign in or create account
        const createPasswordProps: CreatePasswordBlockProps = {
            ...defaultProps,
            title: {
                ...defaultProps.title,
                value: t('create_password_block.title')
            },
            description: {
                ...defaultProps.description,
                value: t('create_password_block.description')
            },
            createPassword: {
                ...defaultProps.createPassword,
                state: passwordError ? 'Error' : 'Default',
                label: {
                    ...defaultProps.createPassword?.label,
                    value: t('create_password_block.title')
                },
                textInput: {
                    ...defaultProps.createPassword?.textInput,
                    type: 'Password',
                    showPassword: showCreatePassword,
                    onTextChanged: handlePassword,
                    textValue: password,
                    icon: {
                        ...defaultProps.createPassword?.textInput?.icon,
                        asset: showCreatePassword ? 'Hide' : 'Show',
                        onClick: () => setShowCreatePassword(!showCreatePassword)
                    },
                },
            },
            confirmPassword: {
                ...defaultProps.confirmPassword,
                state: passwordError ? 'Error' : 'Default',
                label: {
                    ...defaultProps.confirmPassword?.label,
                    value: t('textLabels.confirm_password')
                },
                textInput: {
                    ...defaultProps.confirmPassword?.textInput,
                    type: 'Password',
                    showPassword: showConfirmPassword,
                    onTextChanged: handleConfirmPassword,
                    textValue: confirmPassword,
                    icon: {
                        ...defaultProps.confirmPassword?.textInput?.icon,
                        asset: showConfirmPassword ? 'Hide' : 'Show',
                        onClick: () => setShowConfirmPassword(!showConfirmPassword)
                    },
                },
                errorText: {
                    ...defaultProps.confirmPassword?.errorText,
                    value: passwordError,
                    style: 'Red800',
                    align: 'Left'
                }
            },
            button: {
                ...defaultProps.button,
                onButtonClicked: handleCreatePassword,
                disabled: !isValidForm,
                text: {
                    ...defaultProps.button.text,
                    value: t('create_password_block.set_password')
                }
            },
        }

        return <View
            {...createPasswordProps}
        />;
    }
    return Presenter;
};

export default withPresenter;