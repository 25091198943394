import React from "react";
import { ForgotPasswordProps } from "./ForgotPassword";
import { ForgotPasswordPresenterProps } from "./ForgotPassword.presenter";
import { useMutation } from "react-query";
import { forgotPassword } from '../../../modules/account/api';

const withInteractor = (
  Presenter: React.FC<ForgotPasswordPresenterProps>,
): React.FC<ForgotPasswordProps> => {
  const Interactor: React.FC<ForgotPasswordProps> = (props) => {
    const { mutateAsync, isLoading } = useMutation(forgotPassword);

    return (
      <Presenter 
        {...props}
        forgotPassword={mutateAsync} 
        isLoading={isLoading}
      />
    );
  };
  return Interactor;
};
export default withInteractor;