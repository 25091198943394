import React from 'react';
import cx from 'classnames';

import styles from './OrderStepper.module.scss';

import StepItem, { StepItemProps } from '../../molecules/StepItem';

export const defaultProps = {
  stepItems: [],
  stepItem: {
    state: 'Incomplete',
    type: 'Last',
    orderProgressItem: {
      state: 'None',
    },
    stepIndicator: {
      asset: 'Incomplete',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
  } as StepItemProps,
};

export type OrderStepperProps = {
  stepItem?: StepItemProps;
  className?: string;
  stepItems?: StepItemProps[];
};

const OrderStepper: React.FC<OrderStepperProps> = ({
  className,
  stepItems,
}) => {
  let stepItemsView = [<></>];
  if(stepItems){
    stepItems.forEach(element => {
      if(element === stepItems.slice(-1)[0]){
        element = {
          ...element,
          orderProgressItem: {
            state: 'None'
          }
        };
      }
      const stepItemView = <StepItem
        className={styles.element}
        {...element} />
      stepItemsView.push(stepItemView);
    });
  }
  return (
    <div className={cx(styles.orderStepper, className)}>
      { stepItemsView }
    </div>
  );
};

OrderStepper.defaultProps = defaultProps;

export default OrderStepper;
