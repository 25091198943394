import React from 'react';
import cx from 'classnames';

import styles from './EmptyStateContent.module.scss';

import Image, { ImageProps } from '../../atoms/Image';
import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  titleText: {
    type: 'Title2',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
  messageText: {
    type: 'Body3',
    align: 'Center',
    style: 'Grey800',
  } as TextProps,
};

export type EmptyStateContentProps = {
  image?: ImageProps;
  titleText?: TextProps;
  messageText?: TextProps;
  className?: string;
};

const EmptyStateContent: React.FC<EmptyStateContentProps> = ({
  image,
  titleText,
  messageText,
  className,
}) => {
  return (
    <div className={cx(styles.emptyStateContent, className)}>
      <Image
        className={styles.image}
        {...image} />
      <div className={styles.content}>
        <Text
          className={styles.titleText}
          {...titleText} />
        <Text
          className={styles.messageText}
          {...messageText} />
      </div>
    </div>
  );
};

EmptyStateContent.defaultProps = defaultProps;

export default EmptyStateContent;
