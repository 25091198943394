import React from 'react';
import cx from 'classnames';

import styles from './AddTipPage.module.scss';

import AddTipBlock, { AddTipBlockProps } from '../../block/AddTipBlock';

export const defaultProps = {
  addTipBlock: {
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
    radioItemList: {
      radioItems: [
      ],
    },
    customAmount: {
      state: 'Default',
      button: {
        size: 'ExtraSmall',
        type: 'IconText',
        style: 'BasicSquare',
        icon: {
          asset: 'Plus',
          style: 'Grey1100',
        },
        text: {
          type: 'Button1',
          align: 'Center',
          style: 'Grey1100',
        },
      },
    },
    tipAmount: {
      type: 'ListItem',
      state: 'Collapsed',
      item: {
        type: 'Body5',
        align: 'Left',
        style: 'Grey800',
      },
      divider: {
        type: 'HorizontalDotted',
        style: 'Grey500',
      },
      price: {
        type: 'Body5',
        align: 'Right',
        style: 'Grey800',
      },
    },
    total: {
      type: 'ListItemDark',
      state: 'Collapsed',
      item: {
        type: 'Body5',
        align: 'Left',
        style: 'Grey1100',
      },
      divider: {
        type: 'HorizontalSolid',
        style: 'Grey800',
      },
      price: {
        type: 'Body5',
        align: 'Right',
        style: 'Grey1100',
      },
    },
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'NeutralSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
  } as AddTipBlockProps,
};

export type AddTipPageProps = {
  addTipBlock?: AddTipBlockProps;
  className?: string;
};

const AddTipPage: React.FC<AddTipPageProps> = ({
  addTipBlock,
  className,
}) => {
  return (
    <div className={cx(styles.addTipPage, className)}>
      <AddTipBlock
        className={styles.addTipBlock}
        {...addTipBlock} />
    </div>
  );
};

AddTipPage.defaultProps = defaultProps;

export default AddTipPage;
