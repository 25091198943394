import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Account, Address, Profile } from '../../../lib/token';
import { AddressItemProps } from "../../molecules/AddressItem";
import {
  AddressModalBlockProps,
  AddressModalType,
  defaultProps
} from "./AddressModalBlock";
import { formatDisplayAddress, isValidAddress } from '../../../lib/utils';
import { UpdateAccountPayload } from '../../../modules/account/types';

export type AddressModalBlockPresenterProps = AddressModalBlockProps & {
  account?: Account | null;
  addressModalType?: AddressModalType;
  doUpdate: (payload: UpdateAccountPayload) => Promise<Account>;
  setAccount?: (account: Account | null) => void;
  isChangeAddress?: boolean;
  updateSkipToAddressChange?: (isSkipToChange: boolean) => void;
  error?: Error;
};

export type ChangeAddressModalErrors = {
  formattedAddress?: string;
  addressLine2?: string;
  deliveryInstructions?: string;
};

export type ChangeAddressModalFormState = {
  address?: Address;
  formattedAddress?: string;
  addressLine2?: string;
  deliveryInstructions?: string;
  errors?: ChangeAddressModalErrors;
};

const withPresenter = (
  View: React.FC<AddressModalBlockProps>,
): React.FC<AddressModalBlockPresenterProps> => {
  const Presenter: React.FC<AddressModalBlockPresenterProps> = (props) => {
    const [addressModalType, setAddressModalType] = useState<AddressModalType>('view_address');
    const [formState, setFormState] = useState<ChangeAddressModalFormState>({
      formattedAddress: '',
      addressLine2: '',
      deliveryInstructions: '',
      errors: {
        formattedAddress: '',
        addressLine2: '',
        deliveryInstructions: '',
      },
    });
    const { t } = useTranslation();
    const {
      account,
      onClose,
      doUpdate,
      setAccount,
      isChangeAddress,
      updateSkipToAddressChange
  } = props;


    useEffect(() => {
      if(isChangeAddress) {
        setAddressModalType('change_address');
      }
    }, [isChangeAddress])
  
    const resetModalStateAndClose = () => {
      setAddressModalType('view_address');
      if (onClose) {
        onClose();
      }
    }

    const isFormValid = (): boolean => {
      const { address } = formState;
      const errors: ChangeAddressModalErrors = {};
      if (!isValidAddress(address)) {
        errors.formattedAddress = t('error.valid_address', {
          field: t('textLabels.address'),
        });
      }
      setFormState((previousState) => ({
        ...previousState,
        errors,
      }));
      return Object.values(errors)?.length === 0;
    };

    const addressItem: AddressItemProps[] = account?.profile?.address?.map((address) => {
      return {
        ...defaultProps.addressModal.addressItem,
        street: {
          ...defaultProps.addressModal.addressItem.street,
          value: formatDisplayAddress(address)
        },
        postalCode: {
          ...defaultProps.addressModal.addressItem.postalCode,
          value: address?.postalCode
        },
        cityAndProvince: {
          ...defaultProps.addressModal.addressItem.cityAndProvince,
          value: `${address?.city} ${address?.province}`
        },
        changeButton:{
          ...defaultProps.addressModal.addressItem.changeButton,
          text: {
            ...defaultProps.addressModal.addressItem.changeButton?.text,
            value: t('button.change')
          },
          onButtonClicked: () => {
            setFormState({
              address,
              formattedAddress: formatDisplayAddress(address),
              addressLine2: address.addressLine2,
              deliveryInstructions: address.deliveryInstructions,
            } as ChangeAddressModalFormState);
            setAddressModalType('change_address');
          },
        }
      }
    }) || []

    const handleSave = async () => {
      if (isFormValid() && account) {
        const {
          address,
          addressLine2,
          deliveryInstructions,
        } = formState;
        const newAddress = {
          ...address,
          addressLine2,
          deliveryInstructions,
        };
        const data = await doUpdate({
          id: account?.id,
          profile: {
            ...account?.profile,
            address: [newAddress],
            preferredAddress: newAddress,
          } as Profile,
        });
        if (setAccount) {
          setAccount(data);
          resetModalStateAndClose();
          updateSkipToAddressChange && updateSkipToAddressChange(false);
        }
      }
    };

    const handleInputChanged =
        (key) =>
            ({ target: { value } }) => {
              setFormState((previousState) => ({ ...previousState, [key]: value }));
            };

    const handleAddressSelection = (selection: Address) => {
      setFormState({
        ...formState,
        address: selection,
        addressLine2: selection.addressLine2
      });
    };

    const addressModalBlockProps: AddressModalBlockProps = {
      onClose: resetModalStateAndClose,
      addressModal: {
        ...defaultProps.addressModal,
        modalHeader: {
          ...defaultProps.addressModal.modalHeader,
          menuItem: {
            ...defaultProps.addressModal.modalHeader?.menuItem,
            value: t('textLabels.your_address')
          },
          button: {
            ...defaultProps.addressModal.modalHeader?.button,
            onButtonClicked: resetModalStateAndClose
          }
        },
        addressItem,
        button: {
          ...defaultProps.addressModal.button,
          text: {
            ...defaultProps.addressModal.button?.text,
            value: t('button.save')
          }
        }
      },
      changeAddressModal: {
        modalHeader: {
          ...defaultProps.changeAddressModal.modalHeader,
          menuItem: {
            ...defaultProps.changeAddressModal.modalHeader?.menuItem,
            value: t('textLabels.your_address'),
          },
          button: {
            ...defaultProps.changeAddressModal.modalHeader?.button,
            onButtonClicked: resetModalStateAndClose,
            disabled: isChangeAddress,
          },
        },
        addressAutoComplete: {
          label: {
            ...defaultProps.changeAddressModal.addressAutoComplete.label,
            value: t('textLabels.street_address'),
          },
          onSelection: handleAddressSelection,
          errorText: {
            ...defaultProps.changeAddressModal.addressAutoComplete.errorText,
            value: formState?.errors?.formattedAddress,
          },
          value: formState.formattedAddress
        },
        addressLine2: {
          ...defaultProps.changeAddressModal.addressLine2,
          textInput: {
            ...defaultProps?.changeAddressModal?.addressLine2.textInput,
            textValue: formState.addressLine2,
            onTextChanged: handleInputChanged('addressLine2'),
          },
          label: {
            ...defaultProps.changeAddressModal.addressLine2?.label,
            value: t('payment_info.address_line_2'),
          },
          textPlaceholder: t(''),
        },
        deliveryInstructions: {
          ...defaultProps.changeAddressModal.deliveryInstructions,
          textInput: {
            ...defaultProps?.changeAddressModal?.deliveryInstructions.textInput,
            textValue: formState.deliveryInstructions,
            onTextChanged: handleInputChanged('deliveryInstructions'),
            textPlaceholder: t('textLabels.delivery_instructions_placeholder'),
          },
          label: {
            ...defaultProps.changeAddressModal.deliveryInstructions?.label,
            value: t('textLabels.delivery_instructions'),
          },
        },
        button: {
          ...defaultProps.changeAddressModal.button,
          text: {
            ...defaultProps.changeAddressModal.button?.text,
            value: t('button.save'),
          },
          onButtonClicked: () => {
            handleSave();
          },
        },
      },
      addressModalType,
    };
    return <View {...props} {...addressModalBlockProps} />;
  };
  return Presenter;
};

export default withPresenter;
