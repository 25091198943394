import React from 'react';
import styles from './CartOverviewBlock.module.scss';
import { Modal } from 'react-bootstrap';
import ModalHeader, { ModalHeaderProps } from '../../molecules/ModalHeader';
import TopContent, { TopContentProps } from '../../molecules/TopContent';
import CartOverview, { CartOverviewProps } from '../../organisms/CartOverview';
import PriceListItem, {
  PriceListItemProps,
} from '../../molecules/PriceListItem';
import Button, { ButtonProps } from '../../atoms/Button';
import EmptyStateContent, { EmptyStateContentProps } from '../../molecules/EmptyStateContent';
import { MenuItem } from '../../../modules/checkout/types';
import { TextProps } from '../../atoms/Text';

export type CartOverviewBlockTypeType = 'Default' | 'EmptyStateContent';

export const defaultProps = {
  type: 'EmptyStateContent' as CartOverviewBlockTypeType,
  modalHeader: {
    type: 'Close',
    menuItem: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    button: {
      size: 'ExtraSmall',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as ModalHeaderProps,
  topContent: {
    title: {
      type: 'Body1',
      align: 'Left',
      style: 'Grey700',
    },
  } as TopContentProps,
  cartOverview: {
    text: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    } as TextProps,
    cartMessage: {
      type: 'Body1',
      align: 'Left',
      style: 'Grey700',
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
    priceOverviewItemList: {
      priceOverviewItems: [],
    },
  } as CartOverviewProps,
  priceListItem: {
    type: 'Large',
    description: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
  } as PriceListItemProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  emptyStateContent: {
    titleText: {
      type: 'Title2',
      align: 'Center',
      style: 'Grey1100',
    },
    messageText: {
      type: 'Body3',
      align: 'Center',
      style: 'Grey800',
    },
    image: {
      className: styles.image
    }
  } as EmptyStateContentProps,
};

export type CartOverviewBlockProps = {
  type?: CartOverviewBlockTypeType;
  modalHeader?: ModalHeaderProps;
  topContent?: TopContentProps;
  cartOverview?: CartOverviewProps;
  priceListItem?: PriceListItemProps;
  button?: ButtonProps;
  className?: string;
  emptyStateContent?: EmptyStateContentProps;
  onClose?: () => void;
  setItem?: (item: MenuItem, position: number) => void;
  showConfirmationModal?: () => void;
  showEditModal?: () => void;
};

const CartOverviewBlock: React.FC<CartOverviewBlockProps> = ({
  type,
  modalHeader,
  topContent,
  cartOverview,
  priceListItem,
  button,
  emptyStateContent,
}) => {
  const modalHeaderView = (
    <ModalHeader className={styles.modalHeader} {...modalHeader} />
  );

  const modalButtonView = <Button className={styles.button} {...button} />;

  let contentView;

  switch (type) {
    case 'Default':
      contentView = (
        <div className={styles.content}>
          <div className={styles.innerContent}>
            <div className={styles.topContent}>
              <TopContent className={styles.topContent} {...topContent} />
              <CartOverview className={styles.cartOverview} {...cartOverview} />
              <PriceListItem
                className={styles.priceListItem}
                {...priceListItem}
              />
            </div>
          </div>
        </div>
      );
      break;
    case 'EmptyStateContent':
      contentView = (
        <div className={styles.content}>
          <div className={styles.innerContent}>
            <EmptyStateContent className={styles.cartEmpty} {...emptyStateContent} />
          </div>
        </div>
      );
      break;
  }

  return (
    <>
      <Modal.Header className={styles.modalHeader}>
        {modalHeaderView}
      </Modal.Header>
      <Modal.Body className={styles.modalContent}>
        {contentView}
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        {modalButtonView}
      </Modal.Footer>
    </>
  );
};

CartOverviewBlock.defaultProps = defaultProps;

export default CartOverviewBlock;
