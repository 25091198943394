import { Product } from "../../../modules/menu/types"
import { defaultProps as FoodItemCardDefaultProps, FoodItemCardProps } from "../../molecules/FoodItemCard/FoodItemCard"

export function convertToFoodItemCard(foodItemCardInfo: Product): FoodItemCardProps {
  const foodItemCard: FoodItemCardProps = {
    ...FoodItemCardDefaultProps,
    title: {
      ...FoodItemCardDefaultProps.title,
      value: foodItemCardInfo.name
    },
    description: {
      ...FoodItemCardDefaultProps.description,
      value: foodItemCardInfo.description
    },
    price: {
      ...FoodItemCardDefaultProps.price,
      value: formatPrice(foodItemCardInfo.price)
    },
    image: {
      srcValue: foodItemCardInfo.imageUrl
    },
    loading: 'Default',
  }
  return foodItemCard
}
const currencyFormat = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
});
export function formatPrice(price: number): string {
  const priceWithDecimal = (price / 100);
  return currencyFormat.format(priceWithDecimal);
}

export const isValidAmount = (input) => /^\d+(.\d{1,2})?$/.test(input);
