import React, { RefObject } from 'react';
import cx from 'classnames';

import styles from './RestaurantPage.module.scss';

import RestaurantBanner, {
  RestaurantBannerProps,
} from '../../block/RestaurantBanner';
import TagMenu, { TagMenuProps } from '../../block/TagMenu';
import Divider, { DividerProps } from '../../atoms/Divider';
import MenuBlocksList, {
  MenuBlocksListProps,
} from '../../block/MenuBlocksList';
import EmptyBlock, { EmptyBlockProps } from '../../block/EmptyBlock';
import { MenuResponseObject } from '../../../modules/menu/types';
import { Restaurant } from '../../../modules/restaurant/types';
import MenuModalBlock from '../../block/MenuModalBlock';
import MenuUnavailable from '../../../resources/images/MenuUnavailable.png';
import ToastNotification, {
  ToastNotificationProps,
} from '../../molecules/ToastNotification';

export const defaultProps = {
  restaurantBanner: {
    button: {
      size: 'ExtraSmall',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Back',
        style: 'Grey1100',
      },
    },
    titleText: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    cusineTypeText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
    addressText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey700',
    },
  } as RestaurantBannerProps,
  tagMenu: {
    tabList: {
      tabs: [],
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as TagMenuProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  menuListBlock: {
    menuListItemBlock: {
      blockTitle: {
        type: 'Title1',
        align: 'Left',
        style: 'Grey1100',
      },
      foodItemCardList: {
        foodItemCards: [],
      },
    },
  } as MenuBlocksListProps,
  emptyBlock: {
    image: {
      srcValue: MenuUnavailable,
    },
    description: {
      type: 'Body1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as EmptyBlockProps,
  toast: {
    type: 'Success',
    icon: {
      asset: 'CheckCircle',
      style: 'Green700',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Green800',
    },
  } as ToastNotificationProps,
};

export type RestaurantPageProps = {
  restaurantBanner?: RestaurantBannerProps;
  tagMenu?: TagMenuProps;
  divider?: DividerProps;
  menuBlocksList?: MenuBlocksListProps;
  emptyBlock?: EmptyBlockProps;
  restaurant?: Restaurant;
  menu?: MenuResponseObject[];
  productId?: string;
  menuModalOpen?: boolean;
  showNotif?: boolean;
  notifOpen?: () => void;
  toast?: ToastNotificationProps;
  focusedBlockId?: string;
  scrolledBlockId?: string;
  setFocusedBlockId?: (blockId: string) => void;
  className?: string;
  isLoading?: boolean;
  blocksOffsets?: Map<string, object>;
  setBlocksOffsets?: (blocksOffsets: Map<string, object>) => void;
  restaurantPageRef?: RefObject<HTMLDivElement>;
  restaurantBannerRef?: RefObject<HTMLDivElement>;
};

const RestaurantPage: React.FC<RestaurantPageProps> = ({
  restaurantBanner,
  tagMenu,
  divider,
  menuBlocksList,
  emptyBlock,
  restaurant,
  menu,
  productId,
  menuModalOpen,
  showNotif,
  notifOpen,
  toast,
  focusedBlockId,
  setFocusedBlockId,
  className,
  isLoading,
  blocksOffsets,
  setBlocksOffsets,
  restaurantPageRef,
  restaurantBannerRef,
  scrolledBlockId,
}) => {
  const isInRange = restaurant && !restaurant.inRange;
  const isMenuAvailable = menu && (menu.length === 0 || !menu[0].available);
  const contentView = isInRange || isMenuAvailable ? (
    <EmptyBlock className={styles.emptyBlock} {...emptyBlock} />
  ) : (
    <>
      <TagMenu
        className={styles.tagMenu}
        {...tagMenu}
        menu={menu}
        setFocusedBlockId={setFocusedBlockId}
        scrolledBlockId={scrolledBlockId}
        focusedBlockId={focusedBlockId}
      />
      <Divider className={styles.divider} {...divider} />
      <MenuBlocksList
        className={styles.menuListBlock}
        {...menuBlocksList}
        focusedBlockId={focusedBlockId}
        menu={menu}
        isLoading={isLoading}
        blocksOffsets={blocksOffsets}
        setBlocksOffsets={setBlocksOffsets}
      />
    </>
  );
  return (
    <div className={cx(styles.restaurantPage, className)} ref={restaurantPageRef}>
      <ToastNotification show={showNotif} onClose={notifOpen} {...toast} />
      <RestaurantBanner
        className={styles.restaurantBanner}
        {...restaurantBanner}
        restaurant={restaurant}
        restaurantBannerRef={restaurantBannerRef}
      />
      {!isLoading && contentView}
      <MenuModalBlock
        menuModalOpen={menuModalOpen}
        menu={menu}
        productId={productId}
        notifOpen={notifOpen}
        restaurant={restaurant}
        isLoading={isLoading}
      />
    </div>
  );
};

RestaurantPage.defaultProps = defaultProps;

export default RestaurantPage;
