import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerBasket } from '../../../modules/checkout/types';
import {
  StartNewCartModalBlockProps,
  defaultProps,
} from './StartNewCartModalBlock';

export type StartNewCartModalBlockPresenterProps =
  StartNewCartModalBlockProps & {
    basket?: CustomerBasket;
  };

const withPresenter = (
  View: React.FC<StartNewCartModalBlockProps>,
): React.FC<StartNewCartModalBlockPresenterProps> => {
  const Presenter: React.FC<StartNewCartModalBlockPresenterProps> = (props) => {
    const { basket, handleClose, newRestaurantName } = props;
    const { t } = useTranslation();
    const blockProps: StartNewCartModalBlockProps = {
      ...defaultProps,
      modalHeader: {
        ...defaultProps.modalHeader,
        menuItem: {
          ...defaultProps.modalHeader.menuItem,
          value: t('start_new_cart_modal.header'),
        },
        button: {
          ...defaultProps.modalHeader.button,
          icon: {
            ...defaultProps.modalHeader.button?.icon,
            asset: 'Close',
          },
          onButtonClicked: handleClose,
        },
      },
      messageText: {
        ...defaultProps.messageText,
        value: t('start_new_cart_modal.message', {
          restaurant_name: basket?.restaurantName,
          new_restaurant_name: newRestaurantName,
        }),
      },
      buttonGroup1: {
        ...defaultProps.buttonGroup1,
        leftButton: {
          ...defaultProps.buttonGroup1.leftButton,
          onButtonClicked: props.handleStartNewCart,
          text: {
            ...defaultProps.buttonGroup1.leftButton?.text,
            value: t('start_new_cart_modal.ok'),
          },
        },
        rightButton: {
          ...defaultProps.buttonGroup1.rightButton,
          onButtonClicked: props.handleClose,
          text: {
            ...defaultProps.buttonGroup1.rightButton?.text,
            value: t('start_new_cart_modal.cancel'),
          },
        },
      },
    };

    return <View {...props} {...blockProps} />;
  };
  return Presenter;
};

export default withPresenter;
