import React from 'react';
import cx from 'classnames';

import styles from './StepItem.module.scss';

import OrderProgressItem, { OrderProgressItemProps } from '../../atoms/OrderProgressItem';
import StepIndicator, { StepIndicatorProps } from '../../atoms/StepIndicator';
import Text, { TextProps } from '../../atoms/Text';

export type StepItemStateType = 'Incomplete' | 'Inprogress' | 'Complete';
export type StepItemTypeType = 'First' | 'Center' | 'Last';

export const defaultProps = {
  state: 'Complete' as StepItemStateType,
  type: 'Last' as StepItemTypeType,
  orderProgressItem: {
    state: 'None',
  } as OrderProgressItemProps,
  stepIndicator: {
    asset: 'Complete',
  } as StepIndicatorProps,
  text: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
};

export type StepItemProps = {
  state?: StepItemStateType;
  type?: StepItemTypeType;
  orderProgressItem?: OrderProgressItemProps;
  stepIndicator?: StepIndicatorProps;
  text?: TextProps;
  className?: string;
};

const StepItem: React.FC<StepItemProps> = ({
  state,
  type,
  orderProgressItem,
  stepIndicator,
  text,
  className,
}) => {

  const currentStyle = styles[`stepItem${state}${type}`];

  const progressIndicatorView = (
    <div className={styles.progressIndicator}>
      <StepIndicator
        className={styles.stepIndicator}
        {...stepIndicator} />
      <OrderProgressItem
        className={styles.orderProgressItem}
        {...orderProgressItem} />
    </div>
  );
  const textView = (
    <Text
      className={styles.text}
      {...text} />
  );
  

  return (
    <div className={cx(currentStyle, className)}>
      {progressIndicatorView}
      {textView}
    </div>
  );
};

StepItem.defaultProps = defaultProps;

export default StepItem;
