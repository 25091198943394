import React, {
  MutableRefObject,
  RefObject,
  useEffect, useLayoutEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Account } from '../../../lib/token';
import { CustomerBasket, OrderType } from '../../../modules/checkout/types';
import { TopNavProps, TopNavStyleType, defaultProps } from './TopNav';
import { IPosition, useScrollPosition } from '../../../lib/use-scroll-position/src';
import { formatDisplayAddress } from '../../../lib/utils';
import useWindowSize from '../../../lib/useWindowSize';

export type TopNavPresenterProps = TopNavProps & {
  account?: Account | null;
  basket?: CustomerBasket;
  orderType?: OrderType;
  updateOrderType?: (orderType: OrderType) => void;
  scrollPosition?: IPosition;
  restaurantListBlockRef?: RefObject<HTMLDivElement>;
  restaurantListPageRef?: RefObject<HTMLDivElement>;
  setScrollPosition?: (position: IPosition) => void;
};

const HIDE_CART_PATHS = [
  '/signin',
  '/emailsignin',
  '/forgotpassword',
  '/updatesent',
  '/resetsent',
  '/createpassword',
  '/signup',
  '/emailsignup',
  '/verifyemail',
  '/completeprofile',
  '/checkout',
  '/checkout/payment',
  '/checkout/tip',
];

const HIDE_TOGGLE_PATHS = [
  '/profile',
  '/help',
  '/profile/changepassword',
];

const withPresenter = (
  View: React.FC<TopNavPresenterProps>,
): React.FC<TopNavPresenterProps> => {
  const Presenter: React.FC<TopNavPresenterProps> = (props) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const {
      account,
      basket,
      handleCartClick,
      orderType,
      updateOrderType,
      handleDropdownToggleClick,
      handleMenuClick,
      scrollPosition,
      setScrollPosition,
      restaurantListPageRef,
      restaurantListBlockRef,
    } = props;
    const { windowWidth, isMobile } = useWindowSize();
    const [cartState, setCartState] = useState<'Filled' | 'Empty'>('Empty');
    const [topNavStyle, setTopNavStyle] = useState<TopNavStyleType>(isMobile ? 'WithSubheader' : 'WithCart');

    useScrollPosition(
      ({ currPos }): void => {
        setScrollPosition && setScrollPosition(currPos);
      },
      undefined,
      restaurantListBlockRef as MutableRefObject<HTMLDivElement>,
      false,
      0,
      restaurantListPageRef as MutableRefObject<HTMLDivElement>,
    );

    const shouldHideCart = (pathname: string): boolean => HIDE_CART_PATHS.includes(pathname);

    const shouldHideToggle = (pathname: string): boolean => {
      const restaurantRegex = RegExp('/restaurant/.*');
      const orderRegex = RegExp('/order/[0-9]');
      return (
        restaurantRegex.test(pathname)
        || orderRegex.test(pathname)
        || HIDE_TOGGLE_PATHS.includes(pathname)
      );
    };

    const handleExpandToggle = (): void => {
      setTopNavStyle('WithSubheader');
    };

    useEffect(() => {
      if (shouldHideCart(pathname.toLowerCase())) {
        setTopNavStyle('NoCart');
      } else if (shouldHideToggle(pathname.toLowerCase())) {
        setTopNavStyle('NoToggle');
      } else if (isMobile) {
        setTopNavStyle('WithSubheader');
      } else {
        setTopNavStyle('WithCart');
      }
    }, [pathname, isMobile]);

    useLayoutEffect(() => {
      if (pathname === '/' && scrollPosition && isMobile) {
        if (scrollPosition.y > 0) {
          setTopNavStyle('WithSubheaderCollapsed');
        } else {
          setTopNavStyle('WithSubheader');
        }
      }
    }, [windowWidth, isMobile, pathname, scrollPosition]);

    useEffect(() => {
      if (basket && basket.items && basket.items.length > 0) {
        setCartState('Filled');
      } else {
        setCartState('Empty');
      }
    }, [basket, basket?.items?.length]);

    const defaultAddress = account?.profile?.address ? account.profile.address[0] : undefined;
    const preferredAddress = account?.profile?.preferredAddress
      ? account.profile.preferredAddress
      : defaultAddress;
    const topNavProps: TopNavProps = {
      ...defaultProps,
      style: topNavStyle,
      isMobile,
      text: {
        ...defaultProps.text,
        value: orderType,
      },
      cart: {
        state: cartState,
        onClicked: handleCartClick,
      },
      toggle: {
        ...defaultProps.toggle,
        firstOption: {
          ...defaultProps.toggle.firstOption,
          text: {
            ...defaultProps.toggle.firstOption?.text,
            value: t('textLabels.pickup'),
          },
          state: (orderType === 'Pickup') ? 'Selected' : 'Unselected',
          onClick: (): void => {
            if (updateOrderType) {
              updateOrderType('Pickup');
            }
          },
        },
        secondOption: {
          ...defaultProps.toggle.secondOption,
          text: {
            ...defaultProps.toggle.secondOption?.text,
            value: t('textLabels.delivery'),
          },
          state: (orderType === 'Delivery') ? 'Selected' : 'Unselected',
          onClick: (): void => {
            if (updateOrderType) {
              updateOrderType('Delivery');
            }
          },
        },
      },
      dropdownToggle: {
        ...defaultProps.dropdownToggle,
        text: {
          ...defaultProps.dropdownToggle.text,
          value: preferredAddress
            ? formatDisplayAddress(preferredAddress)
            : (defaultAddress ? formatDisplayAddress(defaultAddress) : ''),
        },
        onClick: handleDropdownToggleClick,
      },
      onClick: handleExpandToggle,
      menu: {
        ...defaultProps.menu,
        onButtonClicked: handleMenuClick,
      },
    };
    return <View
      {...props}
      {...topNavProps} />;
  };

  return Presenter;
};

export default withPresenter;
