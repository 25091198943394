import i18n from '../../../modules/locale/i18n';

export const provinceOptions = [{
  label: i18n.t('complete_profile.province.alberta.label'),
  value: i18n.t('complete_profile.province.alberta.value')
},
{
  label: i18n.t('complete_profile.province.british_columbia.label'),
  value: i18n.t('complete_profile.province.british_columbia.value')
},
{
  label: i18n.t('complete_profile.province.manitoba.label'),
  value: i18n.t('complete_profile.province.manitoba.value')
},
{
  label: i18n.t('complete_profile.province.new_brunswick.label'),
  value: i18n.t('complete_profile.province.new_brunswick.value')
},
{
  label: i18n.t('complete_profile.province.newfoundland_and_labrador.label'),
  value: i18n.t('complete_profile.province.newfoundland_and_labrador.value')
},
{
  label: i18n.t('complete_profile.province.northwest_territories.label'),
  value: i18n.t('complete_profile.province.northwest_territories.value')
},
{
  label: i18n.t('complete_profile.province.nova_scotia.label'),
  value: i18n.t('complete_profile.province.nova_scotia.value')
},
{
  label: i18n.t('complete_profile.province.nunavut.label'),
  value: i18n.t('complete_profile.province.nunavut.value')
},
{
  label: i18n.t('complete_profile.province.ontario.label'),
  value: i18n.t('complete_profile.province.ontario.value')
},
{
  label: i18n.t('complete_profile.province.prince_edward_island.label'),
  value: i18n.t('complete_profile.province.prince_edward_island.value')
},
{
  label: i18n.t('complete_profile.province.quebec.label'),
  value: i18n.t('complete_profile.province.quebec.value')
},
{
  label: i18n.t('complete_profile.province.saskatchewan.label'),
  value: i18n.t('complete_profile.province.saskatchewan.value')
},
{
  label: i18n.t('complete_profile.province.yukon.label'),
  value: i18n.t('complete_profile.province.yukon.value')
},
]

export const sourceOptions = [
  {
    label: i18n.t('complete_profile.sources.friend'),
    value: i18n.t('complete_profile.sources.friend'),
  },
  {
    label: i18n.t('complete_profile.sources.blog'),
    value: i18n.t('complete_profile.sources.blog'),
  },
  {
    label: i18n.t('complete_profile.sources.facebook'),
    value: i18n.t('complete_profile.sources.facebook'),
  },
  {
    label: i18n.t('complete_profile.sources.google_ads'),
    value: i18n.t('complete_profile.sources.google_ads'),
  },
  {
    label: i18n.t('complete_profile.sources.google_search'),
    value: i18n.t('complete_profile.sources.google_search'),
  },
  {
    label: i18n.t('complete_profile.sources.instagram'),
    value: i18n.t('complete_profile.sources.instagram'),
  },
  {
    label: i18n.t('complete_profile.sources.news'),
    value: i18n.t('complete_profile.sources.news'),
  },
  {
    label: i18n.t('complete_profile.sources.restaurant'),
    value: i18n.t('complete_profile.sources.restaurant'),
  },
  {
    label: i18n.t('complete_profile.sources.other'),
    value: i18n.t('complete_profile.sources.other'),
  },
];