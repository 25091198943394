import React, { useContext } from "react";
import { MenuItem } from '../../../modules/checkout/types';
import { ConfirmationModalBlockPresenterProps } from "./ConfirmationModalBlock.presenter"
import { ConfirmationModalBlockProps } from ".";
import { CheckoutContext } from "../../../modules/checkout";


const withInteractor = (
	Presenter: React.FC<ConfirmationModalBlockPresenterProps>
): React.FC<ConfirmationModalBlockProps> => {
	const Interactor: React.FC<ConfirmationModalBlockPresenterProps> = (props) => {
		const { basket, updateBasket } = useContext(CheckoutContext);

		const removeFromBasket = (itemPosition: number) => {
			if (basket) {
				const newItems = [...basket?.items];
				newItems.splice(itemPosition, 1);
				updateBasket({...basket, items: newItems});
			}
		};
		
		return <Presenter
			{...props}
			removeFromBasket={removeFromBasket} />;
	};
	return Interactor;
};
export default withInteractor;