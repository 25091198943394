import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  MenuPayload,
  MenuResponseObject,
  Product,
} from '../../../modules/menu/types';
import { MenuModalBlockProps, defaultProps } from './MenuModalBlock';
import { MenuSectionItemProps } from '../../organisms/MenuSectionItem/MenuSectionItem';
import { CustomerBasket, MenuItem } from '../../../modules/checkout/types';
import { Restaurant } from '../../../modules/restaurant/types';

export type MenuModalBlockPresenterProps = MenuModalBlockProps & {
  basket?: CustomerBasket;
  firstMenu?: MenuResponseObject;
  menuObject?: MenuPayload;
  productData?: Product;
  notifOpen?: () => void;
  handleAddToCart: (
    item: MenuItem,
    restaurant: Restaurant,
    menu: MenuResponseObject,
  ) => void;
  handleAddToNewCart: (
    item: MenuItem,
    restaurant: Restaurant,
    menu: MenuResponseObject,
  ) => void;
  handleEditCart: (item: MenuItem, position: number) => void;
  onCloseOutsideModal?: () => void;
};

const withPresenter = (
  View: React.FC<MenuModalBlockProps>,
): React.FC<MenuModalBlockPresenterProps> => {
  const Presenter: React.FC<MenuModalBlockPresenterProps> = (props) => {
    const {
      basket,
      firstMenu,
      menuObject,
      productData,
      menuModalOpen,
      handleAddToNewCart,
      handleAddToCart,
      editModal,
      handleEditCart,
      onEditCart,
      notifOpen,
      restaurant,
      editPosition,
      onCloseOutsideModal,
    } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const { restaurantId } = useParams<{ restaurantId: string }>();
    const [blockProps, setBlockProps] =
      useState<MenuModalBlockProps>(defaultProps);
    const [menuItem, setMenuItem] = useState<MenuItem | undefined>(undefined);
    const [startNewCartOpen, setStartNewCartOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuSectionItems, setMenuSectionItems] = useState<
      MenuSectionItemProps[]
    >([]);
    const [focusedHeaderId, setFocusedHeaderId] = useState<string>();

    const isDifferentRestaurant =
      basket?.restaurantId && restaurantId !== basket.restaurantId;

    const validateRequire = useCallback(
      (
        menuObject: MenuPayload,
        productData: Product,
        menuItem: MenuItem,
      ): string[] => {
        const errorArr: string[] = [];

        if (!productData || !menuObject) {
          return [];
        }
        if (!menuItem?.quantity || menuItem.quantity === 0) {
          return [];
        }

        if (productData && menuObject) {
          // TODO: could have refactor these checks
          // check required againts min on bundle
          productData.subProducts
            ?.filter((subProductId) => menuObject.bundles[subProductId])
            .forEach((subProductId) => {
              if (menuObject.bundles[subProductId].min >= 1) {
                let isMinValue = false;
                menuItem?.subItems?.map((bundle) => {
                  if (
                    bundle.plu === menuObject.bundles[subProductId].plu
                    && bundle.subItems
                    && bundle.subItems.length > 0
                  ) {
                    isMinValue = true;
                  }
                });
                if (!isMinValue) {
                  // bundle group Id stored if the min value not fulfilled
                  errorArr.push(subProductId);
                }
              }
            });
          // check required againts min on groupModifiers
          productData.subProducts
            ?.filter((subProductId) => menuObject.modifierGroups[subProductId])
            .forEach((subProductId) => {
              if (menuObject.modifierGroups[subProductId].min >= 1) {
                let isMinValue = false;
                menuItem?.subItems?.map((modifier) => {
                  if (
                    modifier.plu
                      === menuObject.modifierGroups[subProductId].plu
                    && modifier.subItems
                    && modifier.subItems.length > 0
                  ) {
                    isMinValue = true;
                  }
                });
                if (!isMinValue) {
                  // Modifier group Id stored if the min value not fulfilled
                  errorArr.push(subProductId);
                }
              }
            });
        }
        return errorArr;
      },
      [],
    );

    const validateSubItemQuantity = useCallback(
      (menuItem: MenuItem): MenuItem | undefined => {
        if (!menuItem) {
          return menuItem;
        }

        if (!menuItem.subItems) {
          return menuItem;
        }

        const itemGroupExceedingQuantityLimit = menuItem.subItems.find(
          (itemGroup) => {
            const maxQuantity = itemGroup.multiMax;
            const quantity = itemGroup.quantity;

            if (!maxQuantity) {
              return false;
            }
            if (!itemGroup.subItems) {
              return false;
            }
            return quantity > maxQuantity;
          },
        );
        return itemGroupExceedingQuantityLimit;
      },
      [],
    );

    const isValid = (menuObject, productData, menuItem): boolean => {
      const isRequiredError = validateRequire(
        menuObject,
        productData,
        menuItem,
      );
      if (!menuItem?.quantity || menuItem.quantity === 0) {
        return false;
      }
      const quantityError = validateSubItemQuantity(menuItem);
      let topErrorHeaderId;
      const newMenuSectionItems = menuSectionItems.map(
        (item: MenuSectionItemProps) => {
          if (item.itemGroup?._id) {
            item.isRequiredError =
              isRequiredError.indexOf(item.itemGroup._id) !== -1;
            item.isQuantityError =
              item.itemGroup._id === quantityError?.productId;
            if((item.isRequiredError || item.isQuantityError) && !topErrorHeaderId) {
                topErrorHeaderId = item.itemGroup._id;
            }
          }
          return item;
        },
      );
      setFocusedHeaderId(topErrorHeaderId);
      setMenuSectionItems(newMenuSectionItems);

      return isRequiredError.length === 0 && !quantityError;
    };

    const handleSubItemChange = useCallback(
      (value: MenuItem) => {
        setMenuItem((previousMenuItem) => {
          if (previousMenuItem) {
            const menuItemsData: MenuItem = { ...previousMenuItem };
            if (menuItemsData && menuItemsData.subItems) {
              if (menuItemsData.subItems.length > 0) {
                let subMenuAlreadyExist = false;
                for (
                  let index = 0;
                  index < menuItemsData.subItems.length;
                  index++
                ) {
                  if (menuItemsData.subItems[index].plu === value.plu) {
                    menuItemsData.subItems[index] = value;
                    subMenuAlreadyExist = true;
                  }
                }
                if (!subMenuAlreadyExist) {
                  menuItemsData.subItems.push(value);
                }
              } else {
                menuItemsData.subItems.push(value);
              }
            }

            return menuItemsData;
          }
        });
      },
      [setMenuItem],
    );

    const handleAdditionalNotes = useCallback(
      ({ target: { value } }): void => {
        if (menuItem) {
          setMenuItem({
            ...menuItem,
            remark: value,
          });
        }
      },
      [menuItem],
    );

    const handleQuantity = useCallback(
      ({ target: { value } }): void => {
        if (!!menuItem) {
          setMenuItem({
            ...menuItem,
            quantity: Number(value),
          });
        }
      },
      [menuItem],
    );

    const handleCloseMenuModal = (): void => {
      setMenuOpen(false);
      setMenuItem(undefined);
      if (editModal) {
        onEditCart && onEditCart();
      }
      history.goBack();
    };

    const handleCloseOutsideModal = (): void => {
      setMenuOpen(false);
      setMenuItem(undefined);
      if (editModal) {
        onEditCart && onEditCart();
        onCloseOutsideModal && onCloseOutsideModal();
      }
    }

    const addToCart = (): void => {
      if (isDifferentRestaurant && isValid(menuObject, productData, menuItem)) {
        setStartNewCartOpen(true);
        setMenuOpen(false);
      } else {
        if (menuItem && isValid(menuObject, productData, menuItem)) {
          handleCloseMenuModal();
          if (restaurant && firstMenu) {
            handleAddToCart(menuItem, restaurant, firstMenu);
          }
          notifOpen && notifOpen();
        }
      }
    };

    const editCart = useCallback(() => {
      if (isValid(menuObject, productData, menuItem)) {
        if (menuItem && (editPosition === 0 || editPosition)) {
          handleEditCart && handleEditCart(menuItem, editPosition);
        }
        onEditCart && onEditCart();
      }

      // disable hook on to: `onEditCart`
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItem, editPosition, handleEditCart]);

    const addToNewCart = (): void => {
      if (menuItem) {
        setStartNewCartOpen(false);
        setMenuOpen(false);

        if (restaurant && firstMenu) {
          handleAddToNewCart(menuItem, restaurant, firstMenu);
        }

        notifOpen && notifOpen();
        history.goBack();
      }
    };

    const closeStartNewCartModal = (): void => {
      setStartNewCartOpen(false);
      history.push({
        search: '',
      });
    };

    useEffect(() => {
      if (menuModalOpen) {
        setMenuOpen(true);
        setStartNewCartOpen(false);
      }
    }, [menuModalOpen]);

    useEffect(() => {
      if (productData && firstMenu) {
        const initializedMenuItem: MenuItem = {
          plu: productData.plu,
          name: productData.name,
          price: productData.price,
          productType: productData.productType,
          remark: '',
          subItems: [],
          tax: productData.takeawayTax,
          quantity: 1,
          productId: productData._id,
        };
        setMenuItem(initializedMenuItem);
      }
    }, [productData, firstMenu, menuObject, menuModalOpen]);

    useEffect(() => {
      if (productData && editModal) {
        let editItem: MenuItem | undefined;
        // 0 is falsy, so a valid check is either `editPosition` is 0 or `editPosition` is truthy
        if (editPosition === 0 || editPosition) {
          editItem = basket?.items[editPosition];
          if (editItem) {
            const updatedMenuItem: MenuItem = {
              plu: productData.plu,
              name: productData.name,
              price: productData.price,
              productType: productData.productType,
              remark: editItem.remark,
              tax: productData.takeawayTax,
              productId: productData._id,
              subItems: editItem.subItems,
              quantity: editItem.quantity,
            };
            setMenuItem(updatedMenuItem);
          }
        }
      }
    }, [firstMenu, menuObject, productData, editPosition, basket, editModal]);

    useEffect(() => {
      // list of id of all subItems
      // ['aaa', 'aaa', 'bbb', 'bbb', 'bbb']
      // means the menuItem has 2 'aaa' and 3 'bbb' subItems
      let editSubItemsQuantity: string[] = [];
      if (editPosition === 0 || editPosition) {
        const editItem = basket?.items[editPosition];
        editItem?.subItems?.forEach((item) => {
          item?.subItems?.forEach((subItem) => {
            const numberOfItems: string[] = new Array(subItem.quantity).fill(
              subItem.productId,
            );

            editSubItemsQuantity = [...editSubItemsQuantity, ...numberOfItems];
          });
        });
      }

      // add groupmodifiers & bundles into item list
      if (productData && menuObject) {
        const modifierGroups: MenuSectionItemProps[] = productData.subProducts
          .filter((subProductId) => menuObject.modifierGroups[subProductId])
          .map((subProductId) => ({
            itemGroup: menuObject.modifierGroups[subProductId],
            itemMap: menuObject.modifiers,
            handleChange: handleSubItemChange,
            alreadySelectedItems: editSubItemsQuantity.filter((subItemId) =>
              menuObject.modifierGroups[subProductId].subProducts.find(
                (id) => id === subItemId,
            )),
            isQuantityError: false,
            isRequiredError: false,
          }));

        const bundles: MenuSectionItemProps[] = productData.subProducts
          .filter((subProductId) => menuObject.bundles[subProductId])
          .map((subProductId) => ({
            itemGroup: menuObject.bundles[subProductId],
            itemMap: menuObject.products,
            handleChange: handleSubItemChange,
            alreadySelectedItems: editSubItemsQuantity.filter((subItemId) =>
              menuObject.bundles[subProductId].subProducts.find(
                (id) => id === subItemId,
            )),
            isQuantityError: false,
            isRequiredError: false,
            // TODO: handle nested bundle
          }));

        setMenuSectionItems([...bundles, ...modifierGroups]);
      }
    }, [
      productData,
      menuObject,
      handleSubItemChange,
      editPosition,
      setMenuSectionItems,
      basket,
      menuOpen,
    ]);

    useEffect(() => {
      const newMenuSectionItems = menuSectionItems.map(
        (item: MenuSectionItemProps) => {
          if (item.itemGroup?._id) {
            item.focusedHeaderId = focusedHeaderId;
          }
          return item;
        },
      );
      setMenuSectionItems(newMenuSectionItems);
      setFocusedHeaderId(undefined);
    }, [focusedHeaderId, setFocusedHeaderId])

    useEffect(() => {
      setBlockProps({
        ...defaultProps,
        menuItemConfigurationModal: {
          ...defaultProps.menuItemConfigurationModal,
          onCloseOutsideModal: editModal ? handleCloseOutsideModal : handleCloseMenuModal,
          foodItemHeader: {
            ...defaultProps.menuItemConfigurationModal.foodItemHeader,
            closeButton: {
              ...defaultProps.menuItemConfigurationModal.foodItemHeader?.closeButton,
              onButtonClicked: editModal ? onEditCart : handleCloseMenuModal,
            },
            button: {
              ...defaultProps.menuItemConfigurationModal.foodItemHeader?.button,
              onButtonClicked: editModal ? onEditCart : handleCloseMenuModal,
            },
            titleText: {
              ...defaultProps.menuItemConfigurationModal.foodItemHeader?.titleText,
              value: productData?.name,
            },
          },
          foodItemBanner: {
            ...defaultProps.menuItemConfigurationModal.foodItemBanner,
            titleText: {
              ...defaultProps.menuItemConfigurationModal.foodItemBanner
                ?.titleText,
              value: productData?.name,
            },
            descriptionText: {
              ...defaultProps.menuItemConfigurationModal.foodItemBanner
                ?.descriptionText,
              value: productData?.description,
            },
            bannerUrl: productData?.imageUrl,
          },
          menuSectionItemList: {
            ...defaultProps.menuItemConfigurationModal.menuSectionItemList,
            menuSectionItems,
          },
          additionalNoteTextField: {
            ...defaultProps.menuItemConfigurationModal.additionalNoteTextField,
            label: {
              ...defaultProps.menuItemConfigurationModal.additionalNoteTextField
                ?.label,
              value: t('menu_modal.menu_template.additional_notes'),
            },
            textInput: {
              ...defaultProps.menuItemConfigurationModal.additionalNoteTextField
                ?.textInput,
              textValue: menuItem?.remark,
              onTextChanged: handleAdditionalNotes,
              maxLength: 250,
            },
            helpText: {
              ...defaultProps.menuItemConfigurationModal.additionalNoteTextField
                ?.helpText,
              // value: t('menu_modal.menu_template.note_value'),
            },
          },
          quantityTextField: {
            ...defaultProps.menuItemConfigurationModal.quantityTextField,
            label: {
              ...defaultProps.menuItemConfigurationModal.quantityTextField
                ?.label,
              value: t('menu_modal.menu_template.quantity'),
            },
            textInput: {
              ...defaultProps.menuItemConfigurationModal.quantityTextField
                ?.textInput,
              textValue: menuItem?.quantity ? String(menuItem?.quantity) : '',
              onTextChanged: handleQuantity,
            },
            errorText: {
              ...defaultProps.menuItemConfigurationModal.quantityTextField?.errorText,
              value: (!menuItem?.quantity || menuItem?.quantity === 0) && t('menu_modal.menu_template.quantity_error'),
            },
            state: !menuItem?.quantity || menuItem?.quantity === 0 ? 'Error' : 'Default',
          },
          button: {
            ...defaultProps.menuItemConfigurationModal.button,
            onButtonClicked: editModal ? editCart : addToCart,
            text: {
              ...defaultProps.menuItemConfigurationModal.button?.text,
              value: editModal
                ? t('menu_modal.menu_template.button_edit')
                : t('menu_modal.menu_template.button'),
            },
          },
        },
      });
      // disable hook on to: `addToCart`
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      menuItem,
      editModal,
      handleAdditionalNotes,
      menuSectionItems,
      productData,
      menuObject,
    ]);

    return (
      <View
        {...props}
        {...blockProps}
        menuModalOpen={menuOpen}
        closeMenuModal={handleCloseMenuModal}
        startNewCartModalOpen={startNewCartOpen}
        addToCart={addToCart}
        addToNewCart={addToNewCart}
        closeStartNewCartModal={closeStartNewCartModal}
      />
    );
  };
  return Presenter;
};

export default withPresenter;
