import React, { useContext, useEffect } from "react";
import { RestaurantListBlockProps } from "./RestaurantListBlock";
import { RestaurantListBlockPresenterProps } from "./RestaurantListBlock.presenter";
import { AuthContext } from "../../../modules/auth";
import { useRestaurantList } from "../../../modules/restaurant";
import { CheckoutContext } from "../../../modules/checkout";

const withInteractor = (
  Presenter: React.FC<RestaurantListBlockPresenterProps>
): React.FC<RestaurantListBlockProps> => {
  const Interactor: React.FC<RestaurantListBlockProps> = (props) => {
    const { handleChangeAddress, updateSkipToAddressChange } = props;
    const { account } = useContext(AuthContext);
    const { orderType } = useContext(CheckoutContext);
    let userLocation;
    if(account?.profile?.preferredAddress?.location) {
      userLocation = account.profile.preferredAddress.location;
    } else if(account?.profile?.address) {
      userLocation = account?.profile?.address[0].location;
    }

    useEffect(() => {
      if(!userLocation && handleChangeAddress && updateSkipToAddressChange) {
        // set state to skip view_address to change_address modal
        updateSkipToAddressChange(true);
        // open modal
        handleChangeAddress();
      }
    }, [userLocation, handleChangeAddress, updateSkipToAddressChange])
    
    const { isLoading, data } = useRestaurantList({ userLocation: userLocation, orderType: orderType });

    return <Presenter 
            {...props}
            isLoading={isLoading}
            orderType={orderType}
            data={data}/>;
  };
  return Interactor;
};
export default withInteractor;
