import React, { useContext } from 'react';
import { StartNewCartModalBlockPresenterProps } from './StartNewCartModalBlock.presenter';
import { StartNewCartModalBlockProps } from '.';
import { CheckoutContext } from '../../../modules/checkout';

const withInteractor = (
  Presenter: React.FC<StartNewCartModalBlockPresenterProps>,
): React.FC<StartNewCartModalBlockProps> => {
  const Interactor: React.FC<StartNewCartModalBlockProps> = (props) => {
    const { basket } = useContext(CheckoutContext);
    return <Presenter
			{...props}
      basket={basket} />;
  };
  return Interactor;
};
export default withInteractor;
