import React from 'react';
import cx from 'classnames';

import styles from './EmailSignInPage.module.scss';

import EmailSignInBlock, { EmailSignInBlockProps } from '../../block/EmailSignInBlock';
import { NotificationProps } from '../../molecules/Notification';

export const defaultProps = {
  emailSignInBlock: {
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
    email: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    password: {
      state: 'Default',
      type: 'Password',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Password',
        style: 'Default',
        text: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey700',
        },
        icon: {
          asset: 'Show',
          style: 'Grey1100',
        },
      },
    },
    forgotPassword: {
      type: 'Body2',
      align: 'Left',
      style: 'Green800',
    },
    secondaryAction: {
      title: {
        type: 'Title4',
        align: 'Center',
        style: 'Grey1100',
      },
      link: {
        type: 'Body2',
        align: 'Center',
        style: 'Green800',
      },
    },
    notification: {
      type: 'Default',
      divider: {
        style: "Grey800"
      },
      title: {
        style: 'Grey1100',
        align: 'Left',
        type: 'Title4',
      }
    } as NotificationProps
  } as EmailSignInBlockProps,
};

export type EmailSignInPageProps = {
  emailSignInBlock?: EmailSignInBlockProps;
  className?: string;
};

const EmailSignInPage: React.FC<EmailSignInPageProps> = ({
  emailSignInBlock,
  className,
}) => {
  return (
    <div className={cx(styles.emailSignInPage, className)}>
      <EmailSignInBlock
        className={styles.emailSignInBlock}
        {...emailSignInBlock} />
    </div>
  );
};

EmailSignInPage.defaultProps = defaultProps;

export default EmailSignInPage;
