export type User = {
  id: number;
  uuid: string;
  accountId: number;
  signUpComplete: boolean;
};

export type Account = {
  id: number;
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  profile?: Profile;
  createdAt?: Date;
  status?: string
};

export type Profile = {
  address: Address[];
  preferredAddress?: Address;
  phoneNumber: string;
  reference?: string;
  referenceRestaurant?: string;
  checkPromotionalEvents?: boolean;
};

export type Address = {
  streetNumber: string;
  addressLine: string;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  location: Location;
  addressLine2?: string;
  deliveryInstructions?: string;
}

export type Location = {
  latitude: number;
  longitude: number;
}

/**
 * Extracts payload from a jwt
 */
export function extractJwtPayload(jwt: string): Account {
  const payload = window.atob(jwt.split('.')[1]);
  return JSON.parse(payload);
}

export function getUserInitials(account: Account): string {
  const { firstName = '', lastName = '' } = account;
  const firstInitial: string = firstName.trim()[0] ?? '';
  const secondInitial: string = lastName.trim()[0] ?? '';
  return `${firstInitial}${secondInitial}`.toLocaleUpperCase();
}

export function getUserFullName(account: Account): string {
  const { firstName = '', lastName = '' } = account;
  return `${firstName.trim()} ${lastName.trim()}`.trim();
}
