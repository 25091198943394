import { useQuery } from 'react-query';
import { getAddressSuggestions } from './api';

const useAddressSuggestions = (input: string, sessionToken: string) => useQuery(
  ['getAddressSuggestions', input],
  () => {
    if (input) {
      return getAddressSuggestions({ input, sessionToken });
    }
    return undefined;
  },
);

export default useAddressSuggestions;
