import React from 'react';
import cx from 'classnames';

import styles from './StepIndicator.module.scss';
import CheckboxChecked from '../../../resources/icons/CheckboxChecked.svg';
import CheckboxIntermediate from '../../../resources/icons/CheckboxIntermediate.svg';
import CheckboxUnchecked from '../../../resources/icons/CheckboxUnchecked.svg';

export type StepIndicatorAssetType = 'Inprogress' | 'Incomplete' | 'Complete';

export const defaultProps = {
  asset: 'Complete' as StepIndicatorAssetType,
};

export type StepIndicatorProps = {
  asset?: StepIndicatorAssetType;
  contentAlt?: string;
  className?: string;
};

const StepIndicator: React.FC<StepIndicatorProps> = ({
  asset,
  contentAlt,
  className,
}) => {
  const currentStyle = styles[`stepIndicator${asset}`];
  let content = '';
  switch (asset) {
    case 'Inprogress':
      content = CheckboxIntermediate;
      break;
    case 'Incomplete':
      content = CheckboxUnchecked;
      break;
    case 'Complete':
      content = CheckboxChecked;
      break;
    default:
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      <img
        className={styles.content}
        alt={contentAlt}
        src={content} />
    </div>
  );
};

StepIndicator.defaultProps = defaultProps;

export default StepIndicator;
