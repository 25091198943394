import React from 'react';
import cx from 'classnames';

import styles from './CompleteProfilePage.module.scss';

import CompleteProfileBlock, { CompleteProfileBlockProps } from '../../block/CompleteProfileBlock';

export const defaultProps = {
  completeProfileBlock: {
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey100',
      },
    },
    backButton: {
      size: 'ExtraSmall',
      type: 'IconText',
      style: 'BasicSquare',
      icon: {
        asset: 'ArrowLeft',
        style: 'Grey1100',
      },
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
    phoneNumber: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    address: {
      state: 'Default',
      addressLine1: {
        state: 'Default',
        type: 'Text',
        label: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey1100',
        },
        textInput: {
          type: 'Text',
          style: 'Default',
        },
      },
      addressLine2: {
        type: 'Text',
        style: 'Default',
      },
      city: {
        type: 'Text',
        style: 'Default',
      },
      postalCode: {
        type: 'Text',
        style: 'Default',
      },
      province: {
        text: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey700',
        },
        icon: {
          asset: 'ChevronDown',
          style: 'Grey1100',
        },
      },
      country: {
        type: 'Text',
        style: 'Default',
      },
    },
    referenceField: {
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      select: {
        text: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey700',
        },
        icon: {
          asset: 'ChevronDown',
          style: 'Grey1100',
        },
      },
    },
    additionalFiled: {
      state: 'Default',
      hidden: true,
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    checkboxItem: {
      type: 'Default',
      state: 'Unselected',
      icon: {
        asset: 'CheckboxUnchecked',
        style: 'Grey700',
      },
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
    },
    legal: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
  } as CompleteProfileBlockProps,
};

export type CompleteProfilePageProps = {
  completeProfileBlock?: CompleteProfileBlockProps;
  className?: string;
};

const CompleteProfilePage: React.FC<CompleteProfilePageProps> = ({
  completeProfileBlock,
  className,
}) => {
  return (
    <div className={cx(styles.completeProfilePage, className)}>
      <CompleteProfileBlock
        className={styles.completeProfileBlock}
        {...completeProfileBlock} />
    </div>
  );
};

CompleteProfilePage.defaultProps = defaultProps;

export default CompleteProfilePage;
