import React from 'react';
import cx from 'classnames';

import styles from './OrderProgressItem.module.scss';

export type OrderProgressItemStateType = 'Complete' | 'Incomplete' | 'None' | 'Inprogress';

export const defaultProps = {
  state: 'None' as OrderProgressItemStateType,
};

export type OrderProgressItemProps = {
  state?: OrderProgressItemStateType;
  className?: string;
};

const OrderProgressItem: React.FC<OrderProgressItemProps> = ({
  state,
  className,
}) => {
  const currentStyle = styles[`orderProgressItem${state}`];
  switch (state) {
    case 'Complete':
      break;
    case 'Incomplete':
      break;
    case 'Inprogress':
      return (
        <div className={cx(currentStyle, className)}>
          <div className={styles.progress}></div>
        </div>
      );
    case 'None':
      break;
  }

  return (
    <div className={cx(currentStyle, className)}/>
  );
};

OrderProgressItem.defaultProps = defaultProps;

export default OrderProgressItem;
