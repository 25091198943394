import React from 'react';
import cx from 'classnames';

import styles from './PriceList.module.scss';

import PriceItem, { PriceItemProps } from '../../molecules/PriceItem';

export const defaultProps = {
  priceItems: [
  ] as PriceItemProps[],
};

export type PriceListProps = {
  priceItems?: PriceItemProps[];
  className?: string;
};

const PriceList: React.FC<PriceListProps> = ({
  priceItems,
  className,
}) => {
  const priceItemArray = priceItems?.map((priceItem) => (
    <PriceItem
      className={styles.priceItem}
      {...priceItem} />
  ));
  
  return (
    <div className={cx(styles.priceList, className)}>
      {priceItemArray}
    </div>
  );
};

PriceList.defaultProps = defaultProps;

export default PriceList;
