import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Account } from '../../../lib/token';
import { defaultProps } from '../../organisms/ProfileMenu/ProfileMenu';
import { ProfileMenuModalProps } from './ProfileMenuModal';
import UserAvatar from '../../../resources/images/UserAvatar.png';
import { ButtonItemTypeType, defaultProps as buttonItemDefaultProps } from '../../atoms/ButtonItem/ButtonItem';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { CustomerBasket } from '../../../modules/checkout/types';
import { ChipProps } from '../../atoms/Chip';

export type ProfileMenuModalPresenterProps = ProfileMenuModalProps & {
  account?: Account | null;
  basket?: CustomerBasket;
  onCartOpen: () => void;
  logout: () => void;
};

const withPresenter = (
  View: React.FC<ProfileMenuModalProps>,
): React.FC<ProfileMenuModalPresenterProps> => {
  const Presenter: React.FC<ProfileMenuModalPresenterProps> = (props) => {
    const {
      account, onClose, basket, onCartOpen, logout,
    } = props;
    const { t } = useTranslation();
    const history = useHistory();

    const handleMenuRedirect = (path: string) => (): void => {
      history.push(path);
      onClose();
    };

    const onCartClick = (): void => {
      onClose();
      onCartOpen();
    };

    const onLogoutClick = (): void => {
      logout();
      window.location.reload();
    };

    const isFullBasket = basket && basket.items && basket.items.length > 0;
    const profileMenuModalProps = {
      ...props,
      profileMenu: {
        ...defaultProps,
        userInfo: {
          ...defaultProps.userInfo,
          button: {
            ...defaultProps.userInfo.button,
            onButtonClicked: (): void => onClose(),
          },
          userAvatar: {
            srcValue: UserAvatar,
          },
          name: {
            ...defaultProps.userInfo.name,
            value: `${account?.firstName} ${account?.lastName}`,
          },
          email: {
            ...defaultProps.userInfo.email,
            value: account?.email,
          },
        },
        buttonItemList: {
          buttonItems: [
            {
              ...buttonItemDefaultProps,
              icon: {
                ...buttonItemDefaultProps.icon,
                asset: 'Restaurant',
                style: 'Grey1100',
              } as IconProps,
              text: {
                ...buttonItemDefaultProps.text,
                value: t('profile_menu_modal.browse_restaurants'),
              } as TextProps,
              key: t('profile_menu_modal.browse_restaurants'),
              onButtonItemClicked: handleMenuRedirect('/'),
            },
            {
              ...buttonItemDefaultProps,
              icon: {
                ...buttonItemDefaultProps.icon,
                asset: isFullBasket ? 'CartFull' : 'CartEmpty',
                style: 'Grey1100',
              } as IconProps,
              text: {
                ...buttonItemDefaultProps.text,
                value: t('profile_menu_modal.cart'),
              } as TextProps,
              key: t('profile_menu_modal.cart'),
              onButtonItemClicked: onCartClick,
            },
            {
              ...buttonItemDefaultProps,
              icon: {
                ...buttonItemDefaultProps.icon,
                asset: 'Profile',
                style: 'Grey1100',
              } as IconProps,
              text: {
                ...buttonItemDefaultProps.text,
                value: t('profile_menu_modal.profile'),
              } as TextProps,
              key: t('profile_menu_modal.profile'),
              onButtonItemClicked: handleMenuRedirect('/profile'),
            },
            {
              ...buttonItemDefaultProps,
              type: 'Chip' as ButtonItemTypeType,
              icon: {
                ...buttonItemDefaultProps.icon,
                asset: 'Card',
                style: 'Grey1100',
              } as IconProps,
              text: {
                ...buttonItemDefaultProps.text,
                value: t('profile_menu_modal.payment'),
              } as TextProps,
              chip: {
                ...buttonItemDefaultProps.chip,
                text: {
                  ...buttonItemDefaultProps.chip.text,
                  value: t('profile_menu_modal.coming_soon'),
                },
              } as ChipProps,
              key: t('profile_menu_modal.payment'),
            },
            {
              ...buttonItemDefaultProps,
              icon: {
                ...buttonItemDefaultProps.icon,
                asset: 'Question',
                style: 'Grey1100',
              } as IconProps,
              text: {
                ...buttonItemDefaultProps.text,
                value: t('profile_menu_modal.help'),
              } as TextProps,
              key: t('profile_menu_modal.help'),
              onButtonItemClicked: handleMenuRedirect('/help'),
            },
            {
              ...buttonItemDefaultProps,
              icon: {
                ...buttonItemDefaultProps.icon,
                asset: 'Exit',
                style: 'Grey1100',
              } as IconProps,
              text: {
                ...buttonItemDefaultProps.text,
                value: t('profile_menu_modal.log_out'),
                style: 'Red800',
              } as TextProps,
              key: t('profile_menu_modal.log_out'),
              onButtonItemClicked: onLogoutClick,
            },
          ],
        },
      },
    };

    return (
      <View
        {...profileMenuModalProps}
      />
    );
  };
  return Presenter;
};

export default withPresenter;
