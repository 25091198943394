import React from 'react';
import cx from 'classnames';

import styles from './PriceOverviewItem.module.scss';

import MenuItem, { MenuItemProps } from '../../molecules/MenuItem';
import PriceISectionItemList, { PriceISectionItemListProps } from '../PriceISectionItemList';
import Notification, { NotificationProps } from '../../molecules/Notification';
import ButtonGroup1, { ButtonGroup1Props } from '../../molecules/ButtonGroup1';
import Divider, { DividerProps } from '../../atoms/Divider';

export const defaultProps = {
  menuItem: {
    itemQuantity: {
      type: 'Title4',
      align: 'Center',
      style: 'Grey1100',
    },
    itemName: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    cost: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
  } as MenuItemProps,
  priceISectionItemList: {
    priceSectionItems: [
    ],
  } as PriceISectionItemListProps,
  notification: {
    type: 'Instructions',
    button: {
      size: 'ExtraExtraSmall',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Info',
        style: 'Blue1000',
      },
    },
    title: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    text: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Blue200',
    },
  } as NotificationProps,
  buttonGroup1: {
    leftButton: {
      size: 'Small',
      type: 'Text',
      style: 'InfoRounded',
      text: {
        type: 'Button2',
        align: 'Center',
        style: 'Blue800',
      },
    },
    rightButton: {
      size: 'Small',
      type: 'Text',
      style: 'DangerRounded',
      text: {
        type: 'Button2',
        align: 'Center',
        style: 'Red800',
      },
    },
  } as ButtonGroup1Props,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  displayButtonGroup: true,
};

export type PriceOverviewItemProps = {
  menuItem?: MenuItemProps;
  priceISectionItemList?: PriceISectionItemListProps;
  showNotification?: boolean;
  notification?: NotificationProps;
  buttonGroup1?: ButtonGroup1Props;
  divider?: DividerProps;
  className?: string;
  displayButtonGroup?: boolean;
};

const PriceOverviewItem: React.FC<PriceOverviewItemProps> = ({
  menuItem,
  priceISectionItemList,
  showNotification,
  notification,
  buttonGroup1,
  divider,
  className,
  displayButtonGroup
}) => {
  const notificationView = showNotification ? (
    <Notification
      className={styles.notification}
      {...notification} />
  ) : undefined;
  return (
    <div className={cx(styles.priceOverviewItem, className)}>
      <MenuItem
        className={styles.menuItem}
        {...menuItem} />
      {((priceISectionItemList?.priceSectionItems?.length || 0) > 0) && <PriceISectionItemList
        className={styles.priceISectionItemList}
        {...priceISectionItemList} />}
      {notificationView}
      {displayButtonGroup && <ButtonGroup1
        className={styles.buttonGroup1}
        {...buttonGroup1} />}
      <Divider
        className={styles.divider}
        {...divider} />
    </div>
  );
};

PriceOverviewItem.defaultProps = defaultProps;

export default PriceOverviewItem;
