import React from 'react';
import cx from 'classnames';

import styles from './AddressModal.module.scss';

import ModalHeader, { ModalHeaderProps } from '../../molecules/ModalHeader';
import AddressItem, { AddressItemProps } from '../../molecules/AddressItem';
import Button, { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';

export const defaultProps = {
  modalHeader: {
    type: 'Close',
    menuItem: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    button: {
      size: 'ExtraSmall',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as ModalHeaderProps,
  addressItem: {
    street: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    } as TextProps,
    postalCode: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    } as TextProps,
    cityAndProvince: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    } as TextProps,
    changeButton: {
      size: 'Small',
      type: 'Text',
      style: 'InfoRounded',
      text: {
        type: 'Button2',
        align: 'Center',
        style: 'Blue800',
      },
    },
  } as AddressItemProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type AddressModalProps = {
  modalHeader?: ModalHeaderProps;
  addressItem?: AddressItemProps[];
  button?: ButtonProps;
  className?: string;
};

const AddressModal: React.FC<AddressModalProps> = ({
  modalHeader,
  addressItem,
  button,
  className,
}) => {
  let addressItemListView = [<></>];
  addressItem?.forEach((item : AddressItemProps) => {
    addressItemListView.push(
      <AddressItem
        className={styles.addressItem}
        {...item} />
    )
  })
  return (
    <div className={cx(styles.addressModal, className)}>
      <ModalHeader
        className={styles.modalHeader}
        {...modalHeader} />
      <div className={styles.content}>
        {addressItemListView}
        <div className={styles.buttonContent}>
          <Button
            className={styles.button}
            {...button} />
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
