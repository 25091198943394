import React from 'react';
import cx from 'classnames';

import styles from './SecondaryAction.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  title: {
    type: 'Title4',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
  link: {
    type: 'Body2',
    align: 'Center',
    style: 'Green800',
  } as TextProps,
};

export type SecondaryActionProps = {
  title?: TextProps;
  link?: TextProps;
  className?: string;
};

const SecondaryAction: React.FC<SecondaryActionProps> = ({
  title,
  link,
  className,
}) => {
  return (
    <div className={cx(styles.secondaryAction, className)}>
      <Text
        className={styles.title}
        {...title} />
      <Text
        className={styles.link}
        {...link} />
    </div>
  );
};

SecondaryAction.defaultProps = defaultProps;

export default SecondaryAction;
