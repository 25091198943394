import React from 'react';
import cx from 'classnames';

import styles from './ProfilePage.module.scss';

import ProfileBlock, { ProfileBlockProps } from '../../block/ProfileBlock';

export const defaultProps = {
  profileBlock: {
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    firstName: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    lastName: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    phoneNumber: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    emailAddress: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    changePassword: {
      type: 'Body2',
      align: 'Center',
      style: 'Green800',
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
  } as ProfileBlockProps,
};

export type ProfilePageProps = {
  profileBlock?: ProfileBlockProps;
  className?: string;
};

const ProfilePage: React.FC<ProfilePageProps> = ({
  profileBlock,
  className,
}) => {
  return (
    <div className={cx(styles.profilePage, className)}>
      <ProfileBlock
        className={styles.profileBlock}
        {...profileBlock} />
    </div>
  );
};

ProfilePage.defaultProps = defaultProps;

export default ProfilePage;
