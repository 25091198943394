import React, { useState } from 'react';
import { TFunction } from 'i18next';
import { useHistory, useLocation } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';

import { Account, Address } from '../../../lib/token';
import {
  MenuItem,
  OrderStatusEnum,
  RestaurantOrder,
} from '../../../modules/checkout/types';
import { OrderStatusBlockProps, defaultProps } from './OrderStatusBlock';

import OrderConfirmedAsset from '../../../resources/icons/OrderConfirmed.svg';
import OrderCancelledAsset from '../../../resources/icons/OrderCancelled.svg';
import { formatPrice } from '../MenuBlocksList/utils';
import { StepItemProps } from '../../molecules/StepItem';
import { formatDisplayAddress, getPaymentType } from '../../../lib/utils';
import {
  defaultProps as priceOverviewItemDefaultProps,
  PriceOverviewItemProps,
} from '../../organisms/PriceOverviewItem/PriceOverviewItem';
import FireworksAsset from '../../../resources/icons/SuppliFireworks.svg';
import { PriceISectionItemListProps } from '../../organisms/PriceISectionItemList';
import { defaultProps as orderAccordianDefaultProps } from '../../molecules/OrderAccordian/OrderAccordian';
import { defaultProps as priceItemDefaultProps, PriceItemProps } from '../../molecules/PriceItem/PriceItem';
import { makePriceSectionItem } from '../../../modules/menu/utils';
import { IconAssetType } from '../../atoms/Icon/Icon';
import { ORDER_SERVICE_DELIVERY_FEE_RATE, ORDER_SERVICE_PICKUP_FEE_RATE } from '../../../lib/constants';
import { defaultProps as celebratoryDefaultProps } from '../CelebratoryBlock/CelebratoryBlock'
import { CheckoutState } from '../../../modules/checkout/types';

export type OrderStatusBlockPresenterProps = OrderStatusBlockProps & {
  account: Account | null;
  order?: RestaurantOrder;
  isLoading?: boolean;
  error?: Error;
};

const enum StepIndex {
  PLACED,
  PREPARING,
  COMPLETED
}

const enum StepDeliveryIndex {
  PLACED,
  PREPARING,
  DELIVERING,
  COMPLETED
}

const convertStatus = (
  t: TFunction,
  status?: string,
  ordertype?: string,
) => {
  const result = {
    title: t('order_status.title.confirmed'),
    description: '',
    icon: '',
    error: false,
    stepItemsProp: [
      {
        ...defaultProps.orderTracker.orderStepper?.stepItem,
        stepIndicator: {
          asset: 'Incomplete',
        },
        orderProgressItem: {
          state: 'Incomplete',
        },
        text: {
          ...defaultProps.orderTracker.orderStepper?.stepItem?.text,
          value: t('order_status.label_text.status.placed'),
        },
      },
      {
        ...defaultProps.orderTracker.orderStepper?.stepItem,
        stepIndicator: {
          asset: 'Incomplete',
        },
        orderProgressItem: {
          state: 'Incomplete',
        },
        text: {
          ...defaultProps.orderTracker.orderStepper?.stepItem?.text,
          value: t('order_status.label_text.status.preparing'),
        },
      },
      ...(ordertype && ordertype === 'Delivery' ? [{
        ...defaultProps.orderTracker.orderStepper?.stepItem,
        stepIndicator: {
          asset: 'Incomplete',
        },
        orderProgressItem: {
          state: 'Incomplete',
        },
        text: {
          ...defaultProps.orderTracker.orderStepper?.stepItem?.text,
          value: t('order_status.label_text.status.delivery'),
        },
      }] : []),
      {
        ...defaultProps.orderTracker.orderStepper?.stepItem,
        stepIndicator: {
          asset: 'Incomplete',
        },
        orderProgressItem: {
          state: 'Incomplete',
        },
        text: {
          ...defaultProps.orderTracker.orderStepper?.stepItem?.text,
          value: t('order_status.label_text.status.complete'),
        },
      },
    ] as StepItemProps[],
  };

  switch (status) {
    case OrderStatusEnum.pending:
      result.description = t('order_status.description.processing');
      break;
    case OrderStatusEnum.paid:
    case OrderStatusEnum.dispatched:
    case OrderStatusEnum.parsed:
      result.stepItemsProp[StepIndex.PLACED] = {
        ...result.stepItemsProp[StepIndex.PLACED],
        stepIndicator: {
          asset: 'Inprogress',
        },
      };
      result.stepItemsProp[StepIndex.PREPARING] = {
        ...result.stepItemsProp[StepIndex.PREPARING],
        stepIndicator: {
          asset: 'Incomplete',
        },
      };
      if (ordertype === 'Pickup') {
        result.stepItemsProp[StepIndex.COMPLETED] = {
          ...result.stepItemsProp[StepIndex.COMPLETED],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
      } else {
        result.stepItemsProp[StepDeliveryIndex.DELIVERING] = {
          ...result.stepItemsProp[StepDeliveryIndex.DELIVERING],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
        result.stepItemsProp[StepDeliveryIndex.COMPLETED] = {
          ...result.stepItemsProp[StepDeliveryIndex.COMPLETED],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
      }
      break;
    case OrderStatusEnum.received:
    case OrderStatusEnum.new:
    case OrderStatusEnum.accepted:
      result.stepItemsProp[StepIndex.PLACED] = {
        ...result.stepItemsProp[StepIndex.PLACED],
        stepIndicator: {
          asset: 'Complete',
        },
        orderProgressItem: {
          state: 'Inprogress',
        },
      };
      result.stepItemsProp[StepIndex.PREPARING] = {
        ...result.stepItemsProp[StepIndex.PREPARING],
        stepIndicator: {
          asset: 'Inprogress',
        },
      };
      if (ordertype === 'Pickup') {
        result.stepItemsProp[StepIndex.COMPLETED] = {
          ...result.stepItemsProp[StepIndex.COMPLETED],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
      } else {
        result.stepItemsProp[StepDeliveryIndex.DELIVERING] = {
          ...result.stepItemsProp[StepDeliveryIndex.DELIVERING],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
        result.stepItemsProp[StepDeliveryIndex.COMPLETED] = {
          ...result.stepItemsProp[StepDeliveryIndex.COMPLETED],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
      }
      break;
    case OrderStatusEnum.printed:
    case OrderStatusEnum.preparing:
    case OrderStatusEnum.prepared:
    case OrderStatusEnum.readyForPickUp:
    case OrderStatusEnum.courierArrived:
    case OrderStatusEnum.inDelivery:
    case OrderStatusEnum.enRouteToPickup:
      result.stepItemsProp[StepIndex.PLACED] = {
        ...result.stepItemsProp[StepIndex.PLACED],
        stepIndicator: {
          asset: 'Complete',
        },
        orderProgressItem: {
          state: 'Complete',
        },
      };
      if (ordertype === 'Pickup') {
        result.stepItemsProp[StepIndex.PREPARING] = {
          ...result.stepItemsProp[StepIndex.PREPARING],
          stepIndicator: {
            asset: 'Complete',
          },
          orderProgressItem: {
            state: 'Inprogress',
          },
        };
        result.stepItemsProp[StepIndex.COMPLETED] = {
          ...result.stepItemsProp[StepIndex.COMPLETED],
          stepIndicator: {
            asset: 'Inprogress',
          },
        };
      } else {
        result.stepItemsProp[StepIndex.PREPARING] = {
          ...result.stepItemsProp[StepIndex.PREPARING],
          stepIndicator: {
            asset: 'Inprogress',
          },
          orderProgressItem: {
            state: 'Inprogress',
          },
        };
        result.stepItemsProp[StepDeliveryIndex.DELIVERING] = {
          ...result.stepItemsProp[StepDeliveryIndex.DELIVERING],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
        result.stepItemsProp[StepDeliveryIndex.COMPLETED] = {
          ...result.stepItemsProp[StepDeliveryIndex.COMPLETED],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
      }
      break;
    case OrderStatusEnum.enRouteToDropoff:
    case OrderStatusEnum.arrivedAtDropoff:
      result.icon = OrderConfirmedAsset;
      result.stepItemsProp[StepIndex.PLACED] = {
        ...result.stepItemsProp[StepIndex.PLACED],
        stepIndicator: {
          asset: 'Complete',
        },
        orderProgressItem: {
          state: 'Complete',
        },
      };
      result.stepItemsProp[StepIndex.PREPARING] = {
        ...result.stepItemsProp[StepIndex.PREPARING],
        stepIndicator: {
          asset: 'Complete',
        },
        orderProgressItem: {
          state: 'Complete',
        },
      };
      if (ordertype === 'Pickup') {
        result.stepItemsProp[StepIndex.COMPLETED] = {
          ...result.stepItemsProp[StepIndex.COMPLETED],
          stepIndicator: {
            asset: 'Complete',
          },
        };
      } else {
        result.stepItemsProp[StepDeliveryIndex.DELIVERING] = {
          ...result.stepItemsProp[StepDeliveryIndex.DELIVERING],
          stepIndicator: {
            asset: 'Inprogress',
          },
          orderProgressItem: {
            state: 'Inprogress',
          },
        };
        result.stepItemsProp[StepDeliveryIndex.COMPLETED] = {
          ...result.stepItemsProp[StepDeliveryIndex.COMPLETED],
          stepIndicator: {
            asset: 'Incomplete',
          },
        };
      }
      break;
    case OrderStatusEnum.finalized:
    case OrderStatusEnum.autoFinalized:
      result.icon = OrderConfirmedAsset;
      result.stepItemsProp[StepIndex.PLACED] = {
        ...result.stepItemsProp[StepIndex.PLACED],
        stepIndicator: {
          asset: 'Complete',
        },
        orderProgressItem: {
          state: 'Complete',
        },
      };
      result.stepItemsProp[StepIndex.PREPARING] = {
        ...result.stepItemsProp[StepIndex.PREPARING],
        stepIndicator: {
          asset: 'Complete',
        },
        orderProgressItem: {
          state: 'Complete',
        },
      };
      if (ordertype === 'Pickup') {
        result.stepItemsProp[StepIndex.COMPLETED] = {
          ...result.stepItemsProp[StepIndex.COMPLETED],
          stepIndicator: {
            asset: 'Complete',
          },
        };
      } else {
        result.stepItemsProp[StepDeliveryIndex.DELIVERING] = {
          ...result.stepItemsProp[StepDeliveryIndex.DELIVERING],
          stepIndicator: {
            asset: 'Complete',
          },
          orderProgressItem: {
            state: 'Complete',
          },
        };
        result.stepItemsProp[StepDeliveryIndex.COMPLETED] = {
          ...result.stepItemsProp[StepDeliveryIndex.COMPLETED],
          stepIndicator: {
            asset: 'Complete',
          },
        };
      }
      break;
    case OrderStatusEnum.duplicate:
    case OrderStatusEnum.cancel:
    case OrderStatusEnum.canceled:
    case OrderStatusEnum.failed:
    case OrderStatusEnum.posReceivedFailed:
    case OrderStatusEnum.parseFailed:
    case OrderStatusEnum.resolved:
      result.title = t('order_status.title.failed');
      result.description = t('order_status.description.failed');
      result.icon = OrderCancelledAsset;
      result.error = true;
      break;
    default:
      result.title = t('order_status.title.error');
      result.description = t('order_status.description.error', { status });
      result.icon = OrderCancelledAsset;
      result.error = true;
      break;
  }
  return result;
};

const withPresenter = (
  View: React.FC<OrderStatusBlockProps>,
): React.FC<OrderStatusBlockPresenterProps> => {
  const Presenter: React.FC<OrderStatusBlockPresenterProps> = (props) => {
    const { order, isLoading, openShareModal } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation<CheckoutState>();
    const [showAccordian, setShowAccordian] = useState<boolean>(false);
    const [celebratorySectionShown, setCelebratorySectionShown] = useState(
      location.state?.celebratorySectionShown || false,
    );
    const [showShareModal, setShowShareModal] = useState(false);

    const orderStatusProps: OrderStatusBlockProps = {
      ...defaultProps,
    };

    if (isLoading || !order) {
      return <View {...props} {...orderStatusProps} loading={isLoading} />;
    }
    const {
      orderstatus,
      restaurant,
      pickuptime,
      ordertype,
      deliveryeta,
      deliveryaddress,
      orderbasket,
      paymentdetails,
    } = order || {};
    const paymentDetails = paymentdetails as Record<string, any>;
    let paymentDetailsObject;
    let charges;
    let paymentMethodDetails;

    if (paymentDetails && paymentDetails.data && paymentDetails.data.object) {
      paymentDetailsObject = paymentDetails.data.object;
    }

    if (paymentDetailsObject && paymentDetailsObject.charges && paymentDetailsObject.charges.data) {
      charges = paymentDetailsObject.charges.data;
    }

    if (charges && charges.length > 0 && charges[0].payment_method_details) {
      paymentMethodDetails = charges[0].payment_method_details;
    }

    const parsedAddress = deliveryaddress as Address;
    const {
      title, description, icon, error, stepItemsProp,
    } = convertStatus(t, orderstatus, ordertype);
    orderStatusProps.orderStatus = orderstatus as OrderStatusEnum;

    const priceOverviewItems: PriceOverviewItemProps[] | undefined = orderbasket?.map(
      (item: MenuItem, itemPosition: number) => ({
        ...priceOverviewItemDefaultProps,
        showNotification: Boolean(item.remark),
        notification: {
          ...priceOverviewItemDefaultProps.notification,
          title: {
            ...priceOverviewItemDefaultProps.notification.title,
            value: t('order_status.order_accordian.additional_instructions'),
          },
          text: {
            ...priceOverviewItemDefaultProps.notification.text,
            value: item.remark,
          },
        },
        displayButtonGroup: false,
        menuItem: {
          ...priceOverviewItemDefaultProps.menuItem,
          itemName: {
            ...priceOverviewItemDefaultProps.menuItem.itemName,
            value: item.name,
          },
          cost: {
            ...priceOverviewItemDefaultProps.menuItem.cost,
            value: formatPrice(item.price * item.quantity),
          },
          itemQuantity: {
            ...priceOverviewItemDefaultProps.menuItem.itemQuantity,
            value: item.quantity ? String(item.quantity) : '',
          },
        },
        priceISectionItemList: {
          ...priceOverviewItemDefaultProps.priceISectionItemList,
          priceSectionItems: item.subItems
            ? item.subItems.map((subItem: MenuItem) => makePriceSectionItem(subItem))
            : [],
        } as PriceISectionItemListProps,
      }));

    const priceItems: PriceItemProps[] | undefined = [
      {
        ...orderAccordianDefaultProps.priceItem,
        type: 'ListItem',
        loading: isLoading || !order ? 'Loading' : 'Default',
        item: {
          ...orderAccordianDefaultProps.priceItem.item,
          value: t('textLabels.subtotal'),
          type: 'Title4',
        },
        price: {
          ...orderAccordianDefaultProps.priceItem.price,
          value: formatPrice(order.subtotal),
          type: 'Title4',
        },
        divider: {
          type: 'HorizontalDotted',
          style: 'Grey500',
        },
      },
      {
        ...orderAccordianDefaultProps.priceItem,
        type: 'ListItemWithInfo',
        loading: isLoading || !order ? 'Loading' : 'Default',
        item: {
          ...orderAccordianDefaultProps.priceItem.item,
          value: t('textLabels.service_fee'),
        },
        price: {
          ...orderAccordianDefaultProps.priceItem.price,
          value: formatPrice(order.servicefee),
        },
        notification: {
          ...priceItemDefaultProps.notification,
          title: t('checkout.notification.title'),
          description: t('checkout.notification.description', {
            percentage: order.ordertype === 'Delivery' ? ORDER_SERVICE_DELIVERY_FEE_RATE : ORDER_SERVICE_PICKUP_FEE_RATE,
            deliveryType: order.ordertype === 'Delivery' ? t('textLabels.delivery') : t('textLabels.pickup'),
          }),
        },
        divider: {
          type: 'HorizontalDotted',
          style: 'Grey500',
        },
      },
      {
        ...orderAccordianDefaultProps.priceItem,
        type: 'ListItem',
        loading: isLoading || !order ? 'Loading' : 'Default',
        item: {
          ...orderAccordianDefaultProps.priceItem.item,
          value: t('textLabels.tax'),
        },
        price: {
          ...orderAccordianDefaultProps.priceItem.price,
          value: formatPrice(order.tax),
        },
        divider: {
          type: 'HorizontalDotted',
          style: 'Grey500',
        },
      },
    ];

    // old orders don't have suppli fee
    if (order.supplifee && order.supplifee > 0) {
      const suppliFee = {
        ...orderAccordianDefaultProps.priceItem,
        type: 'ListItemWithInfo',
        loading: isLoading || !order ? 'Loading' : 'Default',
        item: {
          ...orderAccordianDefaultProps.priceItem.item,
          value: t('textLabels.suppli_fee'),
        },
        price: {
          ...orderAccordianDefaultProps.priceItem.price,
          value: formatPrice(order.supplifee),
        },
        notification: {
          ...priceItemDefaultProps.notification,
          title: t('order_status.notification_suppli_fee.title'),
          description: t('order_status.notification_suppli_fee.description'),
        },
        divider: {
          type: 'HorizontalDotted',
          style: 'Grey500',
        },
      } as PriceItemProps;
      priceItems.splice(2, 0, suppliFee);
    }

    // discount price item
    if (typeof order.discount === 'number' && order.discount > 0) {
      const discountPriceItem = {
        ...orderAccordianDefaultProps.priceItem,
        type: 'ListItem',
        loading: isLoading || !order ? 'Loading' : 'Default',
        item: {
          ...orderAccordianDefaultProps.priceItem.item,
          value: t('textLabels.discount'),
        },
        price: {
          ...orderAccordianDefaultProps.priceItem.price,
          value: `-${order.discount && formatPrice(order.discount)}`,
          style: 'Green800',
        },
        divider: {
          type: 'HorizontalDotted',
          style: 'Grey500',
        },
      } as PriceItemProps;
      priceItems.push(discountPriceItem);
    }

    // optional price items, only appear for delivery (delivery fee and tip)
    if (order.ordertype === 'Delivery') {
      const deliveryFeePriceItem = {
        ...orderAccordianDefaultProps.priceItem,
        type: 'ListItem',
        loading: isLoading || !order ? 'Loading' : 'Default',
        item: {
          ...orderAccordianDefaultProps.priceItem.item,
          value: t('textLabels.delivery_fee'),
        },
        price: {
          ...orderAccordianDefaultProps.priceItem.price,
          value: typeof order.deliveryfee === 'number' && formatPrice(order.deliveryfee),
        },
        divider: {
          type: 'HorizontalDotted',
          style: 'Grey500',
        },
      } as PriceItemProps;
      priceItems.splice(1, 0, deliveryFeePriceItem);

      const tipPriceItem = {
        ...orderAccordianDefaultProps.priceItem,
        type: 'ListItem',
        loading: isLoading || !order ? 'Loading' : 'Default',
        item: {
          ...orderAccordianDefaultProps.priceItem.item,
          value: t('textLabels.tip'),
        },
        price: {
          ...orderAccordianDefaultProps.priceItem.price,
          value: typeof order.tip === 'number' && formatPrice(order.tip),
        },
        divider: {
          type: 'HorizontalDotted',
          style: 'Grey500',
        },
      } as PriceItemProps;
      priceItems.splice(priceItems.length - 1, 0, tipPriceItem);
    }

    const celebratoryBlockProps = {
      ...celebratoryDefaultProps,
      title: {
        ...celebratoryDefaultProps.title,
        value: t('checkout.celebratory_block.title', { name: props?.account?.firstName }),
      },
      subHeader: {
        ...celebratoryDefaultProps.subHeader,
        value: t('checkout.celebratory_block.subHeader'),
      },
      image: {
        srcValue: FireworksAsset,
      },
      continueButton: {
        ...celebratoryDefaultProps.continueButton,
        text: {
          ...celebratoryDefaultProps.continueButton.text,
          value: t('checkout.celebratory_block.continueButton'),
        },
        onButtonClicked: () => {
          return setCelebratorySectionShown(true);
        },
      },
      shareButton: {
        ...celebratoryDefaultProps.shareButton,
        onButtonClicked: () => {
          return openShareModal ? openShareModal() : undefined; 
        },
        text: {
          ...celebratoryDefaultProps.shareButton.text,
          value: t('checkout.celebratory_block.shareButton'),
        },
      },
      showShareModal,
    };

    orderStatusProps.orderTracker = {
      ...defaultProps.orderTracker,
      State: error ? 'Error' : 'TrackOrder',
      image: {
        srcValue: icon,
      },
      description: {
        ...defaultProps.orderTracker.description,
        value: description,
      },
      title: {
        ...defaultProps.orderTracker.title,
        value: title,
      },
      notification: {
        ...defaultProps.orderTracker.notification,
        title: {
          ...defaultProps.orderTracker.notification?.title,
          value: t('order_status.notification.title'),
        },
        description: (
          <Trans
            i18nKey="order_status.notification.content" // optional -> fallbacks to defaults if not provided
            values={{
              restaurant_name: restaurant?.details?.name,
              restaurant_number: restaurant?.details?.phoneNumber,
            }}
            components={[
              <strong></strong>,
              <a href={`tel:${restaurant?.details?.phoneNumber}`}>
                {restaurant?.details?.phoneNumber}
              </a>,
              <a href={`sms://+18882052705;?&body=Order%20ID%20${order.id}%3A%20`}></a>,
              <a href={'mailto:hello@mysuppli.ca'}></a>,
            ]}
          />
        ),
      },
      button: {
        ...defaultProps.orderTracker.button,
        text: {
          ...defaultProps.orderTracker.button?.text,
          value: t('button.done'),
        },
        onButtonClicked: () => {
          history.push('/');
        },
      },
      estimatedTime: (ordertype && ordertype === 'Pickup' ? {
        ...defaultProps.orderTracker.estimatedTime,
        labelText: {
          ...defaultProps.orderTracker.estimatedTime?.labelText,
          value: t('order_status.label_text.pickup_time'),
        },
        contentText: {
          ...defaultProps.orderTracker.estimatedTime?.contentText,
          value: pickuptime,
        },
      } : {
        ...defaultProps.orderTracker.estimatedTime,
        labelText: {
          ...defaultProps.orderTracker.estimatedTime?.labelText,
          value: t('order_status.description.confirmed.delivery_time'),
        },
        contentText: {
          ...defaultProps.orderTracker.estimatedTime?.contentText,
          value: deliveryeta,
        },
      }),
      orderPrice: {
        ...defaultProps.orderTracker.orderPrice,
        labelText: {
          ...defaultProps.orderTracker.orderPrice?.labelText,
          value: t('order_status.label_text.order_price'),
        },
        contentText: {
          ...defaultProps.orderTracker.orderPrice?.contentText,
          value: formatPrice(order.ordertotal),
        },
      },
      orderNumber: {
        ...defaultProps.orderTracker.orderNumber,
        labelText: {
          ...defaultProps.orderTracker.orderNumber?.labelText,
          value: t('order_status.label_text.order_number'),
        },
        contentText: {
          ...defaultProps.orderTracker.orderNumber?.contentText,
          value: `#${order.id}`,
        },
      },
      ...(parsedAddress && Object.keys(parsedAddress).length !== 0 ? {
        deliveryAddress: {
          ...defaultProps.orderTracker.deliveryAddress,
          labelText: {
            ...defaultProps.orderTracker.deliveryAddress?.labelText,
            value: t('order_status.label_text.delivery_address'),
          },
          contentText: {
            ...defaultProps.orderTracker.deliveryAddress?.contentText,
            value: `${formatDisplayAddress(parsedAddress)}, ${parsedAddress.city}, ${parsedAddress.postalCode}`,
          },
        },
      } : {}),
      orderStepper: {
        ...defaultProps.orderTracker.orderStepper,
        stepItems: stepItemsProp,
      },
      orderAccordian: {
        ...orderAccordianDefaultProps,
        state: showAccordian ? 'Expanded' : 'Default',
        priceOverviewItemList: {
          ...orderAccordianDefaultProps.priceOverviewItemList,
          priceOverviewItems,
        },
        titleText: {
          ...orderAccordianDefaultProps.titleText,
          value: t('order_status.order_accordian.title'),
        },
        text: {
          ...orderAccordianDefaultProps.text,
          value: showAccordian ? t('order_status.order_accordian.hide_order') : t('order_status.order_accordian.view_order'),
          onClick: () => setShowAccordian(!showAccordian),
        },
        icon: {
          ...orderAccordianDefaultProps.icon,
          asset: showAccordian ? 'ChevronUp' : 'ChevronDown',
          onClick: () => setShowAccordian(!showAccordian),
        },
        priceList: {
          priceItems,
        },
        priceItem: {
          ...orderAccordianDefaultProps.priceItem,
          state: 'Expanded',
          loading: isLoading || !order ? 'Loading' : 'Default',
          item: {
            ...orderAccordianDefaultProps.priceItem.item,
            value: t('textLabels.total'),
            type: 'Title4',
          },
          price: {
            ...orderAccordianDefaultProps.priceItem.price,
            value: formatPrice(order.ordertotal),
            type: 'Title4',
          },
        },
        paymentInfo: {
          ...orderAccordianDefaultProps.paymentInfo,
          loading: isLoading || !order ? 'Loading' : 'Default',
          title: {
            ...orderAccordianDefaultProps.paymentInfo.title,
            value: t('order_status.order_accordian.payment'),
          },
          text: {
            ...orderAccordianDefaultProps.paymentInfo.text,
            value: t('order_status.order_accordian.payment_info', {
              card_type: getPaymentType(paymentMethodDetails?.card?.brand || ''),
              card_number: paymentMethodDetails?.card?.last4,
            }),
          },
          icon: {
            ...orderAccordianDefaultProps.paymentInfo.icon,
            asset: getPaymentType(paymentMethodDetails?.card?.brand || '') as IconAssetType,
          },
        },
      },
    };

    return <View {...props} {...orderStatusProps} {...{
      celebratorySectionShown,
      celebratoryBlock: celebratoryBlockProps,
    }}/>;
  };
  return Presenter;
};

export default withPresenter;
