import React from 'react';
import cx from 'classnames';

import styles from './ButtonGroup1.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  leftButton: {
    size: 'Small',
    type: 'Text',
    style: 'InfoRounded',
    text: {
      type: 'Button2',
      align: 'Center',
      style: 'Blue800',
    },
  } as ButtonProps,
  rightButton: {
    size: 'Small',
    type: 'Text',
    style: 'DangerRounded',
    text: {
      type: 'Button2',
      align: 'Center',
      style: 'Red800',
    },
  } as ButtonProps,
};

export type ButtonGroup1Props = {
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
  className?: string;
};

const ButtonGroup1: React.FC<ButtonGroup1Props> = ({
  leftButton,
  rightButton,
  className,
}) => {
  return (
    <div className={cx(styles.buttonGroup1, className)}>
      <Button
        className={styles.leftButton}
        {...leftButton} />
      <Button
        className={styles.rightButton}
        {...rightButton} />
    </div>
  );
};

ButtonGroup1.defaultProps = defaultProps;

export default ButtonGroup1;
