import React, { RefObject } from 'react';
import cx from 'classnames';
import styles from './RestaurantListBlock.module.scss';
import RestaurantCardList, { RestaurantCardListProps } from '../../organisms/RestaurantCardList';
import NoContentBlock, { NoContentBlockProps } from '../../block/NoContentBlock';
import { EmptyStateContentProps } from '../../molecules/EmptyStateContent';
import Text, { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { ImageProps } from '../../atoms/Image';

export const defaultProps = {
  blockTitle: {
    type: 'Title5',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  restaurantCardList: {
    restaurantCards: [
    ],
  } as RestaurantCardListProps,
  noContentBlock: {
    emptyStateContent: {
      titleText: {
        type: 'Title2',
        align: 'Center',
        style: 'Grey1100',
      },
      messageText: {
        type: 'Body3',
        align: 'Center',
        style: 'Grey1100',
      },
      image: {
        className: styles.image,
      } as ImageProps
    } as EmptyStateContentProps,
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    } as ButtonProps,
  } as NoContentBlockProps,
};

export type RestaurantListBlockProps = {
  blockTitle?: TextProps;
  restaurantCardList?: RestaurantCardListProps;
  className?: string;
  handleChangeAddress?: () => void;
  noContentBlock?: NoContentBlockProps;
  noRestaurantFound?: boolean;
  restaurantListBlockRef?: RefObject<HTMLDivElement>;
  updateSkipToAddressChange?: (isSkipToChange: boolean) => void;
};

const RestaurantListBlock: React.FC<RestaurantListBlockProps> = ({
  blockTitle,
  restaurantCardList,
  className,
  noRestaurantFound,
  noContentBlock,
  restaurantListBlockRef,
}) => {
  let contentView = (
    <>
      <Text
        className={styles.blockTitle}
        {...blockTitle} />
      <RestaurantCardList
        className={styles.restaurantCardList}
        {...restaurantCardList} />
    </>
  );

  if (noRestaurantFound) {
    contentView = (
      <div className={styles.noContent}>
        <NoContentBlock {...noContentBlock} />
      </div>
    );
  }
  
  return (
    <div className={cx(styles.restaurantListBlock, className)} ref={restaurantListBlockRef}>
      {contentView}
    </div>
  );
};

RestaurantListBlock.defaultProps = defaultProps;

export default RestaurantListBlock;
