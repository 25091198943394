import React, { useContext } from 'react';
import { AuthContext } from '../../../modules/auth';
import { HelpBlockProps } from './HelpBlock';
import { HelpBlockPresenterProps } from './HelpBlock.presenter';

const withInteractor = (
  Presenter: React.FC<HelpBlockPresenterProps>,
): React.FC<HelpBlockProps> => {
  const Interactor: React.FC<HelpBlockProps> = (props) => {
    const { account } = useContext(AuthContext);
    return (
            <Presenter
                {...props}
                account={account}
            />
    );
  };
  return Interactor;
};

export default withInteractor;
