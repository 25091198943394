import React from 'react';
import cx from 'classnames';

import styles from './MenuItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  itemQuantity: {
    type: 'Title4',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
  itemName: {
    type: 'Title4',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  cost: {
    type: 'Title4',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
};

export type MenuItemProps = {
  itemQuantity?: TextProps;
  itemName?: TextProps;
  cost?: TextProps;
  className?: string;
};

const MenuItem: React.FC<MenuItemProps> = ({
  itemQuantity,
  itemName,
  cost,
  className,
}) => {
  return (
    <div className={cx(styles.menuItem, className)}>
      <Text
        className={styles.itemQuantity}
        {...itemQuantity} />
      <Text
        className={styles.itemName}
        {...itemName} />
      <Text
        className={styles.cost}
        {...cost} />
    </div>
  );
};

MenuItem.defaultProps = defaultProps;

export default MenuItem;
