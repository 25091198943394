import React from 'react';
import cx from 'classnames';

import styles from './MenuBlocksList.module.scss';

import MenuListItemBlock, { MenuListItemBlockProps } from '../MenuListItemBlock';
import { MenuResponseObject } from '../../../modules/menu/types';

export const defaultProps = {
  menuListItemBlocks: [
  ] as MenuListItemBlockProps[],
};

export type MenuBlocksListProps = {
  menuListItemBlocks?: MenuListItemBlockProps[];
  className?: string;
  menu? : MenuResponseObject[];
  focusedBlockId?: string;
  isLoading?: boolean;
  blocksOffsets?: Map<string, object>;
  setBlocksOffsets?: (blocksOffsets: Map<string, object>) => void;
};

const MenuBlocksList: React.FC<MenuBlocksListProps> = ({
  menuListItemBlocks,
  className,
  isLoading,
  blocksOffsets,
  setBlocksOffsets,
}) => {
  const menuListItemBlockArray = menuListItemBlocks?.map((menuListItemBlock, index) => (
    <MenuListItemBlock
      className={styles.menuListItemBlock}
      key={index}
      isLoading={isLoading}
      blocksOffsets={blocksOffsets}
      setBlocksOffsets={setBlocksOffsets}
      {...menuListItemBlock} />
  ));

  return (
    <div className={cx(styles.menuBlocksList, className)}>
      {menuListItemBlockArray}
    </div>
  );
};

MenuBlocksList.defaultProps = defaultProps;

export default MenuBlocksList;
