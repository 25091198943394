import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import VerifyEmailAsset from '../../../resources/icons/VerifyEmail.svg';
import ResetEmailAsset from '../../../resources/icons/ResetEmail.svg';
import { ActionCompleteBlockProps, defaultProps } from './ActionCompleteBlock';
import { EmailState, ForgotPasswordPayload } from '../../../modules/account/types';
import { resendVerifyAccount } from '../../../modules/account/api';
import { ToastNotificationProps } from '../../molecules/ToastNotification';
import styles from './ActionCompleteBlock.module.scss';
import Text, { TextProps } from '../../atoms/Text';
import { ApiError } from '../../../lib/api/types';

export type ActionCompletePresenterProps = ActionCompleteBlockProps & {
  forgotPassword: (email: ForgotPasswordPayload) => Promise<void>;
};

const getIcon = (actionType: 'VerifyEmail' | 'PasswordReset' | 'UpdatePassword' | undefined): string => {
  switch (actionType) {
    case 'PasswordReset':
    case 'UpdatePassword':
      return ResetEmailAsset;
    default:
      return VerifyEmailAsset;
  }
};

const withPresenter = (
  View: React.FC<ActionCompleteBlockProps>,
): React.FC<ActionCompletePresenterProps> => {
  const Presenter: React.FC<ActionCompletePresenterProps> = (props) => {
    const { actionType, logout, forgotPassword } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const [email, setEmail] = useState<string>('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const { state } = useLocation<EmailState>();

    useEffect(() => {
      if (state && state.email) {
        setEmail(state.email);
      }
    }, [state]);

    const handleResendVerification = async (): Promise<void> => {
      try {
        await resendVerifyAccount(email);
        setIsError(false);
        setShowToast(true);
      } catch {
        setIsError(true);
        setShowToast(true);
      }
    };

    const handleResendForgotPassword = async (): Promise<void> => {
      try {
        await forgotPassword({ email });
        setIsError(false);
        setShowToast(true);
      } catch (e) {
        if (ApiError.isApiError(e) && (e.code === 422 || e.code === 404)) {
          setIsError(false);
          setShowToast(true);
        } else {
          setIsError(true);
          setShowToast(true);
        }
      }
    };

    const closeToast = (): void => {
      setShowToast(false);
    };

    const successText = actionType === 'VerifyEmail'
      ? t('action_complete_block.verify_email.success')
      : t('action_complete_block.password_reset.success');

    const toastNotificationProps: ToastNotificationProps = {
      ...defaultProps.toast,
      type: isError ? 'Error' : 'Success',
      icon: {
        ...defaultProps.toast.icon,
        asset: isError ? 'ErrorCircle' : 'CheckCircle',
        style: isError ? 'Red800' : 'Green700',
      },
      text: {
        ...defaultProps.toast.text,
        value: isError ? t('action_complete_block.error') : successText,
      },
      show: showToast,
      onClose: closeToast,
    };

    const resendVerifyEmailLinkProps: TextProps = {
      ...defaultProps.link,
      value: <a href='#!'>{t('action_complete_block.verify_email.resend')}</a>,
      onClick: handleResendVerification,
    };

    const resendPasswordEmailLinkProps: TextProps = {
      ...defaultProps.link,
      value: <a href='#!'>{t('action_complete_block.password_reset.resend')}</a>,
      onClick: handleResendForgotPassword,
    };

    const checkSpamTextProps: TextProps = {
      ...defaultProps.spamText,
      value:
        actionType === 'PasswordReset' || actionType === 'UpdatePassword'
          ? t('action_complete_block.password_reset.descriptionForm')
          : t('action_complete_block.verify_email.spam'),
    };

    let localization;
    switch (actionType) {
      case 'PasswordReset':
      case 'UpdatePassword':
        localization = {
          title: t('textLabels.reset_link_sent'),
          description: t('action_complete_block.password_reset.description'),
          titleForm: t('action_complete_block.password_reset.titleForm'),
          descriptionForm:
                    <div className={styles.resendEmailText}>
                        <Text {...checkSpamTextProps}/>
                        <Text className={styles.resendButton} {...resendPasswordEmailLinkProps}/>
                    </div>,
        };
        break;
      default:
        localization = {
          title: t('textLabels.verify_email'),
          description: <Trans i18nKey='action_complete_block.verify_email.description'
            components={{
              div: <div className={styles.descriptionText}></div>,
              p: <p></p>,
            }}
          />,
          titleForm: t('action_complete_block.verify_email.titleForm'),
          descriptionForm:
                    <div className={styles.resendEmailText}>
                        <Text {...checkSpamTextProps}/>
                        <Text className={styles.resendButton} {...resendVerifyEmailLinkProps}/>
                    </div>,
        };
        break;
    }

    const handleAction = (): void => {
      if (actionType === 'UpdatePassword' && logout) {
        logout();
        window.location.reload();
      } else {
        history.push('/');
      }
    };

    const actionCompleteBlockProps: ActionCompleteBlockProps = {
      ...defaultProps,
      backButton: {
        ...defaultProps.backButton,
        text: {
          ...defaultProps.backButton?.text,
          value: t('button.back'),
        },
        onButtonClicked: (): void => {
          history.goBack();
        },
      },
      image: {
        srcValue: getIcon(actionType),
      },
      title: {
        ...defaultProps?.title,
        value: t(localization.title),
      },
      description: {
        ...defaultProps?.description,
        value: localization.description,
      },
      titleForm: {
        ...defaultProps?.titleForm,
        value: t(localization.titleForm),
      },
      descriptionForm: {
        ...defaultProps?.descriptionForm,
        value: localization.descriptionForm,

      },
      button: {
        ...defaultProps.button,
        onButtonClicked: handleAction,
        text: {
          ...defaultProps.button?.text,
          value: t('button.done'),
        },
      },
      toast: toastNotificationProps,
      toastMessage: showToast,
    };

    return <View
            {...props}
            {...actionCompleteBlockProps}
        />;
  };
  return Presenter;
};

export default withPresenter;
