import { KeyboardEventHandler, MouseEventHandler, AriaRole } from 'react';
import { utcToZonedTime, format } from 'date-fns-tz';
import { Address, Location } from './token';

export const isEmptyString = (value?: string) => (value ? value.trim().length === 0 : true);

export const isObject = (value: any) => typeof value === 'object' && !Array.isArray(value) && value !== null;

export const isEmpty = (value: any) => (
  value === undefined
  || value == null
  || (isObject(value) && Object.keys(value).length === 0)
  || value.length === 0
);

export const isEmail = (value: string) => {
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexEmail.test(value);
};

export const isPassword = (value) => {
  if (value.length >= 8) {
    return true;
  }
  return false;
};

export const regextPhoneNumber = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const isMobile = (value: string) => {
  return regextPhoneNumber.test(value);
};

export const normalizePhoneNumber = (value, prevValue) => {
  if (!value) {
    return value;
  }
  const currentValue = value.replace(/[^\d]/g, '');
  
  if (!prevValue || value.length > prevValue.length) {
    if (currentValue.length < 4) {
      return currentValue;
    } 
    if (currentValue.length < 7) { 
      return `(${currentValue.slice(0, 3)})-${currentValue.slice(3)}`; 
    }
    return `(${currentValue.slice(0, 3)})-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
  return value;
};

export const formatPhoneNumber = (value: string): string => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber.length == 10) {
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
  }
  return phoneNumber;
}

export const isProvince = (value: string) => {
  const regextProvince = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
  return regextProvince.test(value);
};
export const isPostalCode = (value: string) => {
  const regexIsPostalCode = /^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i;
  return regexIsPostalCode.test(value);
};

export const nameToSlug = (name: string): string => name
  .trim() // trim
  .toLocaleLowerCase() // lowercase
  .replace(/[^a-z0-9 -]/g, '') // remove invalid chars;
  .replace(/\s+/g, '-') // collapse whitespace and replace by -
  .replace(/-+/g, '-'); // collapse dashes

type AccessibilityProps = {
  tabIndex?: number;
  role?: AriaRole;
  onKeyDown?: KeyboardEventHandler<any>;
};
export const getClickAccessibilityProps = (onClick?: VoidFunction): AccessibilityProps => {
  if (!onClick) {
    return {};
  }
  return {
    tabIndex: 0,
    role: 'button',
    onKeyDown: (e) => {
      const enterOrSpace = e.key === 'Enter'
        || e.key === ' '
        || e.key === 'Spacebar';
      if (enterOrSpace && onClick) {
        e.preventDefault();
        onClick();
      }
    },
  };
};

export const formatDistance = (distance: number): string => {
  return `${(distance/1000).toFixed(1)} km`;
}
export const formatDeliveryTime = (distance?: number, prepTime?: number): string => {
  // TODO: improve calculation accuray
  if ((typeof distance === 'number') && prepTime) {
    // 1st version of getting ETA is to assume driver will travel at 7mins/km.
    // we get an estimated travel time required and add prepare time needed for restaurants
    const lowerBound = (distance / 1000) * 7 + prepTime;
    // add 10 mins to get to the upperbound for the range
    const upperBound = lowerBound + 10;
    return `${lowerBound.toFixed(0)}-${upperBound.toFixed(0)} mins`;
  }
  return '- mins';
};

export const formatDeliveryETA = (distance?: number, prepTime?: number): number => {
  // TODO: improve calculation accuray
  if ((typeof distance === 'number') && prepTime) {
    // 1st version of getting ETA is to assume driver will travel at 7mins/km.
    // we get an estimated travel time required and add prepare time needed for restaurants
    const lowerBound = (distance / 1000) * 7 + prepTime;
    // add 10 mins to get to the upperbound for the range
    const upperBound = lowerBound + 10;
    // return upperbound in milliseconds as our initial delivery eta
    return upperBound * 60000;
  }
  // return hardcoded default eta to 60 mins
  return 60 * 60000;
};

export const formatEtaTime = (milliseconds: number, timezone?: string) => {
  const date = new Date();

  const estimatedDeliveredAt = new Date(Date.now() + milliseconds);
  const deliveryEtaAtLocal = utcToZonedTime(estimatedDeliveredAt, timezone || '');
  const formattedDeliveryTime = format(deliveryEtaAtLocal, 'hh:mm a', { timeZone: timezone });

  return formattedDeliveryTime;
};

export const debounce = (cb, delay: number) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      cb(...args);
    }, delay);
  };
};

export const parseAddress = (address: string, lat: number, lng: number): Address => {
  const regexAddress = /(?<streetNumber>(^|\\s)([0-9]+)) (?<addressLine>[^ ].*), (?<city>.*), (?<province>[a-zA-Z ]{2}) (?<postalCode>[\da-zA-Z ]*), (?<country>[\da-zA-Z ]*)/;
  const match = address.match(regexAddress)?.groups;
  const parsedAddress: Address = {
    streetNumber: match ? match.streetNumber : '',
    addressLine: match ? match.addressLine : '',
    city: match ? match.city : '',
    province: match ? match.province : '',
    postalCode: match ? match.postalCode : '',
    country: match ? match.country : '',
    location: {
      latitude: lat,
      longitude: lng,
    },
  };
  return parsedAddress;
};

export const isValidCoordinates = (location: Location): boolean => {
  if (typeof Number(location.latitude) === 'number' && typeof Number(location.longitude) === 'number') {
    return (Math.abs(Number(location.latitude)) <= 90 && Math.abs(Number(location.longitude)) <= 180);
  }
  return false;
};

export const isValidAddress = (address?: Address): boolean => {
  if (address) {
    return (
      !isEmptyString(address.streetNumber)
      && !isEmptyString(address.addressLine)
      && !isEmptyString(address.city)
      && !isEmptyString(address.province)
      && isPostalCode(address.postalCode)
      && !isEmptyString(address.country)
      && isValidCoordinates(address.location)
    );
  }
  return false;
};

export const isNumberOrDot = (char) => '0123456789.'.includes(char);

export const formatDisplayAddress = (address: Address): string => {
  const numRegex = /^\d+$/;
  const { streetNumber, addressLine, addressLine2 } = address;
  if (numRegex.test(addressLine2 || '')) {
    return `${addressLine2}-${streetNumber} ${addressLine}`;
  }

  return `${streetNumber || ''} ${addressLine} ${addressLine2 || ''}`.trim();
};

export const isValidGoogleBrowser = (): boolean => {
  let isValid = false;
  const { userAgent } = navigator;
  const androidPhoneWVRegex = /Chrome\/[.0-9]* Mobile/;
  const androidTabletWVRegex = /Chrome\/[.0-9]* (?!Mobile)/;

  // Firefox
  if (userAgent.includes('Firefox')) {
    isValid = true;
  // SamsungBrowser
  } else if (userAgent.includes('SamsungBrowser')) {
    isValid = true;
  // Opera
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    isValid = true;
  // Edge
  } else if (userAgent.includes('Edge')) {
    isValid = true;
  // Chrome or Chromium
  } else if (
    (userAgent.includes('Chrome')
    || androidPhoneWVRegex.test(userAgent)
    || androidTabletWVRegex.test(userAgent)
    || userAgent.includes('CriOS'))
    && !(userAgent.includes('wv') || /Version\/\d+/.test(userAgent))
  ) {
    isValid = true;
  // Safari
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    isValid = true;
  }
  return isValid;
};

export const getPaymentType = (value: string): string => {
  const lowercase = value.toLocaleLowerCase();
  switch (lowercase) {
    case 'amex':
      return 'Amex';
    case 'visa':
      return 'Visa';
    case 'mastercard':
      return 'Mastercard'
    case 'discover':
      return 'Discover';
    case 'jcb':
      return 'JCB'
    case 'unionpay':
      return 'UnionPay'
    default:
      return 'Card';
  }
}