import React, { RefObject, useState } from 'react';
import cx from 'classnames';

import styles from './FoodItemBanner.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  titleText: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  descriptionText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
};

export type FoodItemBannerProps = {
  titleText?: TextProps;
  descriptionText?: TextProps;
  bannerUrl?: string;
  className?: string;
  foodItemBannerRef?: RefObject<HTMLDivElement>;
};

const FoodItemBanner: React.FC<FoodItemBannerProps> = ({
  titleText,
  descriptionText,
  bannerUrl,
  className,
  foodItemBannerRef,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={cx(styles.foodItemBanner, className)} ref={foodItemBannerRef}>
      <img
          style={{ display: 'none' }}
          src={ bannerUrl }
          onLoad={(): void => setImageLoaded(true)}
        />
      <div
        className={styles.banner}
        style={{
          backgroundImage: `url(${bannerUrl})`,
          flex: imageLoaded ? undefined : '0 0 auto',
        }}
      >
      </div>
      <div className={styles.content}>
        <Text className={styles.titleText} {...titleText} />
        <Text className={styles.descriptionText} {...descriptionText} />
      </div>
    </div>
  );
};

FoodItemBanner.defaultProps = defaultProps;

export default FoodItemBanner;
