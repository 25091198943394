import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ShareSheetModalBlock.module.scss';
import ShareSheetModal, { ShareSheetModalProps } from '../../organisms/ShareSheetModal';
import { defaultProps as ShareSheetModaDefaultlProps } from '../../organisms/ShareSheetModal/ShareSheetModal';
// MANUAL OVERRIDE
export const defaultProps = {
  shareSheetModal: {
    ...ShareSheetModaDefaultlProps,
  },
};

export type ShareSheetModalBlockProps = {
  shareSheetModal?: ShareSheetModalProps;
  onClose?: () => void;
  open?: boolean;
};
// MANUAL OVERRIDE
const ShareSheetModalBlock: React.FC<ShareSheetModalBlockProps> = ({
  shareSheetModal,
  onClose,
  open,
}) => (
  <Modal
    className={styles.modal}
    dialogClassName={styles.modalDialog}
    contentClassName={styles.modalContent}
    show={open}
    onHide={onClose}
  >
    <ShareSheetModal
      className={styles.shareSheetModal}
      {...shareSheetModal}
    />
  </Modal>);

export default ShareSheetModalBlock;
