import React from 'react';
import cx from 'classnames';

import styles from './Chip.module.scss';

import Text, { TextProps } from '../Text';

export type ChipStyleType = 'Default';

export const defaultProps = {
  style: 'Default' as ChipStyleType,
  text: {
    type: 'Body4',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
};

export type ChipProps = {
  style?: ChipStyleType;
  text?: TextProps;
  className?: string;
};

const Chip: React.FC<ChipProps> = ({
  style,
  text,
  className,
}) => {

  const currentStyle = styles[`chip${style}`];

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={styles.text}
        {...text} />
    </div>
  );
};

Chip.defaultProps = defaultProps;

export default Chip;
