import React, { useContext } from "react";
import { AddressModalBlockProps } from ".";
import { AuthContext } from "../../../modules/auth";
import { AddressModalBlockPresenterProps } from "./AddressModalBlock.presenter";
import { useMutation } from 'react-query';
import { updateAccount } from '../../../modules/account/api';

const withInteractor = (
    Presenter: React.FC<AddressModalBlockPresenterProps>,
): React.FC<AddressModalBlockProps> => {
    const Interactor: React.FC<AddressModalBlockProps> = (props) => {
        const { error: authError, account, setAccount } = useContext(AuthContext);
        const { error: updateAccountError, mutateAsync } = useMutation(updateAccount);
        return (
            <Presenter 
                {...props} 
                account={account}
                doUpdate={mutateAsync}
                setAccount={setAccount}
                error={authError || updateAccountError as Error}
            />);
    }
    return Interactor;
}

export default withInteractor;
