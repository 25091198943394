import React from 'react';
import styles from './MenuModalBlock.module.scss';
import MenuItemConfigurationModal, {
  MenuItemConfigurationModalProps,
} from '../../organisms/MenuItemConfigurationModal';
import { MenuResponseObject } from '../../../modules/menu/types';
import StartNewCartModalBlock from '../StartNewCartModalBlock';
import { Restaurant } from '../../../modules/restaurant/types';

export const defaultProps = {
  menuItemConfigurationModal: {
    foodItemHeader: {
      titleText: {
        type: 'Title4',
        align: 'Left',
        style: 'Grey1100',
      },
      closeButton: {
        size: 'Medium',
        type: 'Icon',
        style: 'ClearSquare',
        icon: {
          asset: 'Close',
          style: 'Grey1100',
        },
      },
      button: {
        size: 'Medium',
        type: 'Icon',
        style: 'ClearSquare',
        icon: {
          asset: 'Close',
          style: 'Grey1100',
        },
      },
      divider: {
        type: 'HorizontalSolid',
        style: 'Grey400',
      },
    },
    foodItemBanner: {
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
      titleText: {
        type: 'Title3',
        align: 'Left',
        style: 'Grey1100',
      },
      descriptionText: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey700',
      },
    },
    menuSectionItemList: {
      menuSectionItems: [],
    },
    quantityTextField: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
      errorText: {
        style: 'Red800',
        align: 'Left',
        type: 'Body2',
      },
    },
    additionalNoteTextField: {
      state: 'Default',
      type: 'TextArea',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'TextArea',
        style: 'Default',
      },
      helpText: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
    },
    button: {
      size: 'Medium',
      type: 'TextIcon',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
      icon: {
        asset: 'PlusCircle',
        style: 'Grey100',
      },
    },
  } as MenuItemConfigurationModalProps,
};

export type MenuModalBlockProps = {
  menuItemConfigurationModal?: MenuItemConfigurationModalProps;
  className?: string;
  menu?: MenuResponseObject[];
  productId?: string;
  menuModalOpen?: boolean;
  startNewCartModalOpen?: boolean;
  restaurant?: Restaurant;
  addToCart?: () => void;
  addToNewCart?: () => void;
  editCart?: () => void;
  onEditCart?: () => void;
  editModal?: boolean;
  editPosition?: number;
  closeStartNewCartModal?: () => void;
  closeMenuModal?: () => void;
  notifOpen?: () => void;
  isLoading?: boolean;
  onCloseOutsideModal?: () => void;
};

const MenuModalBlock: React.FC<MenuModalBlockProps> = ({
  menuItemConfigurationModal,
  menuModalOpen,
  startNewCartModalOpen,
  addToNewCart,
  closeStartNewCartModal,
  closeMenuModal,
  restaurant,
  isLoading,
}) => (
  <>
    <MenuItemConfigurationModal
      className={styles.menuItemConfigurationModal}
      menuModalClose={closeMenuModal}
      menuModalOpen={menuModalOpen}
      isLoading={isLoading}
      {...menuItemConfigurationModal}
    />
    <StartNewCartModalBlock
      startNewCartModalOpen={startNewCartModalOpen}
      handleClose={closeStartNewCartModal}
      handleStartNewCart={addToNewCart}
      newRestaurantName={restaurant?.details?.name}
    />
  </>
);

MenuModalBlock.defaultProps = defaultProps;

export default MenuModalBlock;
