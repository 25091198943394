import React from 'react';
import cx from 'classnames';

import styles from './RadioItemList.module.scss';

import RadioItem, { RadioItemProps } from '../../atoms/RadioItem';

export const defaultProps = {
  radioItems: [
  ] as RadioItemProps[],
};

export type RadioItemListProps = {
  radioItems?: RadioItemProps[];
  className?: string;
};

const RadioItemList: React.FC<RadioItemListProps> = ({
  radioItems,
  className,
}) => {
  const radioItemArray = radioItems?.map((radioItem) => (
    <RadioItem
      className={styles.radioItem}
      {...radioItem} />
  ));
  
  return (
    <div className={cx(styles.radioItemList, className)}>
      {radioItemArray}
    </div>
  );
};

RadioItemList.defaultProps = defaultProps;

export default RadioItemList;
