import React from 'react';
import cx from 'classnames';

import styles from './RestaurantCardList.module.scss';

import RestaurantCard, { RestaurantCardProps } from '../../molecules/RestaurantCard';

export const defaultProps = {
  restaurantCards: [
  ] as RestaurantCardProps[],
};

export type RestaurantCardListProps = {
  restaurantCards?: RestaurantCardProps[];
  className?: string;
};

const RestaurantCardList: React.FC<RestaurantCardListProps> = ({
  restaurantCards,
  className,
}) => {
  const restaurantCardArray = restaurantCards?.map((restaurantCard, i) => (
    <RestaurantCard key={i}
      className={styles.restaurantCard}
      {...restaurantCard} />
  ));
  
  return (
    <div className={cx(styles.restaurantCardList, className)}>
      {restaurantCardArray}
    </div>
  );
};

RestaurantCardList.defaultProps = defaultProps;

export default RestaurantCardList;
