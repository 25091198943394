import React from 'react';
import cx from 'classnames';
import styles from './ForgotPasswordPage.module.scss';
import ForgotPassword, { ForgotPasswordProps } from '../../block/ForgotPassword';
import { NotificationProps } from '../../molecules/Notification';

export const defaultProps = {
  forgotPassword: {
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
    email: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    notification: {
      type: 'Default',
      divider: {
        style: "Grey800"
      },
      title: {
        style: 'Grey1100',
        align: 'Left',
        type: 'Title4',
      }
    } as NotificationProps
  } as ForgotPasswordProps,
};

export type ForgotPasswordPageProps = {
  className?: string;
  forgotPassword?: ForgotPasswordProps;
};

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({
  className,
  forgotPassword,
}) => {
  return (
    <div className={cx(styles.forgotPasswordPage, className)}>
      <ForgotPassword
        className={styles.forgotPassword}
        {...forgotPassword} />
    </div>
  );
};

ForgotPasswordPage.defaultProps = defaultProps;

export default ForgotPasswordPage;
