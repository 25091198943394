import React from 'react';
import cx from 'classnames';

import styles from './ListItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';

export type ListItemTypeType = 'InApp' | 'OffApp';
export type ListItemListItemTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  type: 'OffApp' as ListItemTypeType,
  label: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  button: {
    size: 'Medium',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'ArrowUpRight',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type ListItemProps = {
  type?: ListItemTypeType;
  listItemType?: ListItemListItemTypeType;
  onListItemClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  label?: TextProps;
  description?: TextProps;
  button?: ButtonProps;
  className?: string;
};

const ListItem: React.FC<ListItemProps> = ({
  type,
  listItemType,
  onListItemClicked,
  label,
  description,
  button,
  className,
}) => {

  const currentStyle = styles[`listItem${type}`];

  const leftContentView = (
    <div className={styles.leftContent}>
      <Text
        className={styles.label}
        {...label} />
      <Text
        className={styles.description}
        {...description} />
    </div>
  );
  const buttonView = (
    <Button
      className={styles.button}
      {...button} />
  );
  
  
  switch (type) {
    case 'InApp':
      break;
    case 'OffApp':
      break;
  }

  return (
    <button
      type={listItemType}
      onClick={onListItemClicked}
      className={cx(currentStyle, className)}>
      {leftContentView}
      {buttonView}
    </button>
  );
};

ListItem.defaultProps = defaultProps;

export default ListItem;
