import React from 'react';
import cx from 'classnames';

import styles from './RadioOptionItem.module.scss';

import RadioItem, { RadioItemProps } from '../../atoms/RadioItem';
import Divider, { DividerProps } from '../../atoms/Divider';
import TextInput, { TextInputProps } from '../../atoms/TextInput';
import Select, { SelectProps } from '../../atoms/Select';
import Text, { TextProps } from '../../atoms/Text';

export type RadioOptionItemStateType = 'Default' | 'Selected' | 'Error';
export type RadioOptionItemTypeType = 'Default' | 'TextField' | 'SelectField';

export const defaultProps = {
  state: 'Default' as RadioOptionItemStateType,
  type: 'SelectField' as RadioOptionItemTypeType,
  radioItem: {
    type: 'Default',
    state: 'Unselected',
    icon: {
      asset: 'RadioUnchecked',
      style: 'Green800',
    },
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    trailingText: {
      type: 'Body2',
      align: 'Right',
      style: 'Grey700',
    } as TextProps,
  } as RadioItemProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  textInput: {
    type: 'Text',
    style: 'Default',
  } as TextInputProps,
  select: {
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'Grey1100',
    },
  } as SelectProps,
  errorText: {
    type: 'Body2',
    align: 'Left',
    style: 'Red800',
  } as TextProps,
};

export type RadioOptionItemProps = {
  state?: RadioOptionItemStateType;
  type?: RadioOptionItemTypeType;
  radioItem?: RadioItemProps;
  divider?: DividerProps;
  className?: string;
  textInput?: TextInputProps;
  select?: SelectProps;
  errorText?: TextProps;
};

const RadioOptionItem: React.FC<RadioOptionItemProps> = ({
  state,
  type,
  radioItem,
  divider,
  className,
  textInput,
  select,
  errorText,
}) => {
  const currentStyle = styles[`radioOptionItem${type}`];

  const dividerView = <Divider className={styles.divider} {...divider} />;

  const errorView =
    state === 'Error' ? (
      <Text className={styles.errorText} {...errorText} />
    ) : undefined;

  let contentView;

  switch (type) {
    case 'Default':
      contentView = (
        <div className={styles.content}>
          <RadioItem className={styles.radioItem} {...radioItem} />
        </div>
      );
      break;
    case 'TextField':
      contentView = (
        <div className={styles.content}>
          <RadioItem className={styles.radioItem} {...radioItem} />
          <TextInput className={styles.textInput} {...textInput} />
          {errorView}
        </div>
      );
      break;
    case 'SelectField':
      contentView = (
        <div className={styles.content}>
          <RadioItem className={styles.radioItem} {...radioItem} />
          <Select className={styles.select} {...select} />
          {errorView}
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {contentView}
      {dividerView}
    </div>
  );
};

RadioOptionItem.defaultProps = defaultProps;

export default RadioOptionItem;
