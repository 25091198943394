import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { CartModalPresenterProps } from './CartModal.presenter';
import { CartModalProps } from '.';
import { CheckoutContext } from '../../../modules/checkout/CheckoutContext';
import { getRestaurant } from '../../../modules/restaurant/api';
import { getMenuList } from '../../../modules/menu/api';
import { AuthContext } from '../../../modules/auth';

const withInteractor = (
  Presenter: React.FC<CartModalPresenterProps>,
): React.FC<CartModalProps> => {
  const Interactor: React.FC<CartModalProps> = (props) => {
    const { basket } = useContext(CheckoutContext);
    const { account } = useContext(AuthContext);
    const { orderType } = useContext(CheckoutContext);
    let userLocation;
    if(account?.profile?.preferredAddress?.location) {
      userLocation = account.profile.preferredAddress.location;
    } else if(account?.profile?.address) {
      userLocation = account?.profile?.address[0].location;
    }

    const restaurantState = useQuery(
      ['getRestaurant', basket?.restaurantId],
      () => {
        if (basket?.restaurantId) {
          return getRestaurant(basket.restaurantId, { userLocation: userLocation, orderType: orderType });
        }
      },
    );

    const menuState = useQuery(['getMenuList', basket?.restaurantId], () => {
      if (basket?.restaurantId) {
        return getMenuList(basket?.restaurantId);
      }
    });

    return (
      <Presenter
        {...props}
        isLoading={restaurantState.isLoading || menuState.isLoading}
        restaurant={restaurantState.data}
        menu={menuState.data}
      />
    );
  };
  return Interactor;
};
export default withInteractor;
