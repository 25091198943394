import React from 'react';
import cx from 'classnames';

import styles from './Notification.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';

export type NotificationTypeType = 'Default' | 'NotificationWithClose' | 'Instructions' | 'Announcement';

export const defaultProps = {
  type: 'Instructions' as NotificationTypeType,
  title: {
    type: 'Title4',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Blue200',
  } as DividerProps,
  description: '',
  button: {
    size: 'ExtraExtraSmall',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'Info',
      style: 'Blue1000',
    },
  } as ButtonProps,
  text: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
};

export type NotificationProps = {
  type?: NotificationTypeType;
  title?: TextProps;
  divider?: DividerProps;
  description?: string | React.ReactNode;
  className?: string;
  button?: ButtonProps;
  text?: TextProps;
};

const Notification: React.FC<NotificationProps> = ({
  type,
  title,
  divider,
  description,
  className,
  button,
  text,
}) => {

  const currentStyle = styles[`notification${type}`];

  const dividerView = type === 'Announcement' ? null : (
    <Divider
      className={styles.divider}
      {...divider} />
  );
  
  let topContentView;
  let descriptionView;
  let textView;
  
  switch (type) {
    case 'Announcement':
    case 'Default':
      topContentView = (
        <div className={styles.topContent}>
          <Text
            className={styles.title}
            {...title} />
        </div>
      );
      descriptionView = (
        <p className={styles.description}>
          {description}
        </p>
      );
      break;
    case 'NotificationWithClose':
      topContentView = (
        <div className={styles.topContent}>
          <p className={styles.title}>
            {title}
          </p>
          <Button
            className={styles.button}
            {...button} />
        </div>
      );
      descriptionView = (
        <p className={styles.description}>
          {description}
        </p>
      );
      break;
    case 'Instructions':
      topContentView = (
        <div className={styles.topContent}>
          <Button
            className={styles.button}
            {...button} />
          <Text
            className={styles.title}
            {...title} />
        </div>
      );
      textView = (
        <Text
          className={styles.text}
          {...text} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {topContentView}
      {dividerView}
      {descriptionView}
      {textView}
    </div>
  );
};

Notification.defaultProps = defaultProps;

export default Notification;
