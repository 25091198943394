import React from 'react';
import cx from 'classnames';

import styles from './ButtonItemList.module.scss';

import ButtonItem, { ButtonItemProps } from '../../atoms/ButtonItem';

export const defaultProps = {
  buttonItems: [
  ] as ButtonItemProps[],
};

export type ButtonItemListProps = {
  buttonItems?: ButtonItemProps[];
  className?: string;
};

const ButtonItemList: React.FC<ButtonItemListProps> = ({
  buttonItems,
  className,
}) => {
  const buttonItemArray = buttonItems?.map((buttonItem) => (
    <ButtonItem
      className={styles.buttonItem}
      {...buttonItem} />
  ));
  
  return (
    <div className={cx(styles.buttonItemList, className)}>
      {buttonItemArray}
    </div>
  );
};

ButtonItemList.defaultProps = defaultProps;

export default ButtonItemList;
