import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ForgotPasswordProps, defaultProps } from './ForgotPassword';
import { isEmail, isEmptyString } from '../../../lib/utils';
import { ForgotPasswordPayload } from '../../../modules/account/types';
import { ApiError } from '../../../lib/api/types';

export type ForgotPasswordPresenterProps = ForgotPasswordProps & {
  forgotPassword: (email: ForgotPasswordPayload) => Promise<void>;
  isLoading: boolean;
};

const withPresenter = (
  View: React.FC<ForgotPasswordProps>,
): React.FC<ForgotPasswordPresenterProps> => {
  const Presenter: React.FC<ForgotPasswordPresenterProps> = (props) => {
    const { forgotPassword, isLoading } = props;
    const history = useHistory();
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const isValidForm = !isEmptyString(email);
    const handleEmail = (e): void => {
      setEmail(e.target.value);
      setEmailError('');
    };

    const handleResetLink = async (): Promise<void> => {
      try {
        if (!isEmail(email)) {
          setEmailError(t('error.format.email'));
        } else {
          await forgotPassword({ email });
          history.push('/resetSent', { email });
        }
      } catch (e) {
        if (ApiError.isApiError(e) && (e.code === 422 || e.code === 404)) {
          history.push('/resetSent', { email });
        } else if (ApiError.isApiError(e) && e.message) {
          setError(e.message);
        } else {
          setError(t('error.default'));
        }
      }
    };

    // render proper props base on sign in or create account
    const forgotPasswordProps: ForgotPasswordProps = {
      ...defaultProps,
      backButton: {
        ...defaultProps.backButton,
        text: {
          ...defaultProps.backButton?.text,
          value: t('button.back'),
        },
        onButtonClicked: (): void => {
          history.goBack();
        },
      },
      title: {
        ...defaultProps.title,
        value: t('textLabels.forgot_password'),
      },
      description: {
        ...defaultProps.description,
        value: t('forgotPassword.forgot_password_description'),
      },
      email: {
        ...defaultProps.email,
        state: emailError ? 'Error' : 'Default',
        label: {
          ...defaultProps.email?.label,
          value: t('textLabels.email'),
        },
        textInput: {
          ...defaultProps.email?.textInput,
          onTextChanged: handleEmail,
        },
        errorText: {
          ...defaultProps.email?.errorText,
          value: emailError,
          style: 'Red800',
          align: 'Left',
        },
      },
      button: {
        ...defaultProps.button,
        onButtonClicked: handleResetLink,
        disabled: !isValidForm,
        text: {
          ...defaultProps.button?.text,
          value: t('button.reset_link'),
        },
      },
      notification: {
        ...defaultProps.notification,
        type: 'Announcement',
        title: {
          ...defaultProps.notification?.title,
          value: t('notification.title'),
        },
        description: <div dangerouslySetInnerHTML={{ __html: t('notification.description') }} />,
      },
    };

    return <View {...props} {...forgotPasswordProps} />;
  };
  return Presenter;
};

export default withPresenter;
