import React from 'react';
import cx from 'classnames';

import styles from './ForgotPassword.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import Notification, { NotificationProps } from '../../molecules/Notification';

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
  } as ButtonProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  email: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  notification: {
    type: 'Default',
    divider: {
      style: "Grey800"
    },
    title: {
      style: 'Grey1100',
      align: 'Left',
      type: 'Title4',
    }
  } as NotificationProps
};

export type ForgotPasswordProps = {
  backButton?: ButtonProps;
  button?: ButtonProps;
  title?: TextProps;
  description?: TextProps;
  email?: TextFieldProps;
  className?: string;
  notification?: NotificationProps;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  backButton,
  button,
  title,
  description,
  email,
  className,
  notification,
}) => {
  return (
    <div className={cx(styles.forgotPassword, className)}>
      <div className={styles.topContent}>
        <Button
          className={styles.backButton}
          {...backButton} />
        <Notification 
          className={styles.notification}
          {...notification} />
        <Text
          className={styles.title}
          {...title} />
        <Text
          className={styles.description}
          {...description} />
      </div>
      <div className={styles.formContent}>
        <TextField
          className={styles.email}
          {...email} />
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

ForgotPassword.defaultProps = defaultProps;

export default ForgotPassword;
