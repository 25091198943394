import React from 'react';
import cx from 'classnames';

import styles from './PriceListItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export type PriceListItemTypeType = 'Default' | 'Large';

export const defaultProps = {
  type: 'Large' as PriceListItemTypeType,
  description: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  cost: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps
};

export type PriceListItemProps = {
  type?: PriceListItemTypeType;
  description?: TextProps;
  cost?: TextProps;
  className?: string;
};

const PriceListItem: React.FC<PriceListItemProps> = ({
  type,
  description,
  cost,
  className,
}) => {
  const currentStyle = styles[`priceListItem${type}`];

  let descriptionView;
  
  switch (type) {
    case 'Default':
      descriptionView = (
        <div className={styles.holder}>
        <Text
          className={styles.description}
          {...description} />
        <Text
          className={styles.cost}
          {...cost} />
        </div>
      );
      break;
    case 'Large':
      descriptionView = (
        <div className={styles.holder}>
        <Text
          className={styles.description}
          {...description} />
        <Text
          className={styles.cost}
          {...cost} />
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {descriptionView}
    </div>
  );
};

PriceListItem.defaultProps = defaultProps;

export default PriceListItem;
