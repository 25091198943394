import React from 'react';
import cx from 'classnames';

import styles from './PriceSectionItem.module.scss';

import PriceListItem, { PriceListItemProps } from '../../molecules/PriceListItem';
import PriceItemList, { PriceItemListProps } from '../PriceItemList';

export const defaultProps = {
  priceListItem: {
    type: 'Default',
    description: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
  } as PriceListItemProps,
  priceItemList: {
    menuItems: [
    ],
  } as PriceItemListProps,
};

export type PriceSectionItemProps = {
  priceListItem?: PriceListItemProps;
  priceItemList?: PriceItemListProps;
  className?: string;
};

const PriceSectionItem: React.FC<PriceSectionItemProps> = ({
  priceListItem,
  priceItemList,
  className,
}) => {
  return (
    <div className={cx(styles.priceSectionItem, className)}>
      <PriceListItem
        className={styles.priceListItem}
        {...priceListItem} />
      <PriceItemList
        className={styles.priceItemList}
        {...priceItemList} />
    </div>
  );
};

PriceSectionItem.defaultProps = defaultProps;

export default PriceSectionItem;
