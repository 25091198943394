import React from 'react';
import cx from 'classnames';

import styles from './FoodItemCardList.module.scss';

import FoodItemCard, { FoodItemCardProps } from '../../molecules/FoodItemCard';

export const defaultProps = {
  foodItemCards: [
  ] as FoodItemCardProps[],
};

export type FoodItemCardListProps = {
  foodItemCards?: FoodItemCardProps[];
  className?: string;
};

const FoodItemCardList: React.FC<FoodItemCardListProps> = ({
  foodItemCards,
  className,
}) => {
  const foodItemCardArray = foodItemCards?.map((foodItemCard, i) => (
    <FoodItemCard key={i}
      className={styles.foodItemCard}
      {...foodItemCard} />
  ));
  return (
    <div className={cx(styles.foodItemCardList, className)}>
      {foodItemCardArray}
    </div>
  );
};

FoodItemCardList.defaultProps = defaultProps;

export default FoodItemCardList;
