import React from 'react';
import cx from 'classnames';

import styles from './StartBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Image, { ImageProps } from '../../atoms/Image';
import Button, { ButtonProps } from '../../atoms/Button';
import SecondaryAction, { SecondaryActionProps } from '../../organisms/SecondaryAction';

export type StartBlockStyleType = 'SignIn' | 'CreateAccount';
export type StartBlockProperty1Type = 'Default' | 'Variant2';

export const defaultProps = {
  style: 'CreateAccount' as StartBlockStyleType,
  title: {
    type: 'Title1',
    align: 'Center',
    style: 'Grey100',
  } as TextProps,
  image: {
    style: 'Square',
  } as ImageProps,
  description: {
    type: 'Body1',
    align: 'Center',
    style: 'Grey100',
  } as TextProps,
  googleButton: {
    size: 'Medium',
    type: 'IconText',
    style: 'GoogleSquare',
    icon: {
      asset: 'Google',
      style: 'Grey1100',
    },
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  facebookButton: {
    size: 'Medium',
    type: 'IconText',
    style: 'FacebookSquare',
    icon: {
      asset: 'Facebook',
      style: 'Grey1100',
    },
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'ContrastSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  secondaryAction: {
    title: {
      type: 'Title4',
      align: 'Center',
      style: 'Grey100',
    },
    link: {
      type: 'Body2',
      align: 'Center',
      style: 'Grey100',
    },
  } as SecondaryActionProps,
};

export type StartBlockProps = {
  style?: StartBlockStyleType;
  title?: TextProps;
  image?: ImageProps;
  description?: TextProps;
  googleButton?: ButtonProps;
  facebookButton?: ButtonProps;
  button?: ButtonProps;
  secondaryAction?: SecondaryActionProps;
  type?: 'signIn' | 'signUp';
  className?: string;
  isGoogleSupportedBrowser?: boolean;
};

const StartBlock: React.FC<StartBlockProps> = ({
  style,
  title,
  image,
  description,
  googleButton,
  facebookButton,
  button,
  secondaryAction,
  className,
  isGoogleSupportedBrowser,
}) => {
  const currentStyle = styles[`startBlock${style}`];
  const facebook = (
    <Button className={styles.facebookButton} {...facebookButton} />
  );
  return (
    <div className={cx(styles.startBlock, className)}>
      <div className={styles.topContent}>
        <Text
          className={styles.title}
          {...title} />
        <Image
          className={styles.image}
          {...image} />
        <Text
          className={styles.description}
          {...description} />
      </div>
      <div className={styles.formContent}>
        {isGoogleSupportedBrowser && <Button
          className={styles.googleButton}
          {...googleButton} />}
        {/* <Button
          className={styles.facebookButton}
          {...facebookButton} /> */}
        <Button
          className={styles.button}
          {...button} />
      </div>
      <SecondaryAction
        className={styles.secondaryAction}
        {...secondaryAction} 
        />
    </div>
  );
};

StartBlock.defaultProps = defaultProps;

export default StartBlock;
