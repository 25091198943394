import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  CompleteProfileBlockProps,
  defaultProps,
} from './CompleteProfileBlock';
import {
  formatDisplayAddress, isEmptyString, isMobile, isValidAddress,
} from '../../../lib/utils';
import {
  LocationState,
  CompleteAccountPayload,
} from '../../../modules/account/types';
import { Account, Address, Profile } from '../../../lib/token';
import { sourceOptions } from './utils';
import styles from './CompleteProfileBlock.module.scss';

export type CompleteProfilePresenterProps = CompleteProfileBlockProps & {
  doUpdate: (payload: CompleteAccountPayload) => Promise<Account>;
  isLoading: boolean;
  account: Account | null;
  setAccount: (account: Account | null) => void;
  error?: Error;
};

type AccountDetails = {
  phoneNumber: string;
  address: Address;
  reference: string;
  referenceRestaurant: string;
};

const withPresenter = (
  View: React.FC<CompleteProfileBlockProps>,
): React.FC<CompleteProfilePresenterProps> => {
  const Presenter: React.FC<CompleteProfilePresenterProps> = (props) => {
    const {
      doUpdate, isLoading, account, setAccount, error,
    } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const { state } = useLocation<LocationState>();
    const [checkPromotionalEvents, setCheckPromotionalEvents] = useState<boolean>(false);
    const [formState, setFormState] = useState({
      phoneNumber: '',
      address: {
        streetNumber: '',
        addressLine: '',
        addressLine2: '',
        city: '',
        province: '',
        postalCode: '',
        country: '',
        location: {
          latitude: 0,
          longitude: 0,
        },
      },
      reference: '',
      referenceRestaurant: '',
      errors: {
        phoneNumber: '',
        address: '',
        addressLine2: '',
        reference: '',
      },
    });
    const [focused, setFocusedState] = useState({
      phoneNumber: false,
      address: false,
      addressLine2: false,
      reference: false,
    });

    if (account?.profile) {
      history.replace('/');
    }

    const handleInputChanged = (key: keyof AccountDetails) => ({ target: { value } }): void => {
      setFormState((previousState) => ({ ...previousState, [key]: value }));
    };

    const handleAddressLine2Changed = ({ target: { value } }): void => {
      setFormState((previousState) => ({
        ...previousState,
        address: {
          ...previousState.address,
          addressLine2: value,
        },
        errors: {
          ...previousState.errors,
          addressLine2: '',
        },
      }));
    };

    const handleAddressSelection = (selection: Address): void => {
      setFormState((previousState) => ({
        ...previousState,
        address: {
          ...selection,
          addressLine2: selection.addressLine2 ? selection.addressLine2 : '',
        },
        errors: {
          ...previousState.errors,
          address: '',
          addressLine2: '',
        },
      }));
    };

    const isFormValid = (): boolean => {
      setFocusedState({
        phoneNumber: false,
        address: false,
        reference: false,
        addressLine2: false,
      });

      const {
        phoneNumber,
        address,
        reference,
      } = formState;

      let phoneNumberError = '';
      if (isEmptyString(phoneNumber)) {
        phoneNumberError = t('error.required', {
          field: t('textLabels.phone_number'),
        });
      } else if (!isMobile(phoneNumber)) {
        phoneNumberError = t('error.format.phone_number');
      }

      let addressError = '';
      if (!isValidAddress(address)) {
        addressError = t('error.valid_address', {
          field: t('textLabels.address'),
        });
      }

      let referenceError = '';
      if (isEmptyString(reference)) {
        referenceError = t('error.required', {
          field: t('textLabels.source'),
        });
      }

      const errors = {
        phoneNumber: phoneNumberError,
        address: addressError,
        addressLine2: '',
        reference: referenceError,
      };

      setFormState((previousState) => ({
        ...previousState,
        errors,
      }));
      return (
        Object.values(errors).filter((errorMessage: string) => !!errorMessage)
          .length === 0
      );
    };

    const handleCreateAccount = async (): Promise<void> => {
      if (isFormValid() && account) {
        setIsCreatingAccount(true);
        const {
          phoneNumber,
          address,
          reference,
          referenceRestaurant,
        } = formState;
        const profile: Profile = {
          phoneNumber,
          address: [address],
          reference,
          referenceRestaurant,
          checkPromotionalEvents,
        };
        const data = await doUpdate({
          id: account.id,
          firstName: account.firstName,
          lastName: account.lastName,
          email: account.email,
          profile,
        });
        setAccount(data);
        setIsCreatingAccount(false);
        if (state?.referrer) {
          history.push(state.referrer);
        } else {
          history.push('/');
        }
      }
    };

    useEffect(() => {
      const newFocusedState = {
        phoneNumber: false,
        address: false,
        addressLine2: false,
        reference: false,
      };

      if (formState.errors.phoneNumber) {
        newFocusedState.phoneNumber = true;
      } else if (formState.errors.address) {
        newFocusedState.address = true;
      } else if (formState.errors.reference) {
        newFocusedState.reference = true;
      } else if (formState.errors.addressLine2) {
        newFocusedState.addressLine2 = true;
      }

      setFocusedState(newFocusedState);
    }, [formState.errors]);

    useEffect(() => {
      setFormState((previousState) => ({
        ...previousState,
        errors: {
          ...previousState.errors,
          phoneNumber: formState.phoneNumber ? '' : previousState.errors.phoneNumber,
          reference: formState.reference ? '' : previousState.errors.reference,
        },
      }));
    }, [formState.phoneNumber, formState.reference]);

    if ((isLoading || isCreatingAccount) && !error) {
      return <div className={styles.loading}>Loading...</div>;
    }

    const completeProfileBlockProps: CompleteProfileBlockProps = {
      ...defaultProps,
      backButton: {
        ...defaultProps.backButton,
        text: {
          ...defaultProps.backButton?.text,
          value: t('button.back'),
        },
        onButtonClicked: (): void => {
          history.goBack();
        },
      },
      title: {
        ...defaultProps.title,
        value: t('complete_profile.title'),
      },
      description: {
        ...defaultProps.description,
        value: t('complete_profile.description'),
      },
      phoneNumber: {
        ...defaultProps.phoneNumber,
        state: formState.errors.phoneNumber ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.phoneNumber.textInput,
          onTextChanged: handleInputChanged('phoneNumber'),
          textPlaceholder: t('textLabels.phone_number'),
          focused: focused.phoneNumber,
        },
        label: {
          ...defaultProps.phoneNumber?.label,
          value: t('textLabels.phone_number'),
        },
        errorText: {
          value: formState.errors.phoneNumber,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      addressAutoComplete: {
        label: {
          ...defaultProps.addressAutoComplete.label,
          value: t('textLabels.address'),
        },
        value: formatDisplayAddress(formState.address),
        onSelection: handleAddressSelection,
        errorText: {
          ...defaultProps.addressAutoComplete.errorText,
          value: formState.errors.address,
        },
      },
      addressLine2: {
        ...defaultProps.addressLine2,
        textInput: {
          ...defaultProps?.addressLine2.textInput,
          textValue: formState.address.addressLine2,
          onTextChanged: handleAddressLine2Changed,
        },
        label: {
          ...defaultProps.addressLine2.label,
          value: t('textLabels.address_line_2'),
        },
      },
      referenceField: {
        ...defaultProps.referenceField,
        state: formState.errors.reference ? 'Error' : 'Default',
        select: {
          options: sourceOptions,
          onSelectChanged: handleInputChanged('reference'),
          value: formState.reference,
          selectPlaceholder: t('textLabels.reference'),
        },
        errorText: {
          value: formState.errors.reference,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
        label: {
          ...defaultProps.referenceField.label,
          value: t('textLabels.reference'),
        },
      },
      additionalFiled: {
        label: {
          ...defaultProps.referenceField.label,
          value: t('textLabels.restaurant_name'),
        },
        textInput: {
          onTextChanged: handleInputChanged('referenceRestaurant'),
          textValue: formState.referenceRestaurant,
        },
        hidden: formState.reference !== t('complete_profile.sources.restaurant'),
      },
      checkboxItem: {
        ...defaultProps.checkboxItem,
        onCheckboxItemClicked: (): void => setCheckPromotionalEvents(!checkPromotionalEvents),
        state: checkPromotionalEvents ? 'Selected' : 'Unselected',
        icon: {
          asset: checkPromotionalEvents
            ? 'CheckboxChecked'
            : 'CheckboxUnchecked',
          style: checkPromotionalEvents ? 'Green800' : 'Grey700',
        },
        label: {
          ...defaultProps.checkboxItem?.label,
          value: t('complete_profile.check_box'),
        },
      },
      legal: {
        ...defaultProps.legal,
        value: (
          <Trans key='complete_profile.legal'>
            By creating an account, you agree to Suppli’s&nbsp;
            <a href={t('urls.terms')}>Terms of Use</a>
            &nbsp; and&nbsp;
            <a href={t('urls.privacyPolicy')}>Privacy Policy</a>.
          </Trans>
        ),
      },
      button: {
        ...defaultProps.button,
        onButtonClicked: handleCreateAccount,
        text: {
          ...defaultProps.button?.text,
          value: t('textLabels.create_account'),
        },
      },
    };
    return <View {...props} {...completeProfileBlockProps} />;
  };

  return Presenter;
};

export default withPresenter;
