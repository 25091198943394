import React from 'react';
import cx from 'classnames';

import styles from './CompleteProfileBlock.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import SelectField, { SelectFieldProps } from '../../molecules/SelectField';
import CheckboxItem, { CheckboxItemProps } from '../../atoms/CheckboxItem';
import AddressAutoComplete from '../../organisms/AddressAutoComplete';
import { AddressAutoCompleteProps } from '../../organisms/AddressAutoComplete/AddressAutoComplete';

type TextFieldAutoHideProps = TextFieldProps & { hidden: boolean };

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
  } as ButtonProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  phoneNumber: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  addressAutoComplete: {
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    errorText: {
      style: 'Red800',
      align: 'Left',
      type: 'Body2',
    },
  } as AddressAutoCompleteProps,
  addressLine2: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  referenceField: {
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    select: {
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      icon: {
        asset: 'ChevronDown',
        style: 'Grey1100',
      },
    },
  } as SelectFieldProps,
  additionalFiled: {
    state: 'Default',
    type: 'Text',
    hidden: true,
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldAutoHideProps,
  checkboxItem: {
    type: 'Default',
    state: 'Unselected',
    icon: {
      asset: 'CheckboxUnchecked',
      style: 'Grey700',
    },
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
  } as CheckboxItemProps,
  legal: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
};

export type CompleteProfileBlockProps = {
  backButton?: ButtonProps;
  button?: ButtonProps;
  title?: TextProps;
  description?: TextProps;
  phoneNumber?: TextFieldProps;
  addressAutoComplete?: AddressAutoCompleteProps;
  addressLine2?: TextFieldProps;
  referenceField?: SelectFieldProps;
  additionalFiled?: TextFieldAutoHideProps;
  checkboxItem?: CheckboxItemProps;
  legal?: TextProps;
  className?: string;
  error?: Error;
};

const CompleteProfileBlock: React.FC<CompleteProfileBlockProps> = ({
  backButton,
  button,
  title,
  description,
  phoneNumber,
  addressAutoComplete,
  addressLine2,
  referenceField,
  additionalFiled,
  checkboxItem,
  legal,
  className,
}) => {
  const additionalFieldView = additionalFiled?.hidden ? null : (
    <TextField className={styles.additionalFiled} {...additionalFiled} />
  );

  return (
    <div className={cx(styles.completeProfileBlock, className)}>
      <div className={styles.topContent}>
        <Button
          className={styles.backButton}
          {...backButton} />
        <Text
          className={styles.title}
          {...title} />
        <Text
          className={styles.description}
          {...description} />
      </div>
      <div className={styles.formContent}>
        <TextField
          className={styles.phoneNumber}
          {...phoneNumber} />
        <AddressAutoComplete
          {...addressAutoComplete}
        />
        <TextField
          {...addressLine2}
          className={styles.addressLine2} />
        <SelectField
          className={styles.referenceField}
          {...referenceField} />
        { additionalFieldView }
        <CheckboxItem
          className={styles.checkboxItem}
          {...checkboxItem} />
        <Text
          className={styles.legal}
          {...legal} />
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

CompleteProfileBlock.defaultProps = defaultProps;

export default CompleteProfileBlock;
