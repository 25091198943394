import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { RestaurantPageProps } from './RestaurantPage';
import { RestaurantPagePresenterProps } from './RestaurantPage.presenter';
import { getRestaurant } from '../../../modules/restaurant/api';
import { getMenuList } from '../../../modules/menu/api';
import { AuthContext } from '../../../modules/auth';
import { CheckoutContext } from '../../../modules/checkout';

const withInteractor = (
  Presenter: React.FC<RestaurantPagePresenterProps>,
): React.FC<RestaurantPagePresenterProps> => {
  const Interactor: React.FC<RestaurantPageProps> = (props) => {
    const { restaurantId } = useParams<{ restaurantId: string }>();
    const { account } = useContext(AuthContext);
    const { orderType } = useContext(CheckoutContext);
    let userLocation;
    if(account?.profile?.preferredAddress?.location) {
      userLocation = account.profile.preferredAddress.location;
    } else if(account?.profile?.address) {
      userLocation = account?.profile?.address[0].location;
    }
    
    const restaurantState = useQuery(['getRestaurant',  { restaurantId, userLocation: userLocation, orderType: orderType }], () =>
      getRestaurant(restaurantId, { userLocation: userLocation, orderType: orderType }),
    );
    
    const menuState = useQuery(['getMenuList', restaurantId], () =>
      getMenuList(restaurantId),
    );

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const productId = query.get('product');

    const [showNotif, setNotif] = useState(false);
    const changeNotif = (): void => {
      setNotif((prev) => !prev);
    };

    return (
      <Presenter
        {...props}
        isLoading={restaurantState.isLoading || menuState.isLoading}
        restaurant={restaurantState.data}
        menu={menuState.data}
        productId={productId ? productId : undefined}
        menuModalOpen={productId ? true : false}
        showNotif={showNotif}
        notifOpen={changeNotif}
      />
    );
  };
  return Interactor;
};
export default withInteractor;
