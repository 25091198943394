import React from 'react';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';

import styles from './ProfileMenuModal.module.scss';
import ProfileMenu, { ProfileMenuProps } from '../../organisms/ProfileMenu';

export type ProfileMenuModalProps = {
  onClose: () => void;
  open: boolean;
  profileMenu?: ProfileMenuProps;
  onCartOpen: () => void;
};

const ProfileMenuModal: React.FC<ProfileMenuModalProps> = ({
  onClose,
  open,
  profileMenu,
}) => (
    <Modal
      animation={false}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={cx(styles.modalContent)}
      show={open}
      onHide={onClose}
      onEscapeKeyDown={onClose}
    >
      <ProfileMenu
        className={styles.profileMenu}
        {...profileMenu}
      />
    </Modal>
);

export default ProfileMenuModal;
