import React from "react";
import { useMutation } from "react-query";
import { CompleteProfileBlockProps } from "./CompleteProfileBlock";
import { CompleteProfilePresenterProps } from "./CompleteProfileBlock.presenter";
import { completeAccount } from '../../../modules/account/api';
import { useContext } from "react";
import { AuthContext } from "../../../modules/auth";

const withInteractor = (
  Presenter: React.FC<CompleteProfilePresenterProps>,
): React.FC<CompleteProfileBlockProps> => {
  const Interactor: React.FC<CompleteProfileBlockProps> = (props) => {
    const { account, setAccount } = useContext(AuthContext);
    const { error, mutateAsync, isLoading } = useMutation(completeAccount);
    return (
      <Presenter
        {...props}
        doUpdate={mutateAsync}
        isLoading={isLoading}
        account={account}
        setAccount={setAccount}
        error={error as Error}
      />
    );
  };
  return Interactor;
};
export default withInteractor;