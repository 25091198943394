import React, { RefObject, useEffect, useRef } from 'react';
import cx from 'classnames';

import styles from './Tab.module.scss';

import Text, { TextProps } from '../Text';

export type TabStateType = 'Unselected' | 'Selected';
export type TabTabTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  state: 'Unselected' as TabStateType,
  text: {
    type: 'Body3',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
};

export type TabProps = {
  tabId?: string;
  state?: TabStateType;
  tabType?: TabTabTypeType;
  onTabClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  text?: TextProps;
  className?: string;
  focusedBlockId?: string;
  tabListRef?: RefObject<HTMLDivElement>;
  scrolledBlockId?: string;
};

const Tab: React.FC<TabProps> = ({
  tabId,
  state,
  tabType,
  onTabClicked,
  text,
  className,
  focusedBlockId,
  tabListRef,
  scrolledBlockId,
}) => {
  const currentStyle = styles[`tab${state}`];

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (tabListRef?.current && ref.current && focusedBlockId === tabId) {
      tabListRef.current.scrollTo({
        left: ref.current.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [focusedBlockId, ref, tabId, tabListRef]);

  useEffect(() => {
    if (tabListRef?.current && ref.current && scrolledBlockId === tabId) {
      tabListRef.current.scrollTo({
        left: ref.current.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [focusedBlockId, ref, tabId, scrolledBlockId]);

  const contentView = (
    <div className={styles.content}>
      <Text
        className={styles.text}
        {...text} />
    </div>
  );
  const indicatorView = (
    <div className={styles.indicator}/>
  );

  return (
    <button
      ref={ref}
      type={tabType}
      onClick={onTabClicked}
      className={cx(currentStyle, className)}>
      {contentView}
      {indicatorView}
    </button>
  );
};

Tab.defaultProps = defaultProps;

export default Tab;
