import React from 'react';
import cx from 'classnames';
import BoostrapToast from 'react-bootstrap/Toast';
import ToastBody from 'react-bootstrap/ToastBody';

import styles from './ToastNotification.module.scss';

import Icon, { IconProps } from '../../atoms/Icon';
import Text, { TextProps } from '../../atoms/Text';

export type ToastNotificationTypeType = 'Success' | 'Error';

export const defaultProps = {
  type: 'Error' as ToastNotificationTypeType,
  icon: {
    asset: 'ErrorCircle',
    style: 'Red800',
  } as IconProps,
  text: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  show: false
};

export type ToastNotificationProps = {
  type?: ToastNotificationTypeType;
  icon?: IconProps;
  text?: TextProps;
  show?: boolean;
  onClose?: () => void;
  className?: string;
};

const ToastNotification: React.FC<ToastNotificationProps> = ({
  type,
  icon,
  text,
  show,
  onClose,
  className,
}) => {

  const currentStyle = styles[`toastNotification${type}`];

  const contentView = (
    <div className={styles.content}>
      <Icon
        className={styles.icon}
        {...icon} />
      <Text
        className={styles.text}
        {...text} />
    </div>
  );
  
  
  switch (type) {
    case 'Success':
      break;
    case 'Error':
      break;
  }

  return (
    <BoostrapToast
      show={show}
      onClose={onClose}
      delay={3000}
      autohide
      className={cx(styles.toast, className)}
    >
      <ToastBody className={cx(styles.toastBody, className)}>
        <div className={cx(currentStyle, className)}>
          {contentView}
        </div>
      </ToastBody>
    </BoostrapToast>
  );
};

ToastNotification.defaultProps = defaultProps;

export default ToastNotification;
