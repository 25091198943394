import React from 'react';
import cx from 'classnames';

import styles from './HelpPage.module.scss';

import HelpBlock, { HelpBlockProps } from '../../block/HelpBlock';
import { TextProps } from '../../atoms/Text';

export const defaultProps = {
  helpBlock: {
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    faq: {
      type: 'OffApp',
      label: {
        type: 'Title3',
        align: 'Left',
        style: 'Grey1100',
      },
      description: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      button: {
        size: 'Medium',
        type: 'Icon',
        style: 'BasicSquare',
        icon: {
          asset: 'ArrowUpRight',
          style: 'Grey1100',
        },
      },
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
    sendMessage: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    } as TextProps,
    contactInstructions: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    } as TextProps,
  } as HelpBlockProps,
};

export type HelpPageProps = {
  helpBlock?: HelpBlockProps;
  className?: string;
};

const HelpPage: React.FC<HelpPageProps> = ({
  helpBlock,
  className,
}) => (
    <div className={cx(styles.helpPage, className)}>
      <HelpBlock
        className={styles.helpBlock}
        {...helpBlock} />
    </div>
);

HelpPage.defaultProps = defaultProps;

export default HelpPage;
