import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import { updateAccount } from '../../../modules/account/api';
import { AuthContext } from '../../../modules/auth';
import { ProfileBlockProps } from './ProfileBlock';
import { ProfileBlockPresenterProps } from './ProfileBlock.presenter';

const withInteractor = (
  Presenter: React.FC<ProfileBlockPresenterProps>,
): React.FC <ProfileBlockProps> => {
  const Interactor: React.FC <ProfileBlockProps> = (props) => {
    const { account, setAccount } = useContext(AuthContext);
    const { error, mutateAsync, isLoading } = useMutation(updateAccount);
    return (
      <Presenter
          {...props}
          updateAccount={mutateAsync}
          isLoading={isLoading}
          account={account}
          setAccount={setAccount}
          error={error as Error}
        />
    );
  };
  return Interactor;
};
export default withInteractor;
