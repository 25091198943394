import React from 'react';
import cx from 'classnames';

import styles from './StateDefaultTypeTextArea.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextInput, { TextInputProps } from '../../atoms/TextInput';

export const defaultProps = {
  label: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  textInput: {
    type: 'TextArea',
    style: 'Default',
  } as TextInputProps,
  helpText: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
};

export type StateDefaultTypeTextAreaProps = {
  label?: TextProps;
  textInput?: TextInputProps;
  helpText?: TextProps;
  className?: string;
};

const StateDefaultTypeTextArea: React.FC<StateDefaultTypeTextAreaProps> = ({
  label,
  textInput,
  helpText,
  className,
}) => {
  return (
    <div className={cx(styles.stateDefaultTypeTextArea, className)}>
      <Text
        className={styles.label}
        {...label} />
      <TextInput
        className={styles.textInput}
        {...textInput} />
      <Text
        className={styles.helpText}
        {...helpText} />
    </div>
  );
};

StateDefaultTypeTextArea.defaultProps = defaultProps;

export default StateDefaultTypeTextArea;
