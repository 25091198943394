import React from 'react';
import cx from 'classnames';

import styles from './AddTipBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Image, { ImageProps } from '../../atoms/Image';
import RadioItemList, { RadioItemListProps } from '../../organisms/RadioItemList';
import CustomAmount, { CustomAmountProps } from '../../molecules/CustomAmount';
import PriceItem, { PriceItemProps } from '../../molecules/PriceItem';
import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
  } as ButtonProps,
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  radioItemList: {
    radioItems: [
    ],
  } as RadioItemListProps,
  customAmount: {
    state: 'Default',
    button: {
      size: 'Medium',
      type: 'IconText',
      style: 'BasicSquare',
      icon: {
        asset: 'Plus',
        style: 'Grey1100',
      },
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    textInput: {
      type: 'Text',
      style: 'LeadingIcon',
      icon: {
        asset: 'Dollar',
        style: 'Grey1100',
      },
    },
    errorText: {
      style: 'Red800',
      align: 'Left',
      type: 'Body2',
    },
  } as CustomAmountProps,
  tipAmount: {
    type: 'ListItem',
    state: 'Collapsed',
    item: {
      type: 'Body5',
      align: 'Left',
      style: 'Grey800',
    },
    divider: {
      type: 'HorizontalDotted',
      style: 'Grey500',
    },
    loading: 'Loading',
    price: {
      type: 'Body5',
      align: 'Right',
      style: 'Grey800',
    },
  } as PriceItemProps,
  total: {
    type: 'ListItemDark',
    state: 'Collapsed',
    item: {
      type: 'Body5',
      align: 'Left',
      style: 'Grey1100',
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey800',
    },
    loading: 'Loading',
    price: {
      type: 'Body5',
      align: 'Right',
      style: 'Grey1100',
    },
  } as PriceItemProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type AddTipBlockProps = {
  backButton?: ButtonProps,
  title?: TextProps;
  description?: TextProps;
  image?: ImageProps;
  radioItemList?: RadioItemListProps;
  customAmount?: CustomAmountProps;
  tipAmount?: PriceItemProps;
  total?: PriceItemProps;
  button?: ButtonProps;
  className?: string;
  loading?: boolean;
  error?: Error;
};

const AddTipBlock: React.FC<AddTipBlockProps> = ({
  backButton,
  title,
  description,
  image,
  radioItemList,
  customAmount,
  tipAmount,
  total,
  button,
  className,
  loading,
}) => {
  const loadingIcon = loading ? (<div>Loading...</div>) : undefined;

  const tipBlock = !loading ? 
  <>
    <div className={styles.tipOptions}>
      <RadioItemList
        className={styles.radioItemList}
        {...radioItemList} />
      <CustomAmount
        className={styles.customAmount}
        {...customAmount} />
    </div>
    <div className={styles.priceItems}>
      <PriceItem
        className={styles.tipAmount}
        {...tipAmount} />
      <PriceItem
        className={styles.total}
        {...total} />
    </div>
  </> : undefined;

  return (
    <div className={cx(styles.addTipBlock, className)}>
      <Button
            className={styles.backButton}
            {...backButton} />
      <div className={styles.topContent}>
        <Text
          className={styles.title}
          {...title} />
        <Text
          className={styles.description}
          {...description} />
      </div>
      <Image
        className={styles.image}
        {...image} />
        {loadingIcon}
        {tipBlock}
      <Button
        className={styles.button}
        {...button} />
    </div>
  );
};

AddTipBlock.defaultProps = defaultProps;

export default AddTipBlock;
