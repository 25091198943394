import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomerBasket, PaymentIntent } from '../../../modules/checkout/types';
import { provinceOptions } from '../CompleteProfileBlock/utils';
import { isEmptyString, isPostalCode } from '../../../lib/utils';

import { PaymentInfoBlockProps, defaultProps } from './PaymentInfoBlock';

const COUNTRY_CODES = {
  canada: 'CA',
  //TODO confirm country codes for credit card
};

export type PaymentInfoBlockPresenterProps = PaymentInfoBlockProps & {
  updateBasket: (basket?: CustomerBasket) => void;
};

type BillingDetails = {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  province?: string;
  country: string;
};

const withPresenter = (
  View: React.FC<PaymentInfoBlockProps>
): React.FC<PaymentInfoBlockPresenterProps> => {
  const Presenter: React.FC<PaymentInfoBlockPresenterProps> = (props) => {
    const { updateBasket } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const { state } = useLocation<PaymentIntent>();
    const stripe = useStripe();
    const elements = useElements();
    const [stripeError, setStripeError] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [creditCardState, setCreditCardState] =
      useState<StripeCardElementChangeEvent>();
    const [focused, setFocusedState] = useState({
      name: false,
      addressLine1: false,
      city: false,
      postalCode: false,
      province: false,
    });

    const [formState, setFormState] = useState({
      name: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      province: '',
      country: 'Canada',
      errors: {
        name: '',
        addressLine1: '',
        city: '',
        postalCode: '',
        province: '',
        address: '',
      },
    });

    const { clientSecret, id } = state || {};

    const handleInputChanged =
      (key: keyof BillingDetails) =>
      ({ target: { value } }) => {
        setFormState((previousState) => ({ ...previousState, [key]: value }));
      };

    const handleCardChange = (stripeEvent: StripeCardElementChangeEvent) => {
      setStripeError(undefined);
      setCreditCardState(stripeEvent);
    };

    const isFormValid = (): boolean => {
      // reset focused state on every validation
      setFocusedState({
        name: false,
        addressLine1: false,
        city: false,
        postalCode: false,
        province: false,
      });
      setStripeError(undefined);

      const { name, addressLine1, city, postalCode, country, province } =
        formState;

      let nameError = '';
      if (isEmptyString(name)) {
        nameError = t('error.required', {
          field: t('payment_info.name_on_card'),
        });
      }

      let addressError = '';
      let addressLine1Error = '';
      if (isEmptyString(addressLine1)) {
        addressLine1Error = t('error.required', {
          field: t('payment_info.address_line_1'),
        });
        addressError = t('error.required', {
          field: t('payment_info.billing_address'),
        });
      }

      let cityError = '';
      if (isEmptyString(city)) {
        cityError = t('error.required', {
          field: t('payment_info.city'),
        });
        addressError = t('error.required', {
          field: t('payment_info.billing_address'),
        });
      }

      let provinceError = '';
      if (isEmptyString(province)) {
        provinceError = t('error.required', {
          field: t('payment_info.province'),
        });
        addressError = t('error.required', {
          field: t('payment_info.billing_address'),
        });
      }

      let postalCodeError = '';
      if (isEmptyString(postalCode)) {
        postalCodeError = t('error.required', {
          field: t('payment_info.postal_code'),
        });
        addressError = t('error.required', {
          field: t('payment_info.billing_address'),
        });
      } else if (!isPostalCode(postalCode)) {
        postalCodeError = t('error.format.postal_code');
        addressError = postalCodeError;
      }

      const errors = {
        name: nameError,
        addressLine1: addressLine1Error,
        city: cityError,
        province: provinceError,
        postalCode: postalCodeError,
        address: addressError,
      };
      setFormState((previousState) => ({
        ...previousState,
        errors,
      }));

      return (
        Object.values(errors).filter((errorMessage: string) => !!errorMessage)
          .length === 0
      );
    };

    const updateFormFocus = () => {
      const newFocusedState = {
        name: false,
        addressLine1: false,
        city: false,
        country: false,
        province: false,
        postalCode: false,
      };
      
      if (formState.errors.name) {
        newFocusedState.name = true;
      } else if (formState.errors.addressLine1) {
        newFocusedState.addressLine1 = true;
      } else if (formState.errors.city) {
        newFocusedState.city = true;
      } else if (formState.errors.postalCode) {
        newFocusedState.postalCode = true;
      } else if (formState.errors.province) {
        newFocusedState.province = true;
      }
      setFocusedState(newFocusedState);
    }

    useEffect(() => {
      if(formState.errors.address){
        updateFormFocus();
      }
    }, [formState.errors.address]);

    useEffect(() => {
      setFormState((previousState) => ({
        ...previousState,
        errors: {
          ...previousState.errors,
          name: formState.name ? '' : previousState.errors.name,
          addressLine1: formState.addressLine1 ? '' : previousState.errors.addressLine1,
          city: formState.city ? '' : previousState.errors.city,
          postalCode: formState.postalCode ? '' : previousState.errors.postalCode,
          province: formState.province ? '' : previousState.errors.province,
        },
      }));
      if (
        formState.name && 
        formState.addressLine1 && 
        formState.city &&
        formState.postalCode && 
        formState.province
        ) {
          isFormValid();
        }
    }, [
      formState.name,
      formState.addressLine1,
      formState.city,
      formState.postalCode,
      formState.province
    ])

    const handleSubmit = async (e) => {
      e?.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const countryCode = COUNTRY_CODES[formState.country.toLowerCase()];

      setIsLoading(true);
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!!,
          billing_details: {
            name: formState.name,
            address: {
              line1: formState.addressLine1,
              line2: formState.addressLine2,
              city: formState.city,
              postal_code: formState.postalCode,
              state: formState.province,
              country: countryCode,
            },
          },
        },
      });
      setIsLoading(false);
      if (!isFormValid()) {
        updateFormFocus();
      }
      // Payment failed
      if (result.error) {
        setStripeError(result.error.message);
      // Payment has been processed!
      } else if (result.paymentIntent.status === 'succeeded') {
        updateBasket(undefined);
        history.push(`/order/${id}`);
      }
    };

    const paymentBlockProps: PaymentInfoBlockProps = {
      ...defaultProps,
      backButton: {
        ...defaultProps.backButton,
        text: {
          ...defaultProps.backButton?.text,
          value: t('button.back'),
        },
        onButtonClicked: (e) => {
          e?.preventDefault();
          history.goBack();
        },
      },
      text: {
        ...defaultProps.text,
        value: t('payment_info.title'),
      },
      textField: {
        ...defaultProps.textField,
        state: formState.errors.name ? 'Error' : 'Default',
        label: {
          ...defaultProps.textField.label,
          value: t('payment_info.name_on_card'),
        },
        textInput: {
          ...defaultProps.textField.textInput,
          onTextChanged: handleInputChanged('name'),
          focused: focused.name,
        },
        errorText: {
          value: formState.errors.name,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      cardInfo: {
        ...defaultProps.cardInfo,
        label: {
          ...defaultProps.cardInfo.label,
          value: t('payment_info.card_info'),
        },
        cardElementOptions: {
          iconStyle: 'solid',
          hidePostalCode: true,
          style: {
            base: {
              color: '#000000',
              fontFamily: 'Inter, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#747474',
              },
            },
            invalid: {
              color: '#B73831',
              iconColor: '#B73831',
            },
          },
        },
        error: stripeError
          ? {
              type: 'Body2',
              align: 'Left',
              style: 'Red800',
              value: stripeError,
            }
          : undefined,
        handleChange: handleCardChange,
      },
      address: {
        ...defaultProps.address,
        addressLine1: {
          ...defaultProps.address.addressLine1,
          state: formState.errors.addressLine1 ? 'Error' : 'Default',
          label: {
            ...defaultProps.address.addressLine1?.label,
            value: t('payment_info.billing_address'),
          },
          textInput: {
            ...defaultProps.address.addressLine1?.textInput,
            textPlaceholder: t('payment_info.address_line_1'),
            textValue: formState.addressLine1,
            onTextChanged: handleInputChanged('addressLine1'),
            focused: focused.addressLine1,
          },
        },
        addressLine2: {
          ...defaultProps.address.addressLine2,
          textPlaceholder: t('payment_info.address_line_2'),
          textValue: formState.addressLine2,
          onTextChanged: handleInputChanged('addressLine2'),
        },
        city: {
          ...defaultProps?.address?.city,
          state: formState.errors.city ? 'Error' : 'Default',
          textInput: {
            ...defaultProps.address.addressLine1?.textInput,
            textPlaceholder: t('payment_info.city'),
            textValue: formState.city,
            onTextChanged: handleInputChanged('city'),
            focused: focused.city,
          },
        },
        postalCode: {
          ...defaultProps?.address?.postalCode,
          state: formState.errors.postalCode ? 'Error' : 'Default',
          textInput: {
            ...defaultProps?.address?.postalCode?.textInput,
            onTextChanged: handleInputChanged('postalCode'),
            textValue: formState.postalCode,
            textPlaceholder: t('payment_info.postal_code'),
            focused: focused.postalCode,
          },
        },
        province: {
          ...defaultProps?.address?.province,
          state: formState.errors.province ? 'Error' : 'Default',
          select: {
            options: provinceOptions,
            onSelectChanged: handleInputChanged('province'),
            value: formState.province,
            selectPlaceholder: t('payment_info.province'),
            focused: focused.province,
          },
        },
        country: {
          ...defaultProps.address.country,
          textPlaceholder: t('payment_info.country'),
          textValue: formState.country,
        },
        state: formState.errors.address ? 'Error' : 'Default',
        errorText: {
          value: formState.errors.address,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      button: {
        ...defaultProps.button,
        text: {
          ...defaultProps.button.text,
          value: t('payment_info.submit'),
        },
        disabled: isLoading,
      },
      handleSubmit: handleSubmit,
    };

    return <View {...props} {...paymentBlockProps} />;
  };
  return Presenter;
};

export default withPresenter;
