import React, {
  MutableRefObject,
  useRef, useState,
} from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router-dom';
import styles from './SimpleLayout.module.scss';
import TopNav, { TopNavProps } from '../../organisms/TopNav';
import StartBlockPage, { StartBlockPageProps } from '../../pages/StartBlockPage';
import EmailSignInPage, { EmailSignInPageProps } from '../../pages/EmailSignInPage';
import EmailSignUpPage, { EmailSignUpPageProps } from '../../pages/EmailSignUpPage';
import CompleteProfilePage, { CompleteProfilePageProps } from '../../pages/CompleteProfilePage';
import ForgotPasswordPage, { ForgotPasswordPageProps } from '../../pages/ForgotPasswordPage';
import CreatePasswordPage, { CreatePasswordPageProps } from '../../pages/CreatePasswordPage';
import ActionCompletePage, { ActionCompletePageProps } from '../../pages/ActionCompletePage';
import ProtectedRoute from '../../../modules/auth/ProtectedRoute';
import RestaurantListPage from '../../pages/RestaurantListPage';
import RestaurantPage from '../../pages/RestaurantPage';
import CheckoutPricePage from '../../pages/CheckoutPricePage';
import PaymentInfoPage from '../../pages/PaymentInfoPage';
import AddTipPage from '../../pages/AddTipPage';
import OrderStatusPage from '../../pages/OrderStatusPage';
import CartModal from '../../block/CartModal';
import AddressModalBlock from '../../block/AddressModalBlock';
import ShareSheetModalBlock from '../../block/ShareSheetModalBlock';
import { IPosition, useScrollPosition } from '../../../lib/use-scroll-position/src';
import ProfilePage from '../../pages/ProfilePage';
import ProfileMenuModal from '../../block/ProfileMenuModal';
import HelpPage from '../../pages/HelpPage';
import ChangePasswordPage from '../../pages/ChangePasswordPage';

export const defaultProps = {
  topNav: {
    Style: 'WithCart',
    logoDark: {
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as TopNavProps,
  startBlockPage: {
    startBlock: {
      title: {
        type: 'Title1',
        align: 'Left',
        style: 'Grey1100',
      },
      description: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      googleButton: {
        size: 'Medium',
        type: 'IconText',
        style: 'GoogleSquare',
        icon: {
          asset: 'Google',
          style: 'Grey1100',
        },
        text: {
          type: 'Button1',
          align: 'Center',
          style: 'Grey1100',
        },
      },
      facebookButton: {
        size: 'Medium',
        type: 'IconText',
        style: 'FacebookSquare',
        icon: {
          asset: 'Facebook',
          style: 'Grey1100',
        },
        text: {
          type: 'Button1',
          align: 'Center',
          style: 'Grey1100',
        },
      },
      dividerSection: {
        divider: {
          type: 'HorizontalDotted',
          style: 'Grey500',
        },
        text: {
          type: 'Body1',
          align: 'Left',
          style: 'Grey700',
        },
      },
      button: {
        size: 'Medium',
        type: 'Text',
        style: 'BrandSquare',
        text: {
          type: 'Button1',
          align: 'Center',
          style: 'Grey1100',
        },
      },
      secondaryAction: {
        title: {
          type: 'Title4',
          align: 'Center',
          style: 'Grey1100',
        },
        link: {
          type: 'Body2',
          align: 'Center',
          style: 'Green800',
        },
      },
    },
  } as StartBlockPageProps,
};

export type SimpleLayoutProps = {
  topNav?: TopNavProps;
  startBlockPage?: StartBlockPageProps;
  emailSignInPage?: EmailSignInPageProps;
  emailSignUpPage?: EmailSignUpPageProps;
  forgotPasswordPage?: ForgotPasswordPageProps;
  completeProfilePage?: CompleteProfilePageProps;
  createPasswordPage?: CreatePasswordPageProps;
  ActionCompletePage?: ActionCompletePageProps;
  className?: string;
};

const SimpleLayout: React.FC<SimpleLayoutProps> = ({
  className,
}) => {
  const [cartOpen, setCartOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<IPosition>({ x: 0, y: 0 });
  const restaurantListPageRef = useRef<HTMLDivElement>(null);
  const restaurantListBlockRef = useRef<HTMLDivElement>(null);

  useScrollPosition(
    ({ currPos }): void => {
      setScrollPosition && setScrollPosition(currPos);
    },
    undefined,
    restaurantListBlockRef as MutableRefObject<HTMLDivElement>,
    false,
    0,
    restaurantListPageRef as MutableRefObject<HTMLDivElement>,
  );
  
  const handleCartClick = (): void => {
    setCartOpen(true);
  };

  const openMenu = (): void => {
    setIsMenuOpen(true);
  };

  const closeMenu = (): void => {
    setIsMenuOpen(false);
  };

  const closeCart = (): void => {
    setCartOpen(false);
  };

  const openShareModal = (): void => {
    setShareModalOpen(true);
  };

  const closeShareModal = (): void => {
    setShareModalOpen(false);
  };

  const openAddressModal = (): void => {
    setModalOpen(true);
  };

  const closeAddressModal = (): void => {
    setIsChangeAddress(false);
    setModalOpen(false);
  };

  const updateSkipToAddressChange = (isSkipToChange: boolean): void => {
    setIsChangeAddress(isSkipToChange);
  };

  return (
    <>
      <div className={cx(styles.simpleLayout, className)}>
        <TopNav
          className={styles.topNav}
          handleCartClick={handleCartClick}
          handleDropdownToggleClick={openAddressModal}
          handleMenuClick={openMenu}
          setScrollPosition={setScrollPosition}
          restaurantListPageRef={restaurantListPageRef}
          restaurantListBlockRef={restaurantListBlockRef}
          scrollPosition={scrollPosition} />
        <div className={styles.body}>
          <div className={styles.bodyContent}>
            <Switch>
              <Route exact path='/signIn'>
                <StartBlockPage
                  type='signIn'
                  className={styles.startBlockPage} />
              </Route>
              <Route exact path='/emailSignIn'>
                <EmailSignInPage
                  className={styles.startBlockPage} />
              </Route>
              <Route exact path='/forgotPassword'>
                <ForgotPasswordPage
                  className={styles.startBlockPage} />
              </Route>
              <Route exact path='/resetSent'>
                <ActionCompletePage
                  className={styles.startBlockPage}
                  actionType='PasswordReset' />
              </Route>
              <ProtectedRoute exact path='/updateSent'>
                <ActionCompletePage
                  className={styles.startBlockPage}
                  actionType='UpdatePassword' />
              </ProtectedRoute>
              <Route exact path='/createPassword'>
                <CreatePasswordPage
                  className={styles.startBlockPage} />
              </Route>

              <Route exact path='/signUp'>
                <StartBlockPage
                  type='signUp'
                  className={styles.startBlockPage} />
              </Route>
              <Route exact path='/emailSignUp'>
                <EmailSignUpPage
                  className={styles.startBlockPage} />
              </Route>
              <Route exact path='/verifyEmail'>
                <ActionCompletePage
                  className={styles.startBlockPage}
                  actionType='VerifyEmail' />
              </Route>

              <ProtectedRoute exact path='/completeProfile'>
                <CompleteProfilePage
                  className={styles.startBlockPage} />
              </ProtectedRoute>

              <ProtectedRoute exact path='/checkout/payment'>
                <PaymentInfoPage
                  className={styles.startBlockPage} />
              </ProtectedRoute>
              <ProtectedRoute exact path='/checkout/tip'>
                <AddTipPage
                className={styles.startBlockPage} />
              </ProtectedRoute>
              <ProtectedRoute path='/checkout'>
                <CheckoutPricePage
                  className={styles.startBlockPage}
                  handleDropdownToggleClick={openAddressModal} />
              </ProtectedRoute>

              <ProtectedRoute path='/restaurant/:restaurantId'>
                <RestaurantPage className={styles.startBlockPage}/>
              </ProtectedRoute>

              <ProtectedRoute path='/order/:orderId'>
                <OrderStatusPage className={styles.startBlockPage} openShareModal={openShareModal} />
              </ProtectedRoute>

              <ProtectedRoute exact path='/profile'>
                <ProfilePage
                  className={styles.startBlockPage} />
              </ProtectedRoute>

              <ProtectedRoute exact path='/help'>
                <HelpPage className={styles.startBlockPage} />
              </ProtectedRoute>

              <ProtectedRoute exact path='/profile/changePassword'>
                <ChangePasswordPage
                  className={styles.startBlockPage} />
              </ProtectedRoute>

              <ProtectedRoute path='/'>
                <RestaurantListPage
                  className={styles.startBlockPage}
                  restaurantListPageRef={restaurantListPageRef}
                  restaurantListBlockRef={restaurantListBlockRef}
                  handleChangeAddress={openAddressModal}
                  updateSkipToAddressChange={updateSkipToAddressChange}
                  scrollPosition={scrollPosition}
                   />
              </ProtectedRoute>
            </Switch>
          </div>
        </div>
      </div>
      <CartModal
        open={cartOpen}
        onClose={closeCart}/>
      <ShareSheetModalBlock
        open={shareModalOpen}
        onClose={closeShareModal}/>
      <AddressModalBlock
        open={modalOpen}
        isChangeAddress={isChangeAddress}
        updateSkipToAddressChange={updateSkipToAddressChange}
        onClose={closeAddressModal}/>
      <ProfileMenuModal
        open={isMenuOpen}
        onClose={closeMenu}
        onCartOpen={handleCartClick}
      />
      
    </>
  );
};

SimpleLayout.defaultProps = defaultProps;

export default SimpleLayout;
