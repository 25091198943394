import React, { RefObject, Suspense } from 'react';
import cx from 'classnames';

import styles from './RestaurantListPage.module.scss';

import RestaurantListBlock, { RestaurantListBlockProps } from '../../block/RestaurantListBlock';
import { IPosition } from '../../../lib/use-scroll-position/src';
import OrderInProgressBanner, { OrderInProgressBannerProps } from '../../organisms/OrderInProgressBanner';

export type RestaurantPageStyle = 'Default' | 'Compact';

export const defaultProps = {
  restaurantPageStyle: 'Default' as RestaurantPageStyle,
  restaurantListBlock: {
    blockTitle: {
      type: 'Title5',
      align: 'Left',
      style: 'Grey1100',
    },
    restaurantCardList: {
      restaurantCards: [
      ],
    },
  } as RestaurantListBlockProps,
};

export type RestaurantListPageProps = {
  restaurantListBlock?: RestaurantListBlockProps;
  className?: string;
  restaurantListPageRef?: RefObject<HTMLDivElement>;
  restaurantListBlockRef?: RefObject<HTMLDivElement>;
  handleChangeAddress?: () => void;
  updateSkipToAddressChange?: (isSkipToChange: boolean) => void;
  scrollPosition?: IPosition;
  restaurantPageStyle?: RestaurantPageStyle;
  pendingOrder?: boolean;
  orderInProgressBanner?: OrderInProgressBannerProps;
};

const RestaurantListPage: React.FC<RestaurantListPageProps> = ({
  restaurantListBlock,
  className,
  restaurantListPageRef,
  // TODO: remove when delivery is implemented
  handleChangeAddress,
  restaurantListBlockRef,
  updateSkipToAddressChange,
  restaurantPageStyle,
  pendingOrder,
  orderInProgressBanner,
}) => (
    <div className={cx(styles.restaurantListPage, className, styles[restaurantPageStyle || 'Default'])} ref={restaurantListPageRef}>
      <RestaurantListBlock
          className={styles.restaurantListBlock}
          {...restaurantListBlock}
          handleChangeAddress={handleChangeAddress}
          restaurantListBlockRef={restaurantListBlockRef}
          updateSkipToAddressChange={updateSkipToAddressChange}
      />
      { pendingOrder ? <OrderInProgressBanner {...orderInProgressBanner}/> : ''}
    </div>
);

RestaurantListPage.defaultProps = defaultProps;

export default RestaurantListPage;
