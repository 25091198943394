import React from 'react';
import cx from 'classnames';

import styles from './TextField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextInput, { TextInputProps } from '../../atoms/TextInput';

export type TextFieldStateType = 'Default' | 'Error' | 'Disabled';
export type TextFieldTypeType = 'Text' | 'Password' | 'TextArea';

export const defaultProps = {
  state: 'Default' as TextFieldStateType,
  type: 'TextArea' as TextFieldTypeType,
  label: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  textInput: {
    type: 'TextArea',
    style: 'Default',
  } as TextInputProps,
  errorText: {
    type: 'Body2',
    align: 'Left',
    style: 'Red800',
  } as TextProps,
  helpText: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
};

export type TextFieldProps = {
  state?: TextFieldStateType;
  type?: TextFieldTypeType;
  label?: TextProps;
  textInput?: TextInputProps;
  className?: string;
  errorText?: TextProps;
  helpText?: TextProps;
};

const TextField: React.FC<TextFieldProps> = ({
  state,
  type,
  label,
  textInput,
  className,
  errorText,
  helpText,
}) => {
  const currentStyle = styles[`textField${state}${type}`];

  const labelView = label?.value ? (
    <Text className={styles.label} {...label} />
  ) : undefined;

  const textInputView = (
    <TextInput className={styles.textInput} {...textInput} />
  );

  let errorTextView;
  let helpTextView;

  switch (`${state}${type}`) {
    case 'DefaultText':
      break;
    case 'ErrorText':
      errorTextView = <Text className={styles.errorText} {...errorText} />;
      break;
    case 'DefaultPassword':
      break;
    case 'ErrorPassword':
      errorTextView = <Text className={styles.errorText} {...errorText} />;
      break;
    case 'DefaultTextArea':
      helpTextView = <Text className={styles.helpText} {...helpText} />;
      break;
    case 'ErrorTextArea':
      errorTextView = <Text className={styles.errorText} {...errorText} />;
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {labelView}
      {textInputView}
      {errorTextView}
      {helpTextView}
    </div>
  );
};

TextField.defaultProps = defaultProps;

export default TextField;
