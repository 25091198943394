import React from 'react';
import cx from 'classnames';

import styles from './TopContent.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  title: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
};

export type TopContentProps = {
  title?: TextProps;
  className?: string;
};

const TopContent: React.FC<TopContentProps> = ({
  title,
  className,
}) => {
  return (
    <div className={cx(styles.topContent, className)}>
      <Text
        className={styles.title}
        {...title} />
    </div>
  );
};

TopContent.defaultProps = defaultProps;

export default TopContent;
