import React from 'react';
import cx from 'classnames';

import styles from './PaymentInfoBlock.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import CardInfo, { CardInfoProps } from '../../molecules/CardInfo';
import Address, { AddressProps } from '../../molecules/Address';

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  text: {
    type: 'Title2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  textField: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  cardInfo: {
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
  } as CardInfoProps,
  address: {
    addressLine1: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    addressLine2: {
      type: 'Text',
      style: 'Default',
    },
    city: {
      type: 'Text',
      style: 'Default',
    },
    postalCode: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    province: {
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      icon: {
        asset: 'ChevronDown',
        style: 'Grey1100',
      },
    },
    country: {
      type: 'Text',
      style: 'Default',
    },
  } as AddressProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type PaymentInfoBlockProps = {
  backButton?: ButtonProps;
  text?: TextProps;
  textField?: TextFieldProps;
  cardInfo?: CardInfoProps;
  address?: AddressProps;
  addressError?: TextProps;
  button?: ButtonProps;
  handleSubmit?: (event?: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
};

const PaymentInfoBlock: React.FC<PaymentInfoBlockProps> = ({
  backButton,
  text,
  textField,
  cardInfo,
  address,
  addressError,
  button,
  handleSubmit,
  className,
}) => {
  return (
    <form
      className={cx(styles.paymentInfoBlock, className)}
      onSubmit={handleSubmit}
    >
      <div className={styles.content}>
        <div className={styles.topContent}>
          <Button className={styles.backButton} {...backButton} />
          <Text className={styles.text} {...text} />
          <TextField className={styles.textField} {...textField} />
          <CardInfo className={styles.cardInfo} {...cardInfo} />
          <Address className={styles.address} {...address} />
        </div>

        <Button className={styles.button} {...button} buttonType='submit' />
      </div>
    </form>
  );
};

PaymentInfoBlock.defaultProps = defaultProps;

export default PaymentInfoBlock;
