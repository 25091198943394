import React from 'react';
import cx from 'classnames';

import styles from './PriceISectionItemList.module.scss';

import PriceSectionItem, { PriceSectionItemProps } from '../PriceSectionItem';

export const defaultProps = {
  priceSectionItems: [
  ] as PriceSectionItemProps[],
};

export type PriceISectionItemListProps = {
  priceSectionItems?: PriceSectionItemProps[];
  className?: string;
};

const PriceISectionItemList: React.FC<PriceISectionItemListProps> = ({
  priceSectionItems,
  className,
}) => {
  const priceSectionItemArray = priceSectionItems?.map((priceSectionItem, i) => (
    <PriceSectionItem key={i}
      className={styles.priceSectionItem}
      {...priceSectionItem} />
  ));
  
  return (
    <div className={cx(styles.priceISectionItemList, className)}>
      {priceSectionItemArray}
    </div>
  );
};

PriceISectionItemList.defaultProps = defaultProps;

export default PriceISectionItemList;
