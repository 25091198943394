import React, { useContext, useEffect } from "react";
import { OrderTypeSessionStoragekey } from "../../../lib/constants";
import { AuthContext } from "../../../modules/auth";
import { CheckoutContext } from "../../../modules/checkout";
import { OrderType } from "../../../modules/checkout/types";
import { TopNavProps } from "./TopNav";
import { TopNavPresenterProps } from "./TopNav.presenter";


const withInteractor = (
    Presenter: React.FC<TopNavPresenterProps>,
): React.FC<TopNavPresenterProps> => {
    const Interactor: React.FC<TopNavProps> = (props) => {
        const { basket, orderType, updateOrderType } = useContext(CheckoutContext);
        const { account } = useContext(AuthContext);
              
        useEffect(() => {
            if (window.sessionStorage.getItem(OrderTypeSessionStoragekey)) {
                updateOrderType(window.sessionStorage.getItem(OrderTypeSessionStoragekey) as OrderType);
            }
        }, [])
        
        return <Presenter 
            {...props}
            basket={basket}
            orderType={orderType}
            updateOrderType={updateOrderType}
            account={account} />
    };
    return Interactor;
};

export default withInteractor;