import React from 'react';
import cx from 'classnames';

import styles from './ToggleItem.module.scss';

import Text, { TextProps } from '../Text';

export type ToggleItemStateType = 'Unselected' | 'Selected';

export const defaultProps = {
  state: 'Selected' as ToggleItemStateType,
  text: {
    type: 'Button2',
    align: 'Center',
    style: 'Grey100',
  } as TextProps,
};

export type ToggleItemProps = {
  state?: ToggleItemStateType;
  text?: TextProps;
  className?: string;
  onClick?: () => void;
};

const ToggleItem: React.FC<ToggleItemProps> = ({
  state,
  text,
  className,
  onClick,
}) => {

  const currentStyle = styles[`toggleItem${state}`];
  switch (state) {
    case 'Unselected':
      text = {
        ...text,
        style: 'Grey1100',
      };
      break;
    case 'Selected':
      text = {
        ...text,
        style: 'Grey1100',
      };
      break;
  }

  const textView = (
    <Text
      className={styles.text}
      {...text} />
  );

  return (
    <div className={cx(currentStyle, className)} onClick={onClick}>
      {textView}
    </div>
  );
};

ToggleItem.defaultProps = defaultProps;

export default ToggleItem;
