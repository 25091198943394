import React from 'react';
import cx from 'classnames';

import styles from './CopyLinkField.module.scss';

import Icon, { IconProps } from '../../atoms/Icon';
import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  icon: {
    asset: 'Link',
    style: 'Grey800',
  } as IconProps,
  link: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey800',
  } as TextProps,
  copyText: {
    type: 'Button1',
    align: 'Center',
    style: 'Purple800',
  } as TextProps,
};

export type CopyLinkFieldProps = {
  icon?: IconProps;
  link?: TextProps;
  copyText?: TextProps;
  className?: string;
};

const CopyLinkField: React.FC<CopyLinkFieldProps> = ({
  icon,
  link,
  copyText,
  className,
}) => {
  return (
    <div className={cx(styles.copyLinkField, className)}>
      <div className={styles.linkContent}>
        <Icon
          className={styles.icon}
          {...icon} />
        <Text
          className={styles.link}
          {...link} />
      </div>
      <Text
        className={styles.copyText}
        {...copyText} />
    </div>
  );
};

CopyLinkField.defaultProps = defaultProps;

export default CopyLinkField;
