import React from 'react';
import cx from 'classnames';

import styles from './OrderStatusBlock.module.scss';

import OrderTracker, { OrderTrackerProps } from '../../organisms/OrderTracker';
import { OrderStatusEnum } from '../../../modules/checkout/types';
import SpinningIcon from '../../atoms/SpinningIcon';
import Text, { TextProps } from '../../atoms/Text';
import CelebratoryBlock, { CelebratoryBlockProps } from '../CelebratoryBlock';

export const defaultProps = {
  orderTracker: {
    State: 'Error',
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
    notification: {
      type: 'Default',
      style: 'Blue',
      title: {
        type: 'Title4',
        align: 'Left',
        style: 'Grey1100',
      },
      divider: {
        type: 'HorizontalSolid',
        style: 'Blue200',
      },
      description: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey1100',
      },
      phoneNumber: {
        type: 'Body2',
        align: 'Left',
        style: 'Blue800',
      },
    },
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    estimatedTime: {
      labelText: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey800',
      },
      contentText: {
        type: 'Body5',
        align: 'Left',
        style: 'Grey800',
      },
    },
    orderPrice: {
      labelText: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey800',
      },
      contentText: {
        type: 'Body5',
        align: 'Left',
        style: 'Grey800',
      },
    },
    orderNumber: {
      labelText: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey800',
      },
      contentText: {
        type: 'Body5',
        align: 'Left',
        style: 'Grey800',
      },
    },
    deliveryAddress: {
      labelText: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey800',
      },
      contentText: {
        type: 'Body5',
        align: 'Left',
        style: 'Grey800',
      },
    },
    orderStepper: {
      stepItem: {
        state: 'Incomplete',
        type: 'First',
        orderProgressItem: {
          state: 'None',
        },
        stepIndicator: {
          asset: 'Inprogress',
        },
        text: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey1100',
        },
      },
    },
    orderAccordian: {
      state: 'Default',
      titleText: {
        type: 'Title3',
        align: 'Left',
        style: 'Grey1100',
      },
      text: {
        type: 'Button2',
        align: 'Center',
        style: 'Green800',
      },
      icon: {
        asset: 'ChevronUp',
        style: 'Green800',
      },
    },
  } as OrderTrackerProps,
};

export type OrderStatusBlockProps = {
  orderTracker?: OrderTrackerProps;
  className?: string;
  loading?: boolean;
  orderStatus?: OrderStatusEnum;
  description?: TextProps;
  error?: Error;
  celebratoryBlock?: CelebratoryBlockProps;
  celebratorySectionShown?: boolean;
  openShareModal?: () => void;
};

const OrderStatusBlock: React.FC<OrderStatusBlockProps> = ({
  orderTracker,
  className,
  loading,
  orderStatus,
  celebratoryBlock,
  celebratorySectionShown,
}) => {
  const loadingIcon = loading ? <div>Loading...</div> : undefined;
  const orderStatusContent = (
    <OrderTracker
        className={styles.orderTracker}
        {...orderTracker} />
  );
  const orderPendingContent = (
    <div>
      <SpinningIcon />
      <Text  {...orderTracker?.description}/>
    </div>
  );
  let content;
  if(loading) {
    content = loadingIcon;
  } else if (orderStatus === OrderStatusEnum.pending) {
    content = orderPendingContent;
  } else if (!celebratorySectionShown) {
    content = (<CelebratoryBlock 
      className={styles.celebratoryBlock}
      {...celebratoryBlock}/>)
  } else {
    content = orderStatusContent;
  }

  return (
    <div className={cx(styles.orderStatusBlock, className)}>
      { content }
    </div>
  )
};

OrderStatusBlock.defaultProps = defaultProps;

export default OrderStatusBlock;