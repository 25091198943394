import React, { useState } from 'react';
import { MenuItem } from '../../../modules/checkout/types';
import { CartModalProps } from './CartModal';

export type CartModalPresenterProps = CartModalProps & {};

const withPresenter = (
  View: React.FC<CartModalProps>,
): React.FC<CartModalPresenterProps> => {
  const Presenter: React.FC<CartModalPresenterProps> = (props) => {
    const { onClose } = props;
    const [modalType, setModalType] = useState<string | null>('overview');
    const [itemToChange, setItemToChange] = useState<MenuItem>();
    const [itemToChangePosition, setItemToChangePosition] = useState(0);

    const setItem = (item: MenuItem, position: number): void => {
      setItemToChange(item);
      setItemToChangePosition(position);
    };

    const changeCartView = (modalType?: string | null) => {
      if (!modalType) {
        setModalType(null);
      } else {
        setModalType(modalType);
      }
    };

    const onCloseOutsideModal = (): void => {
      changeCartView('overview');
      onClose();
    }

    return (
      <View
        {...props}
        changeCartView={changeCartView}
        itemToChange={itemToChange}
        itemToChangePosition={itemToChangePosition}
        setItem={setItem}
        modalType={modalType}
        onCloseOutsideModal={onCloseOutsideModal}
      />
    );
  };
  return Presenter;
};

export default withPresenter;
