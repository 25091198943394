import React from 'react';
import cx from 'classnames';

import styles from './LogoDark.module.scss';

import ContentAsset from '../../../resources/icons/LogoDark.svg';

export const defaultProps = {
  content: ContentAsset,
};

export type LogoDarkProps = {
  content?: string;
  contentAlt?: string;
  className?: string;
};

const LogoDark: React.FC<LogoDarkProps> = ({
  content,
  contentAlt,
  className,
}) => {
  return (
    <a className={cx(styles.logoDark, className)} href='/'>
      <img className={styles.content} alt={contentAlt} src={content} />
    </a>
  );
};

LogoDark.defaultProps = defaultProps;

export default LogoDark;
