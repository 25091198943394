import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import styles from './EmailSignUpBlock.module.scss';
import { EmailSignUpBlockProps, defaultProps } from './EmailSignUpBlock';
import { isEmptyString, isPassword, isEmail } from '../../../lib/utils';
import { AccountResponse, SignUpPayload } from '../../../modules/account/types';
import {
  ToastNotificationProps,
  defaultProps as toastNotificationDefaultProps,
} from '../../molecules/ToastNotification/ToastNotification';
import { ApiError } from '../../../lib/api/types';

export type EmailSignUpPresenterProps = EmailSignUpBlockProps & {
  doSignUp: (payload: SignUpPayload) => Promise<AccountResponse>;
  isLoading?: boolean;
};

const withPresenter = (
  View: React.FC<EmailSignUpBlockProps>
): React.FC<EmailSignUpPresenterProps> => {
  const Presenter: React.FC<EmailSignUpPresenterProps> = (props) => {
    const { doSignUp } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const [error, setError] = useState('');
    const [errorCode, setErrorCode] = useState(0);
    const [showCreatePassword, setShowCreatePassword] =
      useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] =
      useState<boolean>(false);

    const [formState, setFormState] = useState({
      firstName: '',
      lastName: '',
      email: '',
      createPassword: '',
      confirmPassword: '',
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        createPassword: '',
        confirmPassword: '',
      },
    });

    const [focused, setFocusedState] = useState({
      firstName: false,
      lastName: false,
      email: false,
      createPassword: false,
      confirmPassword: false,
    });

    const handleFirstName = ({ target: { value } }): void => {
      setFormState((previousState) => ({
        ...previousState,
        firstName: value,
      }));
    };

    const handleLastName = ({ target: { value } }): void => {
      setFormState((previousState) => ({ ...previousState, lastName: value }));
    };

    const handleEmail = ({ target: { value } }): void => {
      setFormState((previousState) => ({ ...previousState, email: value }));
    };

    const handlePassword = ({ target: { value } }): void => {
      setFormState((previousState) => ({
        ...previousState,
        createPassword: value,
      }));
    };

    const handleConfirmPassword = ({ target: { value } }): void => {
      setFormState((previousState) => ({
        ...previousState,
        confirmPassword: value,
      }));
    };

    const isFormValid = (): boolean => {
      // reset focused state on every validation
      setFocusedState({
        firstName: false,
        lastName: false,
        email: false,
        createPassword: false,
        confirmPassword: false,
      });

      const { firstName, lastName, email, createPassword, confirmPassword } =
        formState;

      let confirmPasswordError = '';
      if (isEmptyString(confirmPassword)) {
        confirmPasswordError = t('error.required', {
          field: t('textLabels.confirm_password'),
        });
      } else if (createPassword !== confirmPassword) {
        confirmPasswordError = t('error.password_do_not_match');
      }

      let createPasswordError = '';
      if (isEmptyString(createPassword)) {
        createPasswordError = t('error.required', {
          field: t('textLabels.create_password'),
        });
      } else if (!isPassword(createPassword)) {
        createPasswordError = t('error.password_length');
      }

      let emailError = '';
      if (isEmptyString(email)) {
        emailError = t('error.required', { field: t('textLabels.email') });
      } else if (!isEmail(email)) {
        emailError = t('error.format.email');
      }

      const lastNameError = isEmptyString(lastName)
        ? t('error.required', { field: t('textLabels.last_name') })
        : '';

      const firstNameError = isEmptyString(firstName)
        ? t('error.required', { field: t('textLabels.first_name') })
        : '';

      const errors = {
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError,
        createPassword: createPasswordError,
        confirmPassword: confirmPasswordError,
      };

      setFormState((previousState) => ({
        ...previousState,
        errors,
      }));

      return (
        Object.values(errors).filter((errorMessage: string) => !!errorMessage)
          .length === 0
      );
    };

    const handleSignUp = async (): Promise<void> => {
      if (isFormValid()) {
        const { firstName, lastName, email, createPassword } = formState;

        try {
          await doSignUp({
            firstName,
            lastName,
            email,
            password: createPassword,
            enabled: true,
          });
        } catch (e) {
          if (ApiError.isApiError(e) && e && e.message) {
            setError(e.message);
            setErrorCode(e.code);
            if (e.code === 409) {
              setError(t('emailSignUp.email_already_exists'));
            } else {
              history.push('/verifyEmail', { email });
            }
          } else {
            setError(t('error.default'));
          }
        }
      }
    };

    const toastNotificationProps: ToastNotificationProps = {
      ...toastNotificationDefaultProps,
      text: {
        ...toastNotificationDefaultProps.text,
        value: error,
      },
      show: errorCode === 409,
      onClose: () => setErrorCode(0),
    };

    useEffect(() => {
      const newFocusedState = {
        firstName: false,
        lastName: false,
        email: false,
        createPassword: false,
        confirmPassword: false,
      };

      if (formState.errors.firstName) {
        newFocusedState.firstName = true;
      } else if (formState.errors.lastName) {
        newFocusedState.lastName = true;
      } else if (formState.errors.email) {
        newFocusedState.email = true;
      } else if (formState.errors.createPassword) {
        newFocusedState.createPassword = true;
      } else if (formState.errors.confirmPassword) {
        newFocusedState.confirmPassword = true;
      }

      setFocusedState(newFocusedState);
    }, [formState.errors]);

    const emailSignUpBlockProps: EmailSignUpBlockProps = {
      ...defaultProps,
      backButton: {
        ...defaultProps.backButton,
        text: {
          ...defaultProps.backButton?.text,
          value: t('button.back'),
        },
        onButtonClicked: (): void => {
          history.goBack();
        },
      },
      title: {
        ...defaultProps.title,
        value: t('emailSignUp.create_account_email_text'),
      },
      description: {
        ...defaultProps.description,
        value: (
          <Trans i18nKey='emailSignUp.create_account_description'
            components={{ 
              span: <span className={styles.boldedDescription}></span>,
            }}
          />
        ),
      },
      firstName: {
        ...defaultProps.firstName,
        label: {
          ...defaultProps.firstName?.label,
          value: t('textLabels.first_name'),
        },
        state: formState.errors.firstName ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.firstName?.textInput,
          textValue: formState.firstName,
          onTextChanged: handleFirstName,
          focused: focused.firstName,
        },
        errorText: {
          ...defaultProps.firstName.errorText,
          value: formState.errors.firstName,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      lastName: {
        ...defaultProps.lastName,
        label: {
          ...defaultProps.lastName?.label,
          value: t('textLabels.last_name'),
        },
        state: formState.errors.lastName ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.lastName?.textInput,
          textValue: formState.lastName,
          onTextChanged: handleLastName,
          focused: focused.lastName,
        },
        errorText: {
          ...defaultProps.lastName.errorText,
          value: formState.errors.lastName,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      email: {
        ...defaultProps.email,
        label: {
          ...defaultProps.email?.label,
          value: t('textLabels.email'),
        },
        state: formState.errors.email ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.email?.textInput,
          textValue: formState.email,
          onTextChanged: handleEmail,
          focused: focused.email,
        },
        errorText: {
          ...defaultProps.email.errorText,
          value: formState.errors.email,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      createPassword: {
        ...defaultProps.createPassword,
        state: formState.errors.createPassword ? 'Error' : 'Default',
        label: {
          ...defaultProps.createPassword?.label,
          value: t('textLabels.create_password'),
        },
        textInput: {
          ...defaultProps.createPassword?.textInput,
          type: 'Password',
          showPassword: showCreatePassword,
          onTextChanged: handlePassword,
          textValue: formState.createPassword,
          focused: focused.createPassword,
          icon: {
            ...defaultProps.createPassword?.textInput?.icon,
            asset: showCreatePassword ? 'Hide' : 'Show',
            onClick: (): void => {
              setShowCreatePassword(!showCreatePassword);
            },
          },
        },
        errorText: {
          ...defaultProps.confirmPassword?.errorText,
          value: formState.errors.createPassword,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      confirmPassword: {
        ...defaultProps.confirmPassword,
        state: formState.errors.confirmPassword ? 'Error' : 'Default',
        label: {
          ...defaultProps.confirmPassword?.label,
          value: t('textLabels.confirm_password'),
        },
        textInput: {
          ...defaultProps.confirmPassword?.textInput,
          type: 'Password',
          showPassword: showConfirmPassword,
          onTextChanged: handleConfirmPassword,
          textValue: formState.confirmPassword,
          focused: focused.confirmPassword,
          icon: {
            ...defaultProps.confirmPassword?.textInput?.icon,
            asset: showConfirmPassword ? 'Hide' : 'Show',
            onClick: (): void => {
              setShowConfirmPassword(!showConfirmPassword);
            },
          },
        },
        errorText: {
          ...defaultProps.confirmPassword?.errorText,
          value: formState.errors.confirmPassword,
          style: 'Red800',
          align: 'Left',
          type: 'Body2',
        },
      },
      button: {
        ...defaultProps.button,
        onButtonClicked: handleSignUp,
        text: {
          ...defaultProps.button?.text,
          value: t('textLabels.create_account'),
        },
      },
      toast: toastNotificationProps,
      toastMessage: errorCode === 409,
    };

    return <View {...props} {...emailSignUpBlockProps} />;
  };
  return Presenter;
};

export default withPresenter;
