import React, { useRef } from 'react';
import cx from 'classnames';

import styles from './TabList.module.scss';

import Tab, { TabProps } from '../../atoms/Tab';
import { MenuResponseObject } from '../../../modules/menu/types';

export const defaultProps = {
  tabs: [
  ] as TabProps[],
};

export type TabListProps = {
  tabs?: TabProps[];
  data?: MenuResponseObject[];
  className?: string;
  focusedBlockId?: string;
  scrolledBlockId?: string;
};

const TabList: React.FC<TabListProps> = ({
  tabs,
  className,
  focusedBlockId,
  scrolledBlockId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const tabArray = tabs?.map((tab, index) => (
    <Tab
      className={styles.tab}
      key={index}
      focusedBlockId={focusedBlockId}
      scrolledBlockId={scrolledBlockId}
      tabListRef={ref}
      {...tab} />
  ));

  return (
    <div className={cx(styles.tabList, className)} ref={ref}>
      {tabArray}
    </div>
  );
};

TabList.defaultProps = defaultProps;

export default TabList;
