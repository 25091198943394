import React, { useContext } from "react";
import { EmailSignInBlockProps } from ".";
import { EmailSignInPresenterProps } from "./EmailSignIn.presenter";
import { useMutation } from "react-query";
import { signIn, verifyAccount } from '../../../modules/account/api';
import { AuthContext } from "../../../modules/auth";

const withInteractor = (
    Presenter: React.FC<EmailSignInPresenterProps>,
  ): React.FC <EmailSignInBlockProps> => {
    const Interactor: React.FC <EmailSignInBlockProps> = (props) => {
      const { setAccount } = useContext(AuthContext);
      const signInState = useMutation(signIn);
      const verifyState = useMutation(verifyAccount);

      return (
        <Presenter 
          {...props}
          doSignIn={signInState.mutateAsync} 
          doVerifyAccount={verifyState.mutateAsync}
          setAccount={setAccount}
          isLoading={signInState.isLoading}
        />
      );
    };
    return Interactor;
  };
  export default withInteractor;