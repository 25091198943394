import React from 'react';
import cx from 'classnames';

import styles from './CheckoutPricePage.module.scss';

import CheckoutPriceBlock, { CheckoutPriceBlockProps } from '../../block/CheckoutPriceBlock';

export const defaultProps = {
  checkoutPriceBlock: {
    backButton: {
      size: 'ExtraSmall',
      type: 'IconText',
      style: 'BasicSquare',
      icon: {
        asset: 'ArrowLeft',
        style: 'Grey1100',
      },
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    topContent: {
      title: {
        type: 'Title3',
        align: 'Left',
        style: 'Grey1100',
      },
      description: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey700',
      },
    },
    toggle: {
      secondOption: {
        state: 'Selected',
        text: {
          type: 'Button2',
          align: 'Center',
          style: 'Grey100',
        },
      },
      firstOption: {
        state: 'Unselected',
        text: {
          type: 'Button2',
          align: 'Center',
          style: 'Grey1100',
        },
      },
    },
    deliveryInfo: {
      type: 'Delivery',
      text: {
        type: 'Title4',
        align: 'Left',
        style: 'Grey1100',
      },
      topDivider: {
        type: 'HorizontalSolid',
        style: 'Grey400',
      },
      icon: {
        asset: 'InfoOutline',
        style: 'Grey1100',
      },
      dropdownToggle: {
        text: {
          type: 'Body3',
          align: 'Center',
          style: 'Grey1100',
        },
        icon: {
          asset: 'ChevronDown',
          style: 'Grey1100',
        },
      },
      bottomDivider: {
        type: 'HorizontalSolid',
        style: 'Grey400',
      },
    },
    priceList: {
      priceItems: [
      ],
    },
    priceItem: {
      type: 'ListItemDark',
      state: 'Collapsed',
      item: {
        type: 'Body5',
        align: 'Left',
        style: 'Grey1100',
      },
      divider: {
        type: 'HorizontalSolid',
        style: 'Grey800',
      },
      price: {
        type: 'Body5',
        align: 'Right',
        style: 'Grey1100',
      },
    },
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'NeutralSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
  } as CheckoutPriceBlockProps,
};

export type CheckoutPricePageProps = {
  checkoutPriceBlock?: CheckoutPriceBlockProps;
  className?: string;
  handleDropdownToggleClick?: () => void;
};

const CheckoutPricePage: React.FC<CheckoutPricePageProps> = ({
  checkoutPriceBlock,
  className,
  handleDropdownToggleClick
}) => {
  return (
    <div className={cx(styles.checkoutPricePage, className)}>
      <CheckoutPriceBlock
        className={styles.checkoutPriceBlock}
        handleDropdownToggleClick={handleDropdownToggleClick}
        {...checkoutPriceBlock} />
    </div>
  );
};

CheckoutPricePage.defaultProps = defaultProps;

export default CheckoutPricePage;
