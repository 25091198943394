import React, { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "../../../modules/auth";
import { CheckoutContext } from "../../../modules/checkout";
import { calculateTotals, createPaymentIntent } from "../../../modules/checkout/api";

import { AddTipBlockPresenterProps } from './AddTipBlock.presenter';
import { AddTipBlockProps } from './AddTipBlock';


const withInteractor = (
  Presenter: React.FC<AddTipBlockPresenterProps>
): React.FC<AddTipBlockProps> => {
  const Interactor: React.FC<AddTipBlockProps> = (props) => {
    const { account } = useContext(AuthContext);
    const { basket } = useContext(CheckoutContext);
    const calculateTotalsState = useMutation(calculateTotals);
    const paymentIntentState = useMutation(createPaymentIntent);

    return (
      <Presenter 
          {...props}
          account={account}
          basket={basket}
          calculateTotals={calculateTotalsState.mutateAsync}
          createPaymentIntent={paymentIntentState.mutateAsync}
          error={calculateTotalsState.error as Error || paymentIntentState.error as Error}
          />
  );
  }

  return Interactor;
}

export default withInteractor;