import React, { useContext } from "react";
import { OrderStatusBlockPresenterProps } from "./OrderStatusBlock.presenter"
import { OrderStatusBlockProps } from ".";
import { Redirect, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrder } from "../../../modules/checkout/api";
import { AuthContext } from "../../../modules/auth";
import { CheckoutContext } from '../../../modules/checkout/CheckoutContext';

const withInteractor = (
	Presenter: React.FC<OrderStatusBlockPresenterProps>
): React.FC<OrderStatusBlockProps> => {
	const Interactor: React.FC<OrderStatusBlockProps> = (props) => {
		const { error, account } = useContext(AuthContext);
		const { orderId } = useParams<{orderId: string}>();
		const { orderType } = useContext(CheckoutContext);
		let userLocation;
		if(account?.profile?.preferredAddress?.location) {
		  userLocation = account.profile.preferredAddress.location;
		} else if(account?.profile?.address) {
		  userLocation = account?.profile?.address[0].location;
		}
		
		const orderState = useQuery(['getOrder', { orderId, userLocation: userLocation, orderType: orderType }], () => getOrder(orderId, { userLocation: userLocation, orderType: orderType }), {
			refetchInterval: 5000,
		})

		if (orderState.isError || orderState.error) {
			return <Redirect to='/' />
		}

		return <Presenter 
			{...props}
			account={account}
			order={orderState.data}
			isLoading={orderState.isLoading}
			error={error} />;
	};
	return Interactor;
};
export default withInteractor;