import React, { useState } from 'react';
import cx from 'classnames';

import styles from './PriceItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Divider, { DividerProps } from '../../atoms/Divider';
import Button, { ButtonProps } from '../../atoms/Button';
import Notification, { NotificationProps } from '../Notification';

export type PriceItemTypeType = 'ListItem' | 'ListItemWithInfo' | 'ListItemDark';
export type PriceItemStateType = 'Collapsed' | 'Expanded';
export type PriceItemLoadingType = 'Default' | 'Loading';

export const defaultProps = {
  type: 'ListItem' as PriceItemTypeType,
  state: 'Collapsed' as PriceItemStateType,
  loading: 'Loading' as PriceItemLoadingType,
  item: {
    type: 'Body5',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey500',
  } as DividerProps,
  price: {
    type: 'Body5',
    align: 'Right',
    style: 'Grey1100',
  } as TextProps,
  button: {
    size: 'ExtraExtraSmall',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'Info',
      style: 'Blue1000',
    },
  } as ButtonProps,
  notification: {
    type: 'NotificationWithClose',
    style: 'Blue',
    title: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    button: {
      size: 'ExtraExtraSmall',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Blue200',
    },
    description: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
  } as NotificationProps,
};

export type PriceItemProps = {
  type?: PriceItemTypeType;
  state?: PriceItemStateType;
  loading?: PriceItemLoadingType;
  item?: TextProps;
  divider?: DividerProps;
  price?: TextProps;
  className?: string;
  button?: ButtonProps;
  notification?: NotificationProps;
};

const PriceItem: React.FC<PriceItemProps> = ({
  type,
  state,
  loading,
  item,
  divider,
  price,
  className,
  button,
  notification,
}) => {
  const [expanded, setExpanded] = useState(false);
  state = expanded ? 'Expanded' : 'Collapsed';
  const currentStyle = styles[`priceItem${type}${state}${loading}`];

  let contentView;
  let notificationView;

  const handleExpandButton = () => {
    setExpanded(!expanded);
  }

  const handleCloseNotification = () => setExpanded(false);
  notification = {
    ...notification,
    button: {
      ...notification?.button,
      onButtonClicked: handleCloseNotification,
    }
  }
  
  switch (`${type}${state}${loading}`) {
    case 'ListItemCollapsedDefault':
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.item}
            {...item} />
          <div className={styles.contentDivider}>
            <Divider
              className={styles.divider}
              {...divider} />
          </div>
          <Text
            className={styles.price}
            {...price} />
        </div>
      );
      break;
    case 'ListItemWithInfoCollapsedDefault':
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.item}
            {...item} />
          <Button
            className={styles.button}
            onButtonClicked={handleExpandButton}
            {...button} />
          <div className={styles.contentDivider}>
            <Divider
              className={styles.divider}
              {...divider} />
          </div>
          <Text
            className={styles.price}
            {...price} />
        </div>
      );
      break;
    case 'ListItemWithInfoExpandedDefault':
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.item}
            {...item} />
          <Button
            className={styles.button}
            onButtonClicked={handleExpandButton}
            {...button} />
          <div className={styles.contentDivider}>
            <Divider
              className={styles.divider}
              {...divider} />
          </div>
          <Text
            className={styles.price}
            {...price} />
        </div>
      );
      notificationView = (
        <Notification
          className={styles.notification}
          {...notification} />
      );
      break;
    case 'ListItemDarkCollapsedDefault':
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.item}
            {...item} />
          <div className={styles.contentDivider}>
            <Divider
              className={styles.divider}
              {...divider} />
          </div>
          <Text
            className={styles.price}
            {...price} />
        </div>
      );
      break;
    case 'ListItemCollapsedLoading':
      contentView = (
        <div className={styles.content}>
          <div className={styles.contentOne}/>
          <div className={styles.contentDivider}>
            <Divider
              className={styles.divider}
              {...divider} />
          </div>
          <div className={styles.contentTwo}/>
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {contentView}
      {notificationView}
    </div>
  );
};

PriceItem.defaultProps = defaultProps;

export default PriceItem;
