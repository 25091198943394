import React from 'react';
import cx from 'classnames';

import styles from './MenuItemList.module.scss';

import RadioOptionItem, {
  RadioOptionItemProps,
} from '../../molecules/RadioOptionItem';
import CheckboxOptionItem, {
  CheckboxOptionItemProps,
} from '../../molecules/CheckboxOptionItem';
import { MenuItem } from '../../../modules/checkout/types';

export type MenuItemListTypeType = 'Radio' | 'Checkbox';

export const defaultProps = {
  type: 'Checkbox' as MenuItemListTypeType,
  radioOptionItems: [] as RadioOptionItemProps[],
  checkboxOptionItems: [] as CheckboxOptionItemProps[],
};

export type MenuItemListProps = {
  type?: MenuItemListTypeType;
  radioOptionItems?: RadioOptionItemProps[];
  className?: string;
  checkboxOptionItems?: CheckboxOptionItemProps[];
  handleChange?: (items: MenuItem) => void;
};

const MenuItemList: React.FC<MenuItemListProps> = ({
  type,
  radioOptionItems,
  className,
  checkboxOptionItems,
}) => {
  const currentStyle = styles[`menuItemList${type}`];

  let radioOptionItemView;
  let checkboxOptionItemView;

  const radioOptionItemArray = radioOptionItems?.map((radioOptionItem) => (
    <RadioOptionItem className={styles.radioOptionItem} {...radioOptionItem} />
  ));

  const checkboxOptionItemArray = checkboxOptionItems?.map(
    (checkboxOptionItem) => (
      <CheckboxOptionItem
        className={styles.checkboxOptionItem}
        {...checkboxOptionItem}
      />
    ),
  );

  switch (type) {
    case 'Radio':
      radioOptionItemView = radioOptionItemArray;
      break;
    case 'Checkbox':
      checkboxOptionItemView = checkboxOptionItemArray;
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {radioOptionItemView}
      {checkboxOptionItemView}
    </div>
  );
};

MenuItemList.defaultProps = defaultProps;

export default MenuItemList;
