import React from 'react';
import cx from 'classnames';

import styles from './PriceItemList.module.scss';

import PriceListItem, { PriceListItemProps } from '../../molecules/PriceListItem';

export const defaultProps = {
  priceListItems: [
  ] as PriceListItemProps[],
};

export type PriceItemListProps = {
  priceListItems?: PriceListItemProps[];
  className?: string;
};

const PriceItemList: React.FC<PriceItemListProps> = ({
  priceListItems,
  className,
}) => {
  const priceListItemArray = priceListItems?.map((priceListItem, i) => {
    return (
      <PriceListItem key={i}
        className={styles.priceListItem}
        {...priceListItem} />)
  });

  return (
    <div className={cx(styles.priceItemList, className)}>
      {priceListItemArray}
    </div>
  );
};

PriceItemList.defaultProps = defaultProps;

export default PriceItemList;
