import React from 'react';
import cx from 'classnames';

import styles from './ActionCompletePage.module.scss';

import ActionCompleteBlock, { ActionCompleteBlockProps } from '../../block/ActionCompleteBlock';

export const defaultProps = {
  actionCompleteBlock: {
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey100',
      },
    },
    title: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
  } as ActionCompleteBlockProps,
};

export type ActionCompletePageProps = {
  actionCompleteBlock?: ActionCompleteBlockProps;
  className?: string;
  actionType?: 'VerifyEmail' | 'PasswordReset' | 'UpdatePassword';
};

const ActionCompletePage: React.FC<ActionCompletePageProps> = ({
  actionCompleteBlock,
  className,
  actionType,
}) => {
  return (
    <div className={cx(styles.actionCompletePage, className)}>
      <ActionCompleteBlock
        className={styles.actionCompleteBlock}
        {...actionCompleteBlock}
        actionType={actionType} />
    </div>
  );
};

ActionCompletePage.defaultProps = defaultProps;

export default ActionCompletePage;
