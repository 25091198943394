import React from 'react';
import cx from 'classnames';
import styles from './TopNav.module.scss';
import LogoDark, { LogoDarkProps } from '../../atoms/LogoDark';
import Cart, { CartProps } from '../../atoms/Cart';
import Divider, { DividerProps } from '../../atoms/Divider';
import Toggle, { ToggleProps } from '../../molecules/Toggle';
import DropdownToggle, { DropdownToggleProps } from '../../atoms/DropdownToggle';
import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';

export type TopNavStyleType = 'WithCart' | 'NoCart' | 'WithBackButton' | 'WithSubheader' | 'WithSubheaderCollapsed' | 'NoToggle' | 'WithBackButton';

export const defaultProps = {
  style: 'WithBackButton' as TopNavStyleType,
  menu: {
    size: 'Medium',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'HamburgerMenu',
      style: 'Grey1100',
    },
  } as ButtonProps,
  logoDark: {
  } as LogoDarkProps,
  cart: {
    state: 'Empty',
    icon: {
      asset: 'CartEmpty',
      style: 'Grey1100',
    },
  } as CartProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  toggle: {
    secondOption: {
      state: 'Selected',
      text: {
        type: 'Button2',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    firstOption: {
      state: 'Unselected',
      text: {
        type: 'Button2',
        align: 'Center',
        style: 'Grey1100',
      },
    },
  } as ToggleProps,
  dropdownToggle: {
    text: {
      type: 'Body3',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'Grey1100',
    },
  } as DropdownToggleProps,
  deliveryText: {
    type: 'Body3',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
  button: {
    size: 'Medium',
    type: 'Icon',
    style: 'BasicSquare',
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
  } as ButtonProps,
  text: {
    type: 'Title4',
    align: 'Center',
    style: 'Grey1100',
  } as TextProps,
};

export type TopNavProps = {
  style?: TopNavStyleType;
  menu?: ButtonProps;
  logoDark?: LogoDarkProps;
  cart?: CartProps;
  divider?: DividerProps;
  className?: string;
  toggle?: ToggleProps;
  dropdownToggle?: DropdownToggleProps;
  deliveryText?: TextProps;
  button?: ButtonProps;
  text?: TextProps;
  onClick?: () => void;
  handleCartClick?: () => void;
  handleDropdownToggleClick?: () => void;
  handleMenuClick?: () => void;
  isMobile?: boolean;
};

const TopNav: React.FC<TopNavProps> = ({
  style,
  menu,
  logoDark,
  cart,
  divider,
  className,
  toggle,
  dropdownToggle,
  deliveryText,
  button,
  text,
  onClick,
  isMobile,
}) => {
  const currentStyle = styles[`topNav${style}`];
  let toggleView;
  let menuContent;
  switch (style) {
    case 'NoToggle':
      menuContent = (
        <>
          <Button
            className={styles.menu}
            {...menu} />
          <LogoDark
            className={styles.logoDark}
            {...logoDark} />
        </>
      );
      break;
    case 'WithSubheaderCollapsed':
      menuContent = (
        <div className={styles.menuContent}>
          <Button
            className={styles.menu}
            {...menu} />
          <LogoDark
            className={styles.logoDark}
            {...logoDark} />
        </div>
      );
      toggleView = (
        <div className={styles.subContent} onClick={onClick} >
          <Text
            className={styles.text}
            {...text} />
          <DropdownToggle
            className={styles.dropdownToggle}
            {...dropdownToggle} />
        </div>
      );
      break;
    default:
      menuContent = (
        <div className={styles.menuContent}>
          <Button
            className={styles.menu}
            {...menu} />
          <LogoDark
            className={styles.logoDark}
            {...logoDark} />
        </div>
      );
      toggleView = (
        <div className={styles.subContent} >
          <Toggle
            className={styles.toggle}
            {...toggle} />
          <DropdownToggle
            className={styles.dropdownToggle}
            {...dropdownToggle} />
        </div>
      );
      break;
  }

  return isMobile ? (
    <div className={cx(currentStyle, className)}>
      <div className={styles.content}>
        <Button
            className={styles.menu}
            {...menu} />
        <LogoDark
          className={styles.logoDark}
          {...logoDark} />
        <Cart
          className={styles.cart}
          {...cart} />
      </div>
      <Divider
        className={styles.divider}
        {...divider} />
      {toggleView}
      <Divider
          className={styles.divider}
          {...divider} />
    </div>
  ) : (
    <div className={cx(currentStyle, className)}>
      <div className={styles.content}>
        {menuContent}
        {toggleView}
        <Cart
          className={styles.cart}
          {...cart} />
      </div>
      <Divider
        className={styles.divider}
        {...divider} />
    </div>
  );
};

TopNav.defaultProps = defaultProps;

export default TopNav;
