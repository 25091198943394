import React from 'react';
import cx from 'classnames';

import styles from './ShareSheetModal.module.scss';

import ModalHeader, { ModalHeaderProps } from '../../molecules/ModalHeader';
import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';
import Divider, { DividerProps } from '../../atoms/Divider';
import CopyLinkField, { CopyLinkFieldProps } from '../../molecules/CopyLinkField';
import ToastNotification, { ToastNotificationProps } from '../../molecules/ToastNotification';

export const defaultProps = {
  modalHeader: {
    type: 'Close',
    menuItem: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    button: {
      size: 'ExtraSmall',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as ModalHeaderProps,
  topContentText: { // MANUAL OVERRIDE
    type: 'Body2',
    align: 'Center',
    style: 'Grey700',
  } as TextProps,
  text: {
    type: 'Body2',
    align: 'Center',
    style: 'Grey700',
  } as TextProps,
  sMS: {
    size: 'Medium',
    type: 'IconText',
    style: 'SocialSquare',
    icon: {
      asset: 'SMSSocial',
      style: 'Grey100',
    },
    text: {
      type: 'Body2',
      align: 'Center',
      style: 'Grey700',
    },
  } as ButtonProps,
  email: {
    size: 'Medium',
    type: 'IconText',
    style: 'SocialSquare',
    icon: {
      asset: 'EmailSocial',
      style: 'Grey100',
    },
    text: {
      type: 'Body2',
      align: 'Center',
      style: 'Grey700',
    },
  } as ButtonProps,
  facebook: {
    size: 'Medium',
    type: 'IconText',
    style: 'SocialSquare',
    icon: {
      asset: 'FacebookSocial',
      style: 'Grey100',
    },
    text: {
      type: 'Body2',
      align: 'Center',
      style: 'Grey700',
    },
  } as ButtonProps,
  twitter: {
    size: 'Medium',
    type: 'IconText',
    style: 'SocialSquare',
    icon: {
      asset: 'TwitterSocial',
      style: 'Grey100',
    },
    text: {
      type: 'Body2',
      align: 'Center',
      style: 'Grey700',
    },
  } as ButtonProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  copyLinkField: {
    icon: {
      asset: 'Link',
      style: 'Grey800',
    },
    link: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey800',
    },
    copyText: {
      type: 'Button1',
      align: 'Center',
      style: 'Purple800',
    },
  } as CopyLinkFieldProps,
  toast: {
    type: 'Success',
    icon: {
      asset: 'CheckCircle',
      style: 'Green700',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Green800',
    },
  } as ToastNotificationProps,
};

export type ShareSheetModalProps = {
  modalHeader?: ModalHeaderProps;
  topContentText?: TextProps; // MANUAL OVERRIDE
  text?: TextProps;
  sMS?: ButtonProps;
  email?: ButtonProps;
  facebook?: ButtonProps;
  twitter?: ButtonProps;
  divider?: DividerProps;
  copyLinkField?: CopyLinkFieldProps;
  className?: string;
  toast?: ToastNotificationProps; // MANUAL OVERRIDE
  showModal?: boolean; // MANUAL OVERRIDE
  toggleModal?: () => void; // MANUAL OVERRIDE
};

const ShareSheetModal: React.FC<ShareSheetModalProps> = ({
  modalHeader,
  topContentText,
  text,
  sMS,
  email,
  facebook,
  twitter,
  divider,
  copyLinkField,
  className,
  toast, // MANUAL OVERRIDE
  showModal, // MANUAL OVERRIDE
  toggleModal, // MANUAL OVERRIDE
}) => {
  return (
    <div className={cx(styles.shareSheetModal, className)}>
      <ToastNotification show={showModal} onClose={toggleModal} {...toast} />
      <ModalHeader
        className={styles.modalHeader}
        {...modalHeader} />
      <div className={styles.content}>
        <div className={styles.topContent}>
          <Text
            className={styles.topContentText} // MANUAL OVERRIDE
            {...topContentText} />
          <div className={styles.socialButtons}>
            <Button
              className={styles.sMS}
              {...sMS} />
            <Button
              className={styles.email}
              {...email} />
            <Button
              className={styles.facebook}
              {...facebook} />
            <Button
              className={styles.twitter}
              {...twitter} />
          </div>
        </div>
        <Divider
          className={styles.divider}
          {...divider} />
        <div className={styles.bottomContent}>
          <Text
            className={styles.text}
            {...text} />
          <CopyLinkField
            className={styles.copyLinkField}
            {...copyLinkField} />
        </div>
      </div>
    </div>
  );
};

ShareSheetModal.defaultProps = defaultProps;

export default ShareSheetModal;
