import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { Account } from '../../../lib/token';
import { formatDeliveryETA, formatDisplayAddress, formatEtaTime } from '../../../lib/utils';
import { CreatePaymentIntent, CustomerBasket, OrderTotals, PaymentIntent } from '../../../modules/checkout/types';
import { Restaurant } from '../../../modules/restaurant/types';
import { DeliveryInfoProps } from '../../molecules/DeliveryInfo';
import { ErrorNotificationProps } from '../../molecules/ErrorNotification';
import { defaultProps as priceItemDefaultProps, PriceItemProps } from '../../molecules/PriceItem/PriceItem';
import { PriceListProps } from '../../organisms/PriceList';
import { formatPrice } from '../MenuBlocksList/utils';
import {
    ORDER_SERVICE_DELIVERY_FEE_RATE,
    ORDER_SERVICE_PICKUP_FEE_RATE,
  } from '../../../lib/constants';

import { CheckoutPriceBlockProps, defaultProps } from './CheckoutPriceBlock';

export type CheckoutPriceBlockPresenterProps = CheckoutPriceBlockProps & {
    account: Account | null;
    basket: CustomerBasket | undefined;
    updateBasket: (basket?: CustomerBasket) => void;
    calculateTotals: (payload: CreatePaymentIntent) => Promise<OrderTotals>;
    createPaymentIntent: (payload: CreatePaymentIntent) => Promise<PaymentIntent>;
    handleDropdownToggleClick?: () => void;
    isLoading: boolean;
    isCalculating: boolean;
    updateUseBasketOrderType: (flag: boolean) => void;
    restaurantsData?: Restaurant[];
    restaurantData?: Restaurant;
    userLocation: Location;
    error?: Error;
};

const loadingPriceItem : PriceItemProps = {
    ...priceItemDefaultProps,
    type: 'ListItem',
    state: 'Collapsed',
    loading: 'Loading',
}

const loadingDeliveryInfo : DeliveryInfoProps = {
    type: 'Pickup',
    loading: 'Loading'
}

const withPresenter = (
  View: React.FC<CheckoutPriceBlockProps>,
): React.FC<CheckoutPriceBlockPresenterProps> => {
  const Presenter: React.FC<CheckoutPriceBlockPresenterProps> = (props) => {
    const {
        account,
        basket,
        calculateTotals,
        createPaymentIntent,
        handleDropdownToggleClick,
        isLoading,
        isCalculating,
        updateBasket,
        updateUseBasketOrderType,
        restaurantsData,
        restaurantData,
        userLocation
    } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const [orderTotals, setOrderTotals] = useState<OrderTotals>();
    const defaultAddress = account?.profile?.address ? account.profile.address[0] : undefined;
    const [isLocationError, setIsLocationError] = useState<boolean>(false);
    const [formattedDeliveryETA, setFormattedDeliveryEta] = useState<string>();
  
    useEffect(() => {
      const restaurant = restaurantsData?.find(restaurant => restaurant.uuid === restaurantData?.uuid)
      if(basket?.orderType === 'Pickup') {
        setIsLocationError(false);
      }
      else if(restaurant && basket){
        const deliveryETA = formatDeliveryETA(restaurant.details?.distance, restaurant.details?.prepTime)
        updateBasket({
          ...basket, 
          deliveryETA: deliveryETA
        }); 
        setIsLocationError(false);
        setFormattedDeliveryEta(formatEtaTime(deliveryETA, restaurant?.details?.timezoneLocale))
      } else if (!restaurant && !isLoading){
        setIsLocationError(true);
      }
    }, [userLocation, restaurantsData, restaurantData])

    useEffect(() => {
        updateUseBasketOrderType(true);
        return () => {
            updateUseBasketOrderType(false);
        }
    }, []);
    
    useEffect(() => {
        const doCalculateTotals = async (id: string, basket: CustomerBasket) => {
            try {
                const response = await calculateTotals({
                    restaurantId: basket.restaurantId,
                    menuId: basket.menuId,
                    items: basket.items,
                    userId: id,
                    orderType: basket.orderType,
                });
                setOrderTotals(response);
            } catch {
                // no-op
            }
        }

        if (account && basket) {
            doCalculateTotals(account.uuid, basket);
        }
    }, [account, basket]);

    if (!basket || !basket.items) {
        // if the basket is empty then exit checkout
        return <Redirect to='/' />
    }

    const handlePaymentClicked = async () => {
        if(account) {
            const paymentIntent = await createPaymentIntent({
                restaurantId: basket.restaurantId,
                menuId: basket.menuId,
                items: basket.items,
                userId: account.uuid,
                orderType: basket.orderType,
            })
            history.push('/checkout/payment', paymentIntent);
        }
    }

    const handleGoToTipScreen = () => {
        history.push('/checkout/tip');
    }

    let checkoutPriceBlockProps: CheckoutPriceBlockProps = {
        ...defaultProps,
        backButton: {
            ...defaultProps.backButton,
            text: {
                ...defaultProps.backButton.text,
                value: t('button.back'),
            },
            onButtonClicked: () => {
                history.goBack();
            },
        },
        topContent: {
            ...defaultProps.topContent,
            title: {
                ...defaultProps.topContent.title,
                value: basket.restaurantName,
            },
        },
        toggle: {
            ...defaultProps.toggle,
            secondOption: {
                ...defaultProps.toggle.secondOption,
                state: basket.orderType === 'Delivery' ? 'Selected' : 'Unselected' ,
                text: {
                    ...defaultProps.toggle.secondOption?.text,
                    value: t('textLabels.delivery')
                },
                onClick: () => { 
                    updateBasket({
                        ...basket, 
                        orderType: 'Delivery'
                    }); 
                }
            },
            firstOption: {
                ...defaultProps.toggle.firstOption,
                state: basket.orderType === 'Pickup' ? 'Selected' : 'Unselected' ,
                text: {
                    ...defaultProps.toggle.firstOption?.text,
                    value: t('textLabels.pickup')
                },
                onClick: () => { 
                    updateBasket({
                        ...basket, 
                        orderType: 'Pickup'
                    });
                }
            },
        },
        deliveryInfo: {
            ...defaultProps.deliveryInfo,
            type: basket.orderType,
            state: isLocationError ? 'Error': 'Default',
            loading: 'Default',
            text: {
                ...defaultProps.deliveryInfo.text,
                value: basket.orderType === "Pickup" ? 
                t('textLabels.pickup_info') : t('textLabels.delivery_info')
            },
            topIcon: {
                ...defaultProps.deliveryInfo.topIcon,
                asset: "Clock"
            },
            bottomIcon: {
                ...defaultProps.deliveryInfo.bottomIcon,
                asset: basket.orderType === "Pickup" ? "Restaurant" : "Location"
            },
            topText: {
                ...defaultProps.deliveryInfo.topText,
                value: basket.orderType === "Pickup" ? basket.restaurantPrepRange : (isLocationError ? "N/A" : formattedDeliveryETA),
                
            },
            bottomText: {
                ...defaultProps.deliveryInfo.bottomText,
                value: basket.orderType === "Pickup" ? basket.restaurantAddress : null,
            },
            deliveryInstruction: {
                ...defaultProps.deliveryInfo.deliveryInstruction,
                value: account?.profile?.address[0].deliveryInstructions
            },
            dropdownToggle: {
                ...defaultProps.deliveryInfo.dropdownToggle,
                onClick: handleDropdownToggleClick,
                text: {
                    ...defaultProps.deliveryInfo?.dropdownToggle?.text,
                    value: account?.profile?.preferredAddress ? 
                    formatDisplayAddress(account?.profile?.preferredAddress) : 
                    (defaultAddress ? formatDisplayAddress(defaultAddress) : ''),
                }
            },
            errorNotification: {
                ...defaultProps.deliveryInfo.errorNotification,
                type: "Error",
                show: isLocationError,
                text: {
                    ...defaultProps.deliveryInfo?.errorNotification?.text,
                    value: t('checkout.delivery_info.error', {restaurant_name: basket.restaurantName})
                },
                icon: {
                    ...defaultProps.deliveryInfo?.errorNotification?.icon,
                },
            } as ErrorNotificationProps,
        },
        flavourText: {
            ...defaultProps.flavourText,
            value: t('checkout.flavor_text'),
        },
        button: {
            ...defaultProps.button,
            disabled: !orderTotals || isLocationError,
            text: {
                ...defaultProps.button.text,
                value: t('button.next'),
            },
            onButtonClicked: basket.orderType === 'Delivery' ? handleGoToTipScreen : handlePaymentClicked,
        },
        showToggle: basket.delivery && basket.pickup
    };

    // enter loading state while waiting for prices to be calculated for price items props
    if (!orderTotals || isCalculating || isLoading) {
        let loadingPriceItems : PriceItemProps[] = new Array(basket.orderType === 'Pickup' ? 5 : 6);
        loadingPriceItems.fill(loadingPriceItem);
        checkoutPriceBlockProps = {
            ...checkoutPriceBlockProps,
            deliveryInfo: loadingDeliveryInfo,
            priceList: {
                ...defaultProps.priceList,
                priceItems: loadingPriceItems
            }
        }
        return <View 
            {...props}
            {...checkoutPriceBlockProps}
            />
    }

    let priceList: PriceListProps = {
        ...defaultProps.priceList,
        priceItems: [
            {
                ...priceItemDefaultProps,
                type: 'ListItem',
                loading: 'Default',
                item: {
                    ...priceItemDefaultProps.item,
                    value: t('textLabels.subtotal'),
                },
                divider: {
                    ...priceItemDefaultProps.divider,
                    style: 'Grey500',
                },
                price: {
                    ...priceItemDefaultProps.price,
                    value: formatPrice(orderTotals.subtotal),
                },
            },
            {
                ...priceItemDefaultProps,
                type: 'ListItemWithInfo',
                loading: 'Default',
                item: {
                    ...priceItemDefaultProps.item,
                    value: t('textLabels.service_fee'),
                },
                divider: {
                    ...priceItemDefaultProps.divider,
                    style: 'Grey500',
                },
                price: {
                    ...priceItemDefaultProps.price,
                    value: formatPrice(orderTotals.servicefee),
                },
                notification: {
                    ...priceItemDefaultProps.notification,
                    title: t('checkout.notification.title'),
                    description: t('checkout.notification.description', { 
                        percentage: basket.orderType === 'Delivery' ? ORDER_SERVICE_DELIVERY_FEE_RATE : ORDER_SERVICE_PICKUP_FEE_RATE,
                        deliveryType: basket.orderType === 'Delivery' ? t('textLabels.delivery') : t('textLabels.pickup'),
                    }),
                },
            },
            {
                ...priceItemDefaultProps,
                type: 'ListItem',
                loading: 'Default',
                item: {
                    ...priceItemDefaultProps.item,
                    value: t('textLabels.tax'),
                },
                divider: {
                    ...priceItemDefaultProps.divider,
                    style: 'Grey500',
                },
                price: {
                    ...priceItemDefaultProps.price,
                    value: formatPrice(orderTotals.tax),
                },
            },
        ],
    }

    if (basket.orderType === 'Delivery'){
        priceList.priceItems?.splice(2, 0, {
            ...priceItemDefaultProps,
            type: 'ListItem',
            loading: 'Default',
            item: {
                ...priceItemDefaultProps.item,
                value: t('textLabels.delivery_fee'),
            },
            divider: {
                ...priceItemDefaultProps.divider,
                style: 'Grey500',
            },
            price: {
                ...priceItemDefaultProps.price,
                value: formatPrice(orderTotals.deliveryfee),
            },
        })
    }

    if (orderTotals.supplifee && orderTotals.supplifee > 0) {
        priceList.priceItems?.splice(2, 0, {
            ...priceItemDefaultProps,
            type: 'ListItemWithInfo',
            loading: 'Default',
            item: {
                ...priceItemDefaultProps.item,
                value: t('textLabels.suppli_fee'),
            },
            divider: {
                ...priceItemDefaultProps.divider,
                style: 'Grey500',
            },
            price: {
                ...priceItemDefaultProps.price,
                value: formatPrice(orderTotals.supplifee),
            },
            notification: {
                ...priceItemDefaultProps.notification,
                title: t('order_status.notification_suppli_fee.title'),
                description: t('order_status.notification_suppli_fee.description'),
            },
        },)
    }

    if(orderTotals.discount > 0){
        priceList.priceItems?.push(
            {
                ...priceItemDefaultProps,
                type: 'ListItem',
                loading: 'Default',
                item: {
                    ...priceItemDefaultProps.item,
                    value: t('textLabels.discount'),
                },
                divider: {
                    ...priceItemDefaultProps.divider,
                    style: 'Grey500',
                },
                price: {
                    ...priceItemDefaultProps.price,
                    value: formatPrice(-orderTotals.discount),
                },
            });
    }

    checkoutPriceBlockProps = {
        ...checkoutPriceBlockProps,
        priceList: priceList,
        priceItem: {
            ...defaultProps.priceItem,
            type: 'ListItemDark',
            loading: 'Default',
            item: {
                ...defaultProps.priceItem.item,
                value: t('textLabels.total'),
            },
            price: {
                ...defaultProps.priceItem.price,
                value: formatPrice(orderTotals.ordertotal),
            },
        },
    };

    return <View
      {...props}
      {...checkoutPriceBlockProps}
    />;
  }
  return Presenter;
};

export default withPresenter;