import React from 'react';
import { MenuItemConfigurationModalProps } from './MenuItemConfigurationModal';
import { MenuItemConfigurationModalPresenterProps } from './MenuItemConfigurationModal.presenter';

const withInteractor = (
  Presenter: React.FC<MenuItemConfigurationModalPresenterProps>,
): React.FC<MenuItemConfigurationModalProps> => {
  const Interactor: React.FC<MenuItemConfigurationModalProps> = (props) => (
      <Presenter
        {...props}
      />
  );
  return Interactor;
};
export default withInteractor;
