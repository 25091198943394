import React, {
  MutableRefObject, useRef, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useScrollPosition } from '../../../lib/use-scroll-position/src';
import {
  RestaurantPageProps,
  defaultProps,
} from './RestaurantPage';

export type RestaurantPagePresenterProps = RestaurantPageProps & {
  productId?: string | null;
  menuModalOpen?: boolean;
};

const withPresenter = (
  View: React.FC<RestaurantPagePresenterProps>,
): React.FC<RestaurantPagePresenterProps> => {
  const Presenter: React.FC<RestaurantPagePresenterProps> = (props) => {
    const { t } = useTranslation();
    const [focusedBlockId, setFocusedBlockId] = useState<string>();
    const [scrolledBlockId, setScrollBlockId] = useState<string>();
    const [blocksOffsets, setBlocksOffsets] = useState<Map<string, object>>(new Map());
    const { menu, restaurant } = props;
    let emptyBlockContent: React.ReactNode;
    const restaurantPageRef = useRef<HTMLDivElement>(null);
    const restaurantBannerRef = useRef<HTMLDivElement>(null);

    if (restaurant && !restaurant.inRange) {
      emptyBlockContent = (
        <Trans i18nKey='menu.out_of_range'
          components={{ bold: <strong/> }}
        />
      );
    } else if (menu && menu.length > 0 && menu[0].nextAvailability) {
      emptyBlockContent = (
        <Trans i18nKey='menu.restaurant_closed'
          values={{
            restaurant_name: restaurant?.details?.name,
            day: menu[0]?.nextAvailability?.dayOfWeek,
            open: menu[0]?.nextAvailability?.startTime,
            close: menu[0]?.nextAvailability?.endTime,
          }}
          components={{ bold: <strong/> }}
        />
      );
    } else {
      emptyBlockContent = t('menu.empty_menu');
    }

    const updateScrolledFocusedBlockId = (currPos: number): void => {
      const firstValue = blocksOffsets.values().next();
      let currKey = blocksOffsets.keys().next().value;
      if (firstValue.value) {
        const firstOffset = firstValue.value.current.offsetTop;
        let closestOffset = firstOffset;
        blocksOffsets.forEach((value, key) => {
          const block = value as React.RefObject<HTMLDivElement>;
          const blockOffset = block.current?.offsetTop || 0;
          const blockOffsetDiff = blockOffset - currPos;
          if (
            blockOffsetDiff >= 0
            && blockOffsetDiff <= closestOffset
            && currPos <= blockOffset
            && key
            && value
          ) {
            closestOffset = blockOffsetDiff;
            currKey = key;
            setScrollBlockId(currKey);
          }
        });
      }
    };

    useScrollPosition(
      ({ currPos }) => {
        updateScrolledFocusedBlockId(currPos.y);
      },
      undefined,
      restaurantBannerRef as MutableRefObject<HTMLDivElement>,
      false,
      0,
      restaurantPageRef as MutableRefObject<HTMLDivElement>,
    );

    let restaurantPageProps: RestaurantPageProps = {};
    restaurantPageProps = {
      ...defaultProps,
      emptyBlock: {
        ...defaultProps.emptyBlock,
        description: {
          ...defaultProps.emptyBlock.description,
          value: emptyBlockContent,
        },
      },
      toast: {
        ...defaultProps.toast,
        text: {
          ...defaultProps.toast.text,
          value: t('menu.added_to_cart'),
        },
      },
      focusedBlockId,
      setFocusedBlockId,
      blocksOffsets,
      setBlocksOffsets,
      restaurantPageRef,
      restaurantBannerRef,
      scrolledBlockId,
    };

    return <View {...props} {...restaurantPageProps} />;
  };
  return Presenter;
};

export default withPresenter;
