import React from 'react';
import cx from 'classnames';

import styles from './ProfileMenu.module.scss';

import UserInfo, { UserInfoProps } from '../../molecules/UserInfo';
import Divider, { DividerProps } from '../../atoms/Divider';
import ButtonItemList, { ButtonItemListProps } from '../ButtonItemList';

export const defaultProps = {
  userInfo: {
    button: {
      size: 'Medium',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
    },
    userAvatar: {
      style: 'Circle',
    },
    name: {
      type: 'Title1',
      align: 'Center',
      style: 'Grey1100',
    },
    email: {
      type: 'Body2',
      align: 'Center',
      style: 'Grey1100',
    },
  } as UserInfoProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  buttonItemList: {
    buttonItems: [
    ],
  } as ButtonItemListProps,
};

export type ProfileMenuProps = {
  userInfo?: UserInfoProps;
  divider?: DividerProps;
  buttonItemList?: ButtonItemListProps;
  className?: string;
};

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  userInfo,
  divider,
  buttonItemList,
  className,
}) => {
  return (
    <div className={cx(styles.profileMenu, className)}>
      <UserInfo
        className={styles.userInfo}
        {...userInfo} />
      <Divider
        className={styles.divider}
        {...divider} />
      <ButtonItemList
        className={styles.buttonItemList}
        {...buttonItemList} />
    </div>
  );
};

ProfileMenu.defaultProps = defaultProps;

export default ProfileMenu;
