import React from 'react';
import cx from 'classnames';

import styles from './PaymentInfoPage.module.scss';

import PaymentInfoBlock, { PaymentInfoBlockProps } from '../../block/PaymentInfoBlock';

export const defaultProps = {
  paymentInfoBlock: {
    backButton: {
      size: 'ExtraSmall',
      type: 'IconText',
      style: 'BasicSquare',
      icon: {
        asset: 'ArrowLeft',
        style: 'Grey1100',
      },
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    text: {
      type: 'Title2',
      align: 'Left',
      style: 'Grey1100',
    },
    textField: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    cardInfo: {
      state: 'Default',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'LeadingIcon',
        icon: {
          asset: 'Card',
          style: 'Grey600',
        },
        cardNumber: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey700',
        },
      },
      expirationDate: {
        type: 'Text',
        style: 'Default',
      },
      cVV: {
        type: 'Text',
        style: 'Default',
      },
    },
    address: {
      state: 'Default',
      addressLine1: {
        state: 'Default',
        type: 'Text',
        label: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey1100',
        },
        textInput: {
          type: 'Text',
          style: 'Default',
        },
      },
      addressLine2: {
        type: 'Text',
        style: 'Default',
      },
      city: {
        type: 'Text',
        style: 'Default',
      },
      postalCode: {
        type: 'Text',
        style: 'Default',
      },
      province: {
        text: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey700',
        },
        icon: {
          asset: 'ChevronDown',
          style: 'Grey1100',
        },
      },
      country: {
        type: 'Text',
        style: 'Default',
      },
    },
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'NeutralSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
  } as PaymentInfoBlockProps,
};

export type PaymentInfoPageProps = {
  paymentInfoBlock?: PaymentInfoBlockProps;
  className?: string;
};

const PaymentInfoPage: React.FC<PaymentInfoPageProps> = ({
  paymentInfoBlock,
  className,
}) => {
  return (
    <div className={cx(styles.paymentInfoPage, className)}>
      <PaymentInfoBlock
        className={styles.paymentInfoBlock}
        {...paymentInfoBlock} />
    </div>
  );
};

PaymentInfoPage.defaultProps = defaultProps;

export default PaymentInfoPage;
