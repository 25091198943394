import React from 'react';

import styles from './ConfirmationModalBlock.module.scss';

import ModalHeader, { ModalHeaderProps } from '../../molecules/ModalHeader';
import Text, { TextProps } from '../../atoms/Text';
import ButtonGroup2, { ButtonGroup2Props } from '../../molecules/ButtonGroup2';
import MenuItem, { MenuItemProps } from '../../molecules/MenuItem';
import { MenuItem as MenuType } from '../../../modules/checkout/types';
import { Modal } from 'react-bootstrap';


export const defaultProps = {
  modalHeader: {
    type: 'Close',
    menuItem: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    button: {
      size: 'ExtraSmall',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      }
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as ModalHeaderProps,
  messageText: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  buttonGroup2: {
    primaryButton: {
      style: 'BrandSquare',
      size: 'Medium',
      type: 'Text',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100'
      }
    },
    secondaryButton: {
      style: 'BrandSquare',
      size: 'Medium',
      type: 'Text',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100'
      }
    },
  } as ButtonGroup2Props,
  menuItem: {
    itemQuantity: {
      type: 'Title4',
      align: 'Center',
      style: 'Grey1100',
    },
    itemName: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    cost: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
  } as MenuItemProps,
};

export type ConfirmationModalBlockProps = {
  modalHeader?: ModalHeaderProps;
  messageText?: TextProps;
  buttonGroup2?: ButtonGroup2Props;
  className?: string;
  menuItem?: MenuItemProps;
  itemToRemove?: MenuType;
  itemPositionToRemove?: number;
  closeConfirmation?: () => void;
};

const ConfirmationModalBlock: React.FC<ConfirmationModalBlockProps> = ({
  modalHeader,
  messageText,
  buttonGroup2,
  menuItem,
}) => {

  return (
    <>
    <Modal.Header className={styles.modalHeader}>
      <ModalHeader
        className={styles.modalHeader}
        {...modalHeader} />
    </Modal.Header>
    <Modal.Body className={styles.modalContent}>
    <div className={styles.content}>
      <div className={styles.innerContent}>
        <Text
          className={styles.messageText}
          {...messageText} />
        <MenuItem
          className={styles.menuItem}
          {...menuItem} />
        </div>
    </div>
    </Modal.Body>
    <Modal.Footer className={styles.modalFooter}>
        <ButtonGroup2
          className={styles.buttonGroup2}
          {...buttonGroup2} />
    </Modal.Footer>
    </>
  );
};

ConfirmationModalBlock.defaultProps = defaultProps;

export default ConfirmationModalBlock;
