import React from 'react';
import cx from 'classnames';

import styles from './AddressItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  street: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  postalCode: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  cityAndProvince: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  changeButton: {
    size: 'Small',
    type: 'Text',
    style: 'InfoRounded',
    text: {
      type: 'Button2',
      align: 'Center',
      style: 'Blue800',
    },
  } as ButtonProps,
};

export type AddressItemProps = {
  street?: TextProps;
  postalCode?: TextProps;
  cityAndProvince?: TextProps;
  changeButton?: ButtonProps;
  className?: string;
};

const AddressItem: React.FC<AddressItemProps> = ({
  street,
  postalCode,
  cityAndProvince,
  changeButton,
  className,
}) => {
  return (
    <div className={cx(styles.addressItem, className)}>
      <div className={styles.addressText}>
        <Text
          {...street} />
        <Text
          {...cityAndProvince} />
        <Text
          {...postalCode} />
      </div>
      <Button
        className={styles.changeButton}
        {...changeButton} />
    </div>
  );
};

AddressItem.defaultProps = defaultProps;

export default AddressItem;
