import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

import styles from './MenuSectionItem.module.scss';

import SectionHeader, {
  SectionHeaderProps,
} from '../../molecules/SectionHeader';
import MenuItemList, { MenuItemListProps } from '../MenuItemList';
import { ModifierGroup, Product } from '../../../modules/menu/types';
import { MenuItem } from '../../../modules/checkout/types';

export const defaultProps = {
  sectionHeader: {
    titleText: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    helpText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey800',
    },
  } as SectionHeaderProps,
  menuItemList: {
    type: 'Radio',
    radioOptionItems: [],
  } as MenuItemListProps,
};

export type MenuSectionItemProps = {
  sectionHeader?: SectionHeaderProps;
  menuItemList?: MenuItemListProps;
  className?: string;
  itemGroup?: ModifierGroup;
  itemMap?: Record<string, Product>;
  handleChange?: (items: MenuItem) => void;
  alreadySelectedItems?: string[];
  isRequiredError?: boolean;
  isQuantityError?: boolean;
  focusedHeaderId?: string;
};

const MenuSectionItem: React.FC<MenuSectionItemProps> = ({
  sectionHeader,
  menuItemList,
  className,
  handleChange,
  focusedHeaderId,
  itemGroup,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current && itemGroup?._id && focusedHeaderId === itemGroup?._id) {
      ref.current?.scrollIntoView();
    }
  }, [focusedHeaderId, itemGroup, ref]);

  return (
    <div className={cx(styles.menuSectionItem, className)} ref={ref} >
    <SectionHeader className={styles.sectionHeader} {...sectionHeader} />
    <MenuItemList
      className={styles.menuItemList}
      handleChange={handleChange}
      {...menuItemList}
    />
  </div>
  );
};

MenuSectionItem.defaultProps = defaultProps;

export default MenuSectionItem;
