declare global {
  interface Window {
    env: any;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const {
  REACT_APP_DOMAIN: DOMAIN = window.env?.REACT_APP_DOMAIN,
  REACT_APP_BFF_URL: BFF_URL = window.env?.REACT_APP_BFF_URL,
  REACT_APP_FRONTEND_URL: FRONTEND_URL = window.env?.REACT_APP_FRONTEND_URL,
  REACT_APP_SESSION_COOKIE_NAME: SESSION_COOKIE_NAME =
  window.env?.REACT_APP_SESSION_COOKIE_NAME,
  BASKET_COOKIE_NAME = window.env?.REACT_APP_BASKET_COOKIE_NAME || 'basket_cookie',
  REACT_APP_STRIPE_API_KEY: STRIPE_API_KEY = window.env?.REACT_APP_STRIPE_API_KEY,
  REACT_APP_IS_DEV: IS_DEV = false,
  REACT_APP_HEAP_APP_ID: HEAP_APP_ID = window.env?.REACT_APP_HEAP_APP_ID,
  REACT_APP_SENTRY_ENV_FRONTEND: SENTRY_ENV_FRONTEND = window.env?.REACT_APP_SENTRY_ENV_FRONTEND,
  REACT_APP_SENTRY_DSN: SENTRY_DSN = window.env?.REACT_APP_SENTRY_DSN,
  REACT_APP_GTM_ID: GTM_ID = window.env?.REACT_APP_GTM_ID,
} = process.env;

export const SENTRY_TRACES_SAMPLE_RATE =
  Number(process.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) ||
  Number(window.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) ||
  1.0;