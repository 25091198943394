import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import styles from './EmailSignInBlock.module.scss';
import { EmailSignInBlockProps, defaultProps } from './EmailSignInBlock';
import { isEmail, isEmptyString, isPassword } from '../../../lib/utils';
import {
  AccountResponse,
  SignInPayload,
  VerifyAccountPayload,
} from '../../../modules/account/types';
import { Account } from '../../../lib/token';
import { useEffect } from 'react';
import {
  ToastNotificationProps,
} from '../../molecules/ToastNotification/ToastNotification';
import { ApiError } from '../../../lib/api/types';

export type EmailSignInPresenterProps = EmailSignInBlockProps & {
  doSignIn: (payload: SignInPayload) => Promise<AccountResponse>;
  doVerifyAccount: (payload: VerifyAccountPayload) => Promise<void>;
  setAccount: (account: Account | null) => void;
  isLoading: boolean;
};
const UNVERIFIED_USER_ERROR = 'User has not confirmed sign up';

const withPresenter = (
  View: React.FC<EmailSignInBlockProps>,
): React.FC<EmailSignInPresenterProps> => {
  const Presenter: React.FC<EmailSignInPresenterProps> = (props) => {
    const { doSignIn, setAccount, doVerifyAccount, isLoading } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const verifyId = query.get('id');
    const verifyToken = query.get('token');
    const redirectPath = query.get('redirect_to');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isVerificationError, setIsVerificationError] = useState<boolean>(false);
    const isValidForm = !isEmptyString(email) && !isEmptyString(password);

    const { state } = useLocation<{ message: string }>();
    const { message } = state || {};
    const [indicateMessage, setIndicateMessage] = useState<string>('');
    const [showToast, setShowToast] = useState<boolean>(false);

    useEffect(() => {
      const verifyAccount = async (id: string, token: string) => {
        try {
          await doVerifyAccount({ id, token });
          setIndicateMessage(
            t('action_complete_block.verify_email.confirmation'),
          );
        } catch {
          setIsVerificationError(true)
        } finally {
          setShowToast(true);
        }
      };
      if (verifyId && verifyToken) {
        verifyAccount(verifyId, verifyToken);
      }
    }, [verifyId, verifyToken]);

    useEffect(() => {
      if (state && message) {
        setIndicateMessage(message);
        setShowToast(true);
      }
    }, [state, message]);

    const handleEmail = (e) => {
      setEmail(e.target.value);
      setPasswordError('');
      setEmailError('');
    };

    const handlePassword = (e) => {
      setPassword(e.target.value);
      setPasswordError('');
      setEmailError('')
    };

    const handleSignIn = async () => {
      if (!isEmail(email)) {
        setEmailError(t('error.format.email'));
      } else if (!isPassword(password)) {
        setPasswordError(t('error.login'));
      } else {
        try {
          const data = await doSignIn({
            email,
            password,
          });
          setAccount(data);
          history.push(redirectPath ? redirectPath : '/');
        } catch (e) {
          if (ApiError.isApiError(e) && e.message) {
            if (e.message === UNVERIFIED_USER_ERROR) {
              history.push('/verifyEmail', { email });
            } else {
              setPasswordError(t('error.login'));
            }
          } else {
            setPasswordError(t('error.login'));
          }
        }
      }
    };

    const closeToast = () => {
      setShowToast(false);
    };

    const toastNotificationProps: ToastNotificationProps = {
      ...defaultProps.toast,
      type: 'Success',
      icon: {
        ...defaultProps.toast.icon,
        asset: 'CheckCircle',
        style: 'Green700',
      },
      text: {
        ...defaultProps.toast.text,
        value: indicateMessage,
      },
      show: showToast,
      onClose: closeToast,
    };

    const toastErrorNotificationProps: ToastNotificationProps = {
      ...defaultProps.toast,
      type: "Error",
      icon: {
        ...defaultProps.errorToast.icon,
      },
      text: {
        ...defaultProps.errorToast.text,
        value: t('error.default'),
      },
      show: showToast,
      onClose: closeToast,
    };

    // render proper props base on sign in or create account
    const emailSignInBlockProps: EmailSignInBlockProps = {
      ...defaultProps,
      backButton: {
        ...defaultProps.backButton,
        text: {
          ...defaultProps.backButton?.text,
          value: t('button.back'),
        },
        onButtonClicked: () => {
          history.goBack();
        },
      },
      title: {
        ...defaultProps.title,
        value: t('button.sign_in'),
      },
      description: {
        ...defaultProps.description,
        value: t('email_sign_in.description'),
      },
      email: {
        ...defaultProps.email,
        label: {
          ...defaultProps.email?.label,
          value: t('textLabels.email'),
        },
        state: emailError || passwordError ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.email?.textInput,
          onTextChanged: handleEmail,
        },
        errorText: {
          ...defaultProps.email?.errorText,
          value: passwordError !== '' ? '' : emailError,
          style: 'Red800',
          align: 'Left',
        },
      },
      password: {
        ...defaultProps.password,

        label: {
          ...defaultProps.password?.label,
          value: t('textLabels.password'),
        },
        state: passwordError ? 'Error' : 'Default',
        textInput: {
          ...defaultProps.password?.textInput,
          type: 'Password',
          showPassword,
          icon: {
            ...defaultProps.password?.textInput?.icon,
            asset: showPassword ? 'Hide' : 'Show',
            onClick: () => setShowPassword(!showPassword),
          },
          onTextChanged: handlePassword,
        },
        errorText: {
          ...defaultProps.password?.errorText,
          value: passwordError,
          style: 'Red800',
          align: 'Left',
        },
      },
      forgotPassword: {
        ...defaultProps.forgotPassword,
        value: t('textLabels.forgot_password'),
        onClick: () => history.push(`/forgotPassword`),
      },
      button: {
        ...defaultProps.button,
        text: {
          ...defaultProps.button?.text,
          value: t('button.sign_in'),
        },
        onButtonClicked: handleSignIn,
        disabled: !isValidForm,
      },
      secondaryAction: {
        ...defaultProps?.secondaryAction,
        title: {
          ...defaultProps?.secondaryAction?.title,
          value: t('email_sign_in.secondaryAction.title'),
        },
        link: {
          ...defaultProps?.secondaryAction?.link,
          value: t('email_sign_in.secondaryAction.link'),
          onClick: () => history.push(`/signUp`),
        },
      },
      toast: isVerificationError ? toastErrorNotificationProps : toastNotificationProps,
      toastMessage: showToast,
    };

    return <View {...props} {...emailSignInBlockProps} />;
  };
  return Presenter;
};

export default withPresenter;
