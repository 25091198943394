import React from 'react';
import cx from 'classnames';

import styles from './OrderTracker.module.scss';

import Image, { ImageProps } from '../../atoms/Image';
import Text, { TextProps } from '../../atoms/Text';
import Notification, { NotificationProps } from '../../molecules/Notification';
import Button, { ButtonProps } from '../../atoms/Button';
import LineItem, { LineItemProps } from '../../molecules/LineItem';
import OrderStepper, { OrderStepperProps } from '../OrderStepper';
import OrderAccordian, { OrderAccordianProps } from '../../molecules/OrderAccordian';

export type OrderTrackerStateType = 'Error' | 'TrackOrder';

export const defaultProps = {
  State: 'TrackOrder' as OrderTrackerStateType,
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  notification: {
    type: 'Default',
    style: 'Blue',
    title: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Blue200',
    },
    description: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
    phoneNumber: {
      type: 'Body2',
      align: 'Left',
      style: 'Blue800',
    },
  } as NotificationProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  estimatedTime: {
    labelText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey800',
    },
    contentText: {
      type: 'Body5',
      align: 'Left',
      style: 'Grey800',
    },
  } as LineItemProps,
  orderPrice: {
    labelText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey800',
    },
    contentText: {
      type: 'Body5',
      align: 'Left',
      style: 'Grey800',
    },
  } as LineItemProps,
  orderNumber: {
    labelText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey800',
    },
    contentText: {
      type: 'Body5',
      align: 'Left',
      style: 'Grey800',
    },
  } as LineItemProps,
  deliveryAddress: {
    labelText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey800',
    },
    contentText: {
      type: 'Body5',
      align: 'Left',
      style: 'Grey800',
    },
  } as LineItemProps,
  orderStepper: {
    stepItems: [],
    stepItem: {
      state: 'Incomplete',
      type: 'Last',
      orderProgressItem: {
        state: 'None',
      },
      stepIndicator: {
        asset: 'Incomplete',
      },
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
    },
  } as OrderStepperProps,
  orderAccordian: {
    state: 'Default',
    titleText: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    text: {
      type: 'Button2',
      align: 'Center',
      style: 'Green800',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'Green800',
    },
  } as OrderAccordianProps,
};

export type OrderTrackerProps = {
  State?: OrderTrackerStateType;
  image?: ImageProps;
  title?: TextProps;
  description?: TextProps;
  notification?: NotificationProps;
  button?: ButtonProps;
  className?: string;
  estimatedTime?: LineItemProps;
  orderPrice?: LineItemProps;
  orderNumber?: LineItemProps;
  deliveryAddress?: LineItemProps;
  orderStepper?: OrderStepperProps;
  orderAccordian?: OrderAccordianProps;
};

const OrderTracker: React.FC<OrderTrackerProps> = ({
  State,
  image,
  title,
  description,
  notification,
  button,
  className,
  estimatedTime,
  orderPrice,
  orderNumber,
  orderStepper,
  deliveryAddress,
  orderAccordian,
}) => {

  const currentStyle = styles[`orderTracker${State}`];

  const buttonView = (
    <Button
      className={styles.button}
      {...button} />
  );
  
  let contentView;
  
  switch (State) {
    case 'Error':
      contentView = (
        <div className={styles.content}>
          <Image
            className={styles.image}
            {...image} />
          <div className={styles.topContent}>
            <Text
              className={styles.title}
              {...title} />
            <Text
              className={styles.description}
              {...description} />
          </div>
          <Notification
            className={styles.notification}
            {...notification} />
        </div>
      );
      break;
    case 'TrackOrder':
      contentView = (
        <div className={styles.content}>
          <div className={styles.topContent}>
            <Text
              className={styles.title}
              {...title} />
            <LineItem
              className={styles.estimatedTime}
              {...estimatedTime} />
            <LineItem
              className={styles.orderPrice}
              {...orderPrice} />
            <LineItem
              className={styles.orderNumber}
              {...orderNumber} />
            <LineItem
              className={styles.deliveryAddress}
              {...deliveryAddress} />
          </div>
          <div className={styles.midContent}>
            <OrderStepper
              className={styles.orderStepper}
              {...orderStepper} />
            <OrderAccordian
              className={styles.orderAccordian}
              {...orderAccordian} />
          </div>
          <Notification
            className={styles.notification}
            {...notification} />
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {contentView}
      {buttonView}
    </div>
  );
};

OrderTracker.defaultProps = defaultProps;

export default OrderTracker;
