import React from 'react';
import cx from 'classnames';

import { Modal } from 'react-bootstrap';
import styles from './StartNewCartModalBlock.module.scss';

import ModalHeader, { ModalHeaderProps } from '../../molecules/ModalHeader';
import Text, { TextProps } from '../../atoms/Text';
import ButtonGroup1, { ButtonGroup1Props } from '../../molecules/ButtonGroup1';

export const defaultProps = {
  modalHeader: {
    type: 'Close',
    menuItem: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as ModalHeaderProps,
  messageText: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  buttonGroup1: {
    leftButton: {
      style: 'BrandSquare',
      size: 'Medium',
      type: 'Text',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    rightButton: {
      style: 'BasicSquare',
      size: 'Medium',
      type: 'Text',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey800',
      },
    },
  } as ButtonGroup1Props,
};

export type StartNewCartModalBlockProps = {
  className?: string;
  modalHeader?: ModalHeaderProps;
  messageText?: TextProps;
  buttonGroup1?: ButtonGroup1Props;
  startNewCartModalOpen?: boolean;
  newRestaurantName?: string;
  handleStartNewCart?: () => void;
  handleClose?: () => void;
};

const StartNewCartModalBlock: React.FC<StartNewCartModalBlockProps> = ({
  className,
  buttonGroup1,
  messageText,
  modalHeader,
  startNewCartModalOpen,
  handleClose,
}) => (
  <Modal
    animation={false}
    className={cx(styles.modal, className)}
    dialogClassName={styles.modalDialog}
    contentClassName={styles.modalContent}
    show={startNewCartModalOpen}
  >
    <Modal.Header className={styles.modalHeader}>
      <ModalHeader className={styles.modalHeader} {...modalHeader} />
    </Modal.Header>
    <Modal.Body className={styles.modalContent}>
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <Text {...messageText} />
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer className={styles.modalFooter}>
      <ButtonGroup1 className={styles.buttonGroup1} {...buttonGroup1} />
    </Modal.Footer>
  </Modal>
);

StartNewCartModalBlock.defaultProps = defaultProps;

export default StartNewCartModalBlock;
