import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CustomerBasket, MenuItem, OrderType } from '../../../modules/checkout/types';
import EmptyCartAsset from '../../../resources/images/Cart.png';
import { CartOverviewProps } from '../../organisms/CartOverview';
import { CartOverviewBlockProps, defaultProps } from './CartOverviewBlock';
import {
  defaultProps as priceOverviewItemDefaultProps,
  PriceOverviewItemProps,
} from '../../organisms/PriceOverviewItem/PriceOverviewItem';
import { PriceISectionItemListProps } from '../../organisms/PriceISectionItemList/PriceISectionItemList';
import {
  defaultProps as priceListItemDefaultProps,
} from '../../molecules/PriceListItem/PriceListItem';
import { formatPrice } from '../MenuBlocksList/utils';
import { calculateBasketTotal } from '../../../modules/checkout/utils';
import { makePriceSectionItem } from '../../../modules/menu/utils';

export type CartOverviewBlockPresenterProps = CartOverviewBlockProps & {
  basket?: CustomerBasket;
};
const withPresenter = (
  View: React.FC<CartOverviewBlockProps>,
): React.FC<CartOverviewBlockPresenterProps> => {
  const Presenter: React.FC<CartOverviewBlockPresenterProps> = (props) => {
    const { onClose, basket, setItem, showEditModal, showConfirmationModal } =
      props;
    const { t } = useTranslation();
    const history = useHistory();

    const notEmpty = basket && basket.items && basket.items.length > 0;

    const cost = basket ? calculateBasketTotal(basket) : 0;

    const cartMessageValue = basket?.orderType && basket.orderType === 'Pickup' ?
     t('cart_overview_modal.items_for_pickup') : t('cart_overview_modal.items_for_delivery');

    const priceOverviewItems: PriceOverviewItemProps[] | undefined =
      basket?.items.map((item: MenuItem, itemPosition: number) => {
        return {
          ...priceOverviewItemDefaultProps,
          showNotification: Boolean(item.remark),
          notification: {
            ...priceOverviewItemDefaultProps.notification,
            title: {
              ...priceOverviewItemDefaultProps.notification.title,
              value: t('cart_overview_modal.additional_instructions'),
            },
            text: {
              ...priceOverviewItemDefaultProps.notification.text,
              value: item.remark,
            },
          },
          buttonGroup1: {
            ...priceOverviewItemDefaultProps.buttonGroup1,
            leftButton: {
              ...priceOverviewItemDefaultProps.buttonGroup1.leftButton,
              text: {
                ...priceOverviewItemDefaultProps.buttonGroup1.leftButton?.text,
                value: t('cart_overview_modal.edit'),
              },
              onButtonClicked: () => {
                setItem && setItem(item, itemPosition);
                showEditModal && showEditModal();
              },
            },
            rightButton: {
              ...priceOverviewItemDefaultProps.buttonGroup1.rightButton,
              text: {
                ...priceOverviewItemDefaultProps.buttonGroup1.rightButton?.text,
                value: t('cart_overview_modal.remove'),
              },
              onButtonClicked: () => {
                setItem && setItem(item, itemPosition);
                showConfirmationModal && showConfirmationModal();
              },
            },
          },
          menuItem: {
            ...priceOverviewItemDefaultProps.menuItem,
            itemName: {
              ...priceOverviewItemDefaultProps.menuItem.itemName,
              value: item.name,
            },
            cost: {
              ...priceOverviewItemDefaultProps.menuItem.cost,
              value: formatPrice(item.price * item.quantity),
            },
            itemQuantity: {
              ...priceOverviewItemDefaultProps.menuItem.itemQuantity,
              value: item.quantity ? String(item.quantity) : '',
            },
          },
          priceISectionItemList: {
            ...priceOverviewItemDefaultProps.priceISectionItemList,
            priceSectionItems: item.subItems
              ? item.subItems.map((subItem: MenuItem) =>
                  makePriceSectionItem(subItem),
                )
              : [],
          } as PriceISectionItemListProps,
        };
      });

    const blockProps: CartOverviewBlockProps = {
      ...defaultProps,
      type: notEmpty ? 'Default' : 'EmptyStateContent',

      modalHeader: {
        ...defaultProps.modalHeader,
        button: {
          ...defaultProps.modalHeader.button,
          onButtonClicked: onClose,
        },
        menuItem: {
          ...defaultProps.modalHeader.menuItem,
          value: t('cart_overview_modal.cart'),
        },
      },
      topContent: {
        ...defaultProps.topContent,
        title: {
          ...defaultProps.topContent.title,
          value: t('cart_overview_modal.top_content'),
        },
      },
      emptyStateContent: {
        ...defaultProps.emptyStateContent,
        image: {
          ...defaultProps.emptyStateContent.image,
          srcValue: EmptyCartAsset,
        },
        titleText: {
          ...defaultProps.emptyStateContent.titleText,
          value: t('cart_overview_modal.empty_cart.title'),
        },
        messageText: {
          ...defaultProps.emptyStateContent.messageText,
          value: t('cart_overview_modal.empty_cart.message'),
        },
      },
      button: {
        ...defaultProps.button,
        onButtonClicked: () => {
          onClose && onClose();
          if (notEmpty) {
            history.push('/checkout');
          } else {
            history.push('/');
          }
        },
        text: {
          ...defaultProps.button.text,
          value: notEmpty
            ? t('cart_overview_modal.default.button')
            : t('cart_overview_modal.empty_cart.button'),
        },
      },
      cartOverview: {
        ...defaultProps.cartOverview,
        text: {
          ...defaultProps.cartOverview.text,
          value: basket?.restaurantName,
        },
        cartMessage: {
          ...defaultProps.cartOverview.cartMessage,
          value: cartMessageValue,
        },
        priceOverviewItemList: {
          priceOverviewItems,
        },
      },

      priceListItem: {
        ...defaultProps.priceListItem,
        description: {
          ...priceListItemDefaultProps.description,
          value: t('cart_overview_modal.subtotal'),
        },
        cost: {
          ...priceListItemDefaultProps.cost,
          value: formatPrice(cost),
        },
      } as CartOverviewProps,
    };
    return <View {...props} {...blockProps} />;
  };
  return Presenter;
};

export default withPresenter;