import React from 'react';
import cx from 'classnames';

import styles from './CartOverview.module.scss';

import Text, {TextProps} from '../../atoms/Text';
import Divider, { DividerProps } from '../../atoms/Divider';
import PriceOverviewItemList, { PriceOverviewItemListProps } from '../PriceOverviewItemList';

export const defaultProps = {
  text: {
    type: 'Title3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  cartMessage: {
    type: 'Body1',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  divider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  priceOverviewItemList: {
    priceOverviewItems: [
    ],
  } as PriceOverviewItemListProps,
};

export type CartOverviewProps = {
  text?: TextProps;
  cartMessage?: TextProps;
  divider?: DividerProps;
  priceOverviewItemList?: PriceOverviewItemListProps;
  className?: string;
};

const CartOverview: React.FC<CartOverviewProps> = ({
  cartMessage,
  divider,
  priceOverviewItemList,
  className,
  text
}) => {
  return (
    <div className={cx(styles.cartOverview, className)}>
      <Text
        className={styles.text}
        {...text} />
      <Text
        className={styles.cartMessage}
        {...cartMessage} />
      <Divider
        className={styles.divider}
        {...divider} />
      <PriceOverviewItemList
        className={styles.priceOverviewItemList}
        {...priceOverviewItemList} />
    </div>
  );
};

CartOverview.defaultProps = defaultProps;

export default CartOverview;
