import React from "react";
import { MenuBlocksListProps } from "./MenuBlocksList";
import { MenuBlocksListPresenterProps } from "./MenuBlocksList.presenter";

const withInteractor = (
  Presenter: React.FC<MenuBlocksListPresenterProps>
): React.FC<MenuBlocksListProps> => {
  const Interactor: React.FC<MenuBlocksListProps> = (props) => {
    return <Presenter {...props} />;
  };
  return Interactor;
};
export default withInteractor;

