import React from 'react';
import cx from 'classnames';

import styles from './PaymentInfo.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import Text, { TextProps } from '../../atoms/Text';
import Icon, { IconProps } from '../../atoms/Icon';

export type PaymentInfoLoadingType = 'Default' | 'Loading';

export const defaultProps = {
  loading: 'Loading' as PaymentInfoLoadingType,
  topDivider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
  title: {
    type: 'Title4',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  text: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  icon: {
    asset: 'Mastercard',
    style: 'Grey1100',
  } as IconProps,
  bottomDivider: {
    type: 'HorizontalSolid',
    style: 'Grey400',
  } as DividerProps,
};

export type PaymentInfoProps = {
  loading?: PaymentInfoLoadingType;
  topDivider?: DividerProps;
  title?: TextProps;
  text?: TextProps;
  icon?: IconProps;
  bottomDivider?: DividerProps;
  className?: string;
};

const PaymentInfo: React.FC<PaymentInfoProps> = ({
  loading,
  topDivider,
  title,
  text,
  icon,
  bottomDivider,
  className,
}) => {
  const currentStyle = styles[`paymentInfo${loading}`];

  const topDividerView = (
    <Divider
      className={styles.topDivider}
      {...topDivider} />
  );
  const bottomDividerView = (
    <Divider
      className={styles.bottomDivider}
      {...bottomDivider} />
  );

  let textView;
  let contentOneView;
  let contentView;
  let contentTwoView;

  switch (loading) {
    case 'Default':
      textView = (
        <Text
          className={styles.title}
          {...title} />
      );
      contentView = (
        <div className={styles.content}>
          <div className={styles.cardInfo}>
            <Icon
              className={styles.icon}
              {...icon} />
            <Text
              className={styles.text}
              {...text} />
          </div>
        </div>
      );
      break;
    case 'Loading':
      contentOneView = (
        <div className={styles.contentOne}/>
      );
      contentTwoView = (
        <div className={styles.contentTwo}/>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {topDividerView}
      {textView}
      {contentOneView}
      {contentView}
      {contentTwoView}
      {bottomDividerView}
    </div>
  );
};

PaymentInfo.defaultProps = defaultProps;

export default PaymentInfo;
