import React from 'react';
import styles from './App.module.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import SimpleLayout from './components/layout/SimpleLayout';

const App: React.FC<{}> = () => {
  const props: any = {
    className: styles.layout,
  };

  return (
    <Router>
      <Switch>
        <Route path='/'>
          <SimpleLayout {...props} />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;