import React, { RefObject, useState } from 'react';
import cx from 'classnames';

import styles from './OrderInProgressBanner.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Icon, { IconProps } from '../../atoms/Icon';

export const defaultProps = {
  leftIcon: {
    asset: 'OrderCircle',
    style: 'Green800',
  } as IconProps,
  descriptionText: {
    type: 'Button1',
    align: 'Center',
    style: 'Grey100',
  } as TextProps,
  rightIcon: {
    asset: 'ChevronRight',
    style: 'Grey100',
  } as IconProps,
};

export type OrderInProgressBannerProps = {
  leftIcon?: IconProps;
  descriptionText?: TextProps;
  rightIcon?: IconProps;
  onClick?: () => void;
  className?: string;
};

const OrderInProgressBanner: React.FC<OrderInProgressBannerProps> = ({
  leftIcon,
  descriptionText,
  rightIcon,
  onClick,
  className,
}) => {

  return (
    <div className={cx(styles.orderInProgressBanner, className)} onClick={onClick}>
      <Icon
        className={styles.leftIcon}
        {...leftIcon}
      />
      <div className={styles.centerContent}>
        <Text className={styles.descriptionText} {...descriptionText} />
        <Icon
          className={styles.rightIcon}
          {...rightIcon}
        />
      </div>
    </div>
  );
};

OrderInProgressBanner.defaultProps = defaultProps;

export default OrderInProgressBanner;
