import React from "react";
import { RestaurantBannerProps } from "./RestaurantBanner";
import { defaultProps } from "../../pages/RestaurantPage/RestaurantPage";
import { useHistory } from "react-router";

export type RestaurantBannerPresenterProps = RestaurantBannerProps;

const withPresenter = (
  View: React.FC<RestaurantBannerProps>
): React.FC<RestaurantBannerPresenterProps> => {
  const Presenter: React.FC<RestaurantBannerPresenterProps> = (props) => {
    const { restaurant } = props;
    const history = useHistory();
    let blockProps: RestaurantBannerProps = {};

    if (restaurant) {
      const details = restaurant.details;
      blockProps = {
        ...defaultProps.restaurantBanner,
        button: {
          ...defaultProps.restaurantBanner.button,
          onButtonClicked: () => history.replace('/'),
        },
        
        background: details?.thumbnails?.full,

        titleText: {
          ...defaultProps.restaurantBanner.titleText,
          value: details?.name
        },
  
        cusineTypeText: {
          ...defaultProps.restaurantBanner.cusineTypeText,
          value: details?.cuisineType
        },
  
        addressText: {
            ...defaultProps.restaurantBanner.addressText,
            value: details?.address
        }
      };
    }

    return <View {...props} {...blockProps} />;
  };
  return Presenter;
};

export default withPresenter;
