import axios from 'axios';
import { BFF_URL } from '../../lib/config';
import { Address } from '../../lib/token';
import {
  GetAddressDetailsApiRequest,
  GetAddressDetailsApiResponse,
  GetSuggestionsApiRequest,
  GetSuggestionsApiResponse,
} from './types';

export const getAddressSuggestions = async (params: GetSuggestionsApiRequest) => {
  try {
    const { data } = await axios.get<GetSuggestionsApiResponse>(`${BFF_URL}/address/getSuggestions`, {
      params,
    });
    return data;
  } catch (err) {
    throw new Error();
  }
};

export const getAddressDetails = async (params: GetAddressDetailsApiRequest) => {
  try {
    const { data } = await axios.get<Address>(`${BFF_URL}/address/getAddressDetails`, {
      params,
    });
    return data;
  } catch (err) {
    throw new Error();
  }
};
