import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './AddressModalBlock.module.scss';
import AddressModal, { AddressModalProps } from '../../organisms/AddressModal';
import { ModalHeaderProps } from '../../molecules/ModalHeader';
import { ButtonProps } from '../../atoms/Button';
import { AddressItemProps } from '../../molecules/AddressItem';
import ChangeAddressModal from '../../organisms/ChangeAddressModal';
import { TextProps } from '../../atoms/Text';

export type AddressModalType = 'view_address' | 'change_address';

export const defaultProps = {
  addressModal: {
    modalHeader: {
      type: 'Close',
      menuItem: {
        type: 'Title3',
        align: 'Left',
        style: 'Grey1100',
      },
      button: {
        size: 'ExtraSmall',
        type: 'Icon',
        style: 'BasicSquare',
        icon: {
          asset: 'Close',
          style: 'Grey1100',
        },
      },
      divider: {
        type: 'HorizontalSolid',
        style: 'Grey400',
      },
    } as ModalHeaderProps,
    addressItem: {
      street: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey1100',
      } as TextProps,
      postalCode: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey1100',
      } as TextProps,
      cityAndProvince: {
        type: 'Body3',
        align: 'Left',
        style: 'Grey1100',
      } as TextProps,
      changeButton: {
        size: 'Small',
        type: 'Text',
        style: 'InfoRounded',
        text: {
          type: 'Button2',
          align: 'Center',
          style: 'Blue800',
        },
      },
    } as AddressItemProps,
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    } as ButtonProps,
  } as AddressModalProps,
  changeAddressModal: {
    modalHeader: {
      type: 'Close',
      menuItem: {
        type: 'Title3',
        align: 'Left',
        style: 'Grey1100',
      },
      button: {
        size: 'ExtraSmall',
        type: 'Icon',
        style: 'BasicSquare',
        icon: {
          asset: 'Close',
          style: 'Grey1100',
        },
      },
      divider: {
        type: 'HorizontalSolid',
        style: 'Grey400',
      },
    } as ModalHeaderProps,
    addressAutoComplete: {
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      errorText: {
        style: 'Red800',
        align: 'Left',
        type: 'Body2',
      },
    },
    addressLine2: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    deliveryInstructions: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    } as ButtonProps,
  },
} as any;

export type AddressModalBlockProps = {
  addressModal?: AddressModalProps;
  onClose?: () => void;
  open?: boolean;
  addressModalType?: AddressModalType;
  changeAddressModal?: any;
  isChangeAddress?: boolean;
  updateSkipToAddressChange?: (isSkipToChange: boolean) => void;
  error?: Error;
};

const AddressModalBlock: React.FC<AddressModalBlockProps> = ({
  addressModal,
  onClose,
  open,
  addressModalType,
  changeAddressModal,
}) => (
  <Modal
    className={styles.modal}
    dialogClassName={styles.modalDialog}
    contentClassName={styles.modalContent}
    show={open}
    onHide={onClose}
  >
    {
      addressModalType === 'view_address' ? (
        <AddressModal
          className={styles.addressModal}
          {...addressModal}
        />
      ) : (
        <ChangeAddressModal
          className={styles.changeAddressModal}
          {...changeAddressModal}
        />
      )
    }
  </Modal>);

export default AddressModalBlock;
