import React from 'react';
import cx from 'classnames';

import styles from './ErrorNotification.module.scss';

import Icon, { IconProps } from '../../atoms/Icon';
import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  icon: {
    asset: 'ErrorCircle',
    style: 'Red800',
  } as IconProps,
  text: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
};

export type ErrorNotificationProps = {
  icon?: IconProps;
  text?: TextProps;
  className?: string;
};

const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  icon,
  text,
  className,
}) => {
  return (
    <div className={cx(styles.errorNotification, className)}>
      <Icon
        className={styles.icon}
        {...icon} />
      <Text
        className={styles.text}
        {...text} />
    </div>
  );
};

ErrorNotification.defaultProps = defaultProps;

export default ErrorNotification;
