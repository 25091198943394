import React, { useCallback, useState, useEffect } from 'react';
import { Product } from '../../../modules/menu/types';
import { MenuSectionItemProps, defaultProps } from './MenuSectionItem';
import { SelectableProduct } from './types';
import {
  convertToMenuItemList,
  convertToSectionHeader,
  convertToSelectedMenuItem,
} from './utils';

export type MenuSectionItemPresenterProps = MenuSectionItemProps & {};

const withPresenter = (
  View: React.FC<MenuSectionItemProps>,
): React.FC<MenuSectionItemPresenterProps> => {
  const Presenter: React.FC<MenuSectionItemPresenterProps> = (props) => {
    const {
      itemGroup,
      itemMap,
      handleChange,
      alreadySelectedItems,
      isRequiredError,
      isQuantityError,
    } = props;

    const [groupItems, setGroupItems] = useState<Product[]>([]);
    const [selectableGroupItems, setSelectableGroupItems] = useState<
      SelectableProduct[]
    >([]);
    const [selectedItems, setSelectedItems] = useState<string[]>(
      alreadySelectedItems || [],
    );
    const [itemsSelectState, setItemsSelectState] = useState<
      Record<string, 'selected' | 'unselected'>
    >({});
    const [menuSectionItemProps, setMenuSectionItemProps] =
      useState<MenuSectionItemProps>({
        ...defaultProps,
      });

    useEffect(() => {
      const alreadySelected = {};
      alreadySelectedItems?.forEach(
        (item) => (alreadySelected[item] = 'selected'),
      );
      setItemsSelectState(alreadySelected);
    }, [alreadySelectedItems, setItemsSelectState]);

    useEffect(() => {
      if (itemGroup && itemMap) {
        const items = itemGroup.subProducts
          .filter((modifierId) => itemMap[modifierId])
          .map((modifierId) => itemMap[modifierId]);
        setGroupItems(items);
      }
    }, [itemGroup, itemMap]);

    useEffect(() => {
      const items: SelectableProduct[] = groupItems.map((item) => ({
        ...item,
        selected: itemsSelectState[item._id] === 'selected',
        quantity: selectedItems.length,
      }));
      setSelectableGroupItems(items);
    }, [groupItems, selectedItems, itemsSelectState]);

    const onItemSelected = useCallback(
      (id: string, selected: boolean, type: 'Radio' | 'Checkbox') => {
        let updatedItems = [...selectedItems];
        let updatedItemsSelectState = { ...itemsSelectState };

        switch (type) {
          case 'Checkbox':
            if (selected) {
              if (!selectedItems.includes(id)) {
                updatedItems = [...selectedItems, id];
              }
            } else {
              updatedItems = selectedItems.filter(
                (selectedId) => selectedId !== id,
              );
            }
            updatedItemsSelectState = {
              ...updatedItemsSelectState,
              ...{ [id]: selected ? 'selected' : 'unselected' },
            };
            break;
          case 'Radio':
            updatedItems = [id];
            updatedItemsSelectState = {
              [id]: 'selected',
            };
            break;
        }

        if (handleChange && itemGroup && itemMap) {
          handleChange({
            plu: itemGroup.plu,
            name: itemGroup.name,
            price: itemGroup.price || 0,
            productType: itemGroup.productType,
            quantity: updatedItems.length,
            subItems: convertToSelectedMenuItem(itemMap, updatedItems),
            tax: itemGroup.takeawayTax,
            productId: itemGroup._id,
          });
        }

        setItemsSelectState(updatedItemsSelectState);
        setSelectedItems(updatedItems);
      },
      [itemGroup, itemMap, handleChange, selectedItems, itemsSelectState],
    );

    const onQuantityChanged = useCallback(
      (id: string, quantity: string, type: 'Radio' | 'Checkbox') => {
        const updatedItems = [...selectedItems].filter(
          (existingId) => existingId !== id,
        );

        let quantityInt = 0;
        if (quantity !== '') {
          quantityInt = parseInt(quantity, 10);
          for (let amount = 0; amount < quantityInt; amount++) {
            updatedItems.push(id);
          }
        }

        if (handleChange && itemGroup && itemMap) {
          handleChange({
            plu: itemGroup.plu,
            name: itemGroup.name,
            price: itemGroup.price || 0,
            productType: itemGroup.productType,
            quantity: updatedItems.length,
            subItems: convertToSelectedMenuItem(itemMap, updatedItems),
            tax: itemGroup.deliveryTax,
            multiMax: itemGroup.multiMax,
            productId: itemGroup._id,
          });
        }
        setSelectedItems(updatedItems);
      },
      [itemGroup, itemMap, handleChange, selectedItems],
    );

    useEffect(() => {
      if (itemGroup && itemMap) {
        const menuItemList = convertToMenuItemList(
          itemGroup,
          selectableGroupItems,
          selectedItems,
          onItemSelected,
          onQuantityChanged,
          isQuantityError,
        );

        const sectionHeader = convertToSectionHeader(
          itemGroup,
          isRequiredError,
        );
        setMenuSectionItemProps((previousState) => ({
          ...previousState,
          menuItemList,
          sectionHeader,
        }));
      }
    }, [
      itemGroup,
      itemMap,
      setMenuSectionItemProps,
      selectableGroupItems,
      selectedItems,
      onQuantityChanged,
      onItemSelected,
      isRequiredError,
      isQuantityError,
    ]);

    return <View {...props} {...menuSectionItemProps} />;
  };
  return Presenter;
};

export default withPresenter;
