import React from 'react';
import { useHistory } from 'react-router';
import { MenuBlocksListProps } from './MenuBlocksList';
import { MenuListItemBlockProps } from '../MenuListItemBlock';
import { defaultProps as menuListItemBlockDefaultProps } from '../MenuListItemBlock/MenuListItemBlock';
import { convertToFoodItemCard } from './utils';
import { FoodItemCardProps, defaultProps as defaultFoodItemCardProps } from '../../molecules/FoodItemCard/FoodItemCard';

export type MenuBlocksListPresenterProps = MenuBlocksListProps;
const loadingFoodItemCard: FoodItemCardProps = {
  ...defaultFoodItemCardProps,
  loading: 'Loading',
};
const loadingFoodItemCards: FoodItemCardProps[] = new Array(9).fill(loadingFoodItemCard);

const withPresenter = (
  View: React.FC<MenuBlocksListProps>,
): React.FC<MenuBlocksListPresenterProps> => {
  const Presenter: React.FC<MenuBlocksListPresenterProps> = (props) => {
    const { menu, focusedBlockId, isLoading } = props;
    const history = useHistory();
    const menuListItemBlocks: MenuListItemBlockProps[] = [];

    if (isLoading) {
      const menuListItemBlock: MenuListItemBlockProps = {
        ...menuListItemBlockDefaultProps,
        foodItemCardList: {
          foodItemCards: loadingFoodItemCards,
        },
      };
      menuListItemBlocks.push(menuListItemBlock);
    }

    if (menu && menu.length > 0) {
      const menuObject = menu[0].menuobject;
      menuObject.categories.forEach((category) => {
        const foodItemCards = category.products
          // filter existing products
          .filter((productId) => menuObject.products[productId])
          // convert product to food item card props
          .map((productId) => ({
            ...convertToFoodItemCard(menuObject.products[productId]),
            onClick: (): void => history.push({
              search: `?product=${productId}`,
            }),
          }));

        const blockId = category._id;
        const menuListItemBlock: MenuListItemBlockProps = {
          ...menuListItemBlockDefaultProps,
          blockId,
          focusedBlockId,
          blockTitle: {
            ...menuListItemBlockDefaultProps.blockTitle,
            value: category.name,
          },
          foodItemCardList: {
            ...menuListItemBlockDefaultProps.blockTitle,
            foodItemCards,
          },
        };
        menuListItemBlocks.push(menuListItemBlock);
      });
    }
    return <View
      {...props}
      menuListItemBlocks={menuListItemBlocks} />;
  };
  return Presenter;
};

export default withPresenter;
