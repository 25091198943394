import React from 'react';
import cx from 'classnames';

import styles from './ButtonItem.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';
import Chip, { ChipProps } from '../Chip';

export type ButtonItemSizeType = 'Small' | 'Large';
export type ButtonItemTypeType = 'Default' | 'Chip';
export type ButtonItemStyleType = 'Default' | 'Destructive';
export type ButtonItemButtonItemTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  size: 'Large' as ButtonItemSizeType,
  type: 'Default' as ButtonItemTypeType,
  style: 'Default' as ButtonItemStyleType,
  icon: {
    asset: 'Pdf',
    style: 'Grey1100',
  } as IconProps,
  text: {
    type: 'Button2',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  chip: {
    style: 'Default',
    text: {
      type: 'Body4',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ChipProps,
};

export type ButtonItemProps = {
  size?: ButtonItemSizeType;
  type?: ButtonItemTypeType;
  style?: ButtonItemStyleType;
  buttonItemType?: ButtonItemButtonItemTypeType;
  onButtonItemClicked?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  text?: TextProps;
  className?: string;
  chip?: ChipProps;
};

const ButtonItem: React.FC<ButtonItemProps> = ({
  size,
  type,
  style,
  buttonItemType,
  onButtonItemClicked,
  icon,
  text,
  className,
  chip,
}) => {
  
  const currentStyle = styles[`buttonItem${size}${type}${style}`];
  const iconView = (
    <Icon
      className={styles.icon}
      {...icon} />
  );
  const textView = (
    <Text
      className={styles.text}
      {...text} />
  );
  
  let chipView;
  
  switch (type) {
    case 'Default':
      break;
    case 'Chip':
      chipView = (
        <Chip
          className={styles.chip}
          {...chip} />
      );
      break;
  }

  return (
    <button
      type={buttonItemType}
      onClick={onButtonItemClicked}
      className={cx(currentStyle, className)}>
      {iconView}
      {textView}
      {chipView}
    </button>
  );
};

ButtonItem.defaultProps = defaultProps;

export default ButtonItem;
