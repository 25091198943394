import React from 'react';
import cx from 'classnames';

import styles from './CreatePasswordBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  createPassword: {
    state: 'Default',
    type: 'Password',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Password',
      style: 'Default',
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      icon: {
        asset: 'Show',
        style: 'Grey1100',
      },
    },
    errorText: {
      style: 'Red800',
      align: 'Left',
      type: 'Body2',
    },
  } as TextFieldProps,
  confirmPassword: {
    state: 'Default',
    type: 'Password',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Password',
      style: 'Default',
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey700',
      },
      icon: {
        asset: 'Show',
        style: 'Grey1100',
      },
    },
    errorText: {
      style: 'Red800',
      align: 'Left',
      type: 'Body2',
    },
  } as TextFieldProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type CreatePasswordBlockProps = {
  title?: TextProps;
  description?: TextProps;
  createPassword?: TextFieldProps;
  confirmPassword?: TextFieldProps;
  button?: ButtonProps;
  className?: string;
};

const CreatePasswordBlock: React.FC<CreatePasswordBlockProps> = ({
  title,
  description,
  createPassword,
  confirmPassword,
  button,
  className,
}) => {
  return (
    <div className={cx(styles.createPasswordBlock, className)}>
      <div className={styles.topContent}>
        <Text
          className={styles.title}
          {...title} />
        <Text
          className={styles.description}
          {...description} />
      </div>
      <div className={styles.formContent}>
        <TextField
          className={styles.createPassword}
          {...createPassword} />
        <TextField
          className={styles.confirmPassword}
          {...confirmPassword} />
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

CreatePasswordBlock.defaultProps = defaultProps;

export default CreatePasswordBlock;
