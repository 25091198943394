import React from 'react';
import cx from 'classnames';

import styles from './CheckoutPriceBlock.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import TopContent, { TopContentProps } from '../../molecules/TopContent';
import Toggle, { ToggleProps } from '../../molecules/Toggle';
import DeliveryInfo, { DeliveryInfoProps } from '../../molecules/DeliveryInfo';
import PriceList, { PriceListProps } from '../../organisms/PriceList';
import PriceItem, { PriceItemProps } from '../../molecules/PriceItem';
import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
  } as ButtonProps,
  topContent: {
    title: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey700',
    },
  } as TopContentProps,
  toggle: {
    secondOption: {
      state: 'Selected',
      text: {
        type: 'Button2',
        align: 'Center',
        style: 'Grey100',
      },
    },
    firstOption: {
      state: 'Unselected',
      text: {
        type: 'Button2',
        align: 'Center',
        style: 'Grey1100',
      },
    },
  } as ToggleProps,
  deliveryInfo: {
    type: 'Delivery',
    loading: 'Default',
    state: 'Default',
    text: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    icon: {
      asset: 'Location',
      style: 'Grey1100',
    },
    topDivider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
    topIcon: {
      asset: 'Location',
      style: 'Grey1100',
    },
    bottomIcon: {
      asset: 'InfoOutline',
      style: 'Grey1100',
    },
    topText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
    bottomText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey1100',
    },
    deliveryInstruction: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey800',
    },
    dropdownToggle: {
      text: {
        type: 'Body3',
        align: 'Center',
        style: 'Grey1100',
      },
      icon: {
        asset: 'ChevronDown',
        style: 'Grey1100',
      },
    },
    bottomDivider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
    errorNotification: {
      type: 'Error',
      icon: {
        asset: 'ErrorCircle',
        style: 'Red800',
      },
      text: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
    },
  } as DeliveryInfoProps,
  priceList: {
    priceItems: [
    ],
  } as PriceListProps,
  priceItem: {
    type: 'ListItemDark',
    state: 'Collapsed',
    item: {
      type: 'Body5',
      align: 'Left',
      style: 'Grey1100',
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey800',
    },
    price: {
      type: 'Body5',
      align: 'Right',
      style: 'Grey1100',
    },
  } as PriceItemProps,
  flavourText: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type CheckoutPriceBlockProps = {
  backButton?: ButtonProps;
  topContent?: TopContentProps;
  toggle?: ToggleProps;
  deliveryInfo?: DeliveryInfoProps;
  priceList?: PriceListProps;
  priceItem?: PriceItemProps;
  button?: ButtonProps;
  className?: string;
  showToggle?: boolean;
  handleDropdownToggleClick?: () => void;
  toggleUseBasketOrderType?: () => void;
  error?: Error;
  flavourText?: TextProps;
};

const CheckoutPriceBlock: React.FC<CheckoutPriceBlockProps> = ({
  backButton,
  topContent,
  toggle,
  deliveryInfo,
  priceList,
  priceItem,
  button,
  className,
  showToggle,
  flavourText,
}) => {
  const toggleView = showToggle ? (
    <Toggle
    className={styles.toggle}
    {...toggle} />
  ) : undefined;
  const priceLines = (
    <>
      <PriceList
        className={styles.priceList}
        {...priceList} />
      <PriceItem
        className={styles.priceItem}
        {...priceItem} />
    </>
  );
  return (
    <div className={cx(styles.checkoutPriceBlock, className)}>
      <div className={styles.content}>
        <div className={styles.allContent}>
          <Button
            className={styles.backButton}
            {...backButton} />
          <TopContent
              className={styles.topContent}
              {...topContent} />
          {toggleView}
          <DeliveryInfo
            className={styles.deliveryInfo}
            {...deliveryInfo} />
          {priceLines}
        </div>
        <Text
          className={styles.flavourText}
          {...flavourText} />
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

CheckoutPriceBlock.defaultProps = defaultProps;

export default CheckoutPriceBlock;
