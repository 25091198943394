import React from 'react';
import cx from 'classnames';

import styles from './CustomAmount.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import TextInput, { TextInputProps } from '../../atoms/TextInput';
import Text, { TextProps } from '../../atoms/Text';

export type CustomAmountStateType = 'Default' | 'Selected';

export const defaultProps = {
  state: 'Selected' as CustomAmountStateType,
  button: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    icon: {
      asset: 'Plus',
      style: 'Grey1100',
    },
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  textInput: {
    type: 'Text',
    style: 'LeadingIcon',
    icon: {
      asset: 'Dollar',
      style: 'Grey1100',
    },
  } as TextInputProps,
};

export type CustomAmountProps = {
  state?: CustomAmountStateType;
  button?: ButtonProps;
  className?: string;
  textInput?: TextInputProps;
  onButtonClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  errorText?: TextProps;
};

const CustomAmount: React.FC<CustomAmountProps> = ({
  state,
  button,
  className,
  textInput,
  onButtonClicked,
  errorText,
}) => {

  const currentStyle = styles[`customAmount${state}`];

  let buttonView;
  let textInputView;
  const errorTextView = <Text {...errorText} />;

  switch (state) {
    case 'Default':
      buttonView = (
        <Button
          className={styles.button}
          {...button}
          onButtonClicked={onButtonClicked}
        />
      );
      break;
    case 'Selected':
      textInputView = (
          <>
            <TextInput
              className={styles.textInput}
              {...textInput}
            />
            {errorTextView}
          </>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {buttonView}
      {textInputView}
    </div>
  );
};

CustomAmount.defaultProps = defaultProps;

export default CustomAmount;
