import React from "react";
import { RestaurantBannerProps } from "./RestaurantBanner";
import { RestaurantBannerPresenterProps } from "./RestaurantBanner.presenter";

const withInteractor = (
  Presenter: React.FC<RestaurantBannerPresenterProps>
): React.FC<RestaurantBannerProps> => {
  const Interactor: React.FC<RestaurantBannerProps> = (props) => {
    return <Presenter {...props} />;
  };
  return Interactor;
};
export default withInteractor;

