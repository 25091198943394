import React, { useCallback, createContext, useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import { CustomerBasket, OrderType } from './types';
import { BASKET_COOKIE_NAME } from '../../lib/config';
import { OrderTypeSessionStoragekey } from '../../lib/constants';

export type CheckoutContextValue = {
  basket?: CustomerBasket;
  updateBasket: (basket?: CustomerBasket) => void;
  orderType: OrderType;
  updateOrderType: (orderType: OrderType) => void;
  useBasketOrderType: boolean;
  updateUseBasketOrderType: (flag: boolean) => void;
};

const initialCheckoutContext: CheckoutContextValue = {
  basket: undefined,
  updateBasket: () => {},
  orderType: 'Pickup',
  updateOrderType: () => {},
  useBasketOrderType: false,
  updateUseBasketOrderType: () => {}
};

export const CheckoutContext = createContext<CheckoutContextValue>(
  initialCheckoutContext,
);

export const CheckoutProvider: React.FC<{}> = ({ children }) => {
  const cookie = Cookie.get(BASKET_COOKIE_NAME);
  const cookieBasket: CustomerBasket | undefined = cookie
    ? JSON.parse(cookie)
    : undefined;

  const [basket, setBasket] = useState(cookieBasket);
  const [orderType, setOrderType] = useState<OrderType>('Pickup');
  // useBasketOrderType is required for showing correct ChangeAddressModal in CheckoutPriceBlock
  const [useBasketOrderType, setUseBasketOrderType] = useState(false);
  
  useEffect(() => {
    if (basket && !basket?.items?.length) {
      setBasket(undefined);
      Cookie.remove(BASKET_COOKIE_NAME);
    }
  }, [basket, basket?.items?.length]);

  const updateBasket = useCallback((basket?: CustomerBasket): void => {
    setBasket(basket);
    const basketJson = basket ? JSON.stringify(basket) : '';
    Cookie.set(BASKET_COOKIE_NAME, basketJson, {});
  }, []);

  const updateOrderType = useCallback((orderType: OrderType): void => {
    setOrderType(orderType);
    window.sessionStorage.setItem(OrderTypeSessionStoragekey, orderType);
  }, []);

  const updateUseBasketOrderType = useCallback((flag: boolean): void => {
    setUseBasketOrderType(flag);
  }, []);


  return (
    <CheckoutContext.Provider
      value={{
        basket,
        updateBasket,
        orderType,
        updateOrderType,
        useBasketOrderType,
        updateUseBasketOrderType
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
