import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useWindowSize from '../../../lib/useWindowSize';
import { OrderObject } from '../../../modules/checkout/types';
import { defaultProps as bannerDefaultProps, OrderInProgressBannerProps } from '../../organisms/OrderInProgressBanner/OrderInProgressBanner';
import { defaultProps, RestaurantListPageProps, RestaurantPageStyle } from './RestaurantListPage';


export type RestaurantListPagePresenterProps = RestaurantListPageProps & {
  pendingOrders?: OrderObject[];
};

const withPresenter = (
  View: React.FC<RestaurantListPageProps>,
): React.FC<RestaurantListPagePresenterProps> => {
  const Presenter: React.FC<RestaurantListPagePresenterProps> = (props) => {
    const { scrollPosition, pendingOrders } = props;
    const { t } = useTranslation();
    const history = useHistory();
    
    const { isMobile } = useWindowSize();
    const [restaurantPageStyle, setRestaurantPageStyle] = useState<RestaurantPageStyle>('Default');
    const pendingOrderId = pendingOrders && pendingOrders.length > 0 ? pendingOrders[pendingOrders.length - 1].id : undefined;

    const orderInProgressBanner: OrderInProgressBannerProps = {
      ...bannerDefaultProps,
      onClick: () => {
        history.push(`order/${pendingOrderId}`, { celebratorySectionShown: true });
      },
      descriptionText: {
        ...bannerDefaultProps.descriptionText,
        value: t('checkout.orderInProgress'),
      },
    };

    useLayoutEffect(() => {
      if (scrollPosition && isMobile) {
        if (scrollPosition.y > 0) {
          setRestaurantPageStyle('Compact');
        } else {
          setRestaurantPageStyle('Default');
        }
      } else {
        setRestaurantPageStyle('Default');
      }
    }, [isMobile, scrollPosition]);

    const restaurantListPageProps: RestaurantListPageProps = {
      ...defaultProps,
      ...props,
      pendingOrder: !!pendingOrderId,
      restaurantPageStyle,
      orderInProgressBanner,
    };

    return <View {...restaurantListPageProps} />;
  };
  return Presenter;
};

export default withPresenter;
