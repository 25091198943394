import React from 'react';
import cx from 'classnames';

import styles from './Image.module.scss';

export type ImageStyleType = 'Square' | 'Circle';

export const defaultProps = {
  style: 'Square' as ImageStyleType,
};

export type ImageProps = {
  srcValue?: string;
  style?: ImageStyleType;
  className?: string;
};

const Image: React.FC<ImageProps> = ({
  srcValue,
  style,
  className,
}) => {
  const currentStyle = styles[`image${style}`];
  return (
    <div className={cx(currentStyle, className)} style={{ backgroundImage: `url(${srcValue})` }}/>
  );
};

Image.defaultProps = defaultProps;

export default Image;
