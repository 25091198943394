import React from "react";
import { ShareSheetModalBlockProps } from ".";

const withInteractor = (
    Presenter: React.FC,
): React.FC<ShareSheetModalBlockProps> => {
    const Interactor: React.FC<ShareSheetModalBlockProps> = (props) => {
        return (
            <Presenter 
                {...props} 
            />);
    }
    return Interactor;
}

export default withInteractor;
