import React from 'react';
import cx from 'classnames';

import styles from './ClosedRestaurantImage.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

export const defaultProps = {
  icon: {
    asset: 'Closed',
    style: 'Grey800',
  } as IconProps,
  text: {
    type: 'Title3',
    align: 'Center',
    style: 'Grey100',
  } as TextProps,
};

export type ClosedRestaurantImageProps = {
  srcValue?: string; // MANUAL OVERRIDE
  icon?: IconProps;
  text?: TextProps;
  className?: string;
};

const ClosedRestaurantImage: React.FC<ClosedRestaurantImageProps> = ({
  icon,
  text,
  className,
  srcValue, // MANUAL OVERRIDE
}) => (
    <div className={cx(styles.closedRestaurantImage, className)}
      style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${srcValue})`, backgroundSize: 'cover' }} // MANUAL OVERRIDE
    >
      <div className={styles.content}>
        <Icon
          className={styles.icon}
          {...icon} />
        <Text
          className={styles.text}
          {...text} />
      </div>
    </div>
);

ClosedRestaurantImage.defaultProps = defaultProps;

export default ClosedRestaurantImage;
