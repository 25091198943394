/* eslint-disable max-classes-per-file,class-methods-use-this */

import React, { createContext, useState } from 'react';
import Cookie from 'js-cookie';
import { DOMAIN, SESSION_COOKIE_NAME } from '../../lib/config';
import { Account, extractJwtPayload } from '../../lib/token';

export type AuthContextValue = {
  account: Account | null;
  setAccount: (account: Account | null) => void;
  logout: () => void;
  loading: boolean;
  error: Error | undefined;
};

type NonNull<T> = {
  [P in keyof T]: Exclude<T[P], null>;
};

export type AuthenticatedContextValue = Readonly<NonNull<AuthContextValue>>;

export const logout = (): void => {
  Cookie.remove(SESSION_COOKIE_NAME, {
    domain: DOMAIN,
    secure: true,
  });
};

const initialAuthContext: AuthContextValue = {
  account: null,
  setAccount: () => {},
  logout: () => {},
  loading: false,
  error: undefined,
};

export const AuthContext = createContext<AuthContextValue>(initialAuthContext);

export const AuthProvider: React.FC<{}> = ({ children }) => {
  const cookie = Cookie.get(SESSION_COOKIE_NAME);
  const [account, updateAccount] = 
    useState<Account | null>(cookie ? extractJwtPayload(cookie) : null);

  const setAccount = (account: Account | null) => {
    updateAccount(account);
  }

  return (
    <AuthContext.Provider value={{
      account, 
      setAccount,
      logout,
      loading: false, 
      error: undefined, 
    }}>
      {children}
    </AuthContext.Provider>
  );
};
