import React from 'react';
import cx from 'classnames';

import styles from './ActionCompleteBlock.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Image, { ImageProps } from '../../atoms/Image';
import Text, { TextProps } from '../../atoms/Text';
import ToastNotification, { ToastNotificationProps } from '../../molecules/ToastNotification';

export const defaultProps = {
  backButton: {
    size: 'ExtraSmall',
    type: 'IconText',
    style: 'BasicSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'ArrowLeft',
      style: 'Grey1100',
    },
  } as ButtonProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
  title: {
    type: 'Title1',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  description: {
    type: 'Body2',
    align: 'Left',
    style: 'Grey700',
  } as TextProps,
  titleForm: {
    type: 'Title4',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  descriptionForm: {
    type: 'Body3',
    align: 'Left',
  } as TextProps,
  toast: {
    type: 'Success',
    icon: {
      asset: 'CheckCircle',
      style: 'Green700',
    },
    text: {
      type: 'Body2',
      align: 'Left',
      style: 'Green800',
    },
  } as ToastNotificationProps,
  link: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
  spamText: {
    type: 'Body3',
    align: 'Left',
    style: 'Grey1100',
  } as TextProps,
};

export type ActionCompleteBlockProps = {
  backButton?: ButtonProps;
  button?: ButtonProps;
  image?: ImageProps;
  title?: TextProps;
  description?: TextProps;
  titleForm?: TextProps;
  descriptionForm?: TextProps;
  className?: string;
  actionType?: 'VerifyEmail' | 'PasswordReset' | 'UpdatePassword';
  resendVerifyEmail?: (email: string) => Promise<void>;
  toast?: ToastNotificationProps;
  toastMessage?: boolean;
  logout?: () => void;
};

const ActionCompleteBlock: React.FC<ActionCompleteBlockProps> = ({
  backButton,
  button,
  image,
  title,
  description,
  titleForm,
  descriptionForm,
  className,
  toast,
  toastMessage,
}) => {
  let toastDisplay;
  if (toastMessage) {
    toastDisplay = <ToastNotification {...toast} />;
  }
  return (
    <div className={cx(styles.actionCompleteBlock, className)}>
      <Button
        className={styles.backButton}
        {...backButton} />
      {toastDisplay}
      <Image
        className={styles.image}
        {...image} />
      <div className={styles.topContent}>
        <Text
          className={styles.title}
          {...title} />
        <Text
          className={styles.description}
          {...description} />
      </div>
      <div className={styles.formContent}>
        <div className={styles.textGroup}>
          <Text
            className={styles.title}
            {...titleForm} />
          <Text
            className={styles.description}
            {...descriptionForm} />
        </div>
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

ActionCompleteBlock.defaultProps = defaultProps;

export default ActionCompleteBlock;
