import React from 'react';
import cx from 'classnames';

import styles from './EmailSignUpPage.module.scss';

import EmailSignUpBlock, { EmailSignUpBlockProps } from '../../block/EmailSignUpBlock';

export const defaultProps = {
  emailSignUpBlock: {
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'BrandSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    title: {
      type: 'Title1',
      align: 'Left',
      style: 'Grey1100',
    },
    description: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
    firstName: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    lastName: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    email: {
      state: 'Default',
      type: 'Text',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Text',
        style: 'Default',
      },
    },
    createPassword: {
      state: 'Default',
      type: 'Password',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Password',
        style: 'Default',
        text: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey700',
        },
        icon: {
          asset: 'Show',
          style: 'Grey1100',
        },
      },
    },
    confirmPassword: {
      state: 'Default',
      type: 'Password',
      label: {
        type: 'Body2',
        align: 'Left',
        style: 'Grey1100',
      },
      textInput: {
        type: 'Password',
        style: 'Default',
        text: {
          type: 'Body2',
          align: 'Left',
          style: 'Grey700',
        },
        icon: {
          asset: 'Show',
          style: 'Grey1100',
        },
      },
    },
  } as EmailSignUpBlockProps,
};

export type EmailSignUpPageProps = {
  emailSignUpBlock?: EmailSignUpBlockProps;
  className?: string;
};

const EmailSignUpPage: React.FC<EmailSignUpPageProps> = ({
  emailSignUpBlock,
  className,
}) => {
  return (
    <div className={cx(styles.emailSignUpPage, className)}>
      <EmailSignUpBlock
        className={styles.emailSignUpBlock}
        {...emailSignUpBlock} />
    </div>
  );
};

EmailSignUpPage.defaultProps = defaultProps;

export default EmailSignUpPage;
