import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChangePasswordBlockProps, defaultProps } from './ChangePasswordBlock';
import { isEmptyString } from '../../../lib/utils';
import { ForgotPasswordPayload } from '../../../modules/account/types';
import { ApiError } from '../../../lib/api/types';
import { Account } from '../../../lib/token';

export type ChangePasswordBlockPresenterProps = ChangePasswordBlockProps & {
  forgotPassword: (email: ForgotPasswordPayload) => Promise<void>;
  isLoading: boolean;
  account: Account | null;
};

const withPresenter = (
  View: React.FC<ChangePasswordBlockProps>,
): React.FC<ChangePasswordBlockPresenterProps> => {
  const Presenter: React.FC<ChangePasswordBlockPresenterProps> = (props) => {
    const { forgotPassword, account } = props;
    const history = useHistory();
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [showToast, setShowToast] = useState<boolean>(false);
    const isValidForm = !isEmptyString(email);

    useEffect(() => {
      if (account?.email) {
        setEmail(account.email);
      }
    }, [account]);

    const handleResetLink = async (): Promise<void> => {
      try {
        await forgotPassword({ email });
        history.push('/updateSent', { email });
      } catch (e) {
        if (ApiError.isApiError(e) && e.code === 422) {
          history.push('/updateSent', { email });
        } else if (ApiError.isApiError(e) && e.message) {
          setError(e.message);
          setShowToast(true);
        } else {
          setError(t('error.default'));
          setShowToast(true);
        }
      }
    };

    const closeToast = (): void => {
      setShowToast(false);
    };

    const changePasswordBlockProps: ChangePasswordBlockProps = {
      ...defaultProps,
      backButton: {
        ...defaultProps.backButton,
        text: {
          ...defaultProps.backButton?.text,
          value: t('button.back'),
        },
        onButtonClicked: (): void => {
          history.goBack();
        },
      },
      title: {
        ...defaultProps.title,
        value: t('changePassword.title'),
      },
      instructions: {
        ...defaultProps.instructions,
        value: t('changePassword.change_password_description'),
      },
      email: {
        ...defaultProps.email,
        state: 'Disabled',
        label: {
          ...defaultProps.email?.label,
          value: t('textLabels.email'),
        },
        textInput: {
          ...defaultProps.email?.textInput,
          textValue: email,
          disabled: true,
        },
        errorText: {
          ...defaultProps.email?.errorText,
          style: 'Red800',
          align: 'Left',
        },
      },
      updateButton: {
        ...defaultProps.updateButton,
        onButtonClicked: handleResetLink,
        disabled: !isValidForm,
        text: {
          ...defaultProps.updateButton?.text,
          value: t('button.reset_link'),
        },
      },
      toast: {
        ...defaultProps.errorToast,
        type: 'Error',
        icon: {
          ...defaultProps.errorToast.icon,
        },
        text: {
          ...defaultProps.errorToast.text,
          value: error,
        },
        show: showToast,
        onClose: closeToast,
      },
    };

    return <View {...props} {...changePasswordBlockProps} />;
  };
  return Presenter;
};

export default withPresenter;
