import React from 'react';
import cx from 'classnames';

import styles from './MenuSectionItemList.module.scss';

import MenuSectionItem, { MenuSectionItemProps } from '../MenuSectionItem';

export const defaultProps = {
  menuSectionItems: [
  ] as MenuSectionItemProps[],
};

export type MenuSectionItemListProps = {
  menuSectionItems?: MenuSectionItemProps[];
  className?: string;
};

const MenuSectionItemList: React.FC<MenuSectionItemListProps> = ({
  menuSectionItems,
  className,
}) => {
  const menuSectionItemArray = menuSectionItems?.map((menuSectionItem) => (
    <MenuSectionItem
      className={styles.menuSectionItem}
      {...menuSectionItem} />
  ));
  
  return (
    <div className={cx(styles.menuSectionItemList, className)}>
      {menuSectionItemArray}
    </div>
  );
};

MenuSectionItemList.defaultProps = defaultProps;

export default MenuSectionItemList;
