import React from "react";
import { useMutation } from "react-query";
import { EmailSignUpBlockProps } from "./EmailSignUpBlock";
import { EmailSignUpPresenterProps } from "./EmailSignUp.presenter";
import { signUp } from '../../../modules/account/api';

const withInteractor = (
  Presenter: React.FC<EmailSignUpPresenterProps>,
): React.FC<EmailSignUpBlockProps> => {
  const Interactor: React.FC<EmailSignUpBlockProps> = (props) => {
    const { mutateAsync, isLoading } = useMutation(signUp);
    return (
      <Presenter 
        {...props}
        doSignUp={mutateAsync}
        isLoading={isLoading}
        />
    );
  };
  return Interactor;
};
export default withInteractor;