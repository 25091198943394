import React from "react";
import { StartBlockProps } from ".";
import { StartBlockPresenterProps } from "./StartBlock.presenter";

const withInteractor = (
    Presenter: React.FC<StartBlockPresenterProps>,
  ): React.FC <StartBlockProps> => {
    const Interactor: React.FC <StartBlockProps> = (props) => {
      // initialize sso api calls with react-query
      
      // pass the mutations to presenter
      return (
        <Presenter
          {...props}
        />
      );
    };
    return Interactor;
  };
  export default withInteractor;