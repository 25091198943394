import React from 'react';
import cx from 'classnames';

import styles from './StartBlockPage.module.scss';

import StartBlock, { StartBlockProps } from '../../block/StartBlock';

export const defaultProps = {
  startBlock: {
    title: {
      type: 'Title1',
      align: 'Center',
      style: 'Grey100',
    },
    image: {
      style: 'Square',
    },
    description: {
      type: 'Body1',
      align: 'Center',
      style: 'Grey100',
    },
    googleButton: {
      size: 'Medium',
      type: 'IconText',
      style: 'GoogleSquare',
      icon: {
        asset: 'Google',
        style: 'Grey1100',
      },
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey800',
      },
    },
    facebookButton: {
      size: 'Medium',
      type: 'IconText',
      style: 'FacebookSquare',
      icon: {
        asset: 'Facebook',
        style: 'Grey1100',
      },
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey800',
      },
    },
    button: {
      size: 'Medium',
      type: 'Text',
      style: 'ContrastSquare',
      text: {
        type: 'Button1',
        align: 'Center',
        style: 'Grey1100',
      },
    },
    secondaryAction: {
      title: {
        type: 'Title4',
        align: 'Center',
        style: 'Grey100',
      },
      link: {
        type: 'Body2',
        align: 'Center',
        style: 'Grey100',
      },
    },
  } as StartBlockProps,
};

export type StartBlockPageProps = {
  startBlock?: StartBlockProps;
  type?: 'signIn' | 'signUp',
  className?: string;
};

const StartBlockPage: React.FC<StartBlockPageProps> = ({
  startBlock,
  type,
  className,
}) => {
  return (
    <div className={cx(styles.startBlockPage, className)}>
      <StartBlock
        className={styles.startBlock}
        type={type}
        {...startBlock} />
    </div>
  );
};

StartBlockPage.defaultProps = defaultProps;

export default StartBlockPage;
