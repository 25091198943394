import axios, { isAxiosError } from '../../lib/api/axios';
import { ApiError } from '../../lib/api/types';
import { BFF_URL } from '../../lib/config';
import { Account } from '../../lib/token';
import {
  AccountResponse, CompleteAccountPayload, ForgotPasswordPayload, SignInPayload, SignUpPayload,
  UpdateAccountPayload, UpdatePasswordPayload, VerifyAccountPayload,
} from './types';

export const signUp = async (payload: SignUpPayload): Promise<AccountResponse> => {
  try {
    const { data } = await axios.post<AccountResponse>(`${BFF_URL}/auth/signUp`, payload, { withCredentials: true });
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const signIn = async (payload: SignInPayload): Promise<AccountResponse> => {
  try {
    const { data } = await axios.post<AccountResponse>(`${BFF_URL}/auth/signIn`, payload, { withCredentials: true });
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.response?.data?.message || error.message,
      });
    }
    throw error;
  }
};

export const refreshToken = async (): Promise<void> => {
  try {
    const { data } = await axios.post(`${BFF_URL}/auth/refreshToken`, { withCredentials: true });
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const verifyAccount = async (payload: VerifyAccountPayload): Promise<void> => {
  const { id, token } = payload;
  try {
    await axios.post(`${BFF_URL}/accounts/${id}/actions/verifyAccount`, {
      token,
    }, { withCredentials: true });
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const resendVerifyAccount = async (email: string): Promise<void> => {
  try {
    await axios.post(`${BFF_URL}/accounts/actions/resendVerifyEmail`, {
      email,
    }, { withCredentials: true });
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const forgotPassword = async (payload: ForgotPasswordPayload): Promise<void> => {
  try {
    await axios.post(`${BFF_URL}/accounts/actions/forgotPassword`,
      payload, { withCredentials: true });
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const updatePassword = async (payload: UpdatePasswordPayload): Promise<void> => {
  try {
    const { id, password, token } = payload;
    await axios.patch(`${BFF_URL}/accounts/${id}/actions/updatePassword`, {
      password, token,
    },
    { withCredentials: true });
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const updateAccount = async (payload: UpdateAccountPayload): Promise<Account> => {
  try {
    const {
      id, profile, firstName, lastName,
    } = payload;
    const { data, status } = await axios.patch(
      `${BFF_URL}/accounts/${id}`,
      {
        firstName,
        lastName,
        profile,
      },
      { withCredentials: true },
    );
    if (status === 200) {
      await refreshToken();
    }
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};

export const completeAccount = async (payload: CompleteAccountPayload): Promise<Account> => {
  try {
    const {
      id, profile, firstName, lastName, email,
    } = payload;
    const { data, status } = await axios.patch(
      `${BFF_URL}/accounts/${id}/actions/completeAccount`,
      {
        firstName,
        lastName,
        email,
        profile,
      },
      { withCredentials: true },
    );
    if (status === 200) {
      await refreshToken();
    }
    return data;
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      throw new ApiError({
        code: error.response.status,
        type: error.response.statusText,
        message: error.message,
      });
    }
    throw error;
  }
};
