import React, { RefObject } from 'react';
import cx from 'classnames';

import { Modal } from 'react-bootstrap';
import styles from './MenuItemConfigurationModal.module.scss';

import FoodItemBanner, { FoodItemBannerProps } from '../FoodItemBanner';
import MenuSectionItemList, {
  MenuSectionItemListProps,
} from '../MenuSectionItemList';
import TextField, { TextFieldProps } from '../../molecules/TextField';
import Button, { ButtonProps } from '../../atoms/Button';
import FoodItemHeader, { FoodItemHeaderProps } from '../FoodItemHeader';

export const defaultProps = {
  foodItemHeader: {
    titleText: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    closeButton: {
      size: 'Medium',
      type: 'Icon',
      style: 'ClearSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
    },
    button: {
      size: 'Medium',
      type: 'Icon',
      style: 'ClearSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as FoodItemHeaderProps,
  foodItemBanner: {
    titleText: {
      type: 'Title3',
      align: 'Left',
      style: 'Grey1100',
    },
    descriptionText: {
      type: 'Body3',
      align: 'Left',
      style: 'Grey700',
    },
  } as FoodItemBannerProps,
  menuSectionItemList: {
    menuSectionItems: [],
  } as MenuSectionItemListProps,
  quantityTextField: {
    state: 'Default',
    type: 'Text',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'Text',
      style: 'Default',
    },
  } as TextFieldProps,
  additionalNoteTextField: {
    state: 'Default',
    type: 'TextArea',
    label: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey1100',
    },
    textInput: {
      type: 'TextArea',
      style: 'Default',
    },
    helpText: {
      type: 'Body2',
      align: 'Left',
      style: 'Grey700',
    },
  } as TextFieldProps,
  button: {
    size: 'Medium',
    type: 'TextIcon',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
    icon: {
      asset: 'PlusCircle',
      style: 'Grey100',
    },
  } as ButtonProps,
};

export type MenuItemConfigurationModalProps = {
  foodItemHeader?: FoodItemHeaderProps;
  foodItemBanner?: FoodItemBannerProps;
  menuSectionItemList?: MenuSectionItemListProps;
  quantityTextField?: TextFieldProps;
  additionalNoteTextField?: TextFieldProps;
  button?: ButtonProps;
  menuModalOpen?: boolean;
  menuModalClose?: () => void;
  className?: string;
  isLoading?: boolean;
  menuItemContentRef?: RefObject<HTMLDivElement>;
  onCloseOutsideModal?: () => void;
};

const MenuItemConfigurationModal: React.FC<MenuItemConfigurationModalProps> = ({
  foodItemBanner,
  menuSectionItemList,
  quantityTextField,
  additionalNoteTextField,
  menuModalOpen,
  menuModalClose,
  button,
  className,
  isLoading,
  foodItemHeader,
  menuItemContentRef,
  onCloseOutsideModal
}) => {
  const loadingContent = <div className={styles.loading}>Loading...</div>;
  const innerContent = (
    <>
      <MenuSectionItemList
        className={styles.menuSectionItemList}
        {...menuSectionItemList}
      />
      <div className={styles.bottomContent}>
        <TextField
                  className={styles.additionalNoteTextField}
                  {...additionalNoteTextField}
                />
        <TextField
          className={styles.quantityTextField}
          {...quantityTextField}
        />
        <Button className={styles.button} {...button} />
      </div>
    </>
  );

  return (
    <Modal
      animation={false}
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={menuModalOpen}
      onHide={onCloseOutsideModal}
    >
      <Modal.Body className={styles.modelContent}>
        <div className={styles.content} ref={menuItemContentRef}>
          <div className={styles.innerContent}>
            <div className={cx(styles.menuItemConfigurationModal, className)}>
              <FoodItemHeader
                className={styles.foodItemHeader}
                {...foodItemHeader} />
              <FoodItemBanner
                className={styles.foodItemBanner}
                {...foodItemBanner}
              />
              {isLoading ? loadingContent : innerContent}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

MenuItemConfigurationModal.defaultProps = defaultProps;

export default MenuItemConfigurationModal;
