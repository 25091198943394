import React from 'react';
import cx from 'classnames';

import styles from './Icon.module.scss';

import CartEmptyAsset from '../../../resources/icons/CartEmpty.svg';
import CartFullAsset from '../../../resources/icons/CartFull.svg';
import ArrowRightAsset from '../../../resources/icons/ArrowRight.svg';
import ArrowLeftAsset from '../../../resources/icons/ArrowLeft.svg';
import ArrowUpRightAsset from '../../../resources/icons/ArrowUpRight.svg';
import ChevronDownAsset from '../../../resources/icons/ChevronDown.svg';
import ChevronUpAsset from '../../../resources/icons/ChevronUp.svg';
import ChevronRightAsset from '../../../resources/icons/ChevronRight.svg';
import BackAsset from '../../../resources/icons/Back.svg';
import CloseAsset from '../../../resources/icons/Close.svg';
import VeganAsset from '../../../resources/icons/Vegan.svg';
import VegAsset from '../../../resources/icons/Veg.svg';
import CheckboxUncheckedAsset from '../../../resources/icons/CheckboxUnchecked.svg';
import CheckboxCheckedAsset from '../../../resources/icons/CheckboxChecked.svg';
import CheckboxIntermediateAsset from '../../../resources/icons/CheckboxIntermediate.svg';
import RadioUncheckedAsset from '../../../resources/icons/RadioUnchecked.svg';
import RadioCheckedAsset from '../../../resources/icons/RadioChecked.svg';
import PlusCircleAsset from '../../../resources/icons/PlusCircle.svg';
import InfoAsset from '../../../resources/icons/Info.svg';
import InfoOutlineAsset from '../../../resources/icons/InfoOutline.svg';
import CheckCircleAsset from '../../../resources/icons/CheckCircle.svg';
import ErrorCircleAsset from '../../../resources/icons/ErrorCircle.svg';
import GoogleAsset from '../../../resources/icons/Google.svg';
import FacebookAsset from '../../../resources/icons/Facebook.svg';
import PlusAsset from '../../../resources/icons/Plus.svg';
import ShowAsset from '../../../resources/icons/Show.svg';
import HideAsset from '../../../resources/icons/Hide.svg';
import CardAsset from '../../../resources/icons/Card.svg';
import DollarAsset from '../../../resources/icons/Dollar.svg';
import LocationAsset from '../../../resources/icons/Location.svg';
import RestaurantAsset from '../../../resources/icons/Restaurant.svg';
import ClockAsset from '../../../resources/icons/Clock.svg';
import VisaAsset from '../../../resources/icons/Visa.svg';
import MastercardAsset from '../../../resources/icons/Mastercard.svg';
import DiscoverAsset from '../../../resources/icons/Discover.svg';
import AmExAsset from '../../../resources/icons/AmEx.svg';
import JCBAsset from '../../../resources/icons/JCB.svg';
import UnionPayAsset from '../../../resources/icons/UnionPay.svg';
import PdfAsset from '../../../resources/icons/Pdf.svg';
import HamburgerMenuAsset from '../../../resources/icons/HamburgerMenu.svg';
import ProfileAsset from '../../../resources/icons/Profile.svg';
import QuestionAsset from '../../../resources/icons/Question.svg';
import ExitAsset from '../../../resources/icons/Exit.svg';
import ClosedAsset from '../../../resources/icons/Closed.svg';
import EmailAsset from '../../../resources/icons/Email.svg';
import LinkAsset from '../../../resources/icons/Link.svg';
import OrderCircleAsset from '../../../resources/icons/OrderCircle.svg';
import SocialShareAsset from '../../../resources/icons/SocialShare.svg';
import SMSSocialAsset from '../../../resources/icons/SMSSocial.svg';
import EmailSocialAsset from '../../../resources/icons/EmailSocial.svg';
import FacebookSocialAsset from '../../../resources/icons/FacebookSocial.svg';
import TwitterSocialAsset from '../../../resources/icons/TwitterSocial.svg';
import { getClickAccessibilityProps } from '../../../lib/utils';

export type IconAssetType = 'CartEmpty' | 'CartFull' | 'ArrowRight' | 'ArrowLeft' | 'ArrowUpRight' | 'ChevronDown' | 'ChevronUp' | 'ChevronRight' | 'Back' | 'Close' | 'Vegan' | 'Veg' | 'CheckboxUnchecked' | 'CheckboxChecked' | 'CheckboxIntermediate' | 'RadioUnchecked' | 'RadioChecked' | 'PlusCircle' | 'Info' | 'InfoOutline' | 'CheckCircle' | 'ErrorCircle' | 'Google' | 'Facebook' | 'Plus' | 'Show' | 'Hide' | 'Card' | 'Dollar' | 'Location' | 'Restaurant' | 'Clock' | 'Visa' | 'Mastercard' | 'Discover' | 'AmEx' | 'JCB' | 'UnionPay' | 'Pdf' | 'HamburgerMenu' | 'Profile' | 'Question' | 'Exit' | 'Closed' | 'Email' | 'Link' | 'OrderCircle' | 'SocialShare' | 'SMSSocial' | 'EmailSocial' | 'FacebookSocial' | 'TwitterSocial';
export type IconStyleType = 'Grey1100' | 'Grey600' | 'Grey100' | 'Green800' | 'Grey700' | 'Grey200' | 'Grey300' | 'Purple400' | 'Blue1000' | 'Purple800' | 'Green700' | 'Red800' | 'Transparent' | 'Grey800';

export const defaultProps = {
  asset: 'TwitterSocial' as IconAssetType,
  style: 'Grey100' as IconStyleType,
};

export type IconProps = {
  asset?: IconAssetType;
  style?: IconStyleType;
  contentAlt?: string;
  className?: string;
  onClick?: VoidFunction;
  assetCartFullStyleGrey1100HtmlTagImgAssetKeyContentAlt?: string;
  assetBackStyleGrey1100HtmlTagImgAssetKeyContentAlt?: string;
  assetBackStyleGrey200HtmlTagImgAssetKeyContentAlt?: string;
  assetBackStyleGrey300HtmlTagImgAssetKeyContentAlt?: string;
  assetBackStyleGrey600HtmlTagImgAssetKeyContentAlt?: string;
  assetCloseStyleGrey1100HtmlTagImgAssetKeyContentAlt?: string;
  assetCloseStyleGrey200HtmlTagImgAssetKeyContentAlt?: string;
  assetCloseStyleGrey300HtmlTagImgAssetKeyContentAlt?: string;
  assetCloseStyleGrey600HtmlTagImgAssetKeyContentAlt?: string;
  assetCheckboxCheckedStylePurple400HtmlTagImgAssetKeyContentAlt?: string;
  assetCheckboxIntermediateStyleGreen800HtmlTagImgAssetKeyContentAlt?: string;
  assetRadioCheckedStyleGreen800HtmlTagImgAssetKeyContentAlt?: string;
  iconAlt?: string;
  assetGoogleStyleGrey100HtmlTagImgAssetKeyContentAlt?: string;
  assetGoogleStyleTransparentHtmlTagImgAssetKeyContentAlt?: string;
  assetMastercardStyleGrey1100HtmlTagImgAssetKeyContentAlt?: string;
  assetAmExStyleGrey1100HtmlTagImgAssetKeyContentAlt?: string;
  assetJCBStyleGrey1100HtmlTagImgAssetKeyContentAlt?: string;
  assetUnionPayStyleGrey1100HtmlTagImgAssetKeyContentAlt?: string;
  frame149Alt?: string;
  unionAlt?: string;
  vectorAlt?: string;
  assetClosedStyleGrey800HtmlTagImgAssetKeyContentAlt?: string;
  assetOrderCircleStyleGreen800HtmlTagImgAssetKeyContentAlt?: string;
  assetSMSSocialStyleGrey100HtmlTagImgAssetKeyContentAlt?: string;
  assetEmailSocialStyleGrey100HtmlTagImgAssetKeyContentAlt?: string;
  flogoRGBBlue10241Alt?: string;
  assetTwitterSocialStyleGrey100HtmlTagImgAssetKeyContentAlt?: string;
};

const Icon: React.FC<IconProps> = ({
  asset,
  style,
  contentAlt,
  className,
  onClick,
  assetCartFullStyleGrey1100HtmlTagImgAssetKeyContentAlt,
  assetBackStyleGrey1100HtmlTagImgAssetKeyContentAlt,
  assetBackStyleGrey200HtmlTagImgAssetKeyContentAlt,
  assetBackStyleGrey300HtmlTagImgAssetKeyContentAlt,
  assetBackStyleGrey600HtmlTagImgAssetKeyContentAlt,
  assetCloseStyleGrey1100HtmlTagImgAssetKeyContentAlt,
  assetCloseStyleGrey200HtmlTagImgAssetKeyContentAlt,
  assetCloseStyleGrey300HtmlTagImgAssetKeyContentAlt,
  assetCloseStyleGrey600HtmlTagImgAssetKeyContentAlt,
  assetCheckboxCheckedStylePurple400HtmlTagImgAssetKeyContentAlt,
  assetCheckboxIntermediateStyleGreen800HtmlTagImgAssetKeyContentAlt,
  assetRadioCheckedStyleGreen800HtmlTagImgAssetKeyContentAlt,
  iconAlt,
  assetGoogleStyleGrey100HtmlTagImgAssetKeyContentAlt,
  assetGoogleStyleTransparentHtmlTagImgAssetKeyContentAlt,
  assetMastercardStyleGrey1100HtmlTagImgAssetKeyContentAlt,
  assetAmExStyleGrey1100HtmlTagImgAssetKeyContentAlt,
  assetJCBStyleGrey1100HtmlTagImgAssetKeyContentAlt,
  assetUnionPayStyleGrey1100HtmlTagImgAssetKeyContentAlt,
  frame149Alt,
  unionAlt,
  vectorAlt,
  assetClosedStyleGrey800HtmlTagImgAssetKeyContentAlt,
  assetOrderCircleStyleGreen800HtmlTagImgAssetKeyContentAlt,
  assetSMSSocialStyleGrey100HtmlTagImgAssetKeyContentAlt,
  assetEmailSocialStyleGrey100HtmlTagImgAssetKeyContentAlt,
  flogoRGBBlue10241Alt,
  assetTwitterSocialStyleGrey100HtmlTagImgAssetKeyContentAlt,
}) => {

  const currentStyle = styles[`icon${asset}${style}`];

  let content = '';
  switch (asset) {
    case 'CartEmpty':
      content = CartEmptyAsset;
      break;
    case 'CartFull':
      content = CartFullAsset;
      break;
    case 'ArrowRight':
      content = ArrowRightAsset;
      break;
    case 'ArrowLeft':
      content = ArrowLeftAsset;
      break;
    case 'ArrowUpRight':
      content = ArrowUpRightAsset;
      break;
    case 'ChevronDown':
      content = ChevronDownAsset;
      break;
    case 'ChevronUp':
      content = ChevronUpAsset;
      break;
    case 'ChevronRight':
      content = ChevronRightAsset;
      break;
    case 'Back':
      content = BackAsset;
      break;
    case 'Close':
      content = CloseAsset;
      break;
    case 'Vegan':
      content = VeganAsset;
      break;
    case 'Veg':
      content = VegAsset;
      break;
    case 'CheckboxUnchecked':
      content = CheckboxUncheckedAsset;
      break;
    case 'CheckboxChecked':
      content = CheckboxCheckedAsset;
      break;
    case 'CheckboxIntermediate':
      content = CheckboxIntermediateAsset;
      break;
    case 'RadioUnchecked':
      content = RadioUncheckedAsset;
      break;
    case 'RadioChecked':
      content = RadioCheckedAsset;
      break;
    case 'PlusCircle':
      content = PlusCircleAsset;
      break;
    case 'Info':
      content = InfoAsset;
      break;
    case 'InfoOutline':
      content = InfoOutlineAsset;
      break;
    case 'CheckCircle':
      content = CheckCircleAsset;
      break;
    case 'ErrorCircle':
      content = ErrorCircleAsset;
      break;
    case 'Google':
      content = GoogleAsset;
      break;
    case 'Facebook':
      content = FacebookAsset;
      break;
    case 'Plus':
      content = PlusAsset;
      break;
    case 'Show':
      content = ShowAsset;
      break;
    case 'Hide':
      content = HideAsset;
      break;
    case 'Card':
      content = CardAsset;
      break;
    case 'Dollar':
      content = DollarAsset;
      break;
    case 'Location':
      content = LocationAsset;
      break;
    case 'Restaurant':
      content = RestaurantAsset;
      break;
    case 'Clock':
      content = ClockAsset;
      break;
    case 'Visa':
      content = VisaAsset;
      break;
    case 'Mastercard':
      content = MastercardAsset;
      break;
    case 'Discover':
      content = DiscoverAsset;
      break;
    case 'AmEx':
      content = AmExAsset;
      break;
    case 'JCB':
      content = JCBAsset;
      break;
    case 'UnionPay':
      content = UnionPayAsset;
      break;
    case 'Pdf':
      content = PdfAsset;
      break;
    case 'HamburgerMenu':
      content = HamburgerMenuAsset;
      break;
    case 'Profile':
      content = ProfileAsset;
      break;
    case 'Question':
      content = QuestionAsset;
      break;
    case 'Exit':
      content = ExitAsset;
      break;
    case 'Closed':
      content = ClosedAsset;
      break;
    case 'Email':
      content = EmailAsset;
      break;
    case 'Link':
      content = LinkAsset;
      break;
    case 'OrderCircle':
      content = OrderCircleAsset;
      break;
    case 'SocialShare':
      content = SocialShareAsset;
      break;
    case 'SMSSocial':
      content = SMSSocialAsset;
      break;
    case 'EmailSocial':
      content = EmailSocialAsset;
      break;
    case 'FacebookSocial':
      content = FacebookSocialAsset;
      break;
    case 'TwitterSocial':
      content = TwitterSocialAsset;
      break;
    case 'SocialShare':
      content = SocialShareAsset;
      break;
    default:
      break;
  }
  const accessibilityProps = getClickAccessibilityProps(onClick);

  return (
    <div className={cx(currentStyle, className)}
    onClick={onClick}
    {...accessibilityProps}
    >
      <img
        className={styles.content}
        alt={contentAlt}
        src={content} />
    </div>
  );
};

Icon.defaultProps = defaultProps;

export default Icon;
