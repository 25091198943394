import React from 'react';
import cx from 'classnames';

import styles from './ButtonGroup2.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  primaryButton: {
    style: 'BrandSquare',
    size: 'Medium',
    type: 'Text',
    text: {
      type: 'Title4',
      align: 'Center',
      style: 'Grey1100'
    }
  } as ButtonProps,
  secondaryButton: {
    style: 'BrandSquare',
    size: 'Medium',
    type: 'Text',
    text: {
      type: 'Title4',
      align: 'Center',
      style: 'Grey1100'
    }
  } as ButtonProps,
  button: {
    style: 'BrandSquare',
    size: 'Medium',
    type: 'TextIcon',
    icon: {
      name: 'PlusCircle',
      style: 'Grey100',
    },
  } as ButtonProps,
};

export type ButtonGroup2Props = {
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  className?: string;
  button?: ButtonProps;
};

const ButtonGroup2: React.FC<ButtonGroup2Props> = ({
  primaryButton,
  secondaryButton,
  className,
  button,
}) => {
  return (
    <div className={cx(styles.buttonGroup2, className)}>
      <Button
        className={styles.button}
        {...button} />
      <Button
        className={styles.primaryButton}
        {...primaryButton} />
      <Button
        className={styles.secondaryButton}
        {...secondaryButton} />
    </div>
  );
};

ButtonGroup2.defaultProps = defaultProps;

export default ButtonGroup2;
