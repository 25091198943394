import { Address } from '../../lib/token';
import { Restaurant } from '../restaurant/types';

export type CreatePaymentIntent = {
  restaurantId: string;
  userId: string;
  items: MenuItem[];
  menuId: string;
  tip?: number;
  orderType: OrderType;
  deliveryAddress?: Address;
  deliveryTime?: number;
};

export type CustomerBasket = Pick<
  CreatePaymentIntent,
  'restaurantId' | 'menuId' | 'items'
> & {
  restaurantName: string;
  restaurantAddress: string;
  restaurantPrepRange: string;
  restaurantDistance?: number;
  orderType: OrderType;
  note?: string;
  delivery?: boolean;
  deliveryETA?: number;
  pickup?: boolean;
};

export type MenuItem = {
  plu: string;
  name?: string;
  price: number;
  productType: number;
  quantity: number;
  remark?: string;
  subItems?: MenuItem[];
  tax?: number;
  multiMax?: number;
  productId: string;
};

export enum OrderStatusEnum {
  pending = 'pending', // Initial order state when payment intent is created
  paid = 'paid', // Payment successfully completed
  dispatched = 'dispatched', // Order sent to deliverect

  // Deliverect statuses
  parsed = 'PARSED', // Received and parsed by Deliverect.
  received = 'RECEIVED', // Received by POS.
  new = 'NEW', // Received by POS and receipt is created.
  accepted = 'ACCEPTED', // Accepted/confirmed.
  duplicate = 'DUPLICATE', // Order has been received multiple times due to a technical error.
  printed = 'PRINTED', // Order has been printed.
  preparing = 'PREPARING', // Order is in preparation.
  prepared = 'PREPARED', // Order is prepared.
  readyForPickUp = 'READY FOR PICKUP', // Order is ready for pickup
  courierArrived = 'COURIER ARRIVED', // Courier arrived for delivery
  inDelivery = 'IN DELIVERY', // En route to customer
  enRouteToPickup = 'EN ROUTE TO PICKUP', // En route to pickup for delivery
  enRouteToDropoff = 'EN ROUTE TO DROPOFF', // En route to drop off for delivery
  arrivedAtDropoff = 'ARRIVED AT DROPOFF', // Arrived at dropoff for customer
  finalized = 'FINALIZED', // Order has been fully completed.
  autoFinalized = 'AUTO-FINALIZED', // Accepted by POS, but any other status updates unsupported by POS (to be avoided at all costs!)
  cancel = 'CANCEL', // Order should be voided.
  canceled = 'CANCELED', // Order has been voided on POS.
  failed = 'FAILED', // Order has failed. To be used when the POS could not be reached, or when an order could not be processed correctly. For example, when a product is available on the merchant's online store, but deleted in their POS account.
  posReceivedFailed = 'POS RECEIVED FAILED', // Order not received by POS.
  parseFailed = 'PARSE FAILED', // Parsing failed for the order.
  resolved = 'RESOLVED', // User has acknowledged a failed order on the Delivery Manager App
}

export type OrderObject = {
  id: number;
  created_at: Date;
  updated_at: Date;
  orderbasket: MenuItem[];
  paymentdetails: object;
  orderstatus: string;
  subtotal: number;
  servicefee: number;
  supplifee: number;
  tax: number;
  ordertotal: number;
  restaurantid: string;
  pickuptime: string;
  userid: string;
  ordertype: string;
  deliveryeta: string;
  deliveryaddress: object;
  discount?: number;
  deliveryfee?: number;
  tip?: number;
  orderversion?: string;
};

export type OrderType = 'Delivery' | 'Pickup';

export type PaymentIntent = OrderObject & {
  clientSecret: string;
};

export type RestaurantOrder = OrderObject & {
  restaurant: Restaurant;
};

export type OrderTotals = {
  subtotal: number;
  servicefee: number;
  supplifee: number;
  deliveryfee: number;
  tax: number;
  discount: number;
  ordertotal: number;
};

export type TipInfo = {
  sum: number,
  state: "Selected" | "Unselected",
  percentage: number
}

export type CheckoutState = {
  celebratorySectionShown: boolean;
};