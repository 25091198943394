import React from 'react';
import { Modal } from 'react-bootstrap';

import styles from './CartModal.module.scss';
import CartOverviewBlock from '../CartOverviewBlock';
import ConfirmationModalBlock from '../ConfirmationModalBlock';
import { MenuItem } from '../../../modules/checkout/types';
import MenuModalBlock from '../MenuModalBlock';
import { MenuResponseObject } from '../../../modules/menu/types';
import { Restaurant } from '../../../modules/restaurant/types';

export type CartModalProps = {
  changeCartView?: (modalType?: string | null) => void;
  itemToChange?: MenuItem;
  itemToChangePosition?: number;
  setItem?: (item: MenuItem, position: number) => void;
  modalType?: string | null;
  onClose: () => void;
  onCloseOutsideModal?: () => void;
  open: boolean;
  isLoading?: boolean;
  restaurant?: Restaurant;
  menu?: MenuResponseObject[];
};

const CartModal: React.FC<CartModalProps> = ({
  changeCartView,
  isLoading,
  itemToChange,
  itemToChangePosition,
  menu,
  modalType,
  onClose,
  open,
  restaurant,
  setItem,
  onCloseOutsideModal,
}) => {
  if (modalType === 'edit' && !isLoading) {
    return (
      <MenuModalBlock
        menuModalOpen={true}
        menu={menu}
        restaurant={restaurant}
        productId={itemToChange?.productId}
        onEditCart={() => {
          changeCartView && changeCartView('overview');
        }}
        editModal={true}
        editPosition={itemToChangePosition}
        onCloseOutsideModal={onCloseOutsideModal}
      />
    );
  }

  const modalDialog: string = modalType === 'overview' ? styles.modalDialog : styles.modalDialogConfirmation;
  return (
    <Modal
      animation={false}
      className={styles.modal}
      dialogClassName={modalDialog}
      contentClassName={styles.modalContent}
      show={open}
      onHide={onCloseOutsideModal}
    >
      {modalType === 'overview' ? (
        <CartOverviewBlock
          setItem={setItem}
          onClose={onClose}
          showConfirmationModal={() =>
            changeCartView && changeCartView('confirm')
          }
          showEditModal={() => changeCartView && changeCartView('edit')}
        />
      ) : null}
      {modalType === 'confirm' ? (
        <ConfirmationModalBlock
          itemToRemove={itemToChange}
          itemPositionToRemove={itemToChangePosition}
          closeConfirmation={() => changeCartView && changeCartView('overview')}
        />
      ) : null}
    </Modal>
  );
};

export default CartModal;
