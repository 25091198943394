import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import { ChangePasswordBlockProps } from './ChangePasswordBlock';
import { ChangePasswordBlockPresenterProps } from './ChangePasswordBlock.presenter';
import { forgotPassword } from '../../../modules/account/api';
import { AuthContext } from '../../../modules/auth';

const withInteractor = (
  Presenter: React.FC<ChangePasswordBlockPresenterProps>,
): React.FC<ChangePasswordBlockProps> => {
  const Interactor: React.FC<ChangePasswordBlockProps> = (props) => {
    const { account } = useContext(AuthContext);
    const { mutateAsync, isLoading } = useMutation(forgotPassword);

    return (
      <Presenter
        {...props}
        forgotPassword={mutateAsync}
        account={account}
        isLoading={isLoading}
      />
    );
  };
  return Interactor;
};
export default withInteractor;
