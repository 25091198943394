import TopNav, {
  TopNavProps,
  defaultProps as TopNavDefaultProps
} from './TopNav'
import withPresenter from './TopNav.presenter';
import withInteractor from './TopNav.interactor';

export type { TopNavProps }
export default withInteractor(withPresenter(TopNav));
export { TopNavDefaultProps }
