import React, { useEffect, useState } from 'react';
import styles from './StartBlock.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { StartBlockProps } from './StartBlock';
import { getSsoLink } from '../../../modules/auth/api';
import { defaultProps } from '../../pages/StartBlockPage/StartBlockPage';
import { Trans, useTranslation } from 'react-i18next';
import { isValidGoogleBrowser } from '../../../lib/utils';
import EarthAsset from '../../../resources/images/Earth.png';
import SushiContainerAsset from '../../../resources/images/SushiContainer.png';

export type StartBlockPresenterProps = StartBlockProps;

const withPresenter = (
  View: React.FC<StartBlockProps>,
): React.FC<StartBlockPresenterProps> => {
  const Presenter: React.FC<StartBlockPresenterProps> = (props) => {
    const history = useHistory();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const redirectPath = query.get("redirect_to");
    const { t } = useTranslation();
    const [isGoogleSupportedBrowser, setIsGoogleSupportedBrowser] = useState<boolean>(false);

    useEffect(() => {
      setIsGoogleSupportedBrowser(isValidGoogleBrowser());
    }, []);

    const handleSsoClick = (ssoLink) => {
      return () => {
        window.location.replace(ssoLink);
      };
    };
    const facebookClickHandler = handleSsoClick(getSsoLink('facebook', '/'));
    const googleClickHandler = handleSsoClick(getSsoLink('google', redirectPath ? redirectPath : '/'));
        
    // render proper props base on sign in or create account
    const startBlockProps: StartBlockProps = {
      ...defaultProps.startBlock,
      isGoogleSupportedBrowser: isGoogleSupportedBrowser,
      image: {
        ...defaultProps.startBlock.image,
        srcValue: props.type === 'signIn'
        ? SushiContainerAsset
        : EarthAsset,
      },
      title: {
        ...defaultProps.startBlock.title,
        value:
          props.type === 'signIn'
            ? t('sign_in.title')
            : t('sign_up.title'),
      },
      description: {
        ...defaultProps.startBlock.description,
        value:(
          <Trans i18nKey={props.type === 'signIn' ? 'sign_in.description' : 'sign_up.description'}
            components={{ bold: <span className={styles.boldedDescription} />, }}
          />
        )
      },
      googleButton: {
        ...defaultProps.startBlock.googleButton,
        style: 'GoogleSquare',
        onButtonClicked: googleClickHandler,
        text: {
          ...defaultProps.startBlock.googleButton?.text,
          value:
            props.type === 'signIn'
              ? t('sign_up.continue_with_google')
              : t('sign_up.continue_with_google'),
        },
      },
      facebookButton: {
        ...defaultProps.startBlock.facebookButton,
        style: 'FacebookSquare',
        onButtonClicked: facebookClickHandler,
        text: {
          ...defaultProps.startBlock.facebookButton?.text,
          value:
            props.type === 'signIn'
              ? t('sign_up.continue_with_facebook')
              : t('sign_up.continue_with_facebook'),
        },
      },
      button: {
        ...defaultProps.startBlock.button,
        text: {
          ...defaultProps.startBlock.button?.text,
          value:
            props.type === 'signIn'
              ? t('sign_up.sign_in_with_email')
              : t('sign_up.sign_up_with_email'),
        },
        onButtonClicked: () =>
          props.type === 'signIn'
            ? history.push(redirectPath ? `/emailSignIn?redirect_to=${redirectPath}` : `/emailSignIn`)
            : history.push(`/emailSignUp`),
      },
      secondaryAction: {
        ...defaultProps.startBlock?.secondaryAction,
        title: {
          ...defaultProps.startBlock?.secondaryAction?.title,
          value:
            props.type === 'signIn'
              ? t('email_sign_in.secondaryAction.title')
              : t('sign_up.already_have_an_account'),
        },
        link: {
          ...defaultProps.startBlock?.secondaryAction?.link,
          value:
            props.type === 'signIn'
              ? t('email_sign_in.secondaryAction.link')
              : t('button.sign_in'),
          onClick: () =>
            props.type === 'signIn'
              ? history.push(`/signup`)
              : history.push(`/signin`),
        },
      },
    };
    
    return <View {...props} {...startBlockProps} />;
  };
  return Presenter;
};

export default withPresenter;
