import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";

import { LocaleProvider } from './modules/locale';
import { AuthProvider } from './modules/auth';
import { CheckoutProvider } from './modules/checkout';

const queryClient = new QueryClient();
const AppProvider: React.FC<{}> = ({ children }) => (
  <LocaleProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <CheckoutProvider>
          {children}
        </CheckoutProvider>
      </AuthProvider>
    </QueryClientProvider>
  </LocaleProvider>
);

export default AppProvider;
