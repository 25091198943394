import React, { useContext } from 'react';
import cx from 'classnames';

import styles from './ChangeAddressModal.module.scss';
import ModalHeader, { ModalHeaderProps } from '../../molecules/ModalHeader';
import Button, { ButtonProps } from '../../atoms/Button';
import AddressAutoComplete from '../AddressAutoComplete';
import TextField from '../../molecules/TextField';
import StateDefaultTypeTextArea from '../StateDefaultTypeTextArea';
import { AddressAutoCompleteProps } from '../AddressAutoComplete/AddressAutoComplete';
import { CheckoutContext } from '../../../modules/checkout';
import { OrderType } from '../../../modules/checkout/types';

export const defaultProps = {
  modalHeader: {
    type: 'Close',
    menuItem: {
      type: 'Title4',
      align: 'Left',
      style: 'Grey1100',
    },
    button: {
      size: 'ExtraSmall',
      type: 'Icon',
      style: 'BasicSquare',
      icon: {
        asset: 'Close',
        style: 'Grey1100',
      },
    },
    divider: {
      type: 'HorizontalSolid',
      style: 'Grey400',
    },
  } as ModalHeaderProps,
  button: {
    size: 'Medium',
    type: 'Text',
    style: 'BrandSquare',
    text: {
      type: 'Button1',
      align: 'Center',
      style: 'Grey1100',
    },
  } as ButtonProps,
};

export type ChangeAddressModalProps = {
  modalHeader?: ModalHeaderProps;
  button?: ButtonProps;
  className?: string;
  addressAutoComplete?: AddressAutoCompleteProps;
  addressLine2?: string;
  deliveryInstructions?: string;
};

const ChangeAddressModal: React.FC<ChangeAddressModalProps> = ({
     modalHeader,
     button,
     className,
     addressAutoComplete,
     addressLine2,
     deliveryInstructions,
    }) => {
    const { orderType, basket, useBasketOrderType } = useContext(CheckoutContext);

    let currentOrderType: OrderType;

    if (basket && useBasketOrderType) {
      currentOrderType = basket.orderType;
    } else {
      currentOrderType = orderType;
    }
    return (
        <div className={cx(styles.changeAddressModal, className)}>
            <ModalHeader className={styles.modalHeader} {...modalHeader} />
            <div className={styles.content}>
                <AddressAutoComplete
                    {...addressAutoComplete}
                    className={styles.addressAutoComplete}
                />
                <TextField {...addressLine2} className={styles.addressLine2}/>
                {currentOrderType === 'Delivery' && <StateDefaultTypeTextArea
                    {...deliveryInstructions}
                    className={styles.deliveryInstructions}
                />}
                <div className={styles.buttonContent}>
                    <Button className={styles.button} {...button} />
                </div>
            </div>
        </div>
    )

};

ChangeAddressModal.defaultProps = defaultProps;

export default ChangeAddressModal;
